/**
 * Collection of terms, legalese, information to clean up the presentation layer
 */

import { ROLES } from './codeConstants'

export const DOB_VERIFY = 'Your date of birth is used to verify your age.'
export const MINIMUM_AGE = 'You must be at least 13 years of age to join myFloc.'

export const ADMIN_MINIMUM_AGE =
  'You must be at least 18 years of age to be on a myFloc Card Account as a Primary Cardholder (myFloc Lead) or Secondary Cardholder (myFloc Insider).'

export const PHONE_INFO =
  'If you prefer to receive certain alerts and notifications from myFloc via text message instead of email, please include a cell phone number.'

export const TRANSFER_INFO =
  'You can transfer up to $3,500.00 per transfer, $5,000.00 per day or up to $7,500.00 per month.'

export const SSN_VERIFY = 'Your 9-digit Social Security Number is used to verify your identity.'

export const CARD_RECIPIENT_ADDRESS_ENTRY = (role = ROLES.TEAM_MEMBER) => {
  const displayRole = role === ROLES.TEAM_MEMBER ? 'member' : role
  return `Please note that if you select to send the card to the ${displayRole}, they will be asked to input their address when they accept the invitation.`
}

export const CARD_RESTRICTIONS_INFO =
  'This feature prevents spending at stores categorized by a specific business type (merchant category code). Please note, purchases for items in these categories may be made when they are available at stores that are not categorized by that business type.'

export const CARD_TRANSACTIONS_ACCESS_INFO =
  'This feature controls what the myFloc Team Member will have access to view: either just their own tranactions and card balance, or visibility of the account balance and transactions of all members of the myFloc Team including the primary account.'

export const OPEN_CARD_ACCOUNT_LEGAL =
  'IMPORTANT INFORMATION FOR OPENING A CARD ACCOUNT: To help the federal government fight the funding of terrorism and money laundering activities, the USA PATRIOT Act requires us to obtain, verify, and record information that identifies each person who opens a Card Account. WHAT THIS MEANS FOR YOU: When you open a Card Account, we will ask for your name, address, date of birth, and your government ID number. We may also ask to see your driver’s license or other identifying information. Card activation and identity verification required before you can use the Card Account. If your identity is partially verified, full use of the Card Account will be restricted, but you may be able to use the Card for in-store purchase transactions. Restrictions include: no ATM withdrawals, international transactions, account-to-account transfers and additional loads. Use of Card Account also subject to fraud prevention restrictions at any time, with or without notice. Residents of Vermont are ineligible to open a Card Account.'
