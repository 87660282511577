import React from 'react'

import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
// import Icon from '@/common/components/Icon'
import Loading from '@/common/components/Loading'
import { ROUTES } from '@/common/constants'
import { currency } from '@/common/utils'

/**
 * Factored out balance summary widget
 *
 * @param   {Object}      props
 * @param   {boolean}     [props.addFundsButton]
 * @param   {Object[]}    props.balances
 * @returns {JSX.Element}
 */
export const BalanceSummary = ({ balances = [] }) => {
  if (balances.length < 1) {
    return <Loading />
  }
  else {
    return (
      <div className='flex flex-col bg-white'>
        <div className='flex flex-col md:flex-row md:justify-between'>
          <h3>Card Account Summary</h3>
          <Button className='flat-x' link={ROUTES.PROFILE} arrow primary text>
            View My Profile
          </Button>
        </div>

        {balances.map(balance => (
          <div key={balance.title}>
            <div className='flex flex-row mt-4'>
              {balance.title}
              {/* TODO on hold for now - amrit */}
              {/* <Icon className='ml-1 text-primary' name='info' width={14} /> */}
            </div>
            <div className='text-1-75xl text-secondary'>
              <h2 className='text-secondary'>{currency(balance.amount / 100)}</h2>
            </div>
            {balance.transferFundsButton && (
              <Button className='mt-3 flat-left' link={ROUTES.TRANSFER_FUNDS} arrow primary text>
                Transfer Funds
              </Button>
            )}
          </div>
        ))}
      </div>
    )
  }
}

BalanceSummary.propTypes = {
  addFundsButton: PropTypes.bool,
  balanceLoading: PropTypes.bool,
  balances: PropTypes.arrayOf(PropTypes.object),
}

export default BalanceSummary
