import React from 'react'

const DropdownContext = React.createContext({
  direction: 'down',
  nav: false,
  open: false,
  toggle: () => {},
})

export default DropdownContext
