import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import FormSelect from '@/common/components/FormSelect'
import Loading from '@/common/components/Loading'
import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import TransferDepositLinks from '@/common/components/TransferDepositLinks'
import {
  ACCOUNT_STATUS, ENDPOINTS, HTTP_METHODS, ROUTES, TRANSFER_TYPES,
} from '@/common/constants'
import { useGetBankCard, useBankLink, useSubmitForm } from '@/common/hooks'
import { currency, isEmpty } from '@/common/utils'
import { generateTransferSchema } from '@/common/validations'
import { history } from '@/history'
import { selectMyFlocPerson, setAccounts } from '@/redux'
import { FORMS, saveStep } from '@/redux/formSteps'

const TARGETS = {
  BANK: 'bank',
  MY_FLOC: 'myFloc',
}

const TransferFunds = () => {
  const dispatch = useDispatch()

  const [modalOpen, setModalOpen] = useState(false)
  const myFlocPerson = useSelector(selectMyFlocPerson)

  const { accounts, cardAch } = useGetBankCard({ getAccounts: false })
  const { bankLinkContainer, changeBank, linkBank, status } = useBankLink({
    changeBankDialog: true,
  })
  const { apiErrors, loading, showValidationToast, submitForm } = useSubmitForm()

  // Pre-load values if coming back from review
  let defaultValues = {
    from: TARGETS.BANK,
    to: TARGETS.MY_FLOC,
  }
  const transferForm = useSelector(state => state.formSteps[FORMS.transferFunds])?.[1]
  let defaultAction = TRANSFER_TYPES.WITHDRAWAL
  if (transferForm) {
    defaultValues = {
      amount: transferForm.cents / 100,
      from: transferForm.action === TRANSFER_TYPES.WITHDRAWAL ? TARGETS.BANK : TARGETS.MY_FLOC,
      to: transferForm.action === TRANSFER_TYPES.WITHDRAWAL ? TARGETS.MY_FLOC : TARGETS.BANK,
    }
    defaultAction = transferForm.action
  }
  const [action, setAction] = useState(defaultAction)

  // Get the correct limit based on what is selected
  const maxLimit = accounts?.[`${action.toLowerCase()}Limits`]
    ? accounts[`${action.toLowerCase()}Limits`].max_amount_remaining.amount / 100
    : 5000
  const minLimit = accounts?.[`${action.toLowerCase()}Limits`]
    ? accounts[`${action.toLowerCase()}Limits`].min_amount_allowed.amount / 100
    : 5000
  const transferSchema = generateTransferSchema(maxLimit, minLimit)

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(transferSchema),
  })

  /**
   * Wrapper for getting banks
   *
   * Will open the dialog of [re]linking a bank if needed
   */
  const getAccounts = async () => {
    const {
      data: { accounts },
    } = await submitForm(
      `${ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_BANKS(myFlocPerson.id)}?limits=true`,
      {
        method: HTTP_METHODS.GET,
      }
    )
    openAccountDialog(accounts)
    dispatch(setAccounts(accounts))
  }
  useEffect(() => {
    getAccounts()
  }, [])

  const openAccountDialog = accounts => {
    if (accounts === null || accounts?.status === ACCOUNT_STATUS.AUTHENTICATION_REQUIRED) {
      setModalOpen(true)
    }
  }

  /**
   * Triggers the change bank dialog and will trigger the other dialogs if neccessary
   */
  const changeBankWrapper = async () => {
    const { accounts } = await changeBank()
    openAccountDialog(accounts)
  }

  // [re-]link bank modal props
  let children =
    'You currently do not have a bank account linked, in order to utilize this feature, you must first link a bank account.'
  let successText = 'Link bank account'
  if (accounts?.status === ACCOUNT_STATUS.AUTHENTICATION_REQUIRED) {
    children =
      'For your security, your previously linked bank account is requesting re-authorization to proceed with transfer.'
    successText = 'Re-authorize bank account'
  }

  const openBankLinking = async () => {
    await linkBank(
      accounts?.status === ACCOUNT_STATUS.AUTHENTICATION_REQUIRED
        ? { params: { bankLinkId: accounts.id } }
        : {}
    )
    getAccounts()
  }

  const onSubmit = payload => {
    dispatch(
      saveStep({
        data: {
          action,
          cents: Math.round(payload.amount * 100),
        },
        form: FORMS.transferFunds,
        step: 1,
      })
    )
    history.push(ROUTES.TRANSFER_REVIEW)
  }

  // Ensure the accounts always swap when you change them
  const [from, to] = watch(['from', 'to'])
  useEffect(() => {
    setValue('from', to === TARGETS.BANK ? TARGETS.MY_FLOC : TARGETS.BANK)
    setAction(to === TARGETS.BANK ? TRANSFER_TYPES.DEPOSIT : TRANSFER_TYPES.WITHDRAWAL)
  }, [setValue, to])
  useEffect(() => {
    setValue('to', from === TARGETS.BANK ? TARGETS.MY_FLOC : TARGETS.BANK)
    setAction(from === TARGETS.BANK ? TRANSFER_TYPES.WITHDRAWAL : TRANSFER_TYPES.DEPOSIT)
  }, [from, setValue])

  let accountOptions
  if (accounts && cardAch) {
    accountOptions = (
      <>
        <option value={TARGETS.BANK}>
          {accounts.bank} (...{accounts.accountNumberLast4})
        </option>
        <option value={TARGETS.MY_FLOC}>
          myFloc Card Account (...
          {cardAch.account_number.slice(cardAch.account_number.length - 4)})
        </option>
      </>
    )
  }

  return (
    <>
      <PageHeader>Transfer funds to your myFloc Card Account</PageHeader>
      <div className='relative flex-col text-left main-container'>
        <h4>Set up recurring transfers to maintain funding</h4>

        <div className='mb-10 text-lg'>
          We recommend establishing recurring deposits directly from your bank account to help keep
          your account continuously funded. You can also have other funds such as income from the
          Social Security Administration directly deposited into your myFloc Card Account.
        </div>

        <TransferDepositLinks />

        <div className='my-12 w-full border-t' />

        {bankLinkContainer}

        {/* Confirmation Modal */}
        <Modal
          cancelCallback={history.goBack}
          cancelText='Go back'
          open={modalOpen}
          setOpen={setModalOpen}
          successCallback={openBankLinking}
          successText={successText}
          type={MODAL_TYPES.confirm}
        >
          {children}
        </Modal>

        {accounts && cardAch ? (
          <>
            <h4>Request a one-time electronic bank transfer</h4>

            <div className='text-lg'>
              Set the amount you would like to transfer to your myFloc account from your linked
              external account below:
            </div>

            <form className='mt-12' onSubmit={handleSubmit(onSubmit, showValidationToast)}>
              <FormSelect
                className='min-h-0'
                errors={errors}
                label='From'
                name='from'
                register={register}
                validationErrors={apiErrors?.validationErrors}
                required
              >
                {accountOptions}
              </FormSelect>

              {from === TARGETS.BANK && (
                <Button
                  className='mt-3 flat-left'
                  isLoading={status !== 'closed' || loading}
                  onClick={changeBankWrapper}
                  type='button'
                  primary
                  text
                >
                  Change Bank Account
                </Button>
              )}

              <FormSelect
                className='mt-10 min-h-0'
                errors={errors}
                label='To'
                name='to'
                register={register}
                validationErrors={apiErrors?.validationErrors}
                required
              >
                {accountOptions}
              </FormSelect>

              {to === TARGETS.BANK && (
                <Button
                  className='mt-3 flat-left'
                  isLoading={status === 'loading' || loading}
                  onClick={changeBankWrapper}
                  type='button'
                  primary
                  text
                >
                  Change Bank Account
                </Button>
              )}

              <div className='mt-10 text-lg font-medium'>Fee</div>
              <div className='text-lg'>$0.00</div>

              <FormInput
                className='mt-10'
                errors={errors}
                hintContent={`Today you can transfer up to ${currency(maxLimit)}`}
                label='Transfer Amount'
                name='amount'
                register={register}
                type={FORM_INPUT_TYPES.currency}
                validationErrors={apiErrors?.validationErrors}
                required
              />

              {/* Actions */}
              <div className='flex flex-col-reverse items-center mt-8 md:flex-row md:justify-around md:items-center md:mt-14'>
                <Button
                  className='m-0'
                  disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}
                  isLoading={loading}
                >
                  Review Transfer
                </Button>
              </div>
            </form>
          </>
        ) : (
          <Loading />
        )}

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.transfer}
        />
        <Panel className='mx-auto mt-10 w-5/6 lg:hidden' type={PANEL_TYPES.transfer} />
      </div>
    </>
  )
}

export default TransferFunds
