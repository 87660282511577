import React from 'react'

import { useQuery } from '@/common/hooks'

import JoinTeamMemberAcceptInvite from './JoinTeamMemberAcceptInvite'
import JoinTeamMemberRetryNetspend from './JoinTeamMemberRetryNetspend'

/**
 * Team Member Enrollment form
 */

const JoinTeamMember = () => {
  const query = useQuery()
  const inviteToken = query.get('invite')

  return (
    inviteToken
      ? <JoinTeamMemberAcceptInvite token={inviteToken} />
      : <JoinTeamMemberRetryNetspend />
  )
}

export default JoinTeamMember
