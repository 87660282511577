/**
 * Store for the api /version string
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ENDPOINTS, MYFLOC_API_HOST_URL } from '@/common/constants'
//  import { createCard, getAlertsForToasts } from '@/redux'

export const getApiVersion = createAsyncThunk(
  'user/getApiVersion',
  /**
   * Get API /version endpoint string
   *
   * @returns {Promise<string>}
   */
  async () => {
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.VERSION}`
    const resp = await fetch(url, {
      method: 'GET',
    })
    return await resp.text()
  }
)

const versionSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getApiVersion.fulfilled, (state, action) => {
      state.version = action.payload
    })
    builder.addCase(getApiVersion.rejected, (state, _action) => {
      state.version = ''
    })
  },
  initialState: {
    version: null,
  },
  name: 'version',
  reducers: {},
})

/**
 * Additional Selectors
 */
export const selectApiVersion = state => {
  return state.version.version
}

export default versionSlice.reducer
