import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { HTTP_METHODS, MYFLOC_API_HOST_URL } from '@/common/constants'
import { isRequired, log, removeEmptyNested } from '@/common/utils'
import { history } from '@/history'
import { setLoadingCursor } from '@/redux/loading'
import { store } from '@/redux/store'
import { addToast, TOAST_TYPES } from '@/redux/toasts'

/**
 * Hook for submitting form data
 * Returns the following:
 * - apiErrors: the error object returned from the API, may contain field validations
 * - data: the data returned from the response of the submission
 * - loading: simple loading boolean
 * - submitForm: the method to to trigger the call
 *
 * Instead of tracking a status string, the way it works is you check if the three variables
 * are truthy
 * loading = status pending
 * data = status fulfilled
 * apiErrors = status error
 * You can then use those in a useEffect to perform any side effects you wish
 *
 * @returns {{ apiErrors, data, loading, setApiErrors, setLoading, showValidationToast, submitForm }}
 */
export const useSubmitForm = () => {
  const dispatch = useDispatch()
  const nsSession = useSelector(state => state.netspend.session)

  const [data, setData] = useState(null)
  const [loading, setLoadingState] = useState(false)
  const [apiErrors, setApiErrors] = useState(null)

  /**
   * Handles loading boolean as well as the cursor
   *
   * @param {boolean} payload loading or not
   */
  const setLoading = payload => {
    setLoadingState(payload)
    dispatch(setLoadingCursor(payload))
  }

  /**
   * Make the api call, has been re-purposed for more than just submitting forms
   * TODO: Should eventually factor the API call bit out into its own hook
   *
   * @param   {String}                      endpoint                           from codeConstants/endpoints
   * @param   {Object}                      [options]                          options object since they are mostly optional
   * @param   {Object}                      [options.headers]                  Headers object
   * @param   {Object}                      [options.payload]                  JSON payload
   * @param   {Object}                      [options.manualLoading]            Manually end loading
   * @param   {String}                      [options.method]                   HTTP Method defaults to POST
   * @param   {boolean}                     [options.includeSessionId]         Include the sessionId in the call
   * @param   {boolean}                     [options.noErrorToast]             Disable automatic error toast
   * @param   {Object}                      [options.removeEmptyKeys]          pass false to not remove null or empty strings from payload
   * @param   {Object}                      [options.success]
   * @param   {Object}                      [options.success.toast]            info to show in the success toast
   * @param   {String}                      options.success.toast.title
   * @param   {String}                      options.success.toast.subtitle
   * @param   {Object}                      [options.success.redirect]
   * @param   {Object}                      options.success.redirect.path      path to redirect to
   * @param   {Object}                      [options.success.redirect.replace] use history.replace method
   * @returns {Promise<{ data, response }>}
   */
  const submitForm = async (
    endpoint,
    {
      headers: headersProp = {},
      includeSessionId: includeSessionIdProp = false,
      manualLoading = false,
      method = HTTP_METHODS.POST,
      noErrorToast = false,
      payload = null,
      removeEmptyKeys = true,
      success = {},
    } = {}
  ) => {
    // Include sessionId for all netspend calls except the initial POST to sessions
    const includeSessionId = nsSession && (endpoint.startsWith('/netspend') || includeSessionIdProp)

    setLoading(true)

    try {
      const url = `${MYFLOC_API_HOST_URL}/v1${endpoint}`
      const headers = {
        'Content-Type': 'application/json',
        ...headersProp,
        ...(includeSessionId ? { 'session-id': nsSession.id } : {}),
      }

      const state = store.getState()
      const user = state?.user

      // If user is logged in, add token
      if (user) {
        const auth0Token = localStorage.getItem('auth0Token') || null
        headers.Authorization = `Bearer ${auth0Token}`
      }

      if (payload) {
        // Remove empty strings, null from payload
        if (removeEmptyKeys) payload = removeEmptyNested(payload)

        // Format phone number as digits only
        if (payload.mobile?.length > 0) {
          payload.mobile = payload.mobile.replace(/[^0-9]/g, '')
        }
      }

      const response = await fetch(url, {
        ...(method === 'GET' ? {} : { body: payload ? JSON.stringify(payload) : '' }),
        headers,
        method,
      })

      const data = response.status === 204 ? {} : await response.json()

      if (response.status >= 400) {
        if (data.error?.toast && !noErrorToast) {
          dispatch(
            addToast({
              subtitle: data.error.message || 'An unexpected error occured.',
              title: data.error.title || response.statusText,
              type: TOAST_TYPES.error,
            })
          )
        }
        setApiErrors(data.error)
      }
      else {
        if (success?.toast) {
          dispatch(
            addToast({
              subtitle: success.toast.subtitle,
              title: success.toast.title || 'Success',
              type: TOAST_TYPES.success,
            })
          )
        }
        setData(data)

        if (success?.redirect) {
          history[success.redirect.replace ? 'replace' : 'push'](success.redirect.path)
        }
      }
      return { data, response }
    }
    catch (error) {
      // Handle 500 errors
      dispatch(
        addToast({
          subtitle: 'Please check your connection and try again.',
          title: 'Could not contact the server.',
          type: TOAST_TYPES.error,
        })
      )

      console.error(error)
    }
    finally {
      if (!manualLoading) setLoading(false)
    }
  }

  const showValidationToast = (formErrors, { forceError }) => {
    log(formErrors)
    const requiredValidations = isRequired(formErrors)
    if (requiredValidations || forceError) {
      dispatch(
        addToast({
          subtitle:
            'Please review and make sure you’ve included all required information before proceeding.',
          title: 'Oops, some required details were not entered.',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  return {
    apiErrors, data, loading, setApiErrors, setLoading, showValidationToast, submitForm,
  }
}

export default useSubmitForm
