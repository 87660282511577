import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import FormPassword from '@/common/components/FormPassword'
import FormRadio from '@/common/components/FormRadio'
import HiddenPanel from '@/common/components/HiddenPanel'
import PageHeader from '@/common/components/PageHeader'
import {
  CUSTOM_ERROR_MESSAGES,
  ENDPOINTS,
  EXTERNAL_URLS,
  MINIMUM_AGE,
  OPEN_CARD_ACCOUNT_LEGAL,
  PHONE_INFO,
  ROLES,
  ROUTES,
} from '@/common/constants'
import {
  useAuthentication, useFormFieldTracking, useParseApiErrors, usePassword, useQuery,
} from '@/common/hooks'
import useSubmitForm from '@/common/hooks/useSubmitForm'
import useValidateToken from '@/common/hooks/useValidateToken'
import { isEmpty } from '@/common/utils'
import { friendEnrollmentSchema } from '@/common/validations'
import { history } from '@/history'
import { updateAndSwitchUser } from '@/redux'
import AnimateIn from '@components/AnimateIn'
import Button, { SIGN_TYPES } from '@components/Button'
import FormCheckbox from '@components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'
import Loading from '@components/Loading'
import Panel, { PANEL_TYPES } from '@components/Panel'

/**
 * Friend enrollment form
 *
 * param {String} query.invite token will be passed in through the query parameters
 */
const JoinFriend = () => {
  const auth0State = useAuth0()
  const {
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
  } = auth0State
  const dispatch = useDispatch()
  const query = useQuery()
  const token = query.get('invite')

  const [isRedirecting, setIsRedirecting] = useState(false)
  const [showPhoneInfo, setShowPhoneInfo] = useState(false)
  const [showBdayInfo, setShowBdayInfo] = useState(false)
  const [showDisplayName, setDisplayName] = useState(false)

  const { passwordErrors, passwordSchema, validatePassword } = usePassword()
  const { passwordErrors: password2Errors, validatePassword: validatePassword2 } = usePassword()
  const { decoded, loading: loadingValidateToken, existingUser } = useValidateToken({ token })
  const {
    apiErrors,
    loading,
    setApiErrors,
    setLoading,
    showValidationToast,
    submitForm,
  } = useSubmitForm()
  const { enrollmentLogin } = useAuthentication()

  const loadFieldsForNewUser = !existingUser?.user

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      loadFieldsForNewUser
        ? friendEnrollmentSchema
          .concat(passwordSchema)
        : friendEnrollmentSchema
    ),
  })

  const { reset: resetTracking, updatedFields: updatedFieldsSinceFormSubmit } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: updatedFieldsSinceFormSubmit })

  const onSubmit = async payload => {
    resetTracking()
    const resp = await submitForm(ENDPOINTS.ACCEPT_FRIEND, {
      manualLoading: true,
      method: 'PUT',
      payload: { ...payload, token },
    })
    if (resp.response && resp.response.status === 201) {
      dispatch(updateAndSwitchUser(resp.data))
    }
    else {
      localStorage.removeItem('activePersonId')
    }

    if (!auth0Authenticated) {
      await enrollmentLogin(resp)
      setLoading(false)
      history.replace(ROUTES.DASHBOARD)
    }
    else {
      window.location.href = '/'
      setLoading(false)
    }
  }

  const isLegalAge = watch('isLegalAge')
  const isLegalAgeRadioOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  // Pre-fill data from token
  useEffect(() => {
    try {
      if (!decoded) return

      if (decoded.role !== ROLES.FRIEND) {
        history.replace(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.INVITATION_EXPIRED))
        return
      }
      let firstName = decoded?.firstName || ''
      let lastName = decoded?.lastName || ''
      if (existingUser?.user) {
        if (!auth0Authenticated && !isRedirecting && !auth0Loading && !loadingValidateToken) {
          setIsRedirecting(true)
          loginWithRedirect({ appState: { returnTo: `${window.location.pathname}${window.location.search}` } })
        }
        firstName = existingUser.user.firstName
        lastName = existingUser.user.lastName
      }

      setValue('email', existingUser?.user?.email || decoded.email, { shouldValidate: false })
      setValue('firstName', firstName, { shouldValidate: false })
      setValue('lastName', lastName, { shouldValidate: false })
      if (existingUser) {
        setValue('isLegalAge', true)
      }
    }
    catch (e) {
      history.replace(ROUTES.ERROR_ENROLLMENT)
    }
  }, [decoded, setValue, isRedirecting, auth0Authenticated, existingUser, auth0Loading, loadingValidateToken])

  // if legal age updates, trigger validation on field
  useEffect(() => {
    if (!isLegalAge) return

    trigger('isLegalAge')
  }, [isLegalAge])

  if (!decoded || loadingValidateToken || auth0Loading || isRedirecting) {
    return (
      <div className='relative flex-col text-left main-container'>
        <Loading className='min-h-screen' />
      </div>
    )
  }

  const teamName = existingUser?.teamName || 'myFloc'
  return (
    <>
      <PageHeader>Enter your personal details</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <form onSubmit={handleSubmit(onSubmit, showValidationToast)}>
          <div className='text-sm'>*Required field</div>
          <div className='mt-1 text-lg'>Please enter the following information to join {teamName}</div>

          {/* Personal Info */}
          <div className='flex flex-row justify-between mt-6'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
          </div>
          <AnimateIn maxHeight={121} show={showDisplayName}>
            <FormInput
              errors={errors}
              label='Preferred First Name (Optional)'
              name='displayName'
              register={register}
              validationErrors={apiValidationErrors}
            />
          </AnimateIn>

          <Button
            className='mb-7 flat-left'
            onClick={() => setDisplayName(!showDisplayName)}
            sign={showDisplayName ? SIGN_TYPES.minus : SIGN_TYPES.plus}
            type='button'
            primary
            text
          >
            {showDisplayName ? 'Hide Preferred First Name' : 'Add Preferred First Name (Optional)'}
          </Button>

          <FormInput
            autoComplete='email'
            className='mb-7'
            hintContent='You will use this email address when logging in to myFloc. Email address may be updated from My Profile.'
            label='Email Address'
            name='email'
            register={register}
            type={FORM_INPUT_TYPES.email}
            disabled
            required
          />

          <div style={loadFieldsForNewUser ? {} : { display: 'none', visibility: 'hidden' }}>
            <FormInput
              autoComplete='tel'
              errors={errors}
              inputClass='w-60'
              label='Cell Phone Number (optional)'
              labelButtonAction={() => setShowPhoneInfo(true)}
              labelButtonText='Why Provide This?'
              name='mobile'
              register={register}
              type={FORM_INPUT_TYPES.phone}
              validationErrors={apiValidationErrors}
            />
            <HiddenPanel setShow={setShowPhoneInfo} show={showPhoneInfo}>
              {PHONE_INFO}
            </HiddenPanel>
            <FormCheckbox
              className='mb-0'
              id='joinFriendSmsConsent'
              name='smsConsent'
              register={register}
              subtitle='Phone provider message and data rates may apply.'
              title='Allow Text Message Notifications'
              plain
            />
          </div>

          {
            loadFieldsForNewUser && (<>
              <FormRadio
                errors={errors}
                label='Are you 13 or older?'
                labelButtonAction={() => setShowBdayInfo(true)}
                labelButtonText='Why Do We Need This?'
                name='isLegalAge'
                onChange={() => setApiErrors({ ...apiValidationErrors, isLegalAge: undefined })}
                options={isLegalAgeRadioOptions}
                register={register}
                validationErrors={apiValidationErrors}
                circular
                // required
              />

              <HiddenPanel setShow={setShowBdayInfo} show={showBdayInfo}>
                {MINIMUM_AGE}
              </HiddenPanel>
            </>)
          }

          <div className='mb-8'></div>

          {
            loadFieldsForNewUser && (<>
              <FormPassword
                apiErrors={{ validationErrors: apiValidationErrors }}
                errors={errors}
                passwordErrors={passwordErrors}
                register={register}
                validatePassword={validatePassword}
              />
              <FormPassword
                apiErrors={{ validationErrors: apiValidationErrors }}
                controlName='password2'
                errors={errors}
                passwordErrors={password2Errors}
                register={register}
                validatePassword={validatePassword2}
              />
            </>)
          }

          <div className='mt-7 w-full border-t' />

          <h3 className='mt-7'>By continuing your enrollment, you agree to:</h3>
          <div className='mt-4 font-bold'>
            myFloc
            <Button
              className='font-bold'
              onClick={() => window.open(EXTERNAL_URLS.TERMS_AND_CONDITIONS, '_blank')}
              type='button'
              primary
              text
            >
              Terms and Conditions
            </Button>
            and
            <Button
              className='font-bold flat-right'
              onClick={() => window.open(EXTERNAL_URLS.PRIVACY_POLICY, '_blank')}
              type='button'
              primary
              text
            >
              Privacy Policy
            </Button>
            .
          </div>

          <div className='flex flex-row justify-center items-center mt-9'>
            <Button
              disabled={
                !isEmpty(errors) ||
                !isEmpty(apiValidationErrors) ||
                isLegalAge === 'false'
              }
              isLoading={loading}
            >
              Continue
            </Button>
          </div>
        </form>

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.info}
        />
      </div>

      {/* Bottom Legal */}
      <div className='mx-auto mb-12 max-w-5xl'>
        <div className='flex flex-row'>
          <div>1.</div>
          <div className='ml-2'>{OPEN_CARD_ACCOUNT_LEGAL}</div>
        </div>

        <Panel className='mx-auto mt-10 lg:hidden' type={PANEL_TYPES.info} />
      </div>
    </>
  )
}

JoinFriend.propTypes = {
  loadPasswordField: PropTypes.bool,
}

export default JoinFriend
