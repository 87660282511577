import React from 'react'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Loading from '@/common/components/Loading'
import { ROUTES } from '@/common/constants'
import { history } from '@/history'
import { createSession } from '@/redux'
import { updateAndSwitchTeam } from '@/redux/user'

import { TeamCard } from './TeamCard/TeamCard'

export const BuildListTeam = ({
  teamList,
  rolesToNumbersMap,
}) => {
  const dispatch = useDispatch()
  const teamsCard = Object.values(teamList)
    .sort((a, b) => (
      rolesToNumbersMap[a.role] - rolesToNumbersMap[b.role]
    )).map(person => {
      const handleSelect = e => {
        dispatch(updateAndSwitchTeam(person))
        dispatch(createSession())
        history.push(ROUTES.DASHBOARD)
      }
      return (
        <TeamCard
          handleSelect={handleSelect}
          key={person.id}
          member={person}
        />
      )
    })

  return teamList ? (
    <div className='max-w-5xl bg-white rounded mx-auto p-8 px-5 md:px-8 mt-8 whitespace-pre-wrap'>
      <div className='flex-row md:flex md:flex-col' key='teamList'>
        {teamsCard}
      </div>
    </div>
  ) : (
    <Loading />
  )
}

BuildListTeam.propTypes = {
  /**
   * Map of roles to numbers
   */
  rolesToNumbersMap: PropTypes.object,

  /**
   * All team members for this section sorted by role
   */
  teamList: PropTypes.array,
}

export default BuildListTeam
