import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import { ENDPOINTS, ROUTES } from '@/common/constants'
import useSubmitForm from '@/common/hooks/useSubmitForm'
import { isEmpty } from '@/common/utils'
import { otpSchema } from '@/common/validations/user'
import { history } from '@/history'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { getPersonStatus, updateUser } from '@/redux/user'
import Button from '@components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'

const VerifyEmail = props => {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)

  const { apiErrors, loading, setApiErrors, submitForm } = useSubmitForm()

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver: yupResolver(otpSchema) })

  const user = userState.user

  const requestNewOTP = () => {
    submitForm(ENDPOINTS.RESEND_OTP, {
      payload: {
        userId: user.id,
      },
      success: {
        toast: {
          subtitle: 'Please check your email for a new verification code.',
          title: 'Success',
        },
      },
    })
  }

  const onSubmit = async ({ otp }) => {
    const resp = await submitForm(ENDPOINTS.VERIFY_OTP, {
      payload: {
        otp,
        userId: user.id,
      },
      success: {
        toast: {
          subtitle: 'Your email has been verified.',
          title: 'Success',
        },
      },
    })

    if (resp.response.status < 400) {
      await dispatch(updateUser({ status: resp.data.status }))
      await dispatch(getPersonStatus())
      history.replace(ROUTES.DASHBOARD)
    }
  }

  // For clearing the validation errors
  const onFocus = () => {
    setApiErrors(null)
  }

  // Show the toast when we have an error
  const onError = formErrors => {
    if (formErrors.otp?.type === 'required') {
      dispatch(
        addToast({
          subtitle:
            'Please review and make sure you’ve included all required information before proceeding.',
          title: 'Oops, some required details were not entered.',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  return (
    <div className='relative'>
      <PageHeader stepText='Step 2 of 2'>Verify your email address</PageHeader>

      {/* Info */}
      <div className='relative flex-col text-left main-container'>
        <div className='text-lg'>
          For your security, we have emailed a verification code to{' '}
          <span className='font-medium break-words text-secondary'>{user?.email || 'Loading'}</span>
          .
        </div>

        {/* Form */}
        <div className='mt-9 text-center'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <FormInput
              errors={errors}
              label='Enter your 6-digit verification code.'
              name='otp'
              onFocus={onFocus}
              register={register}
              type={FORM_INPUT_TYPES.otp}
              validationErrors={apiErrors?.validationErrors}
            />
            <div className='text-left mt-15'>
              <div>
                <span className='font-medium'>Didn't receive it?</span> Be sure to check your
                junk/spam folder.
              </div>
              <Button
                className='pl-0 ml-0 border-l-0'
                isLoading={loading}
                onClick={requestNewOTP}
                type='button'
                primary
                text
              >
                Request a New Verification Code
              </Button>
            </div>

            {/* Actions */}
            <div className='flex flex-row justify-center items-center mt-7'>
              {/* eslint-disable-next-line react/jsx-handler-names */}
              <Button className='mr-8' onClick={history.goBack} type='button' primary text>
                Go Back
              </Button>
              <Button
                disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}
                isLoading={loading}
              >
                Submit
              </Button>
            </div>
          </form>

          <Panel
            className='mx-auto mt-11 lg:absolute lg:top-20 lg:-left-3/4'
            type={PANEL_TYPES.info}
          />

          {/* Spacer for web */}
          <div className='hidden mt-36 lg:block' />
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
