/* eslint-disable no-useless-escape */
/**
 * Collection of different formatters
 */

/**
 * Converts camelCase strings to Sentence Case
 *
 * @param   {string} text
 * @returns {string}
 */
export const camelToSentence = text => {
  if (typeof text !== 'string') {
    return text
  }
  const spaces = text.replace(/([A-Z])/g, ' $1')
  return spaces.charAt(0)
    .toUpperCase() + spaces.slice(1)
}

/**
 * Simple currency format with thousand separator
 *
 * @param {number}  amount            The amount
 * @param {boolean} [decimalIfNeeded] Only show the decimal places if they are required
 */
export const currency = (amount = 0, decimalIfNeeded = false) =>
  '$' +
  amount
    .toFixed(decimalIfNeeded && amount % 1 === 0 ? 0 : 2)
    .toString()
    .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')

/**
 * Mask sensitive numbers
 *
 * @param {string} sensitive
 * @param {number} [showLast]      the last n digits
 * @param {string} [maskCharacter] the character to use for the mask
 */
export const maskSensitive = (sensitive, showLast = 0, maskCharacter = '•') =>
  // eslint-disable-next-line prefer-regex-literals
  sensitive.replace(new RegExp(`\\w(?=(?:\\W*\\w){${showLast}})`, 'g'), maskCharacter)
