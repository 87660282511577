import React from 'react'

import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
import { ROUTES } from '@/common/constants'
import { history } from '@/history'

const ReplaceComplete = ({ cardPersonId }) => {
  return (
    <>
      <div className='h2'>The new card is on the way!</div>
      <p>The new card will be delivered in 7-10 business days. </p>
      <p>If the card was lost or stolen please be sure to check card purchases
        carefully for any purchases that are not authorized.
        A dispute may be filed for any unauthorized purchases. </p>
      <div className='flex flex-col items-center mt-9'>
        <Button className='mt-6'
          onClick={() => history.push(ROUTES.ACCOUNT_PERSON(cardPersonId))} type='button' >
          View Transactions
        </Button>
        <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
          Back to Summary
        </Button>
      </div>
    </>
  )
}

ReplaceComplete.propTypes = {
  cardPersonId: PropTypes.string,
}

export default ReplaceComplete
