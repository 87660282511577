import React from 'react'

import { useQuery } from '@/common/hooks'

import JoinInsiderAcceptInvite from './JoinInsiderAcceptInvite'
import JoinInsiderRetryNetspend from './JoinInsiderRetryNetspend'

/**
 * Insider Enrollment form
 */
const JoinInsider = () => {
  const query = useQuery()
  const inviteToken = query.get('invite')

  return (
    inviteToken
      ? <JoinInsiderAcceptInvite token={inviteToken} />
      : <JoinInsiderRetryNetspend />
  )
}

export default JoinInsider
