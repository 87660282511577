import Panel, { PANEL_TYPES } from '@components/Panel'

const EnrollmentSecurityPanel = () => {
  return (
    <Panel
      className='mx-auto mt-16 lg:block lg:absolute lg:top-20 lg:-left-3/4 lg:mt-11'
      type={PANEL_TYPES.info}
    />
  )
}

export default EnrollmentSecurityPanel
