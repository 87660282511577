import React, { useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import { HTTP_METHODS, ENDPOINTS, ROUTES } from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { setPin } from '@/common/validations'
import { history } from '@/history'
import { selectMyFlocPerson, getMyFlocPerson } from '@/redux/user'

const SetPin = ({ verifyResult, onComplete }) => {
  const activePerson = useSelector(selectMyFlocPerson)
  const dispatch = useDispatch()
  // ---------------------------------------------------------------------------
  // FORM HANDLING
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(setPin) })

  const { apiErrors, loading, submitForm, setApiErrors } = useSubmitForm()

  const pin = watch('pin')
  const [showVerify, setShowVerify] = useState(false)

  function resetForm () {
    setValue('pin', '')
    setShowVerify(false)
  }

  // Cancel card and re-order
  const onSubmit = async formEntry => {
    // Move to manual verification before submissions
    if (!showVerify) {
      setShowVerify(true)

      return
    }

    // Change pin on card
    const encryptedData = await window.NetspendSDK.session.encrypt({
      pin: formEntry.pin,
    })

    const payload = {
      encryptedData,
      verificationResult: verifyResult,
    }

    const result = await submitForm(
      ENDPOINTS.NETSPEND_CARD_PIN(activePerson.id),
      { method: HTTP_METHODS.PUT, noErrorToast: true, payload }
    )

    if (result.response.status < 400) {
      dispatch(getMyFlocPerson())

      onComplete()
    }
    else if (result?.data?.error?.validationErrors) {
      setShowVerify(false)
      setError('pin') // Map validation errors to form
    }
    else {
      history.push(ROUTES.ERROR_CUSTOM('Error setting the card PIN.'))
    }
  }

  useEffect(() => {
    setApiErrors(null)
  }, [pin])

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      {!showVerify && (
        <>
          <FormInput
            errors={errors}
            label='Enter a new 4-digit card pin.'
            name='pin'
            // onFocus={onFocus}
            register={register}
            type={FORM_INPUT_TYPES.pin}
            validationErrors={apiErrors?.validationErrors}
          />
          <div className='flex flex-col items-center'>
            <Button disabled={false} isLoading={loading}>Continue</Button>
            <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
              Cancel
            </Button>
          </div>
        </>
      )}
      {showVerify && (
        <>
          <div className='text-xl text-center'>
            <label className='mt-4 mb-2 text-xl'>
              Please confirm your selected PIN:
            </label>
            <div className='text-6xl'>{pin}</div>
          </div>
          <div className='flex flex-col items-center mt-3'>
            <Button disabled={false} isLoading={loading}>Set Pin</Button>
            <Button className='mt-6' onClick={resetForm} type='button' primary text>
              Change Pin
            </Button>
          </div>
        </>
      )}
    </form >)
}

SetPin.propTypes = {
  onComplete: PropTypes.func,
  verifyResult: PropTypes.string,
}

export default SetPin
