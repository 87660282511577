/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup'

import { YES_OR_NO } from '@/common/constants'

const reqStr = 'This information is required.'

export const otpSchema = yup.object()
  .shape({
    otp: yup
      .string()
      .required(reqStr)
      .min(6, 'Verification code must be at least 6 digits'),
  })

export const confirmEmailSchema = yup.object({
  // typo is intentional. field name cannot contain "email" or autocomplete may update it
  confirmEmeil: yup
    .string()
    .required('This field is required.')
    .test(
      'matchesEmail',
      'Must match email address.',
      (value, context) => value.toLowerCase() === context.parent.email?.toLowerCase?.()
    ),
})

export const friendEnrollmentSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    displayName: yup
      .string()
      .max(350)
      .label('Preferred first name'),
    // email: yup
    //   .string()
    //   .required(reqStr)
    //   .email()
    //   .min(5)
    //   .max(350)
    //   .label('Email'),
    textMessage: yup.boolean(),
    isLegalAge: yup
      .boolean()
      .required(reqStr)
      .label('Date of birth')
      .nullable()
      .test(
        'is at least 13 years old',
        'You must be 13 years old to join myFloc.',
        value => value === true
      ),
    mobile: yup
      .string()
      .matches(/^(?:\(\d{3}\)\s\d{3}\s\d{4})?$/, 'Phone number is invalid, must be 10 digits'),
  })

export const friendInvitationSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    displayName: yup
      .string()
      .max(350)
      .label('Nickname'),
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
  })

export const leadInvitationSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
  })

export const insiderInvitationSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    displayName: yup
      .string()
      .max(350)
      .label('Nickname'),
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
    shipToMember: yup
      .boolean()
      .required(reqStr)
      .typeError(reqStr),
  })

export const memberInvitationSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(1)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    displayName: yup
      .string()
      .max(350)
      .label('Nickname'),
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
    shipToMember: yup
      .boolean()
      .nullable()
      .required(reqStr),
  })
  .concat(confirmEmailSchema)

export const memberRestrictionsFormSchema = yup.object()
  .shape({
    // Transaction viewing access
    showAllTransactions: yup
      .string()
      .nullable()
      .required(reqStr),
    // Spending Limits
    limitSpending: yup
      .string()
      .nullable()
      .required(reqStr),
    spendingLimitPeriod: yup
      .string()
      .nullable()
      .when('limitSpending', {
        is: spend => spend === YES_OR_NO.YES,
        then: yup
          .string()
          .nullable()
          .required(reqStr),
      }),
    spendingLimit: yup
      .mixed()
      .nullable()
      .when('limitSpending', {
        is: spend => spend === YES_OR_NO.YES,
        then: yup
          .number()
          .min(0, 'You must specify a dollar value greater than $0.00')
          .max(100000, 'Maximum spending limit is $100 000.00')
          .typeError('You must specify a dollar value greater than $0.00')
          .nullable(),
      }),
    // MCC restrictions
    restrictMerchants: yup
      .string()
      .nullable()
      .required(reqStr),
    locations: yup
      .object()
      .shape({})
      .nullable()
      .when('restrictMerchants', {
        is: restrictMerchants => restrictMerchants === YES_OR_NO.YES,
        then: yup
          .object()
          .shape({
            dating: yup.mixed()
              .transform(val => !!val),
            gambling: yup.mixed()
              .transform(val => !!val),
            pawnshop: yup.mixed()
              .transform(val => !!val),
          })
          .test(
            'hasRestrictedAtLeastOneLocation',
            'Please select at least one location to restrict',
            locations => {
              if (locations.dating || locations.gambling || locations.pawnshop) return true

              throw new yup.ValidationError(
                'Please select at least one location to restrict',
                null,
                'locations'
              )
            }
          ),
      }),
  })

export const addressSchema = yup
  .object()
  .required()
  .shape({
    line1: yup
      .string()
      .required(reqStr)
      .matches(
        /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
        'Physical addresses only (no P.O. Boxes allowed)'
      )
      .label('Address'),
    line2: yup
      .string()
      .matches(
        /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
        'Physical addresses only (no P.O. Boxes allowed)'
      ),
    city: yup
      .string()
      .required(reqStr)
      .label('City'),
    region: yup
      .string()
      .required(reqStr)
      .length(2)
      .label('State'),
    postal: yup
      .string()
      .required(reqStr)
      .matches(/^[0-9]+$/, 'Must be only digits')
      .length(5, 'Zip code must be exactly 5 digits')
      .label('Zip code'),
  })

export const cardAccountSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    middleName: yup
      .string()
      .max(350)
      .nullable()
      .label('Middle name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    residenceAddress: addressSchema,
    dob: yup
      .string()
      .required(reqStr)
      .matches(
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/([1-2][9|0-2][\d][\d])$/,
        'Please ensure your Date of Birth follows the format MM/DD/YYYY'
      )
      .test('isOldEnough', 'Must be at least 18 years old.', value => {
        const now = new Date()
        now.setFullYear(now.getFullYear() - 18)
        return new Date(value) < now
      })
      .label('Date of Birth'),
    social: yup
      .string()
      .required(reqStr)
      .matches(
        /^(?!(000|666|9))(\d{3}\s?(?!(00))\d{2}\s?(?!(0000))\d{4})$/,
        'Please ensure your Social Security Number is correct'
      )
      .label('Social Security Number'),
  })

export const joinLeadSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .matches(/^[a-zA-Z\s]*$/, 'First name must contain only letters')
      .label('First name'),
    middleName: yup
      .string()
      .max(350)
      .label('Middle name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .matches(/^[a-zA-Z\s]*$/, 'Last name must contain only letters')
      .label('Last name'),
    email: yup
      .string()
      .required(reqStr)
      .min(5)
      .max(350)
      .matches(
        // email regex from https://emailregex.com/
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address format.'
      )
      .label('Email address'),
    mobile: yup
      .string()
      .matches(/^(?:\(\d{3}\)\s\d{3}\s\d{4})?$/, 'Phone number is invalid, must be 10 digits'),
    smsConsent: yup
      .boolean(),
    password: yup
      .string()
      .required(reqStr)
      .min(8)
      .max(350)
      .label('Password'),
    password2: yup
      .string()
      .required(reqStr)
      .oneOf([yup.ref('password'), null], 'Your password and confirmation password do not match. Please enter them again.')
      .label('Confirm password'),
  })

export const joinInsiderSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    middleName: yup
      .string()
      .max(350)
      .label('Middle name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    residenceAddress: addressSchema,
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
    dob: yup
      .string()
      .required(reqStr)
      .matches(
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/([1-2][9|0-2][\d][\d])$/,
        'Please ensure your Date of Birth follows the format MM/DD/YYYY'
      )
      .test('isOldEnough', 'Must be at least 18 years old.', value => {
        const now = new Date()
        now.setFullYear(now.getFullYear() - 18)
        return new Date(value) < now
      })
      .label('Date of Birth'),
    social: yup
      .string()
      .required(reqStr)
      .matches(
        /^(?!(000|666|9))(\d{3}\s?(?!(00))\d{2}\s?(?!(0000))\d{4})$/,
        'Please ensure your Social Security Number is correct'
      )
      .label('Social Security Number'),
    mobile: yup
      .string()
      .matches(/^(?:\(\d{3}\)\s\d{3}\s\d{4})?$/, 'Phone number is invalid, must be 10 digits'),
    smsConsent: yup.boolean(),
  })

export const joinTeamMemberSchema = yup.object()
  .shape({
    firstName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('First name'),
    lastName: yup
      .string()
      .required(reqStr)
      .min(2)
      .max(350)
      .label('Last name'),
    displayName: yup
      .string()
      .max(350)
      .label('Preferred first name'),
    residenceAddress: addressSchema,
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
    dob: yup
      .string()
      .required(reqStr)
      .matches(
        /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/([1-2][9|0-2][\d][\d])$/,
        'Please ensure your Date of Birth follows the format MM/DD/YYYY'
      )
      .test('isOldEnough', 'Must be at least 13 years old.', value => {
        const now = new Date()
        now.setFullYear(now.getFullYear() - 13)
        return new Date(value) < now
      })
      .label('Date of Birth'),
    mobile: yup
      .string()
      .matches(/^(?:\(\d{3}\)\s\d{3}\s\d{4})?$/, 'Phone number is invalid, must be 10 digits'),
    smsConsent: yup.boolean(),
  })

export const profileSchema = yup.object()
  .shape({
    displayName: yup
      .string()
      .max(350)
      .nullable()
      .label('Preferred name'),
    email: yup
      .string()
      .required(reqStr)
      .email()
      .min(5)
      .max(350)
      .label('Email address'),
    mobile: yup
      .string()
      .optional()
      .nullable()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s?.-]?\d{3}[\s?.-]?\d{4}$/, {
        message: 'Phone number is invalid, must be 10 digits',
        excludeEmptyString: true,
      }),
    smsConsent: yup.boolean()
      .when('mobile', {
        is: mobile => mobile.length > 0,
        then: yup.boolean()
          .required('SMS consent is required if mobile number entered'),
      }),
  })

export const replaceCard = yup.object()
  .shape({
    reason: yup.string()
      .nullable()
      .required(reqStr),
    needsAddress: yup.boolean()
      .default(false),
    acceptAddress: yup
      .string()
      .nullable()
      .default(YES_OR_NO.YES)
      .when('needsAddress', {
        is: needsAddress => needsAddress,
        then: yup.string().nullable().required(reqStr),
      }),
    address: yup.object()
      .default(null)
      .nullable()
      .when('acceptAddress', {
        is: acceptAddress => acceptAddress === YES_OR_NO.NO,
        then: addressSchema,
      }),
  })

export const verifyCard = yup.object()
  .shape({
    cvc: yup.string()
      .required(reqStr)
      .min(3, 'CVC Must be 3 digits'),
  })

export const setPin = yup.object()
  .shape({
    pin: yup.string()
      .required(reqStr)
      .min(4, 'Pin Must be 4 digits'),
  })
