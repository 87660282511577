import React, { useEffect, useState } from 'react'

import classnames from 'classnames'
import { useSelector } from 'react-redux'

import Dropdown from '@/common/components/Dropdown'
import Icon from '@/common/components/Icon'
import Loading from '@/common/components/Loading'
import { ROUTES, ENDPOINTS, NETSPEND_ERROR_MESSAGES } from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { formatDate, subtractMonths } from '@/common/utils/dates'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux/user'

/**
 * Statements view screen
 *
 */
const Statements = props => {
  // Start management for start/end date, statementList, and statementOrder
  const [statementList, setStatementList] = useState(null)
  const [statementOrder, setStatementOrder] = useState('descending')

  const { submitForm, loading } = useSubmitForm()

  const myFlocPerson = useSelector(selectMyFlocPerson)

  // Container classes for the page
  const baseClasses = 'max-w-5xl container bg-white rounded mx-auto p-6'
  const classes = {
    wrapper: classnames(baseClasses),
  }

  // Build order options dropdown
  const statementOrderOptions = [
    {
      selected: true,
      text: 'View Most Recent',
      value: 'descending',
    },
    {
      text: 'View Oldest',
      value: 'ascending',
    },
  ]

  // ---------------------------------------------------------------------------
  // STATEMENT HANDLING

  // Reverse order of statements
  const onOrderChange = orderValue => {
    if (orderValue !== statementOrder) {
      setStatementOrder(orderValue)
      const newStatementList = [...statementList]
      newStatementList.reverse()
      setStatementList(newStatementList)
    }
  }

  // Convert string in format YYYY-MM to javascript date
  function dateFromPeriod (period) {
    return new Date(parseInt(period.split('-')[0], 10), parseInt(period.split('-')[1], 10) - 1)
  }

  // Query netspend for all the statement URLS
  const getStatements = async () => {
    // ---------------------------------------------------------------------------
    // Get the list of periods for possible statements
    const resp = await submitForm(ENDPOINTS.NETSPEND_STATEMENT_PERIODS(myFlocPerson.id), {
      method: 'GET',
      payload: {
        test: true,
      },
    })

    if (resp.response.status >= 400) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.NO_CONNECTION))
    }
    if (resp.response.status === 204) {
      setStatementList([])
      return
    }

    const dates = {
      end: dateFromPeriod(resp.data.endPeriod),
      start: dateFromPeriod(resp.data.startPeriod),
    }

    // ---------------------------------------------------------------------------
    // Process end periods into a period range
    const timeValues = []

    if (resp.data.endPeriod === resp.data.startPeriod) {
      timeValues.push(formatDate(dates.start, 'mm/dd/yyyy'))
    }
    else {
      const startCheck = dates.start
      let endCheck = dates.end
      while (endCheck.getTime() >= startCheck.getTime()) {
        timeValues.push(formatDate(endCheck, 'mm/dd/yyyy'))
        endCheck = subtractMonths(endCheck, 1)
      }
    }

    // ---------------------------------------------------------------------------
    // Get the urls for all periods in the range
    const urlCalls = timeValues.map(periodData => {
      const apiRoute = ENDPOINTS.NETSPEND_STATEMENT(myFlocPerson.id, formatDate(periodData, 'yyyy-mm'))
      return submitForm(apiRoute, { method: 'GET' })
    })
    const results = await Promise.all(urlCalls)

    // Map all results to the statementList
    results.forEach(result => {
      if (result.response.status >= 400) {
        // Redirect to error if URLS cannot be fetched.
        history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.NO_CONNECTION))
      }
    })

    const statementListData = results.map((result, idx) => {
      return {
        date: formatDate(timeValues[idx], 'mmmm yyyy'),
        url: result.data?.url,
      }
    })

    setStatementList(statementListData)
  }

  // Get statement list on load
  useEffect(() => {
    getStatements()
  }, [])

  return loading || isNaN(statementList?.length) ? (
    <Loading />
  ) : (
    <div className={classes.wrapper}>
      <div className='flex flex-col'>
        <h1 className='text-secondary'>Statements</h1>
        {statementList?.length ? (
          <Dropdown
            className='mt-12'
            label='Most Recent'
            onChange={option => onOrderChange(option.value)}
            options={statementOrderOptions}
            value={statementOrder}
          />
        ) : (
          ''
        )}
        {statementList?.length ? (
          <div className={'max-w-full md:max-w-xl pl-12 pb-4 mt-12 border-b border-gray-500'}>
            Date
          </div>
        ) : (
          <h4 className='mt-3'>There are currently have no statements to display.</h4>
        )}
        {statementList?.map((statement, index) => (
          <div
            className={'border-b border-gray-500 max-w-full md:max-w-xl mt-1 mb-1 pb-1 inline-flex'}
            key={statement.date}
          >
            <Icon name='statement' width={16} />
            <a
              className='pt-4 pl-4 cursor-pointer text-secondary h3'
              href={statement.url}
              rel='noreferrer' target='_blank'
            >
              {statement.date}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Statements
