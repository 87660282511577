import React from 'react'

import { useLocation } from 'react-router'

import Button from '@/common/components/Button'
import Error from '@/common/components/Error'
import {
  MYFLOC_PHONE_NUMBER,
  MYFLOC_PHONE_NUMBER_TEXT,
  ENV_OPTIONS,
  ROUTES,
} from '@/common/constants'
import { useQuery } from '@/common/hooks'
import { history } from '@/history'

/**
 * The Error Page
 *
 * Sorts through the error types and returns the appropriate page
 * For most generic usage use custom parameter
 * Use **for pink text**
 * ex: /error?custom=This text is mostly blue except this **pink text.**
 * Add query tryAgain=true for the try again button
 */
const ErrorScreen = () => {
  let title
  const location = useLocation()
  const query = useQuery()
  const tryAgain = query.get('tryAgain')
  const tryAgainHard = query.get('tryAgainHard')
  const link = query.get('link')
  const linkName = query.get('linkName')

  // Sort through error types to show appropriate message
  const message = query.get('message')
  if (location.pathname === ROUTES.ERROR_ENROLLMENT) {
    title = (
      <>
        <span className='text-accent'>We are sorry.</span> Your enrollment cannot be completed
        online.
      </>
    )
  }
  // Custom error reversed
  // /\*\*([^\*]+)\*\*/g
  else if (query.get('custom')) {
    const customArr = query.get('custom')
      .split('**')
    title = (
      <>
        {customArr.map((val, index) =>
          index % 2 ? (
            <span className='text-accent' key={index}>
              {val}
            </span>
          ) : (
            <span className='text-secondary' key={index}>
              {val}
            </span>
          )
        )}
      </>
    )
  }
  else {
    title = (
      <>
        <span className='text-secondary'>Oh no, we have encountered an</span>{' '}
        <span className='text-accent'>error.</span>
      </>
    )
  }

  const body = (
    <>
      {tryAgain && (
        <div className='text-center' key='try-again'>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <Button onClick={history.goBack}>Please Try Again</Button>
        </div>
      )}
      {link && linkName && (
        <div className='text-center' key='try-again'>
          <Button onClick={() => history.replace(link)}>{linkName}</Button>
        </div>
      )}
      {tryAgainHard && (
        <div className='text-center' key='try-again-hard'>
          <Button href='/' tag='a'>
            Please Try Again
          </Button>
        </div>
      )}
      <h3 className='mt-10 text-center' key='firstMessage'>
        Please contact myFloc Customer Care at{' '}
        <Button
          className='inline flat-x'
          href={'tel:' + MYFLOC_PHONE_NUMBER}
          tag='a'
          secondary
          text
        >
          {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
        </Button>{' '}
        for support.
      </h3>

      {/* Secret dev message */}
      {message && import.meta.env.VITE_ENV !== ENV_OPTIONS.production && (
        <div className='text-error' key='devMessage'>
          {message}
        </div>
      )}
    </>
  )

  return <Error body={body} message={message} title={title} />
}

export default ErrorScreen
