import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  ENDPOINTS, HTTP_METHODS, MYFLOC_API_HOST_URL, OPEN_CARD_ACCOUNT_LEGAL, ROUTES,
} from '@/common/constants'
import { useAuthentication, useSubmitForm, useValidateToken } from '@/common/hooks'
import { history } from '@/history'
import { addToast, TOAST_TYPES, updateAndSwitchUser } from '@/redux'
import { InsiderEnrollmentForm } from '@components/EnrollmentForm'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'
import { EnrollmentSecurityPanel } from '@components/Panel'

const JoinInsiderAcceptInvite = ({ token }) => {
  const auth0State = useAuth0()
  const {
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
  } = auth0State

  const dispatch = useDispatch()
  const { decoded, loading, existingUser } = useValidateToken({ token })
  const { enrollmentLogin } = useAuthentication()
  const { apiErrors, setLoading, submitForm } = useSubmitForm()

  const [isRedirecting, setIsRedirecting] = useState(false)
  const nsSession = useSelector(state => state.netspend.session)
  const nsSessionLoaded = useSelector(state => state.netspend.sessionLoaded)

  const onSubmit = async payload => {
    // create myfloc insider
    const myFlocInsiderPayload = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password,
      password2: payload.password2,
      residenceAddress: {
        ...payload.residenceAddress,
        country: 'US',
      },
      token,
    }

    if (decoded.shipToMember) {
      if (payload.mailToResidence) {
        myFlocInsiderPayload.shippingAddress = myFlocInsiderPayload.residenceAddress
      }
      else {
        myFlocInsiderPayload.shippingAddress = payload.shippingAddress
        myFlocInsiderPayload.shippingAddress.country = 'US'
      }
    }

    if (payload.middleName) myFlocInsiderPayload.middleName = payload.middleName
    if (payload.displayName) myFlocInsiderPayload.displayName = payload.displayName
    if (payload.mobile) myFlocInsiderPayload.mobile = payload.mobile
    if ('smsConsent' in payload) myFlocInsiderPayload.smsConsent = payload.smsConsent

    const createInsiderResponse = await submitForm(ENDPOINTS.ACCEPT_INSIDER, {
      manualLoading: true,
      method: HTTP_METHODS.PUT,
      payload: myFlocInsiderPayload,
    })

    if (createInsiderResponse.response.status !== 201) {
      // handle create insider error
      setLoading(false)

      // validation errors
      // - can be rectified by user, return to form
      if (createInsiderResponse.data.error?.validationErrors) {
        dispatch(
          addToast({
            subtitle: 'Please review submitted information.',
            title: 'Could Not Create Account',
            type: TOAST_TYPES.error,
          })
        )
      }
      else {
        history.push('/error-join-team-failed')
      }

      return
    }
    // NOTE: could use
    // await dispatch(createSession()) but the href redirect will handle it
    // properly already
    dispatch(updateAndSwitchUser(createInsiderResponse.data))

    const myFlocUser = createInsiderResponse.data
    const myFlocInsiderId = myFlocUser.personsList[0]

    // use access token if present else use auth0token from state (exsting users joining)
    const accessToken = myFlocUser.auth?.access_token || localStorage.getItem('auth0Token')

    // create netspend person
    const [MM, DD, YYYY] = payload.dob.split('/')
    const encryptedData = await window.NetspendSDK.session.encrypt({
      date_of_birth: `${YYYY}-${MM}-${DD}`,
      government_id: { type: 'ssn', value: payload.social.replace(/\s/g, '') },
    })

    const netspendPersonPayload = {
      encryptedData,
      firstName: payload.firstName,
      lastName: payload.lastName,
      residenceAddress: myFlocInsiderPayload.residenceAddress,
      shippingAddress: myFlocInsiderPayload.shippingAddress,
      token,
    }

    const createNetspendPersonResponse = await fetch(
      `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NETSPEND_PERSONS}/${myFlocInsiderId}`,
      {
        body: JSON.stringify(netspendPersonPayload),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'session-id': nsSession.id,
        },
        method: HTTP_METHODS.POST,
      }
    )

    if (createNetspendPersonResponse.status !== 200) {
      // return history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
      return history.push(ROUTES.ERROR_ENROLLMENT)
    }

    if (!auth0Authenticated) {
      await enrollmentLogin(createInsiderResponse)
      history.push('/')
    }
    else {
      window.location.href = '/'
    }
  }

  const login = (returnTo = `${window.location.pathname}${window.location.search}`) => {
    loginWithRedirect({ appState: { returnTo } })
  }

  let firstName = decoded?.firstName || ''
  let lastName = decoded?.lastName || ''
  if (existingUser?.user) {
    if (!auth0Authenticated && !isRedirecting && !auth0Loading && !loading) {
      setIsRedirecting(true)
      login()
      return null
    }
    firstName = existingUser.user.firstName
    lastName = existingUser.user.lastName
  }
  return (
    <>
      <PageHeader>Enter your personal details</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <>
          {
            (!decoded || !nsSession || loading || auth0Loading || isRedirecting || !nsSessionLoaded)
              ? <Loading className='min-h-screen' />
              : <InsiderEnrollmentForm
                apiErrors={apiErrors}
                loadPasswordField={!existingUser?.user?.id}
                loadPhoneNumber={!existingUser?.user?.id}
                loadShippingFields={decoded.shipToMember}
                onSubmit={onSubmit}
                prefill={{
                  email: existingUser?.user?.email || decoded.email,
                  firstName,
                  lastName,
                }}
                teamName={existingUser?.teamName || ''}
              />
          }
          <EnrollmentSecurityPanel />
        </>
      </div>

      {/* Bottom Legal */}
      <div className='mx-auto mb-12 max-w-5xl'>
        <div className='flex flex-row'>
          <div>1.</div>
          <div className='ml-2'>{OPEN_CARD_ACCOUNT_LEGAL}</div>
        </div>
      </div>
    </>
  )
}

JoinInsiderAcceptInvite.propTypes = {
  token: PropTypes.string.isRequired,
}

export default JoinInsiderAcceptInvite
