import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

const FormInputAddon = React.memo(({ className, children, tag: Tag = 'div', type, ...rest }) => {
  const classes = classNames(className, `input-group-${type}`)

  return (
    <Tag {...rest} className={classes}>
      {typeof children === 'string' ? (
        <div className='input-group-text'>{children}</div>
      ) : (
        <>{children}</>
      )}
    </Tag>
  )
})

FormInputAddon.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The component's tag type.
   */
  tag: PropTypes.string,
  /**
   * The addon type.
   */
  type: PropTypes.oneOf(['append', 'prepend', 'leading', 'trailing']).isRequired,
}

export default FormInputAddon
