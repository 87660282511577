/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import FormSelect from '@/common/components/FormSelect'
import Loading from '@/common/components/Loading'
import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import {
  ACTION_STATES,
  CUSTOM_ERROR_MESSAGES,
  ENDPOINTS,
  FREQUENCY_TYPES,
  HTTP_METHODS,
  PERSON_STATUS,
  ROLES,
  ROUTES,
  TRANSFER_TYPES,
} from '@/common/constants'
import { useQuery, useSubmitForm } from '@/common/hooks'
import {
  capitalize, currency, daysInMonth, isEmpty, ordinalize, weekdays,
} from '@/common/utils'
import { generateTransferSchema } from '@/common/validations'
import { history } from '@/history'
import { getTeamBalances } from '@/redux/netspend'
import { combineTeamBalance, getTeam } from '@/redux/team'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { selectMyFlocPerson } from '@/redux/user'

import { FAQ, ScheduledMoneyMovement } from './moveMoneyComponents'

const FREQUENCY_OPTIONS = [FREQUENCY_TYPES.ONCE, FREQUENCY_TYPES.WEEKLY, FREQUENCY_TYPES.MONTHLY]
const DAYS = Array.from(Array(7)
  .keys())
const DATES = [1, 15]

const FORM_STATES = {
  CREATE_MOVEMENT: 'create-movement',
  EDIT_MOVEMENT: 'edit-movement',
  REMOVE_MOVEMENT: 'remove-movement',
}

const isInsider = account => account ? account.role === ROLES.INSIDER : false
const isLead = account => account ? account.role === ROLES.LEAD : false
const usesLeadBankAccount = account => account
  ? account.role === ROLES.LEAD ||
  account.role === ROLES.INSIDER
  : false

/**
 * Get next day
 *
 * @param   {number} day
 * @param   {string} frequency
 * @returns {Date}
 */
export const getNextPaymentDate = (day, frequency) => {
  const date = new Date()

  if (frequency === FREQUENCY_TYPES.WEEKLY) {
    // Calculate next day
    date.setDate(date.getDate() + ((7 + day - date.getDay()) % 7))

    // If the same day it should be next week
    if (date.getDay() === new Date()
      .getDay()) {
      date.setDate(date.getDate() + 7)
    }
  }
  else {
    // Check if current or next month
    if (date.getDate() >= day) {
      date.setMonth(date.getMonth() + 1)
    }
    date.setDate(Math.min(day, daysInMonth(date)))
  }
  return date
}

/**
 * param {object} query
 * param {string} to the myFlocPersonId of the person you are trying to transfer to
 */
const MoveMoney = props => {
  const dispatch = useDispatch()
  const query = useQuery()
  const queryTo = query.get('to')

  const [maxLimit, setMaxLimit] = useState(0)
  const [createEditConfirmModalOpen, setCreateEditConfirmModalOpen] = useState(false)
  const [deleteScheduledConfirmModalOpen, setDeleteScheduledConfirmModalOpen] = useState(false)
  const [scheduled, setScheduled] = useState(null)
  const [toInitialized, setToInitialized] = useState(false)
  const [formState, setFormState] = useState(FORM_STATES.CREATE_MOVEMENT)
  const [savedFormState, setSavedFormState] = useState([])
  const [movementToEdit, setMovementToEdit] = useState(null)
  const [movementToRemove, setMovementToRemove] = useState(null)
  const [loadFormWithTargetMoveState, setLoadFormWithTargetMoveState] =
    useState(ACTION_STATES.UNINITIATED)

  const { apiErrors, loading, showValidationToast, submitForm } = useSubmitForm()

  const myFlocPerson = useSelector(selectMyFlocPerson)
  const unfilteredCombinedAccounts = useSelector(combineTeamBalance)
  const combinedAccounts = unfilteredCombinedAccounts
    ?.filter(account =>
      (account.status !== PERSON_STATUS.CLOSED) &&
      (isInsider(myFlocPerson) ? !isLead(account) : true) &&
      (isLead(myFlocPerson) ? !isInsider(account) : true) &&
      (account.firstName !== undefined && account.lastName !== undefined)
    )
    .map(account => {
      let accountName = `${account.firstName} ${account.lastName}`
      if (usesLeadBankAccount(account)) accountName = 'Primary Account'
      return {
        ...account,
        accountName,
      }
    })

  if (combinedAccounts && combinedAccounts.length < 2) {
    history.replace(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.MOVE_MONEY))
  }

  const scheduledTransferSchema = yup.object()
    .shape({
      period: yup
        .string()
        .required('This information is required.')
        .label('Frequency')
        .oneOf(Object.keys(FREQUENCY_TYPES)
          .map(key => FREQUENCY_TYPES[key])),
    })

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      to: queryTo,
    },
    mode: 'onBlur',
    resolver: yupResolver(generateTransferSchema(maxLimit)
      .concat(scheduledTransferSchema)),
  })

  useEffect(() => {
    dispatch(getTeam())
    dispatch(getTeamBalances())
    getScheduled()
  }, [])

  // handle form "mode" changes (create, edit, remove)
  useEffect(() => {
    if (!formState || !combinedAccounts) return

    if (formState === FORM_STATES.CREATE_MOVEMENT) {
      const firstLoad = !from
      if (!firstLoad) return

      // initial recipient specified
      if (queryTo) {
        const expectedTo = combinedAccounts.find(account => account.id === queryTo)
        const validFroms = combinedAccounts.filter(account => {
          return expectedTo
            ? usesLeadBankAccount(account) !== usesLeadBankAccount(expectedTo)
            : true
        })

        setValue('from', validFroms[0].id)
        setValue('to', queryTo)
      }

      // default to current user as sender
      else {
        const expectedFrom = myFlocPerson
        const validTos = combinedAccounts.filter(account =>
          usesLeadBankAccount(account) !== usesLeadBankAccount(expectedFrom)
        )

        setValue('from', expectedFrom.id)
        setValue('to', validTos[0]?.id)
      }
    }

    else if (formState === FORM_STATES.EDIT_MOVEMENT) {
      if (!movementToEdit) return
      if (loadFormWithTargetMoveState !== ACTION_STATES.UNINITIATED) return

      setLoadFormWithTargetMoveState(ACTION_STATES.INITIATED)

      setValue('from', movementToEdit.leadId)
      setValue('to', movementToEdit.memberId)
      setValue('period', movementToEdit.period)
      setValue('day', movementToEdit.day)
      setValue('amount', movementToEdit.amount / 100)

      setLoadFormWithTargetMoveState(ACTION_STATES.COMPLETED)
    }

    else if (formState === FORM_STATES.REMOVE_MOVEMENT) {
      if (!movementToRemove) return
      if (loadFormWithTargetMoveState !== ACTION_STATES.UNINITIATED) return

      setValue('from', movementToRemove.leadId)
      setValue('to', movementToRemove.memberId)
      setValue('period', movementToRemove.period)
      setValue('day', movementToRemove.day)
      setValue('amount', movementToRemove.amount / 100)

      setLoadFormWithTargetMoveState(ACTION_STATES.COMPLETED)
    }
  }, [formState, combinedAccounts, movementToEdit, movementToRemove, loadFormWithTargetMoveState])

  // if edited movement is updated, reset edit mode's loading state
  useEffect(() => {
    if (!formState || !movementToEdit) return
    if (formState !== FORM_STATES.EDIT_MOVEMENT) return

    setLoadFormWithTargetMoveState(ACTION_STATES.UNINITIATED)
  }, [formState, movementToEdit, setLoadFormWithTargetMoveState])

  const lead = unfilteredCombinedAccounts?.find(account => account.role === ROLES.LEAD)
  const from = combinedAccounts?.find(account => account.id === watch('from'))
  const to = combinedAccounts?.find(account => account.id === watch('to'))
  const amount = parseFloat(watch('amount'))
  const day = parseInt(watch('day'))
  const frequency = watch('period')

  // Set default values
  useEffect(() => {
    if (!combinedAccounts) return

    setMaxLimit(lead.available.amount / 100)
  }, [combinedAccounts?.length])

  // Set both dropdown options
  let fromOptions
  let toOptions

  const optionsFromArray = arr =>
    arr.map(account => (
      <option key={account.id} value={account.id}>{`${account.accountName
      } (available balance = ${currency(account.available.amount / 100)})`}</option>
    ))

  if (combinedAccounts) {
    fromOptions = optionsFromArray(combinedAccounts.sort((a, b) => {
      if (usesLeadBankAccount(a) && !usesLeadBankAccount(b)) return -1
      if (!usesLeadBankAccount(a) && usesLeadBankAccount(b)) return 1
      return 0
    }))

    // To options are resticted by what is selected in from
    toOptions = optionsFromArray(combinedAccounts.filter(account => {
      return from
        ? usesLeadBankAccount(from) !== usesLeadBankAccount(account)
        : true
    }))
  }

  // Re-sort movements by transaction date
  const sorted = useMemo(
    () =>
      scheduled
        ?.map(movement => ({
          ...movement,
          nextTransaction: getNextPaymentDate(movement.day, movement.period),
        }))
        ?.sort((a, b) => a.nextTransaction.getTime() - b.nextTransaction.getTime()),
    [scheduled]
  )

  // Restrict "to" options based on "from" but only after the initialization
  useEffect(() => {
    if (!from) return

    if (!queryTo || toInitialized) {
      const filteredTo = combinedAccounts.filter(account => usesLeadBankAccount(account) !== usesLeadBankAccount(from))
      toOptions = optionsFromArray(filteredTo)

      formState === FORM_STATES.EDIT_MOVEMENT
        ? setValue('to', movementToEdit.memberId)
        : setValue('to', filteredTo[0].id)
    }

    if (!usesLeadBankAccount(from)) setValue('period', FREQUENCY_TYPES.ONCE)

    setMaxLimit(from.available.amount / 100)
    setToInitialized(true)
  }, [from?.id])

  // Make sure we trigger the field after max limit has been updated
  useEffect(() => {
    if (!from || !to || !getValues('amount')) return
    trigger('amount')
  }, [maxLimit])

  // exit edit scheduled movement mode if user sets invalid params
  useEffect(() => {
    if (!formState || !movementToEdit) return
    if (formState !== FORM_STATES.EDIT_MOVEMENT) return
    if (loadFormWithTargetMoveState !== ACTION_STATES.COMPLETED) return

    if (
      from?.id !== movementToEdit.leadId || // scheduled movement origin must be lead
      frequency === FREQUENCY_TYPES.ONCE // scheduled movement cannot be a one-time move
    ) {
      cancelEditMovement()
    }
  }, [formState, movementToEdit, loadFormWithTargetMoveState, from, frequency])

  // Submit a scheduled money movement
  const submitScheduled = async () => {
    const { response } = await submitForm(ENDPOINTS.NETSPEND_ACCOUNTS_SCHEDULED(myFlocPerson.id), {
      payload: {
        amount: amount * 100,
        day,
        leadId: from.id,
        memberId: to.id,
        period: frequency,
      },
    })

    if (response.status === 200) {
      dispatch(
        addToast({
          subtitle: `You have scheduled a ${frequency} movement of ${currency(
            amount
          )} from your Primary Card Account to ${to.accountName}.`,
          title: frequencyConfirmation2,
          type: TOAST_TYPES.success,
        })
      )
      getScheduled()
      resetForm()
    }
  }

  // Edit a scheduled money movement
  const editScheduled = async () => {
    const { response } =
      await submitForm(
        ENDPOINTS.NETSPEND_ACCOUNTS_SCHEDULED_ID(myFlocPerson.id, movementToEdit.id),
        {
          method: HTTP_METHODS.PATCH,
          payload: {
            amount: amount * 100,
            day,
            leadId: from.id,
            memberId: to.id,
            period: frequency,
          },
        })

    if (response.status === 200) {
      dispatch(
        addToast({
          subtitle: `You have updated a ${frequency} scheduled movement to ${currency(
            amount
          )} from your Primary Card Account to ${to.accountName}.`,
          title: frequencyConfirmation2,
          type: TOAST_TYPES.success,
        })
      )
      getScheduled()
      resetForm()
      cancelEditMovement()
    }
  }

  const resetForm = () => {
    setValue('amount', '', { shouldDirty: false })
    setValue('period', FREQUENCY_TYPES.ONCE, { shouldDirty: false })
    setValue('from', combinedAccounts.find(account => usesLeadBankAccount(account)).id, { shouldDirty: false })
  }

  const getScheduled = async () => {
    const { data, response } = await submitForm(
      ENDPOINTS.NETSPEND_ACCOUNTS_SCHEDULED(myFlocPerson.id),
      {
        method: HTTP_METHODS.GET,
      }
    )
    if (response.status === 200) setScheduled(data)
  }

  // Submit a one time money movement
  const submitOnce = async () => {
    let target = to
    let action = TRANSFER_TYPES.DEPOSIT

    if (to.role === ROLES.LEAD || to.role === ROLES.INSIDER) {
      target = from
      action = TRANSFER_TYPES.WITHDRAWAL
    }

    const { data, response } = await submitForm(
      ENDPOINTS[`NETSPEND_SUBACCOUNTS_${action.toUpperCase()}S`](myFlocPerson.id),
      {
        payload: {
          amount: amount * 100,
          subAccountId: target.id,
        },
      }
    )

    if (response.status === 200) {
      // TODO refactor/clean this up later, currently using old logic from previous api call
      let fromAccount
      let fromWithBalance
      let toAccount
      let toWithBalance
      const primaryBalance = currency(data.primaryAccountBalance.available.amount / 100)
      const subBalance = currency(data.subAccountBalance.available.amount / 100)

      // Build wild message
      if (from.role === ROLES.LEAD) {
        fromAccount = `your ${from.accountName}`
        fromWithBalance = `${fromAccount}: ${primaryBalance}`
        toAccount = `${to.accountName}'s Account`
        toWithBalance = `${toAccount}: ${subBalance}`
      }
      else {
        fromAccount = `${from.accountName}'s Account`
        fromWithBalance = `${fromAccount}: ${subBalance}`
        toAccount = `your ${to.accountName}`
        toWithBalance = `${toAccount}: ${primaryBalance}`
      }
      dispatch(
        addToast({
          subtitle: `Remaining funds in ${fromWithBalance}. ${toWithBalance}.`,
          title: `You have moved ${currency(amount)} from ${fromAccount} to ${toAccount}.`,
          type: TOAST_TYPES.success,
        })
      )
      resetForm()
      dispatch(getTeamBalances())
    }
  }

  // clear target movement when exiting edit or remove movement mode
  useEffect(() => {
    if (formState !== FORM_STATES.EDIT_MOVEMENT && movementToEdit !== null) {
      setMovementToEdit(null)
    }
    if (formState !== FORM_STATES.REMOVE_MOVEMENT && movementToRemove !== null) {
      setMovementToRemove(null)
    }
  }, [formState, movementToEdit, movementToRemove])

  // when entering a new form state, reset "load form with target movement details" state
  useEffect(() => {
    if (!formState) return

    setLoadFormWithTargetMoveState(ACTION_STATES.UNINITIATED)
  }, [formState])

  if (!combinedAccounts) {
    return <Loading className='min-h-header' />
  }

  const onOptions =
    frequency === FREQUENCY_TYPES.WEEKLY
      ? DAYS.map(day => (
        <option key={day} value={day}>
          {weekdays[day]}
        </option>
      ))
      : DATES.map(date => (
        <option key={date} value={date}>
          {ordinalize(date)} of the month
        </option>
      ))

  // Set frequency confirmation text
  let frequencyConfirmation
  let frequencyConfirmation2

  if (frequency === FREQUENCY_TYPES.WEEKLY || frequency === FREQUENCY_TYPES.MONTHLY) {
    if (frequency === FREQUENCY_TYPES.WEEKLY) {
      frequencyConfirmation = `Weekly, on ${weekdays[day]}s`
    }
    else {
      frequencyConfirmation = `Monthly, on the ${ordinalize(day)}`
    }

    const date = getNextPaymentDate(day, frequency)
    frequencyConfirmation2 = `Starts on ${date.toLocaleDateString(
      'en-US'
    )} and will continue until you remove`
  }
  else {
    frequencyConfirmation = 'Once only'
  }

  const saveCurrentState = () => {
    if (!formState) return

    let targetMovement

    switch (formState) {
      case FORM_STATES.EDIT_MOVEMENT:
        targetMovement = movementToEdit
        break

      case FORM_STATES.REMOVE_MOVEMENT:
        targetMovement = movementToRemove
        break

      default:
        targetMovement = null
    }

    setSavedFormState(
      [].concat(
        {
          fields: {
            amount,
            day,
            frequency,
            from: from?.id,
            to: to?.id,
          },
          state: formState,
          targetMovement,
        },
        savedFormState
      )
    )
  }

  const restoreSavedFormState = () => {
    const mostRecentSavedState = savedFormState[0]
    if (!mostRecentSavedState) return

    if (mostRecentSavedState.state !== formState) setFormState(mostRecentSavedState.state)
    if (mostRecentSavedState.fields.from !== from) {
      setValue('from', mostRecentSavedState.fields.from)
    }
    if (mostRecentSavedState.fields.to !== to) setValue('to', mostRecentSavedState.fields.to)
    if (mostRecentSavedState.fields.amount !== amount) {
      setValue('amount',
        typeof mostRecentSavedState.fields.amount === 'number'
          ? (!isNaN(mostRecentSavedState.fields.amount) ? mostRecentSavedState.fields.amount : '')
          : parseFloat(mostRecentSavedState.fields.amount) ?? ''
      )
    }
    if (mostRecentSavedState.fields.day !== day) setValue('day', mostRecentSavedState.fields.day)
    if (mostRecentSavedState.fields.frequency !== frequency) {
      setValue('period', mostRecentSavedState.fields.frequency)
    }

    if (mostRecentSavedState.state === FORM_STATES.EDIT_MOVEMENT) {
      setMovementToEdit(mostRecentSavedState.targetMovement)
    }
    else if (mostRecentSavedState.state === FORM_STATES.REMOVE_MOVEMENT) {
      setMovementToRemove(mostRecentSavedState.targetMovement)
    }

    // remove most-recent saved form state
    setSavedFormState(savedFormState.filter(savedState => savedState !== mostRecentSavedState))
  }

  const startEditMovement = movement => {
    if (formState !== FORM_STATES.EDIT_MOVEMENT) saveCurrentState()

    setFormState(FORM_STATES.EDIT_MOVEMENT)
    setMovementToEdit(movement)
  }

  const cancelEditMovement = () => {
    if (formState !== FORM_STATES.EDIT_MOVEMENT) return

    if (savedFormState) {
      restoreSavedFormState()
      if (formState === FORM_STATES.EDIT_MOVEMENT) setFormState(FORM_STATES.CREATE_MOVEMENT)
    }
  }

  const startRemoveMovement = movement => {
    if (!movement) return

    if (formState !== FORM_STATES.REMOVE_MOVEMENT) {
      saveCurrentState()
      setFormState(FORM_STATES.REMOVE_MOVEMENT)
    }

    setMovementToRemove(movement)
    setDeleteScheduledConfirmModalOpen(true)
  }

  const cancelRemoveMovement = () => {
    if (formState !== FORM_STATES.REMOVE_MOVEMENT) return

    if (savedFormState) restoreSavedFormState()
    setDeleteScheduledConfirmModalOpen(false)
  }

  const removeMovement = async movement => {
    const { response } = await submitForm(
      ENDPOINTS.NETSPEND_ACCOUNTS_SCHEDULED_ID(myFlocPerson.id, movement.id),
      {
        method: HTTP_METHODS.DELETE,
      }
    )
    if (response.status === 204) {
      setScheduled(scheduled.filter(_movement => _movement.id !== movement.id))
    }

    cancelRemoveMovement()
  }

  return (
    <>
      <div className='container pt-6 mx-auto max-w-7xl md:px-10 md:pt-12 md:pb-16'>
        <h1 className='pl-5 md:pl-0 text-secondary'>Move Funds</h1>

        <div className='grid grid-cols-1 gap-x-10 lg:grid-cols-2 xl:gap-x-0'>
          {/* Form */}
          <div className='px-5 pt-8 pb-12 mt-6 bg-gray-100 rounded-md border-gray-400 md:p-8 md:mt-12 md:border md:shadow h-min md:max-w-50'>
            <form onSubmit={handleSubmit(() => setCreateEditConfirmModalOpen(true), showValidationToast)}>
              <FormSelect
                className='min-h-0'
                errors={errors}
                label='From'
                name='from'
                register={register}
                validationErrors={apiErrors?.validationErrors}
                required
              >
                {fromOptions}
              </FormSelect>

              <FormSelect
                className='mt-10'
                errors={errors}
                label='To'
                name='to'
                register={register}
                validationErrors={apiErrors?.validationErrors}
                required
              >
                {toOptions}
              </FormSelect>

              {from && usesLeadBankAccount(from) && (
                <div className='grid grid-cols-2'>
                  <FormSelect
                    className='mr-8'
                    errors={errors}
                    label='Frequency'
                    name='period'
                    register={register}
                    validationErrors={apiErrors?.validationErrors}
                    required
                  >
                    {FREQUENCY_OPTIONS.map(option => (
                      <option key={option} value={option}>
                        {capitalize(option)}
                      </option>
                    ))}
                  </FormSelect>

                  {frequency !== FREQUENCY_TYPES.ONCE && (
                    <FormSelect
                      errors={errors}
                      label='On'
                      name='day'
                      register={register}
                      validationErrors={apiErrors?.validationErrors}
                      required
                    >
                      {onOptions}
                    </FormSelect>
                  )}
                </div>
              )}

              <FormInput
                errors={errors}
                label='Transfer Amount'
                name='amount'
                register={register}
                type={FORM_INPUT_TYPES.currency}
                validationErrors={apiErrors?.validationErrors}
                required
              />

              <div className='flex flex-row'>
                <Button
                  className='mr-8'
                  disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}
                  isLoading={loading}
                >
                  Continue
                </Button>
                <Button onClick={history.goBack} type='button' primary text>
                  Cancel
                </Button>
              </div>

              {/* Create + Edit Movement Confirmation Modal */}
              {from && to && !isNaN(amount) && (
                <Modal
                  open={createEditConfirmModalOpen}
                  setOpen={() => setCreateEditConfirmModalOpen(!createEditConfirmModalOpen)}
                  successCallback={() => {
                    if (frequency === FREQUENCY_TYPES.ONCE) {
                      submitOnce()
                    }
                    else {
                      formState === FORM_STATES.CREATE_MOVEMENT
                        ? submitScheduled()
                        : editScheduled()
                    }
                  }}
                  type={MODAL_TYPES.continue}
                >
                  <h4>Review and move funds</h4>
                  <div className='mt-8 text-lg font-medium'>From</div>
                  <div className='mt-3 text-lg'>{from.accountName}</div>
                  <div className='mt-8 text-lg font-medium'>To</div>
                  <div className='mt-3 text-lg'>{to.accountName}</div>
                  <div className='mt-8 text-lg font-medium'>Frequency</div>
                  <div className='my-3 text-lg'>
                    <div>{frequencyConfirmation}</div>
                    {frequencyConfirmation2 && <div>{frequencyConfirmation2}</div>}
                  </div>
                  <div className='mt-8 text-lg font-medium'>Amount</div>
                  <div className='my-3 text-lg'>{currency(amount)}</div>
                </Modal>
              )}

              {/* Delete Movement Confirmation Modal */}
              <Modal
                cancelCallback={cancelRemoveMovement}
                cancelText='No, Keep It'
                open={deleteScheduledConfirmModalOpen}
                setOpen={() => setDeleteScheduledConfirmModalOpen(!deleteScheduledConfirmModalOpen)}
                successCallback={() => removeMovement(movementToRemove)}
                successText='Yes, Remove'
                type={MODAL_TYPES.confirm}
              >
                <h4>Are you sure you want to remove this scheduled event?</h4>
                <p className='mt-5 mb-0'>
                  All future scheduled money movements will be canceled.
                  You can schedule a new money movement at any time.
                </p>
              </Modal>
            </form>
          </div>

          {/* Scheduled */}
          <div className='px-5 pt-0.5 pb-12 lg:px-0'>
            <h3>Scheduled Money Movements</h3>

            {!sorted && <Loading />}

            {sorted?.length === 0 && (
              <div className='mt-4'>You currently do not have any money movements scheduled</div>
            )}

            {sorted?.map(movement => (
              <ScheduledMoneyMovement
                isEditing={
                  (formState === FORM_STATES.EDIT_MOVEMENT && movementToEdit?.id === movement.id) ||
                  (formState === FORM_STATES.REMOVE_MOVEMENT && movementToRemove?.id === movement.id)
                }
                key={movement.id}
                movement={movement}
                onCancelEdit={() => cancelEditMovement()}
                onEdit={() => startEditMovement(movement)}
                onRemove={() => startRemoveMovement(movement)}
              />
            ))}
          </div>
        </div>
      </div>

      <FAQ />
    </>
  )
}

export default MoveMoney
