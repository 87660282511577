import React, { useEffect, useState } from 'react'

import { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { getNotifications, updateAlertViewed } from '@/redux/notifications'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import Button from '@components/Button'
import HiddenPanel from '@components/HiddenPanel'

import { NotificationAlert, NotificationOptions } from './notificationsComponents'

const Notifications = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getNotifications())
  }, [dispatch])

  const state = useSelector(state => state)

  const [[alerts, notifications], setNotificationList] = useState([
    // alerts
    [],
    // notifications
    [],
  ])
  const [perPage] = useState(7)
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [displayNotifications, setDisplayNotifications] = useState([])
  const [pagination, setPagination] = useState({
    first: false,
    next: false,
    prev: false,
  })

  // Map the mixed list of alerts and notifications into two lists to render
  useEffect(() => {
    if (state.notifications && state.notifications.items) {
      setNotificationList(
        state.notifications.items.reduce(
          (arr, item) => {
            if (item.channel === 'notification') {
              arr[1].push(item)
            }
            if (item.channel === 'alert') {
              arr[0].push(item)
            }
            return arr
          },
          [[], []]
        )
      )
      const pageCount = Math.ceil(notifications.length / perPage)
      setPageCount(pageCount)
    }
  }, [state.notifications, pageCount, notifications.length, perPage])

  useEffect(() => {
    setDisplayNotifications(
      notifications.slice(currentPage * perPage - perPage, currentPage * perPage)
    )
  }, [currentPage, notifications, pageCount, perPage])

  useEffect(() => {
    if (currentPage === pageCount && pageCount > 1) {
      setPagination({
        first: true,
        next: false,
        prev: true,
      })
    }
    else if (pageCount === 1) {
      setPagination({
        first: false,
        next: false,
        prev: false,
      })
    }
    else if (pageCount === 0) {
      setPagination({
        first: false,
        next: false,
        prev: false,
      })
    }
    else if (currentPage > 1 && currentPage < pageCount) {
      setPagination({
        first: true,
        next: true,
        prev: true,
      })
    }
    else {
      setPagination({
        first: false,
        next: true,
        prev: false,
      })
    }
  }, [displayNotifications, currentPage, pageCount])

  const [showAlertDetails, setShowAlertDetails] = useState(false)
  const [showNotificationDetails, setShowNotificationDetails] = useState(false)

  // When an alert is dismissed, dispatch an action to set the viewed time in db and remove from list
  const dismissAlert = item => () => {
    if (item.channel === 'alert') {
      dispatch(updateAlertViewed(item.id))
      const newAlerts = cloneDeep(alerts)
      const newNotifications = cloneDeep(notifications)

      ;(item.channel === 'alert' ? newAlerts : newNotifications).splice(item.key, 1)
      setNotificationList([newAlerts, newNotifications])
      dispatch(
        addToast({
          subtitle: 'Alert has been dismissed successfully.',
          title: 'Alert Dismissed',
          type: TOAST_TYPES.success,
        })
      )
    }
  }

  const handlePagination = (e, action) => {
    if (action === 'next') {
      if (pagination.next) setCurrentPage(currentPage + 1)
    }
    else if (action === 'prev') {
      if (pagination.prev) setCurrentPage(currentPage - 1)
    }
    else {
      if (pagination.first) setCurrentPage(1)
    }
  }

  return (
    <>
      <div className='md:pb-24 wide-container'>
        <h1 className='text-secondary'>Notifications and Alerts</h1>
        <div className='flex flex-row flex-wrap mt-10'>
          <h4 className='text-dark'>Alerts</h4>
          <Button
            className='p-0 m-0 mt-0.5 ml-10 whitespace-nowrap'
            onClick={() => setShowAlertDetails(true)}
            tag='a'
            secondary
            text
          >
            Learn More
          </Button>
          <HiddenPanel
            className='w-full'
            setShow={() => setShowAlertDetails(!showAlertDetails)}
            show={showAlertDetails}
          >
            Alerts seen here are important messages about your account that require your attention.
          </HiddenPanel>
        </div>

        <div className='rounded-md border-gray-200 md:p-6 md:border md:shadow-md'>
          {alerts.map((alert, index) => (
            <NotificationAlert
              dismissAlert={dismissAlert}
              hr={index !== alerts.length - 1}
              index={index}
              key={alert.id}
              notification={alert}
            />
          ))}
          {alerts.length === 0 ? 'There are no new alerts to show at this time.' : ''}
        </div>

        <div className='flex flex-row flex-wrap mt-10'>
          <h4 className='text-dark'>Notifications</h4>
          <Button
            className='p-0 m-0 mt-0.5 ml-10 whitespace-nowrap'
            onClick={() => setShowNotificationDetails(true)}
            tag='a'
            secondary
            text
          >
            Learn More
          </Button>
          <HiddenPanel
            className='w-full'
            setShow={() => setShowNotificationDetails(!showNotificationDetails)}
            show={showNotificationDetails}
          >
            Notifications help keep you updated on items you should be aware of for your account.
             You may opt in to receiving an email in addition to on-screen notifications seen here.
          </HiddenPanel>
        </div>

        <NotificationOptions />

        <div className='mt-8 rounded-md border-gray-200 md:p-6 md:border md:shadow-md'>
          {displayNotifications.map((notification, index) => (
            <NotificationAlert
              hr={index !== displayNotifications.length - 1}
              index={index}
              key={notification.id}
              notification={notification}
            />
          ))}
          {displayNotifications.length === 0
            ? 'There are no new notifications to show at this time.'
            : ''}
        </div>

        <div className='flex flex-row flex-wrap mt-5 md:flex-row'>
          <Button
            className='mr-4 mb-4 md:mb-0'
            disabled={!pagination.first}
            onClick={e => handlePagination(e, 'first')}
            leftArrow
            primary
            text
          >
            First Page
          </Button>
          <div className='flex flex-row justify-between flex-basis-100 md:flex-basis-auto'>
            <Button
              className='mr-3'
              disabled={!pagination.prev}
              onClick={e => handlePagination(e, 'prev')}
              leftArrow
              primary
              text
            >
              Previous Page
            </Button>
            <Button
              disabled={!pagination.next}
              onClick={e => handlePagination(e, 'next')}
              arrow
              primary
              text
            >
              Next page
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications
