/**
 * Apparently the Netspend Plaid Microapp needs all of these values to launch the micro app
 */
export const MICRO_APP_THEME = {
  accent: {
    color50: '#cf337b',
    color100: '#cf337b',
    color200: '#cf337b',
    color300: '#cf337b',
    color400: '#cf337b',
    color500: '#cf337b',
    color600: '#cf337b',
    color700: '#cf337b',
    color800: '#cf337b',
    color900: '#cf337b',
  },
  default: {
    color50: '#137fa0',
    color100: '#137fa0',
    color200: '#137fa0',
    color300: '#137fa0',
    color400: '#137fa0',
    color500: '#137fa0',
    color600: '#137fa0',
    color700: '#137fa0',
    color800: '#137fa0',
    color900: '#137fa0',
  },
  negative: {
    color50: '#de0008',
    color100: '#de0008',
    color200: '#de0008',
    color300: '#de0008',
    color400: '#de0008',
    color500: '#de0008',
    color600: '#de0008',
    color700: '#de0008',
    color800: '#de0008',
    color900: '#de0008',
  },
  neutral: {
    color50: '#f0f0f0',
    color100: '#ececec',
    color200: '#dbdbdb',
    color300: '#c7c8c8',
    color400: '#aeb0b0',
    color500: '#919394',
    color600: '#747678',
    color700: '#55585a',
    color800: '#383b3f',
    color900: '#212529',
  },
  positive: {
    color50: '#cf337b',
    color100: '#cf337b',
    color200: '#cf337b',
    color300: '#cf337b',
    color400: '#cf337b',
    color500: '#cf337b',
    color600: '#cf337b',
    color700: '#cf337b',
    color800: '#cf337b',
    color900: '#cf337b',
  },
  special: {
    color50: '#0000ff',
    color100: '#0000ff',
    color200: '#0000ff',
    color300: '#0000ff',
    color400: '#0000ff',
    color500: '#0000ff',
    color600: '#0000ff',
    color700: '#0000ff',
    color800: '#0000ff',
    color900: '#0000ff',
  },
}
