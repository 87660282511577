import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import RobitDropdown from './RobitDropdown'
import RobitDropdownItem from './RobitDropdownItem'
import RobitDropdownMenu from './RobitDropdownMenu'
import RobitDropdownToggle from './RobitDropdownToggle'

/**
 * A wrapper around the RobitDropdown component to match careSix
 *
 * @param   {Object}          props
 * @param   {boolean}         [props.buttonClasses] classes to add to the inner button
 * @param   {boolean}         [props.changeText]    if false, do not update the text when clicked
 * @param   {string}          [props.className]
 * @param   {boolean}         [props.disabled]
 * @param   {boolean}         [props.isLoading]
 * @param   {string}          props.label
 * @param   {function}        props.onChange
 * @param   {[{text, value}]} [props.options]
 * @param   {string}          props.value           Need to pass the current value to pre-focus on dropdown open
 * @returns {JSX.Element}
 *
 */
export const Dropdown = ({
  buttonClasses,
  changeText = true,
  className,
  disabled = false,
  label,
  isLoading = false,
  onChange,
  options,
  value,
}) => {
  const [open, toggleOpen] = useState(false)
  const [displayLabel, setDisplayLabel] = useState(label)

  const selectOption = option => {
    if (changeText) setDisplayLabel(option.text)
    onChange?.(option)
  }

  // Pre-select item
  useEffect(() => {
    options.forEach(option => option.selected && selectOption(option))
  }, [])

  return options?.length ? (
    <RobitDropdown className={className} open={open} toggle={() => toggleOpen(!open)} value={value}>
      <RobitDropdownToggle
        className={`min-w-0 ${buttonClasses}`}
        disabled={disabled}
        isLoading={isLoading}
        caret
        outline
      >
        {displayLabel}
      </RobitDropdownToggle>
      <RobitDropdownMenu>
        {options.map(option => (
          <RobitDropdownItem
            key={option.value || option.text}
            onClick={selectOption.bind(this, option)}
            value={option.value || option.text}
          >
            {option.text}
          </RobitDropdownItem>
        ))}
      </RobitDropdownMenu>
    </RobitDropdown>
  ) : null
}

Dropdown.propTypes = {
  /**
   * Classes to add to the inner button
   */
  buttonClasses: PropTypes.string,
  /**
   * If false, do not change the text of the dropdown
   */
  changeText: PropTypes.bool,
  /**
   * Any classNames we wish to propogate to this element
   */
  className: PropTypes.string,
  /**
   * Component is disabled
   */
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
   * Label for the dropdown
   */
  label: PropTypes.string.isRequired,
  /**
   * Change handler for the dropdown, actually uses click under the hood
   */
  onChange: PropTypes.func,
  /**
   * The array of options, object must have a text property and a unique value property
   */
  options: PropTypes.array.isRequired,
  /**
   * Need to pass the current value to pre-focus on dropdown open
   */
  value: PropTypes.string,
}

export default Dropdown
