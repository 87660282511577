import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import HiddenPanel from '@/common/components/HiddenPanel'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import { ROUTES, TRANSFER_INFO } from '@/common/constants'
import { useGetBankCard, useSubmitForm } from '@/common/hooks'
import { currency, isEmpty } from '@/common/utils'
import { generateTransferSchema } from '@/common/validations'
import { history } from '@/history'
import { FORMS, saveStep } from '@/redux/formSteps'

const StepFour = props => {
  const dispatch = useDispatch()

  const [transferInfo, setTransferInfo] = useState(false)
  const { accounts, cardAch } = useGetBankCard()
  const { apiErrors, loading, showValidationToast } = useSubmitForm()
  const withdrawalLimit = accounts?.withdrawalLimits
    ? accounts.withdrawalLimits.max_amount_remaining.amount / 100
    : 5000

  const transferSchema = generateTransferSchema(withdrawalLimit)

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(transferSchema),
  })

  // If we are coming back, pre-populate value
  const formStepFour = useSelector(state => state.formSteps[FORMS.createCardAccount])?.[4]
  useEffect(() => {
    if (!formStepFour) return
    setValue('amount', formStepFour.cents / 100)
  }, [formStepFour])

  const onSubmit = data => {
    dispatch(
      saveStep({
        data: { cents: Math.round(data.amount * 100) },
        form: FORMS.createCardAccount,
        step: 4,
      })
    )
    history.push(ROUTES.CREATE_CARD_ACCOUNT.STEP_FIVE)
  }

  return (
    <>
      <PageHeader stepText='Step 4 of 5'>Transfer funds to your myFloc Card Account</PageHeader>
      <div className='relative flex-col text-left main-container min-h-step-4'>
        <div className='mt-4 text-lg'>
          Set the amount you would like to transfer to your myFloc account from your external
          account.
        </div>

        <Button className='mt-4 flat-left' onClick={() => setTransferInfo(true)} primary text>
          How Much Can I Transfer?
        </Button>

        <HiddenPanel className='mt-4' setShow={setTransferInfo} show={transferInfo}>
          {TRANSFER_INFO}
        </HiddenPanel>

        {accounts && cardAch ? (
          <>
            <div className='mt-4 text-lg font-medium'>From</div>
            <div className='text-lg'>
              {accounts.bank} (...{accounts.accountNumberLast4})
            </div>

            <div className='mt-10 text-lg font-medium'>To</div>
            <div className='text-lg'>
              myFloc Card Account (...
              {cardAch.account_number.slice(cardAch.account_number.length - 4)})
            </div>

            <form className='mt-12' onSubmit={handleSubmit(onSubmit, showValidationToast)}>
              <FormInput
                errors={errors}
                hintContent={`Today you can transfer up to ${currency(withdrawalLimit)}`}
                label='Transfer Amount'
                name='amount'
                register={register}
                type={FORM_INPUT_TYPES.currency}
                validationErrors={apiErrors?.validationErrors}
                required
              />

              {/* Actions */}
              <div className='flex flex-col-reverse items-center mt-8 md:flex-row md:justify-around md:items-center md:mt-14'>
                {/* eslint-disable-next-line react/jsx-handler-names */}
                <Button
                  className='mt-8 md:mt-0'
                  onClick={history.goBack}
                  type='button'
                  primary
                  text
                >
                  Back to Summary
                </Button>

                <Button
                  className='m-0'
                  disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}
                  isLoading={loading}
                >
                  Review Transfer
                </Button>
              </div>
            </form>
          </>
        ) : (
          <Loading />
        )}

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.transfer}
        />
        <Panel className='mx-auto mt-10 w-5/6 lg:hidden' type={PANEL_TYPES.transfer} />
      </div>
    </>
  )
}

export default StepFour
