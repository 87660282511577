/**
 * Notifications store management for global access to notifications/alerts for the currently logged in user
 */
// eslint-disable-next-line import/order
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/order
import { MYFLOC_API_HOST_URL, ENDPOINTS } from '@/common/constants'

export const getNotifications = createAsyncThunk(
  'notifications/get',
  /**
   * Get user's notifications and alerts
   *
   * @returns {Promise<>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const user = state.user
    const activePersonId = localStorage.getItem('activePersonId') || null
    const notificationsUrl = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NOTIFICATIONS(
      activePersonId
    )}`
    const headers = {
      Accept: 'application/json, text/plain',
    }
    // If user is logged in, add token
    if (user.id) {
      const auth0Token = localStorage.getItem('auth0Token') || null
      headers.Authorization = `Bearer ${auth0Token}`
    }

    const notificationsFetched = await fetch(notificationsUrl, {
      headers,
      method: 'GET',
    })

    return notificationsFetched.json()
  }
)

export const getAlertsForToasts = createAsyncThunk(
  'notifications/getAlertsForToasts',
  /**
   * Get alerts and dispatch them as toasts
   *
   * @returns {Promise<>}
   */
  async (_, { getState }) => {
    const state = getState()
    const user = state.user
    const activePersonId = localStorage.getItem('activePersonId') || null
    if (!activePersonId) {
      return null
    }

    const alertsUrl = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NOTIFICATIONS_GET_TOASTS(
      activePersonId
    )}`

    const headers = {
      Accept: 'application/json, text/plain',
    }
    // If user is logged in, add token
    if (user.id) {
      const auth0Token = localStorage.getItem('auth0Token') || null
      headers.Authorization = `Bearer ${auth0Token}`
    }

    const alertsFetched = await fetch(alertsUrl, {
      headers,
      method: 'GET',
    })

    return alertsFetched.json()
  }
)

export const updateAlertViewed = createAsyncThunk(
  'notifications/updateAlertViewed',
  /**
   * Mark an alert as dismissed in the database (set the viewed time)
   *
   * @returns {Promise<>}
   */
  async (alertId, { getState }) => {
    const state = getState()
    const auth0Token = localStorage.getItem('auth0Token') || state.user.auth0Token
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NOTIFICATIONS_DISMISS_ALERT(
      alertId
    )}`

    const alertData = {
      id: alertId,
      status: 'dismissed',
      timeReceived: new Date()
        .toISOString(),
      timeViewed: new Date()
        .toISOString(),
    }

    await fetch(url, {
      body: JSON.stringify(alertData),
      headers: {
        Authorization: `Bearer ${auth0Token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    return { alertId }
  }
)

export const markToastNotificationDelivered = createAsyncThunk(
  'notifications/markToastNotificationDelivered',
  /**
   * Remove toasted alerts then update database
   *
   * @returns {Promise<>}
   */
  async (alertId, { getState }) => {
    const state = getState()
    const auth0Token = localStorage.getItem('auth0Token') || state.user.auth0Token
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NOTIFICATIONS_SET_ALERTS_DELIVERED(
      alertId
    )}`

    const alertData = {
      id: alertId,
      status: 'delivered',
      timeDelivered: new Date()
        .toISOString(),
    }

    const updateResult = await fetch(url, {
      body: JSON.stringify(alertData),
      headers: {
        Authorization: `Bearer ${auth0Token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })

    return updateResult.json()
  }
)

const notificationsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.items = action.payload.items
      state.notificationsCount = action.payload.notificationsCount
    })
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.items = []
      state.notificationsCount = 0
    })
    builder.addCase(getAlertsForToasts.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.alertsToToast = action.payload.items
      }
    })
    builder.addCase(
      markToastNotificationDelivered.fulfilled,
      (state, alert) => {
        state.alertsToToast = []
      }
    )
    builder.addCase(updateAlertViewed.fulfilled, (state, { payload }) => {
      state.items = state.items.filter(alert => alert.id !== payload.alertId)
      state.notificationsCount = state.items.length
    })
  },
  initialState: {
    alertsToToast: [],
    items: [],
    notificationsCount: 0,
  },
  name: 'notifications',
  reducers: {},
})

export default notificationsSlice.reducer
