import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import Dropdown from '@/common/components/Dropdown'
import Icon from '@/common/components/Icon'
import Loading from '@/common/components/Loading'
import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import {
  ENDPOINTS,
  MYFLOC_PHONE_NUMBER,
  MYFLOC_PHONE_NUMBER_TEXT,
  ROLES,
  ROUTES,
  HTTP_METHODS,
  ENROLLMENT,
  ENV_OPTIONS,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { checkEnrollmentStepPassed, currency } from '@/common/utils'
import { history } from '@/history'
import { getCards } from '@/redux'
import { getInvited, getTeam } from '@/redux/team'
import { addToast, TOAST_TYPES } from '@/redux/toasts'

import ActionRow from './ActionRow'
import { TeamCard } from './TeamCard'

/**
 * Export out the cardAction call so it can be used in myAccount as well
 *
 * @param {string}   action     freeze, unfreeze
 * @param {function} dispatch
 * @param {Object}   person     must include firstName and lastName
 * @param {function} submitForm
 * @param {boolean}  myAccount  myAccount specific
 */
export const cardAction = async (action, dispatch, person, submitForm, myAccount = false) => {
  try {
    const alertText = action === 'freeze' ? 'frozen' : 'unfrozen'
    const endpoint = action === 'freeze'
      ? ENDPOINTS.NETSPEND_CARD_LOCK(person.id)
      : ENDPOINTS.NETSPEND_CARD_UNLOCK(person.id)

    const { response } = await submitForm(endpoint, {
      method: HTTP_METHODS.POST,
    })
    if (response.status === 200) {
      if (myAccount) {
        dispatch(getCards())
        dispatch(
          addToast({
            subtitle: `Your card has been ${alertText} successfully.`,
            title: 'Card ' + alertText,
            type: TOAST_TYPES.success,
          })
        )
      }
      else {
        dispatch(getTeam())
        const successMessage = action === 'freeze'
          ? `Spending on ${person.firstName} ${person.lastName}'s card has been frozen successfully. `
          : `Spending on ${person.firstName} ${person.lastName}'s card has been reactivated. `

        dispatch(
          addToast({
            subtitle: successMessage,
            title: 'Card ' + alertText,
            type: TOAST_TYPES.success,
          })
        )
      }
    }
    else {
      dispatch(
        addToast({
          subtitle: response.error.message,
          title: 'Unexpected Error',
          type: TOAST_TYPES.error,
        })
      )
    }
  }
  catch (e) {
    dispatch(
      addToast({
        subtitle: e.error.message,
        title: 'Unexpected Error',
        type: TOAST_TYPES.error,
      })
    )
  }
}

export const BuildTeam = ({
  teamList,
  currentStep,
  teamBalances,
  // teamCards,
  myFlocPerson,
  isAdmin,
  teamStatusFilter,
  changeTeamStatusFilter,
  teamFilterOptions,
  teamStatusAllow,
  hasMultipleLeads,
}) => {
  const dispatch = useDispatch()
  const baseClasses = 'max-w-5xl bg-white rounded mx-auto p-8 px-5 md:px-8 mt-8'
  const classes = {
    primarySection: 'flex flex-row items-center bg-primary text-white font-bold py-2 px-4 mt-6',
    wrapper: baseClasses,
  }
  const { submitForm } = useSubmitForm()
  const [actionModalAccepted] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const [actionModalText, setActionModalText] = useState('')
  const [successAction, setSuccessAction] = useState('')
  const [actionSuccessPerson, setActionSuccessPerson] = useState('')
  const [actionToRun, setActionToRun] = useState(null)
  const [myFlocPersonRole] = useState(myFlocPerson?.role)
  const [openReplacement, setOpenReplacement] = useState(false)

  const enrollmentStatus = useSelector(state => state.user.activePersonStatus?.enrollmentFlow)
  const kycStatus = useSelector(state => state.user.activePersonStatus?.nsPersonStatusKyc)

  const disabled = !checkEnrollmentStepPassed(enrollmentStatus, ENROLLMENT.NS_CREATED) || !kycStatus

  // Dropdown generator
  const dropdownActions = {
    card: action => {
      return {
        text: action + ' Card',
        value: camelize(action + 'Card'),
      }
    },
    invite: action => {
      return { text: action + ' Invitation', value: camelize(action) + 'Invitation' }
    },
    removePerson: role => {
      return { text: 'Remove ' + role, value: 'removePerson' }
    },
    view: action => {
      return { text: action, value: camelize(action) }
    },
  }

  // Logic for what options to show in the dropdown based on a bunch of factors
  const leadInsiderActionOptions = teamPerson => {
    const dropdownOptions = []
    switch (teamPerson.role) {
      case ROLES.LEAD:
        if (teamList.lead.cardStatus !== 'no-card') {
          dropdownOptions.push(dropdownActions.view('View Transactions'))

          if (teamList.lead.cardStatus === 'active') {
            if (teamList.lead.cardLockStatus === 'unlocked') {
              dropdownOptions.push(dropdownActions.card('Freeze'))
            }
            else dropdownOptions.push(dropdownActions.card('Unfreeze'))
          }
        }

        if (teamPerson.cardStatus !== 'no-card' &&
          teamPerson.cardStatus !== 'closed' &&
          teamPerson.status !== 'closed') {
          dropdownOptions.push(dropdownActions.card('Request New'))
        }

        break

      case ROLES.INSIDER:
        if (teamPerson.status === 'invited' && myFlocPersonRole !== ROLES.INSIDER) {
          dropdownOptions.push(dropdownActions.invite('Resend'))
          dropdownOptions.push(dropdownActions.invite('Cancel'))
        }
        else if (teamPerson.cardStatus === 'active') {
          if (teamPerson.cardLockStatus === 'unlocked') {
            dropdownOptions.push(dropdownActions.card('Freeze'))
          }
          else dropdownOptions.push(dropdownActions.card('Unfreeze'))
        }

        if (
          myFlocPersonRole !== ROLES.INSIDER &&
          teamPerson.status !== 'closed' &&
          teamPerson.status !== 'invited'
        ) {
          dropdownOptions.push(dropdownActions.removePerson('Insider'))
        }

        if (teamPerson.cardStatus !== 'no-card' &&
          teamPerson.cardStatus !== 'closed' &&
          teamPerson.status !== 'closed') {
          dropdownOptions.push(dropdownActions.card('Request New'))
        }

        break

      case ROLES.TEAM_MEMBER:
        if (teamPerson.status === 'invited') {
          // Invitations can only be cancelled or resent
          dropdownOptions.push(dropdownActions.invite('Resend'))
          dropdownOptions.push(dropdownActions.invite('Cancel'))
        }
        else if (teamPerson.status === 'closed') {
          // Closed persons are view only
          dropdownOptions.push(dropdownActions.view('View Transactions'))
        }
        else if (teamPerson.cardStatus === 'closed') {
          // Possible for card status to be closed while waiting for a new card webhook
          dropdownOptions.push(dropdownActions.view('View Transactions'))
          dropdownOptions.push(dropdownActions.view('View/Edit Permissions'))
          dropdownOptions.push(dropdownActions.card('Add Funds To'))
          dropdownOptions.push(dropdownActions.removePerson('Team Member'))
        }
        else if (teamPerson.cardStatus === 'no-card') {
          // Without netspend we can only remove the member
          dropdownOptions.push(dropdownActions.removePerson('Team Member'))
        }
        else {
          // Once card has been added we have full netspend options
          dropdownOptions.push(dropdownActions.view('View Transactions'))
          dropdownOptions.push(dropdownActions.view('View/Edit Permissions'))
          dropdownOptions.push(dropdownActions.card('Add Funds To'))

          if (teamPerson.cardLockStatus === 'unlocked') {
            dropdownOptions.push(dropdownActions.card('Freeze'))
          }
          else {
            dropdownOptions.push(dropdownActions.card('Unfreeze'))
          }
          dropdownOptions.push(dropdownActions.removePerson('Team Member'))
        }

        if (teamPerson.cardStatus !== 'no-card' &&
          teamPerson.cardStatus !== 'closed' &&
          teamPerson.status !== 'closed') {
          dropdownOptions.push(dropdownActions.card('Request New'))
        }

        break

      case ROLES.FRIEND:
        if (teamPerson.status === 'invited') {
          dropdownOptions.push(dropdownActions.invite('Resend'))
          dropdownOptions.push(dropdownActions.invite('Cancel'))
        }
        else if (teamPerson.status === 'active') {
          dropdownOptions.push(dropdownActions.removePerson('Friend'))
        }
        break

      default:
    }
    return dropdownOptions
  }

  const memberFriendActionOptions = teamPerson => {
    const dropdownOptions = []
    const viewPermissions = !!(
      myFlocPerson.notificationSettings?.showAllTransactions || myFlocPerson.role === ROLES.FRIEND
    )

    if (!viewPermissions || teamPerson.role === ROLES.FRIEND) {
      return []
    }

    if (
      teamPerson.id === myFlocPerson.id &&
      teamPerson.cardLockStatus === 'unlocked' &&
      teamPerson.cardStatus === 'active'
    ) {
      dropdownOptions.push(dropdownActions.card('Freeze'))
    }

    if (
      teamPerson.status === 'active' ||
      teamPerson.status === 'closed' ||
      teamPerson.cardStatus === 'closed'
    ) {
      dropdownOptions.push(dropdownActions.view('View Transactions'))
    }

    return dropdownOptions
  }

  const actionDropdownOptions = teamPerson => {
    let optionDefinitions = []

    switch (myFlocPersonRole) {
      case ROLES.LEAD:
      case ROLES.INSIDER:
        optionDefinitions = leadInsiderActionOptions(teamPerson)
        break

      case ROLES.TEAM_MEMBER:
      case ROLES.FRIEND:
        optionDefinitions = memberFriendActionOptions(teamPerson)
        break

      default:
    }

    return optionDefinitions
  }

  const dropdownOnChange = (person, option) => {
    const action = option.value

    switch (action) {
      case 'viewEditPermissions':
        history.push(`/team/set-permissions/${person.id}`)
        break

      case 'resendInvitation':
        verifyAction(option, person)
        break

      case 'addFundsToCard':
        if (person.role === ROLES.INSIDER) {
          history.push(ROUTES.TRANSFER_FUNDS)
        }
        else {
          history.push(`${ROUTES.MOVE_MONEY}?to=${person.id}`)
        }

        break

      case 'freezeCard':
        verifyAction(option, person)
        break

      case 'unfreezeCard':
        verifyAction(option, person)
        break

      case 'cancelInvitation':
        verifyAction(option, person)
        break

      case 'removePerson':
        if (
          (person.role === ROLES.INSIDER || person.role === ROLES.TEAM_MEMBER) &&
          person.enrollmentFlow !== 'myfloc-account-created'
        ) {
          history.push(`/team/remove-member/${person.id}`)
        }
        else {
          verifyAction(option, person)
        }
        break

      case 'viewTransactions':
        history.push(ROUTES.ACCOUNT_PERSON(person.id))
        break

      case 'requestNewCard':
        if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
          import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
          history.push(ROUTES.REPLACE_CARD_ID(person.id))
        }
        else {
          setOpenReplacement(true)
        }
        break

      default:
    }
  }

  useEffect(() => {
    const action = actionToRun?.value

    if (actionSuccessPerson && action) {
      switch (action) {
        case 'cancelInvitation':
          cancelInvitation(actionSuccessPerson)
          break

        case 'removePerson':
          removePerson(actionSuccessPerson)
          break

        case 'resendInvitation':
          resendInvitation(actionSuccessPerson)
          break

        case 'freezeCard':
          cardAction('freeze', dispatch, actionSuccessPerson, submitForm)
          break

        case 'unfreezeCard':
          cardAction('unfreeze', dispatch, actionSuccessPerson, submitForm)
          break

        default:
      }
    }
    setActionSuccessPerson(null)
    setSuccessAction(null)
  }, [actionToRun])

  const actionConfirmText = (action, person) => {
    let modalText = ''
    switch (action) {
      case 'cancelInvitation':
        modalText =
          'Do you want to cancel this myFloc invitation for ' +
          person.firstName +
          ' ' +
          person.lastName +
          '?'

        break

      case 'removePerson':
        modalText =
          'Do you want to remove ' + person.firstName + ' ' + person.lastName + ' from myFloc?'
        break

      case 'resendInvitation':
        modalText =
          'Do you want to reinvite ' +
          person.firstName +
          ' ' +
          person.lastName +
          ' to your myFloc team?'
        break

      case 'freezeCard':
        modalText =
          'Are you sure you want to freeze ' + person.firstName + ' ' + person.lastName + '\'s card?'
        break

      case 'unfreezeCard':
        modalText =
          'Do you want to remove the spending freeze for ' +
          person.firstName +
          ' ' +
          person.lastName +
          '\'s card?'
        break

      default:
    }
    return modalText
  }

  const verifyAction = (option, person) => {
    setSuccessAction(option)
    setActionSuccessPerson(person)
    setActionModalText(actionConfirmText(option.value, person))
    setShowActionModal(true)
  }

  const cancelInvitation = async person => {
    const { response, data } = await submitForm(ENDPOINTS.INVITATIONS(person.inviteId), {
      method: HTTP_METHODS.DELETE,
      noErrorToast: true,
    })

    if (response.status === 204) {
      dispatch(getInvited())
      dispatch(
        addToast({
          subtitle:
            'Invitation for ' +
            person.firstName +
            ' ' +
            person.lastName +
            '(' +
            person.email +
            ')' +
            ' revoked successfully.',
          title: 'Invitation Revoked',
          type: TOAST_TYPES.success,
        })
      )
    }
    else {
      // invitation not found
      if (response.status === 404) {
        return dispatch(
          addToast({
            subtitle: `${person.firstName} ${person.lastName} (${person.email}) has already accepted the invite.`,
            title: 'Unable to Cancel Invitation',
            type: TOAST_TYPES.error,
          })
        )
      }

      dispatch(
        addToast({
          subtitle: data.error.message,
          title: 'Unexpected Error',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  const resendInvitation = async person => {
    const { response } = await submitForm(ENDPOINTS.INVITATIONS_RESEND(person.inviteId), {
      method: HTTP_METHODS.POST,
    })

    if (response.status === 200) {
      dispatch(
        addToast({
          subtitle:
            'The invitation has been resent to ' +
            person.firstName +
            ' ' +
            person.lastName +
            ' (delivered to ' +
            person.email +
            ')',
          title: 'Invitation Resent',
          type: TOAST_TYPES.success,
        })
      )
    }
    else {
      dispatch(
        addToast({
          subtitle: 'Error resending invitation. Please try again later.',
          title: 'Unexpected Error',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  const removePerson = async person => {
    const { response } = await submitForm(ENDPOINTS.PERSONS_ID(person.id), {
      method: HTTP_METHODS.DELETE,
    })

    if (response.status === 204) {
      dispatch(getTeam())
      dispatch(
        addToast({
          subtitle:
            person.firstName +
            ' ' +
            person.lastName +
            ' has been removed from the team successfully',
          title: 'Person Removed From Team',
          type: TOAST_TYPES.success,
        })
      )
    }
    else {
      dispatch(
        addToast({
          subtitle: response.error.message,
          title: 'Error Removing Person',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  const camelize = string => {
    const newString = string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase()
    })
    return newString.replace('/', '')
  }

  const getBalance = (balances, teamPerson) => {
    if (balances && teamPerson) {
      const balanceFound = balances.find(balance => balance.id === teamPerson.id)
      if (balanceFound) {
        return currency(balanceFound.available.amount / 100)
      }
      else return '$0.00'
    }
  }

  return myFlocPerson && teamList && teamStatusFilter ? (
    <div className={classes.wrapper}>
      {/* team details */
        currentStep < (hasMultipleLeads ? 3 : 4) ? (
          <>
            <h1>{hasMultipleLeads ? 2 : 3}. Build your myFloc Team</h1>

            <h5 className='mt-4 text-lg'>
              Invite people to various team roles to help manage your account, make purchases, or
              monitor transactions.
            </h5>
          </>
        ) : (
          <>
            <div className='flex flex-col md:flex-row'>
              <h3 className='mt-3 align-middle'>myFloc Team</h3>

              <Dropdown
                className='md:ml-6'
                label='Current Team'
                onChange={option => changeTeamStatusFilter(option.value)}
                options={teamFilterOptions}
                value={teamStatusFilter}
              />
              <div className='flex-grow justify-between'>
                {isAdmin && (
                  <Button
                    className='md:float-right flat-left'
                    link={ROUTES.MOVE_MONEY}
                    arrow
                    primary
                    text
                  >
                    Manage/Move Money
                  </Button>
                )}
              </div>
            </div>
          </>
        )}

      {/* Primary Account */}
      <section className={classes.primarySection}>
        <Icon height={20} name='birds_three' width={20} />
        <span className='ml-3 text-white subtitle-1'>Primary Account</span>
      </section>

      {/* Combine lead and insider balance ------------------------------------------- */}
      <div className='md:flex md:flex-row'>
        <div className={`${teamBalances ? 'flex-1 min-w-0 ' : ''}`}>
          {/* lead details */
            teamList.lead && teamStatusAllow[teamStatusFilter].includes(teamList.lead.status) ? (
              <TeamCard
                dropdownOnChange={dropdownOnChange}
                dropdownOptions={actionDropdownOptions(teamList.lead)}
                key={teamList.lead.id}
                member={teamList.lead}
                you={teamList.myFlocPerson.id === teamList.lead.id}
              />
            ) : null}

          {/* insider details */
            teamStatusAllow[teamStatusFilter].map(teamStatus =>
              teamList.insider[teamStatus].length > 0
                ? teamList.insider[teamStatus].map(insider => (
                  <TeamCard
                    dropdownOnChange={dropdownOnChange}
                    dropdownOptions={actionDropdownOptions(insider)}
                    key={insider.id}
                    member={insider}
                    you={teamList.myFlocPerson.id === insider.id}
                  />
                ))
                : null
            )}
        </div>

        {teamStatusAllow[teamStatusFilter].includes(
          teamList.lead.status,
          teamList.insider?.status
        ) &&
          teamBalances && (
          <div className='flex flex-col justify-center items-center py-5 px-2 text-left md:text-right md:border-l-0 border-u'
            style={{ minWidth: '18rem' }}>
            <div className='body-2'>Available Balance</div>
            <h2 className='mb-0 text-secondary'>{getBalance(teamBalances, teamList.lead)}</h2>
          </div>
        )}
      </div>

      {/* invite insider if none exists */
        !teamList.insider.active.length && !teamList.insider.invited.length && isAdmin ? (
          <>
            <ActionRow
              disabled={disabled}
              label='Add a myFloc Insider'
              link={ROUTES.TEAM_ADD_INSIDER}
            >
              Grant <b>full access to your myFloc Card Account</b> with ability to{' '}
              <b>manage other team members</b> (includes a debit card).
              <div className='mt-2' />
              Maximum of 1 per account
            </ActionRow>
          </>
        ) : null
      }

      {/* Team Member Accounts ----------------------------------------------------- */}
      <section className={classes.primarySection}>
        <Icon height={20} name='birds_three' width={20} />
        <span className='ml-3 text-white'>myFloc Team Members</span>
      </section>

      {/* members details */
        teamStatusAllow[teamStatusFilter].map(teamStatus =>
          teamList.teamMember[teamStatus].length > 0
            ? teamList.teamMember[teamStatus].map(member => (
              <div className='flex flex-col md:flex-row border-u' key={member.id}>
                <TeamCard
                  balance={getBalance(teamBalances, member)}
                  dropdownOnChange={dropdownOnChange}
                  dropdownOptions={actionDropdownOptions(member)}
                  member={member}
                  you={teamList.myFlocPerson.id === member.id}
                />
              </div>
            ))
            : null
        )
      }
      {/* add members if quota isn't maxed out */
        (!teamList.teamMember ||
          teamList.teamMember.active.length + teamList.teamMember.invited.length < 5) &&
          isAdmin ? (
            <>
              <ActionRow
                disabled={disabled}
                label='Add a myFloc Team Member'
                link={ROUTES.TEAM_ADD_TEAM_MEMBER}
              >
                <b>Provide a card</b> and set permissions to enable spending from your myFloc Card
              Account.
                <div className='mt-2' />
              Maximum of 5 per account
              </ActionRow>
            </>
          ) : null
      }

      <section className={classes.primarySection}>
        <Icon height={20} name='birds_three' width={20} />
        <span className='ml-3 text-white'>myFloc Friends</span>
      </section>

      {/* friends details */
        teamStatusAllow[teamStatusFilter].map(teamStatus =>
          teamList.friend[teamStatus].length > 0
            ? teamList.friend[teamStatus].map(friend => (
              <div className='flex flex-col md:flex-row border-u' key={friend.id}>
                <TeamCard
                  dropdownOnChange={dropdownOnChange}
                  dropdownOptions={actionDropdownOptions(friend)}
                  member={friend}
                  you={teamList.myFlocPerson.id === friend.id}
                />
              </div>
            ))
            : null
        )
      }
      {/* add friends if quota isn't maxed out */
        (!teamList.friend || teamList.friend.active.length + teamList.friend.invited.length < 5) &&
          isAdmin ? (
            <>
              <ActionRow disabled={disabled} label='Add a myFloc Friend' link={ROUTES.TEAM_ADD_FRIEND}>
              Provide <b>visibility into the myFloc Card Account and transactions</b> made by other
              team members (does not include a card).
                <div className='mt-2' />
              Maximum of 5 per account
              </ActionRow>
            </>
          ) : null
      }
      <Modal
        cancelCallback={() => { }}
        cancelText={(!actionModalAccepted && 'Cancel') || null}
        closeOnBackdropClick={actionModalAccepted}
        open={showActionModal}
        setOpen={() => setShowActionModal(!showActionModal)}
        successCallback={() => {
          setActionToRun(successAction)
        }}
        successText='Confirm'
        title='Please Confirm Action'
        type={(!actionModalAccepted && MODAL_TYPES.confirm) || null}
      >
        {actionModalText}
      </Modal>

      {/* Replacement modal */}
      <Modal open={openReplacement} setOpen={setOpenReplacement} type={MODAL_TYPES.alert}>
        To request a replacement card, please contact
        <Button className='inline' href={'tel:' + MYFLOC_PHONE_NUMBER} tag='a' primary text>
          myFloc Customer Care at {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
        </Button>
      </Modal>
    </div >
  ) : (
    <Loading />
  )
}

BuildTeam.propTypes = {
  /**
   * Setter for teamStatusFilter
   */
  changeTeamStatusFilter: PropTypes.func,

  /**
   * Step that the stepper is on, 4 = past enrollment
   */
  currentStep: PropTypes.number,

  /**
   * Has more than one lead
   */
  hasMultipleLeads: PropTypes.bool,

  /**
   * Whether we're rendering this screen for an admin or not
   */
  isAdmin: PropTypes.bool,

  /**
   * Currently logged in myFlocPerson
   */
  myFlocPerson: PropTypes.object,

  /**
   * Balances for all cards in teamBalances array
   */
  teamBalances: PropTypes.array,

  /**
   * All team member cards
   */
  teamCards: PropTypes.array,

  /**
   * All options for the team status filters
   */
  teamFilterOptions: PropTypes.array,

  /**
   * All team members for this section sorted by role
   */
  teamList: PropTypes.object,

  /**
   * Permitted statuses for each team status category
   */
  teamStatusAllow: PropTypes.object,

  /**
   * The currently selected dashboard filter list
   */
  teamStatusFilter: PropTypes.string,
}

export default BuildTeam
