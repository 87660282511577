import React from 'react'

import Button from '@/common/components/Button'
import Loading from '@/common/components/Loading'
import { ROUTES } from '@/common/constants'
import { useGetBankCard } from '@/common/hooks'
import { history } from '@/history'

const DirectTransfer = props => {
  const { cardAch } = useGetBankCard({ getAccounts: false })

  return (
    <>
      <div className='relative flex-col text-left main-container'>
        <h1 className='text-secondary'>How to set up a transfer directly with your bank</h1>

        <div className='mt-10 text-lg'>
          You can add money to your myFloc Card Account directly from many bank accounts using an
          external bank transfer.
        </div>
        <div className='p-4'>
          <ol className='list-decimal'>
            {/* 1 */}
            <li>
              Log in to your bank account online or visit your local bank branch to{' '}
              <span className='font-bold text-secondary'>request an external bank transfer</span>.
            </li>

            {/* 2 */}
            <li className='mt-5'>
              Follow instructions provided by your bank to{' '}
              <span className='font-bold text-secondary'>
                add your myFloc Card Account as a new External Account
              </span>
              . Use your myFloc details below as requested.
            </li>

            <div className='flex flex-row justify-center my-6'>
              {cardAch ? (
                <>
                  <div className='table py-5 px-8 rounded-md border shadow border-grey'>
                    <div className='font-bold'>Your myFloc Card Account Details</div>
                    <div className='flex flex-row mt-3'>
                      <div className='flex-col flex-grow'>
                        <div className='row'>Bank name:</div>
                        <div className='mt-1 row'>Account Type:</div>
                        <div className='mt-1 row'>Routing No.</div>
                        <div className='mt-1 row'>Account No.</div>
                      </div>
                      <div className='flex-col flex-grow'>
                        <div className='row'>Pathward N.A.</div>
                        <div className='mt-1 row'>Checking</div>
                        <div className='mt-1 row'>{cardAch.routing_number}</div>
                        <div className='mt-1 row'>{cardAch.account_number}</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Loading />
              )}
            </div>

            {/* 3 */}
            <li className='mt-5'>
              Use your new external account to{' '}
              <span className='font-bold text-secondary'>
                transfer money into your myFloc Card Account
              </span>
              . If your bank provides the option, we recommend setting a{' '}
              <span className='font-bold text-secondary'>scheduled recurring deposit</span> to help
              keep your account continuously funded.
            </li>
          </ol>
        </div>

        <div className='mt-12 text-sm'>
          Keep in mind that not all banks will support this feature. Availability of bank transfers
          and associated fees are determined by the transferor’s bank. Fees may be charged to the
          transferor’s bank account by the service provider or originating bank.
        </div>

        <div className='text-center'>
          <Button className='mt-12' onClick={() => history.push(ROUTES.DASHBOARD)}>
            Return to Summary page
          </Button>
        </div>
      </div>
    </>
  )
}

export default DirectTransfer
