import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { formPropTypes } from '@/common/propTypes'
import { registerWithChange } from '@/common/utils'
import Button from '@components/Button'
import ButtonGroup from '@components/ButtonGroup'

import styling from './robitFormRadio.module.scss'

/**
 * The `FormRadio` component is a group of radio buttons under a common form name
 */
export const FormRadio = React.memo(
  ({
    asButtonGroup = false,
    bottomTextClassName,
    circular,
    className,
    defaultSelected,
    disabled,
    errorClassName,
    errorText,
    hintContent,
    inline,
    inputClassName,
    invalid,
    label,
    labelButtonAction,
    labelButtonText,
    labelClassName,
    name,
    options,
    pill,
    register,
    required,
    setFieldValue = () => {},
    size,
    squared,
    valid,
    ...rest
  }) => {
    const [selected, setSelected] = useState(defaultSelected ?? null)

    const containerClasses = classNames(
      'form-control-container',
      styling['radios-container'],
      circular && styling.circular,
      inline && styling.inline,
      asButtonGroup && styling['as-button-group'],
      disabled && styling.disabled,
      className
    )

    const inputClasses = classNames(
      valid && styling['is-valid'],
      invalid && styling['is-invalid'],
      inputClassName
    )

    const handleChange = (e, value) => {
      if (e.target.checked && setFieldValue) {
        setFieldValue(name, value)
      }
      setSelected(value)
    }

    const renderRadio = ({ id, isSelected, value, onChangeHandler }) => {
      return (
        <input
          checked={isSelected}
          className={inputClasses}
          disabled={disabled}
          id={id}
          name={name}
          type='radio'
          value={value}
          {...registerWithChange(name, register, required, onChangeHandler)}
        />
      )
    }

    const renderOptions = () => {
      return options.map(({ label, value }, idx) => {
        const isSelected = selected === value
        const id = `${name}_${idx}`
        const labelClasses = classNames(
          styling['form-radio'],
          valid && styling['is-valid'],
          invalid && styling['is-invalid'],
          isSelected && styling['is-selected'],
          labelClassName
        )
        if (asButtonGroup) {
          return (
            <>
              {renderRadio({ id, isSelected, value })}
              <Button
                onClick={e => handleChange(e, value)}
                outline={!isSelected}
                pill={pill}
                size={size}
                squared={squared}
                styleType={isSelected ? 'primary' : 'light'}
              >
                {label}
              </Button>
            </>
          )
        }
        else {
          return (
            <label className={labelClasses} key={id}>
              {renderRadio({
                id, isSelected, onChangeHandler: e => handleChange(e, value), value,
              })}
              <label aria-hidden='true' className={styling['custom-control-label']} htmlFor={id} />
              <span className={styling.description}>{label}</span>
            </label>
          )
        }
      })
    }

    // update selected to match value
    useEffect(() => {
      if (typeof defaultSelected === 'undefined') return
      if (defaultSelected === selected) return

      setSelected(defaultSelected)
    }, [defaultSelected, selected])

    return (
      <div className={containerClasses} {...rest}>
        {label ? (
          <label className={labelClassName}>
            {label}
            {required && '*'}
            {labelButtonText && (
              <Button
                className='block m-0 border-0 md:inline md:ml-2'
                onClick={labelButtonAction}
                role='button'
                tag='a'
                primary
                text
              >
                {labelButtonText}
              </Button>
            )}
          </label>
        ) : (
          []
        )}
        {hintContent && label ? <div className='form-control-hint'>{hintContent}</div> : []}
        <div className={styling['form-radio-group']}>
          {asButtonGroup ? (
            <ButtonGroup vertical={!inline}>{renderOptions()}</ButtonGroup>
          ) : (
            renderOptions()
          )}
        </div>
        <div className='form-control-descenders'>
          <div>
            {invalid && errorText ? <div className='form-control-error'>{errorText}</div> : []}
            {hintContent && !label ? <div className='form-control-hint'>{hintContent}</div> : []}
          </div>
          {required && !label && !invalid && <div className='form-control-required'>Required</div>}
        </div>
      </div>
    )
  }
)

FormRadio.propTypes = {
  ...formPropTypes,
  /**
   * Whether or not to visually display the radio's as a button group
   */
  asButtonGroup: PropTypes.bool,
  /**
   * A more traditional Circular radio button
   */
  circular: PropTypes.bool,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The array of option config objects. This prop should be memoized for performance
   */
  options: PropTypes.array.isRequired,
  /**
   * Whether or not to pill corners when displayed as a button group
   */
  pill: PropTypes.bool,
  /**
   * Function that takes the name of the form field and the value as parameters for bubbling up the change
   */
  setFieldValue: PropTypes.func,
  /**
   * Button size when displayed as a button group
   */
  size: PropTypes.string,
  /**
   * Whether or not to square corners when displayed as a button group
   */
  squared: PropTypes.bool,
}

export default FormRadio
