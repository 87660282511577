import React from 'react'

import PropTypes from 'prop-types'

import Icon from '@components/Icon'

/**
 * Placeholder for loading component
 *
 * @param   {Object}      props
 * @param   {string}      [props.className]
 * @returns {JSX.Element}                   Loading component
 */
export const Loading = ({ className = '' }) => {
  return (
    <div className={`flex flex-row justify-center align-center ${className}`}>
      <Icon name='loading' />
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
}

export default Loading
