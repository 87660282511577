import React, { useRef, useState } from 'react'

import debounce from 'debounce'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CaretIcon } from '@/assets/svg/caret.svg'
import { ReactComponent as EmailIcon } from '@/assets/svg/email.svg'
import { ReactComponent as SmsIcon } from '@/assets/svg/sms.svg'
import AnimateIn from '@/common/components/AnimateIn'
import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import FormRadio from '@/common/components/FormRadio'
import {
  ENDPOINTS,
  HTTP_METHODS,
  ROLES,
  YES_OR_NO,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import {
  addToast, getMyFlocPerson, selectMyFlocPerson, TOAST_TYPES,
} from '@/redux'

export const NotificationOptions = () => {
  const dispatch = useDispatch()

  const myFlocUser = useSelector(state => state.user.user)
  const myFlocPerson = useSelector(selectMyFlocPerson)
  const { notificationSettings } = myFlocPerson
  const receiveSms = notificationSettings?.receiveSms
  const receiveEmails = notificationSettings?.receiveEmails
  const receiveTransactionAlerts = notificationSettings?.receiveTransactionAlerts
  const showAllTransactions = notificationSettings?.showAllTransactions
  const purchaseNotificationThreshold = (notificationSettings?.purchaseNotificationThreshold ?? 1)
  const canReceiveSms = myFlocUser?.mobile && myFlocUser?.smsConsent
  const canSeeAllTransactions = (
    myFlocPerson.role === ROLES.LEAD ||
    myFlocPerson.role === ROLES.INSIDER ||
    (myFlocPerson.role === ROLES.TEAM_MEMBER && showAllTransactions) ||
    myFlocPerson.role === ROLES.FRIEND
  )

  const [showPreferences, setShowPreferences] = useState(false)

  const { submitForm } = useSubmitForm()

  const updateNotifications = async updates => {
    // Ensure threshold is in cents
    if ('purchaseNotificationThreshold' in updates) {
      updates.purchaseNotificationThreshold =
        updates.purchaseNotificationThreshold > 0
          ? Math.max(updates.purchaseNotificationThreshold, 0)
          : 1
    }

    const resp = await submitForm(ENDPOINTS.PERSONS_NOTIFICATIONS(myFlocPerson.id), {
      method: HTTP_METHODS.PATCH,
      payload: { notificationSettings: { ...updates } },
    })

    if (resp.response.status === 200) {
      dispatch(getMyFlocPerson())
      debouncedSuccessToast.current?.clear()
      longDebouncedSuccessToast.current?.clear()

      updates.receiveTransactionAlerts
        ? longDebouncedSuccessToast.current?.()
        : debouncedSuccessToast.current?.()
    }
  }

  const addSuccessToast = () => {
    dispatch(
      addToast({
        autoHideDuration: 5000,
        subtitle: 'Your settings were successfully saved',
        title: 'Success',
        type: TOAST_TYPES.success,
      })
    )
  }

  const debouncedUpdateNotificationsThreshold = useRef(debounce(updateNotifications, 2000))
  const debouncedBlurField = useRef(debounce(el => el?.blur?.(), 2000))
  const debouncedSuccessToast = useRef(debounce(addSuccessToast, 2000))
  const longDebouncedSuccessToast = useRef(debounce(addSuccessToast, 7500))

  return (
    <>
      <div className='rounded-md border-gray-200 md:p-6 md:border md:shadow-md'>
        <div
          className='flex flex-row cursor-pointer'
          onClick={() => setShowPreferences(!showPreferences)}
        >
          <h3 className='mb-0 text-primary'>
            {showPreferences ? 'Hide' : 'Show'} Notification Options
          </h3>
          <CaretIcon
            className={`ml-3 transition-transform duration-300 ease-in-out ${
              showPreferences ? 'rotate-180' : ''
            }`}
            width={16}
          />
        </div>
        <AnimateIn maxHeight={800} show={showPreferences}>
          <div className='pb-2 mt-3'>
            <div>
              For your security, you will be automatically enrolled to receive email notifications
              about your myFloc Card Account when certain events occur. However, you may choose how
              you would like other important notifications delivered to you by selecting your
              preferences below.{' '}
            </div>
            <h3 className='mt-10 text-secondary'>How would you like to receive notifications?</h3>
            <div className='flex flex-col my-4 md:flex-row'>
              <Button
                onClick={() => updateNotifications({ receiveEmails: !receiveEmails })}
                secondary={receiveEmails}
                type='button'
                gray
                outline
              >
                <div className='flex flex-row justify-center items-center'>
                  <EmailIcon className='mr-2 fill-current' height='16px' />
                  <span>Email</span>
                </div>
              </Button>
              {canReceiveSms && (
                <Button
                  className='mt-2 md:mt-0 md:ml-2.5'
                  onClick={() => updateNotifications({ receiveSms: !receiveSms })}
                  secondary={receiveSms}
                  type='button'
                  gray
                  outline
                >
                  <div className='flex flex-row justify-center items-center'>
                    <SmsIcon className='mr-2 fill-current' height='16px' />
                    <span>Text Message</span>
                  </div>
                </Button>
              )}
            </div>
            {(receiveEmails || receiveSms) && (
              <div className='mt-2 break-words'>
                Notifications will be
                {receiveEmails ? ' emailed to ' + myFlocUser.email : ''}
                {receiveEmails && canReceiveSms && receiveSms ? ' and' : ''}
                {canReceiveSms && receiveSms ? ' texted to ' + myFlocUser.mobile : ''}
              </div>
            )}

            {canSeeAllTransactions && (
              <>
                <div className='mt-8 mb-2'>
                  Do you want to receive notifications for purchases made above a certain amount by team
                  members?
                </div>

                <div className='flex flex-col justify-start items-start md:flex-row md:items-center'>
                  <FormRadio
                    className='block mr-4 w-auto min-h-0 lg:max-w-xl'
                    defaultSelected={receiveTransactionAlerts ? YES_OR_NO.YES : YES_OR_NO.NO}
                    name='setPurchaseNotifications'
                    onChange={event => {
                      const updates = { receiveTransactionAlerts: event.target.value === YES_OR_NO.YES }

                      // if no/negative purchase notification threshold, set to a penny
                      if (
                        updates.receiveTransactionAlerts &&
                        (!purchaseNotificationThreshold || purchaseNotificationThreshold < 1)
                      ) {
                        updates.purchaseNotificationThreshold = 1
                      }

                      updateNotifications(updates)
                    }}
                    options={[
                      {
                        label: 'No',
                        value: YES_OR_NO.NO,
                      },
                      {
                        label: 'Yes',
                        value: YES_OR_NO.YES,
                      },
                    ]}
                    inline
                  />

                  {receiveTransactionAlerts && (
                    <FormInput
                      className='mt-4 min-h-0 md:mt-0'
                      currencyClassName='left-3 md:left-auto right-[10rem] top-9 md:top-0'
                      defaultValue={(purchaseNotificationThreshold / 100).toFixed(2)}
                      inputClassName='w-32 ml-px md:ml-4 md:mt-0'
                      label='Notify me of purchases made above:'
                      labelClassName='md:flex md:flex-row font-normal text-base md:items-center'
                      onChange={event => {
                        const domEl = event.target
                        const domVal = parseFloat(domEl.value)

                        // if pending update, cancel it
                        debouncedBlurField.current?.clear()
                        debouncedUpdateNotificationsThreshold.current?.clear()

                        // if non-number, don't attempt update
                        if (isNaN(domVal)) return

                        // ensure notification value is at least a penny
                        if (domVal < 0.01) domEl.value = 0.01

                        const formattedValue = parseFloat(parseFloat(domEl.value)
                          .toFixed(2)) * 100

                        // send update
                        debouncedBlurField.current?.(domEl)
                        debouncedUpdateNotificationsThreshold.current?.({
                          purchaseNotificationThreshold: formattedValue,
                        })
                      }}
                      type={FORM_INPUT_TYPES.currency}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </AnimateIn>
      </div>
    </>
  )
}
