import React from 'react'
// import PropTypes from 'prop-types';

const EditTeamMember = props => {
  return <div>Edit Team Member</div>
}

EditTeamMember.propTypes = {}

export default EditTeamMember
