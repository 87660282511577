import React from 'react'

import PropTypes from 'prop-types'

import joinlead from '@/assets/images/joinlead.png'
import Button from '@/common/components/Button'
import { OPEN_CARD_ACCOUNT_LEGAL } from '@/common/constants/info'

import style from './getStarted.module.scss'

const GetStarted = ({ setButtonValue, setPageClassHandler, setShowActionModal }) => {
  return (
    <div>
      {/* <PageHeader> Get started with myFloc </PageHeader> */}
      <div className={style.container + ' max-w-5xl mx-auto'}>
        <div className='flex flex-col md:flex-row md:justify-between'>
          <div className={style.control_container + ' md:mt-20'}>

            <div className='flex flex-col md:flex-col md:items-start items-start'>
              <div className='flex flex-col'>
                <div className={style.text + ' md:mt-10'}>
                  <h1 className='text-accent'>
                    New to myFloc?
                  </h1>
                  <p className='mt-4 text-[1.8rem] leading-[2.4rem]'>
                    Enroll in myFloc.
                  </p>
                  <Button
                    className={style.button + ' mt-5'}
                    disabled={false}
                    onClick={() => {
                      setPageClassHandler('basic-information')
                      setButtonValue('get-started')
                    }}
                    value={'get-started'} >
                    Get Started
                  </Button>
                </div>
              </div>

              <div className='w-full border-t border-gray-300 m-auto mt-10 mb-10 md:mb-0 max-w-[58rem]' />

              <div className='flex flex-col'>
                <div className={style.text + ' md:mt-10'}>
                  <h1 className='text-accent'>
                    Already part of myFloc?
                  </h1>
                  <p className='mt-4 text-[1.8rem] leading-[2.4rem]'>
                    You can create a new myFloc Card Account by logging
                    in with your existing email address and password.
                  </p>
                  <Button
                    className={style.button + ' mt-5'}
                    disabled={false}
                    onClick={() => {
                      setButtonValue('continue')
                      setShowActionModal(true)
                    }}
                    value={'continue'} >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={style.image}>
            <img
              alt='myFloc hero'
              className='float-right object-contain'
              height={606}
              src={joinlead}
              style={{
                height: 'intrinsic', // layout-rendering fix for safari. see: https://stackoverflow.com/a/61267144
              }}
              width={606}
            />
          </div>
        </div>
      </div>
      <div className='mx-auto mb-12 max-w-5xl mt-10 md:mt-20 p-4 md:p-0'>
        <div className='flex flex-row'>
          <div className='text-[1.4rem] leading-[1.641rem] font-[400]'>
            {OPEN_CARD_ACCOUNT_LEGAL}
          </div>
        </div>
      </div>
    </div>
  )
}

GetStarted.propTypes = {
  /**
   * Current step of enrollment based on stepper
   * */
  currentStep: PropTypes.number,

  /**
   * function to log in with redirection
   * */
  setButtonValue: PropTypes.func,

  /**
   * Function to set page class
   * */
  setPageClassHandler: PropTypes.func,

  /**
   * Function to set show modal
   * */
  setShowActionModal: PropTypes.func,
}

export default GetStarted
