import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { formPropTypes } from '@/common/propTypes'
import { traverseObject } from '@/common/utils'

import RobitFormInput from './RobitFormInput'

export const FORM_INPUT_TYPES = {
  /**
   * Includes the dollar sign prefix with input type number
   */
  currency: 'currency',

  /**
   * CVC mask
   */
  cvc: 'cvc',

  /**
   * Date mask and type
   */
  date: 'date',

  /**
   * Email verification
   */
  email: 'email',

  /**
   * Letters are a bit spaced out and centered for OTP
   */
  otp: 'otp',

  /**
   * Includes a show/hide button
   */
  password: 'password',

  /**
   * Adds a phone number mask
   */
  phone: 'phone',

  /**
   * Pin mask
   */
  pin: 'pin',

  /**
   * Social Security mask
   */
  social: 'social',
}

/**
 * A wrapper around the RobitFormInput component which provides some variations
 * Will automatically handle validation as long as you pass in the error prop, or the
 * validationErrors prop. Preferably both. If either are passed, no need to use the manual
 * valid or errorText props.
 *
 * @param {Object}   props
 * @param {Object}   [props.errors]           The error object returned from react hook form
 * @param {String}   [props.errorText]        A string to display under the error hint
 * @param {String}   [props.inputClass]       Classes which will be added to the inputElement
 * @param {Boolean}  [props.invalid]          To manually trigger the invalid state
 * @param {String}   [props.inputMode]        Mobile keyboard layout loaded
 * @param {String}   [props.inputType]        To manually trigger the invalid state
 * @param {String}   [props.labelClassName]   className of the label
 * @param {String}   props.name               The name of the field, should match with our back-end schema
 * @param {String}   [props.type]             The type of field for special attributes, see the exported TYPES
 * @param {Function} [props.register]         The register function from react hook forms
 * @param {Object}   [props.validationErrors] The validation errors object returned from our API
 */
export const FormInput = ({
  errors = {},
  errorText = null,
  inputClass = '',
  invalid = false,
  inputMode,
  inputType: inputTypeProp = 'text',
  labelClassName = '',
  name,
  register = null,
  type = null,
  validationErrors = {},
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  let currency = false
  let inputClassName = inputClass
  let bottomTextClassName = ''
  let inputType = inputTypeProp
  let passwordHandler
  let sensitiveInput = false
  let mask
  let maxLength

  // Change the input type among other things
  if (type === FORM_INPUT_TYPES.currency) {
    inputType = 'text'
    inputMode = 'decimal'
    currency = true
  }
  else if (type === FORM_INPUT_TYPES.date) {
    inputType = 'numeric'
    mask = '99/99/9999'
  }
  else if (type === FORM_INPUT_TYPES.email) {
    inputType = 'email'
  }
  else if (type === FORM_INPUT_TYPES.otp) {
    inputType = 'numeric'
    inputClassName += 'text-center tracking-widest text-xl mt-4 max-w-xs'
    labelClassName += 'text-center block text-xl font-medium'
    bottomTextClassName += 'text-center mx-auto block max-w-md'
    maxLength = '6'
  }
  else if (type === FORM_INPUT_TYPES.password) {
    sensitiveInput = true
  }
  else if (type === FORM_INPUT_TYPES.phone) {
    inputType = 'tel'
    mask = '(999) 999 9999'
  }
  else if (type === FORM_INPUT_TYPES.social) {
    inputType = 'numeric'
    mask = '999 99 9999'
  }
  else if (type === FORM_INPUT_TYPES.cvc) {
    inputType = 'numeric'
    inputClassName = 'text-center tracking-widest text-xl mt-4 max-w-xs'
    labelClassName += 'text-center block text-xl font-medium'
    bottomTextClassName += 'text-center mx-auto block max-w-md'
    maxLength = '3'
  }
  else if (type === FORM_INPUT_TYPES.pin) {
    inputType = 'numeric'
    inputClassName += 'text-center tracking-widest text-xl mt-4 max-w-xs'
    labelClassName += 'text-center block text-xl font-medium'
    bottomTextClassName += 'text-center mx-auto block max-w-md'
    maxLength = '4'
  }

  if (sensitiveInput) {
    inputType = showPassword ? 'text' : 'password'

    if (type === FORM_INPUT_TYPES.pin) {
      inputType = ''
    }
    passwordHandler = () => {
      setShowPassword(!showPassword)
    }
  }

  return (
    <>
      <RobitFormInput
        bottomTextClassName={bottomTextClassName}
        currency={currency}
        errorText={
          traverseObject(errors, name)?.message ||
          traverseObject(validationErrors, name) ||
          errorText
        }
        inputClassName={inputClassName}
        inputMode={inputMode ?? inputType}
        invalid={
          !!traverseObject(errors, name) || !!traverseObject(validationErrors, name) || invalid
        }
        labelClassName={labelClassName}
        mask={mask}
        maxLength={maxLength}
        name={name}
        password={passwordHandler}
        register={register}
        showPassword={showPassword}
        type={inputType}
        {...props}
      />
    </>
  )
}

FormInput.propTypes = {
  ...formPropTypes,
  /**
   * HTML autocomplete string
   */
  autoComplete: PropTypes.string,
  /**
   * Input Mode
   * - controls which keyboard layout is loaded
   * - https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
   */
  inputMode: PropTypes.string,
  /**
   * Input type
   */
  inputType: PropTypes.string,
  /**
   * The type of the input as explained above
   */
  type: PropTypes.string,
}

export default FormInput
