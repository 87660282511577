import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { animateScroll } from 'react-scroll'

export const TOAST_TYPES = {
  error: 'error',
  notificationToast: 'success',
  /**
   * Success will be auto hidden after 5 seconds
   */
  success: 'success',
}

export const addToast = createAsyncThunk(
  'toasts/addToast',
  /**
   * Using a thunk so we can use setTimeout
   *
   * @param {Object}   toastParams                    The parameters to add a toast
   * @param {string}   [toastParams.subtitle]
   * @param {string[]} [toastParams.subtitles]
   * @param {string}   [toastParams.title]
   * @param {string}   toastParams.type
   * @param {string}   [toastParams.id]
   * @param {boolean}  [toastParams.autoHide]         Can prevent autohide for success or add it to other types
   * @param {number}   [toastParams.autoHideDuration]
   */
  async (toastParams, { dispatch, getState }) => {
    const state = getState().toasts
    const autoHide =
      toastParams.autoHide ||
      (toastParams.autoHide !== false && toastParams.type === TOAST_TYPES.success)

    const setId = () => {
      if ('id' in toastParams) {
        return toastParams.id
      }
      else {
        if (state.list.length) {
          return state.list[state.list.length - 1].id + 1
        }
        else {
          return 1
        }
      }
    }

    const id = setId()

    // Just in case we use subtitle singular
    if (toastParams.subtitle?.length > 0) {
      toastParams.subtitles = [toastParams.subtitle]
    }

    // scroll to the top of page before inserting toast
    const scrollDurationMs = 250
    animateScroll.scrollToTop({ duration: scrollDurationMs })

    await new Promise(resolve => {
      setTimeout(resolve, scrollDurationMs)
    })

    dispatch(addToastToList({ ...toastParams, id }))

    // Remove other toasts after animation is complete
    // setTimeout(() => {
    //   dispatch(removeOtherToasts(id))
    // }, 300)

    // Auto hide after a set time
    if (autoHide) {
      setTimeout(() => {
        dispatch(removeToast(id))
      }, toastParams.autoHideDuration ?? 5000)
    }
  }
)

/**
 * Simple slice for controlling the toasts
 */
const ToastsSlice = createSlice({
  initialState: {
    list: [],
  },
  name: 'toasts',
  reducers: {
    addToastToList: (state, { payload }) => {
      state.list.push(payload)
    },
    /**
     * Remove all other toasts except id
     */
    removeOtherToasts: (state, { payload }) => {
      state.list = state.list.filter(toast => toast.id === payload)
    },
    /**
     * Remove a toast by id
     */
    removeToast: (state, { payload }) => {
      state.list = state.list.filter(toast => toast.id !== payload)
    },
  },
})

export const { addToastToList, removeToast, removeOtherToasts } = ToastsSlice.actions
export default ToastsSlice.reducer
