import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
import { history } from '@/history'
import CreditCard from '@components/CreditCard'

import { ROUTES } from '../../../common/constants'

export const SetupMyFloc = ({ className, currentStep, createAccountStep, hasMultipleLeads }) => {
  const baseClasses = 'max-w-5xl bg-white rounded mx-auto p-6'
  const classes = {
    wrapper: classnames(className, baseClasses),
  }

  const formSteps = {
    'bank-linked': ROUTES.CREATE_CARD_ACCOUNT.STEP_FOUR,
    'myfloc-account-created': ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE,
    'netspend-account-created': ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO,
    'netspend-card-created': ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO,
    'netspend-legal-checked': ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE,
  }

  return (
    <div className={classes.wrapper}>
      <div className='flex flex-row'>
        <div className='flex flex-wrap flex-column'>
          <h1>{hasMultipleLeads ? 1 : 2}. Set up and fund your myFloc Card Account</h1>

          <h5 className='mt-0 text-lg'>
            Create your myFloc Card Account and transfer funds from your desired financial
            institution to get started.
          </h5>
        </div>
        <CreditCard
          className='hidden float-right pl-2 md:block filter drop-shadow-card'
          renderBg={false}
          width={155}
          active
        />
      </div>

      <Button
        className='mt-8 w-full md:w-auto'
        onClick={() => history.push(formSteps[createAccountStep])}
        type='button'
      >
        Set up myFloc Card Account
      </Button>

      <h4 className='mt-12 text-secondary'>
        Once you have funded your myFloc Card Account,
        {hasMultipleLeads ? ' you can invite people to your myFloc Team.' : ' you will see balance and transaction data here.'}
      </h4>
    </div>
  )
}

SetupMyFloc.propTypes = {
  className: PropTypes.string,
  createAccountStep: PropTypes.string,
  currentStep: PropTypes.number,
  disabled: PropTypes.bool,
  hasMultipleLeads: PropTypes.bool,
}

export default SetupMyFloc
