import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import styling from './button.module.scss'

export const SIGN_TYPES = {
  minus: 'minus',
  plus: 'plus',
}

/**
 * Generic button
 */
export const RobitButton = React.memo(
  ({
    active,
    arrow = false,
    block,
    children,
    className = '',
    disabled = false,
    ghost,
    innerRef,
    isLoading = false,
    leftArrow = false,
    link = null,
    onClick,
    outline,
    pill = true,
    sign,
    styleType = 'tertiary',
    size,
    squared,
    tag: Tag = 'button',
    text,
    ...props
  }) => {
    // Button appearance modifiers
    let modifier = ''
    if (outline) {
      modifier = 'outline-'
    }
    else if (arrow || text) {
      modifier = 'text-'
    }

    const classes = classNames(
      className,
      styling.btn,
      isLoading && styling.loading,
      disabled && styling.disabled,
      styleType && styling[`btn-${modifier}${styleType}`],
      size && styling[`btn-${size}`],
      pill && styling['btn-pill'],
      squared && styling['btn-squared'],
      block && styling['btn-block'],
      active && styling.active,
      ghost && styling['btn-ghost']
    )

    if (isLoading) {
      disabled = true
    }

    const onClickHandler = e => {
      if (disabled) {
        e.preventDefault()
        return
      }

      if (onClick) {
        onClick(e)
      }
    }

    Tag = props.href && Tag === 'button' ? 'a' : Tag
    const tagType = Tag === 'button' && props.onClick ? 'button' : undefined

    const propsHasTabIndex = 'tabIndex' in props
    const setTabIndex = propsHasTabIndex || (Tag === 'a' && !props.href)
    const tabIndex = propsHasTabIndex ? props.tabIndex : '0'

    return link ? (
      <NavLink
        className={classes}
        disabled={disabled}
        onClick={onClickHandler}
        to={link}
        {...props}
      >
        {leftArrow && <div className={styling.leftArrow} />}
        {sign?.length && <div className={styling[sign]} />}
        {children}
        {arrow && <div className={styling.arrow} />}
      </NavLink>
    ) : (
      <Tag
        className={classes}
        disabled={disabled}
        onClick={onClickHandler}
        ref={innerRef}
        tabIndex={setTabIndex ? tabIndex : null}
        type={tagType}
        {...props}
      >
        {leftArrow && <div className={styling.leftArrow} />}
        {sign?.length && <div className={styling[sign]} />}
        {children}
        {arrow && <div className={styling.arrow} />}
      </Tag>
    )
  }
)

RobitButton.propTypes = {
  /**
   * Whether or not to display its active state
   */
  active: PropTypes.bool,
  /**
   * Text button with a right arrow at the end
   */
  arrow: PropTypes.bool,
  /**
   * Whether it should be displayed as a block (full-width), or not.
   */
  block: PropTypes.bool,
  /**
   * The children nodes.
   */
  children: PropTypes.any.isRequired,
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * Whether it is disabled, or not. Defaults to false
   */
  disabled: PropTypes.bool,
  /**
   * Ghost buttons take on their `styleType` color for text, but no background or border
   */
  ghost: PropTypes.bool,
  /**
   * The URL to link to
   */
  href: PropTypes.string,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  /**
   * Whether the button action is in process. Defaults to false.
   */
  isLoading: PropTypes.bool,
  /**
   * Text button with a left arrow at the beginning
   */
  leftArrow: PropTypes.bool,
  /**
   * Link will use navLink to navigate
   */
  link: PropTypes.string,
  /**
   * Click handler (explicit prop to handle disabled state)
   */
  onClick: PropTypes.func,
  /**
   * Whether it is outline, or not.
   */
  outline: PropTypes.bool,
  /**
   * Whether it is pill, or not.
   */
  pill: PropTypes.bool,
  /**
   * For a sign preceeding the button, can be minus or plus
   */
  sign: PropTypes.oneOf([SIGN_TYPES.minus, SIGN_TYPES.plus]),
  /**
   * The size.
   */
  size: PropTypes.string,
  /**
   * Whether it is squared, or not.
   */
  squared: PropTypes.bool,
  /**
   * The style version of the button.
   */
  styleType: PropTypes.string,
  /**
   * To manually set the tabIndex
   */
  tabIndex: PropTypes.number,
  /**
   * The component tag.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Text button
   */
  text: PropTypes.bool,
}

export default RobitButton
