import React, { useCallback, useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Button from '@/common/components/Button'
import CreditCard from '@/common/components/CreditCard'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import {
  HTTP_METHODS, ENDPOINTS, ROUTES, CARD_STATUS,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { useAsyncEffect } from '@/common/hooks/useAsyncEffect'
import { maskSensitive } from '@/common/utils'
import { history } from '@/history'
import { TOAST_TYPES, addToast } from '@/redux'
import { selectMyFlocPerson } from '@/redux/user'
import SetPin from '@/views/account/ActivateCard/SetPin'
import VerifyCard from '@/views/account/ActivateCard/VerifyCard'

const ActivateCard = () => {
  const activePerson = useSelector(selectMyFlocPerson)
  const dispatch = useDispatch()
  const { loading, submitForm } = useSubmitForm()

  // ---------------------------------------------------------------------------
  // ASYNC DATA
  const pushError = (message, page = '/') => {
    history.push(page)
    dispatch(
      addToast({
        subtitle: message,
        title: 'Error',
        type: TOAST_TYPES.error,
      })
    )
  }

  // Query the person who's card will be set to get fresh data
  const [cards, setCards] = useState(null)
  const getCards = useCallback(async () => {
    if (!activePerson) {
      history.push('/')
      return
    }

    const result = await submitForm(ENDPOINTS.NETSPEND_ACCOUNTS_CARD(activePerson.id), { method: HTTP_METHODS.GET })
    if (result.response.status < 400) {
      return result.data.cards
    }
    else {
      pushError('Card not found for that user.')
    }
    return null
  }, [])

  // Run callback with proper state protection
  useAsyncEffect(getCards, setCards)

  const TYPES = {
    ACTIVATE: 'activate',
    RESET_PIN: 'reset',
  }

  // Filter cards and determine flow
  const [card, setCard] = useState(null)
  const [type, setType] = useState(null)
  useEffect(() => {
    if (!cards) {
      return
    }
    const userCard = cards?.find(card => card.id === activePerson.cardId)

    if (!userCard) {
      history.push(ROUTES.ERROR_CUSTOM('No cards found for this account.'))
    }
    if (userCard.status === CARD_STATUS.UNACTIVATED) {
      setType(TYPES.ACTIVATE)
    }
    else {
      setType(TYPES.RESET_PIN)
    }
    setCard(userCard)
  }, [cards])

  // ---------------------------------------------------------------------------
  // DYNAMIC CONTENT
  const TITLE = {
    activate: 'Activate your card',
    reset: 'Reset Pin',
  }

  const SUCCESS_TITLE = {
    activate: 'Your card has been activated',
    reset: 'Your PIN has been reset',
  }

  const SUCCESS_MESSAGE = {
    activate: 'You can now start using your card',
    reset: 'You can now use your new PIN to make purchases',
  }

  // ---------------------------------------------------------------------------
  // FORM STATE VARIABLES

  const FORM_STATES = {
    SET_PIN: 'set-pin',
    SUCCESS: 'success',
    VERIFY: 'verify',
  }
  const [formState, setFormState] = useState(FORM_STATES.VERIFY)
  const [verifyResult, setVerifyResult] = useState(null)

  function completeVerify (result) {
    setVerifyResult(result)
    setFormState(FORM_STATES.SET_PIN)
  }

  function completePin () {
    setFormState(FORM_STATES.SUCCESS)
  }
  return (card) ? (
    <>
      <PageHeader>{TITLE[type]}</PageHeader>
      <div className='relative flex-col text-left main-container'>
        {/* CARD INFO BLOCK */}
        {formState === FORM_STATES.VERIFY && (
          <>
            <label className='mt-4 mb-2 text-xl'>
              Card Details:
            </label>
            <div className='flex flex-row justify-between mt-4 mb-12'>
              <CreditCard active={activePerson.cardStatus === 'active'} person={activePerson} />
              <div className='flex flex-col'>
                <div>Card Number</div>
                <div className='text-xl text-secondary has-tooltip'>
                  {maskSensitive(`1111 1111 1111 ${card.pan_last_4}`, 4)}
                </div>
              </div>
              <div className='flex flex-col'>
                <div>Expiration</div>
                <div className='text-xl text-secondary has-tooltip'>
                  {`${card.expiration_month}/${card.expiration_year.toString()
                    .slice(2)}`}
                </div>
              </div>
            </div>
          </>
        )}
        {/* Input Forms */}
        {formState === FORM_STATES.VERIFY && (
          <VerifyCard card={card} onComplete={completeVerify} />
        )}
        {formState === FORM_STATES.SET_PIN && (
          <SetPin onComplete={completePin} verifyResult={verifyResult} />
        )}
        {formState === FORM_STATES.SUCCESS && (
          <>
            <label className='block mt-4 mb-2 text-xl text-center'>
              {SUCCESS_TITLE[type]}
            </label>
            <div className='text-center'>{SUCCESS_MESSAGE[type]}</div>
            <Button className='block m-auto mt-8'
              disabled={false}
              isLoading={loading}
              onClick={() => history.push('/')}
              type='button'>
              Back to Summary
            </Button>
          </>
        )}
      </div >
    </>
  ) : (<Loading className='min-h-header' />)
}

export default ActivateCard
