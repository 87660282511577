import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'

import { useInterval } from '@/common/hooks'
import { getAuth0Token, getSession } from '@/redux'

/**
 * Hook for Polling
 *
 * Had to break this into its ownhook as you cannot use hooks inside of methods or conditionally
 * Triggers every 14 minutes to refresh Netspend and Auth0 session
 */

// 5 minutes minus the approximate time the update call takes
const UNDER_5_MINUTES = 5 * 60 * 1000 - 1500

export const usePolling = () => {
  const dispatch = useDispatch()
  const nsSession = useSelector(state => state.netspend.session)

  const { getAccessTokenSilently } = useAuth0()

  useInterval(async () => {
    // Refresh Netspend session if available
    if (nsSession?.id) {
      await dispatch(getSession())
    }

    // Refresh Auth0 session
    await dispatch(getAuth0Token({ getAccessTokenSilently, ignoreCache: true }))
  }, UNDER_5_MINUTES)
}
export default usePolling
