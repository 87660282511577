import React, { useState, useEffect, useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import {
  CUSTOM_ERROR_MESSAGES,
  ENDPOINTS, HTTP_METHODS, ROUTES,
} from '@/common/constants'
import { useAuthentication, useSubmitForm } from '@/common/hooks'
import useValidateToken from '@/common/hooks/useValidateToken'
import { history } from '@/history'
import {
  addToast,
  createOtherLead,
  getPersonStatus,
  getUser,
  TOAST_TYPES,
  updateAndSwitchUser,
} from '@/redux'
import { LeadEnrollmentForm } from '@components/EnrollmentForm'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'
import { EnrollmentSecurityPanel } from '@components/Panel'

import styles from './frame.module.scss'
import GetStarted from './getStarted'
import stylesjoinAcceptInvite from './joinLeadAcceptInvite.module.scss'

const dataModal = {
  continue: {
    cancelText: 'No, Go Back',
    content: 'By logging in with your existing email address and password, you will be creating a new myFloc Card Account.',
    successText: 'Yes, Continue',
    title: 'Are you sure you want to create a new myFloc Card Account?',
    type: MODAL_TYPES.confirm,
  },
  'get-started': {
    cancelText: 'Close',
    content: 'You can create a new myFloc Card Account by logging in with your existing email address and password.',
    successText: 'Continue',
    title: 'This email address is already part of myFloc.',
    type: MODAL_TYPES.confirm,
  },
}

const JoinLeadAcceptInvite = ({ token, offer, step, type }) => {
  const { decoded, existingUser } = useValidateToken({ token })

  useEffect(() => {
    // validate existing offer
    if (!offer) {
      dispatch(addToast({
        subtitle: 'Offer not found',
        title: 'Invalid offer',
        type: TOAST_TYPES.error,
      }))
      history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.OFFER_EXPIRED))
    }
  }, [offer])

  const { enrollmentLogin, user } = useAuthentication()
  const { isLoading: auth0Loading, loginWithRedirect } = useAuth0()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [pageClass, setPageClass] = useState('get-started')
  const [currentStep, setCurrentStep] = useState(0)
  const [formUser, setFormUser] = useState(null)
  const [showActionModal, setShowActionModal] = useState(false)
  const [buttonValue, setButtonValue] = useState('get-started')
  const [isOtherAccount, setIsOtherAccount] = useState(type === 'new-account')

  const { apiErrors, setLoading, submitForm } = useSubmitForm()
  const nsSession = useSelector(state => state.netspend.session)
  const nsSessionLoaded = useSelector(state => state.netspend.sessionLoaded)

  useEffect(() => {
    if (decoded && nsSessionLoaded && !auth0Loading) {
      setIsLoading(false)
    }
    if (step !== 'null' && Number(step) !== currentStep && Number(step) === 1) {
      setCurrentStep(Number(step))
      setPageClass('basic-information')
    }
  }, [decoded, nsSessionLoaded, auth0Loading, step, currentStep])

  useEffect(() => {
    if (apiErrors) {
      // validate existing email
      if (apiErrors?.validationErrors?.email) {
        setShowActionModal(true)
      }
    }
  }, [apiErrors])

  const setPageClassHandler = page => {
    setPageClass(page)
    setCurrentStep(currentStep + 1)
  }

  const createAccount = useCallback(
    async () => {
      await dispatch(createOtherLead(offer))
      await dispatch(getUser())
      await dispatch(getPersonStatus())
    },
    [offer, dispatch]
  )

  useEffect(() => {
    let isMounted = true
    if (isOtherAccount) {
      createAccount().then(() => {
        history.replace(ROUTES.DASHBOARD)
      }).catch(() => {
        if (isMounted) {
          setIsOtherAccount(false)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [isOtherAccount, createAccount])

  const onSubmit = async payload => {
    // create myfloc insider
    const myFlocLeadPayload = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password,
      password2: payload.password2,
    }

    if (payload.middleName) myFlocLeadPayload.middleName = payload.middleName
    if (payload.displayName) myFlocLeadPayload.displayName = payload.displayName
    if (payload.mobile) myFlocLeadPayload.mobile = payload.mobile
    if ('smsConsent' in payload) myFlocLeadPayload.smsConsent = payload.smsConsent

    const createLeadResponse = await submitForm(ENDPOINTS.ACCEPT_LEAD + `?order=${offer}`, {
      manualLoading: true,
      method: HTTP_METHODS.POST,
      payload: myFlocLeadPayload,
    })

    setFormUser(myFlocLeadPayload)

    if (createLeadResponse.response.status !== 201) {
      setLoading(false)
      if (
        createLeadResponse.data.error?.validationErrors &&
        !createLeadResponse.data.error?.validationErrors?.email
      ) {
        dispatch(
          addToast({
            subtitle: createLeadResponse.data.error?.message || 'Please review submitted information.',
            title: 'Validation Error',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
    else {
      enrollmentLogin({ data: createLeadResponse.data }).then(() => {
        history.replace(ROUTES.VERIFY_EMAIL_OTP)
      })
      dispatch(updateAndSwitchUser(createLeadResponse.data))
    }
  }

  const login = (returnTo = `${window.location.pathname}${window.location.search}`) => {
    const returnToUrl = returnTo.replace(/&step=[^&]*/, '') + `&type=new-account&step=${1}`
    loginWithRedirect({
      appState: { returnTo: returnToUrl },
      fragment: '&type=new-account',
    })
  }

  const classes = classNames(
    styles[pageClass],
    styles.iframe,
    isLoading && styles.loading
  )

  const classesJoinAccept = classNames(
    stylesjoinAcceptInvite.formContainer,
    'main-container'
  )

  return (
    <div className={classes}>
      {!isLoading && currentStep === 0 && (
        <GetStarted
          currentStep={currentStep}
          setButtonValue={setButtonValue}
          setPageClassHandler={setPageClassHandler}
          setShowActionModal={setShowActionModal}
        />
      )
      }
      {!isLoading && currentStep === 1 && !isOtherAccount ? (
        <>
          <PageHeader stepText={`Step ${currentStep} of 2`}>Enter your personal details</PageHeader>
          <div className={classesJoinAccept}>
            {
              (!decoded || !nsSession || decoded.loading || auth0Loading || !nsSessionLoaded)
                ? <Loading className='min-h-screen' />
                : <LeadEnrollmentForm
                  apiErrors={apiErrors}
                  loadPasswordField={true}
                  onSubmit={onSubmit}
                  prefill={formUser || {
                    email: existingUser?.user?.email || decoded.email || user?.email,
                    firstName: existingUser?.user?.firstName || decoded.firstName || user?.firstName,
                    lastName: existingUser?.user?.lastName || decoded.lastName || user?.lastName,
                    mobile: existingUser?.user?.mobile || decoded.mobile || user?.mobile,
                  }}
                  teamName={existingUser?.teamName || ''}
                />
            }
            <EnrollmentSecurityPanel />
          </div>
        </>
      ) : currentStep !== 0 && <Loading className='min-h-screen' />
      }
      <Modal
        cancelCallback={() => { /**/ }}
        cancelText={dataModal[buttonValue].cancelText}
        closeOnBackdropClick={false}
        open={showActionModal}
        setOpen={() => setShowActionModal(!showActionModal)}
        successCallback={() => {
          login()
        }}
        successText={dataModal[buttonValue].successText}
        title={dataModal[buttonValue].title}
        type={dataModal[buttonValue].type}
      >
        {dataModal[buttonValue].content}
      </Modal>
    </div>
  )
}

JoinLeadAcceptInvite.propTypes = {
  offer: PropTypes.string.isRequired,
  step: PropTypes.string,
  token: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default JoinLeadAcceptInvite
