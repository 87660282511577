import React from 'react'

import PropTypes from 'prop-types'

import AnimateIn from '@components/AnimateIn'
import Button from '@components/Button'

/**
 * Hidden panel component that appears when triggered
 *
 * @param {Object}   props
 * @param {string}   [props.className]
 * @param {string}   [props.contClassName]
 * @param {Object}   props.children
 * @param {boolean}  props.show
 * @param {Function} [props.setShow]
 */
export const HiddenPanel = ({ className, contClassName, children, show = false, setShow }) => {
  return (
    <AnimateIn className={className} maxHeight={240} show={show}>
      <div className={`py-5 px-8 mb-8 rounded-md border shadow bg-light-blue border-mid-blue ${contClassName}`}>
        <div className='mb-2'>{children}</div>

        {setShow && (
          <Button className='flat-left' onClick={() => setShow(false)} type='button' primary text>
            Hide
          </Button>
        )}
      </div>
    </AnimateIn>
  )
}

HiddenPanel.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.node.isRequired,
  /**
   * The class name for the animate container.
   */
  className: PropTypes.string,
  /**
   * The class name for the container.
   */
  contClassName: PropTypes.string,
  /**
   * To set the show value
   */
  setShow: PropTypes.func,
  /**
   * Prop to control when to show
   */
  show: PropTypes.bool.isRequired,
}

export default HiddenPanel
