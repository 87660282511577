import React, { useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import HiddenPanel from '@/common/components/HiddenPanel'
import { HTTP_METHODS, ENDPOINTS, ROUTES } from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { verifyCard } from '@/common/validations'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux/user'

const VerifyCard = ({ onComplete }) => {
  const activePerson = useSelector(selectMyFlocPerson)

  // ---------------------------------------------------------------------------
  // FORM HANDLING
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(verifyCard) })

  const { apiErrors, loading, submitForm, setApiErrors } = useSubmitForm()

  // Cancel card and re-order
  const onSubmit = async formEntry => {
    const encryptedData = await window.NetspendSDK.session.encrypt({
      verification_value: formEntry.cvc,
    })
    const payload = { encryptedData }

    const result = await submitForm(
      ENDPOINTS.NETSPEND_CARD_VERIFY(activePerson.id),
      { method: HTTP_METHODS.PUT, noErrorToast: true, payload }
    )

    if (result.response.status < 400) {
      onComplete(result.data?.verificationResult) // Move to next step
    }
    else if (result?.data?.error?.validationErrors) {
      setError('cvc') // Map validation errors to form
    }
    else {
      if (result.data.error.code === 'max_card_verifications_exceeded') {
        history.push(ROUTES.ERROR_CUSTOM('You have exceeded the maximum number of verification attempts. Please contact customer care.'))
      }
      else {
        history.push(ROUTES.ERROR_CUSTOM('Error verifying card.'))
      }
    }
  }

  const cvc = watch('cvc')
  useEffect(() => {
    setApiErrors(null)
  }, [cvc])

  const [showCVCDetails, setShowCVCDetails] = useState(false)
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      {/* Card Activation/Verification */}
      <FormInput
        className={'min-h-0 mb-4'}
        errors={errors}
        inputClassName={'min-w-0 flex-1 mt-1 ml-4 text-center tracking-widest text-xl max-w-xs'}
        label={'CVC*'}
        labelButtonAction={() => setShowCVCDetails(true)}
        labelButtonText={'What is this?'}
        labelClassName={'flex flex-row items-center justify-center '}
        name='cvc'
        register={register}
        type={FORM_INPUT_TYPES.cvc}
        validationErrors={apiErrors?.validationErrors}
      />
      <HiddenPanel contClassName='mb-1' setShow={setShowCVCDetails} show={showCVCDetails}>
        The CVC (Card Verification Code) is the 3-digit code located
        on the back of your card to the right of the signature strip.
      </HiddenPanel>
      {/* Submit Button ----------------------------------------- */}
      <div className='flex flex-col items-center mt-9'>
        <Button disabled={false} isLoading={loading}>
          Verify CVC
        </Button>
        <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
          Cancel
        </Button>
      </div>
    </form >)
}

VerifyCard.propTypes = {
  onComplete: PropTypes.func,
}

export default VerifyCard
