import React from 'react'

import PropTypes from 'prop-types'

import styling from './teamCard.module.scss'

export const DisplayName = ({
  member,
  className,
}) => {
  const nameClasses = `text-xl font-light md:truncate ${styling.teamNameLabel} md:mb-0 mb-4`
  const name = `myFloc Team - ${member.teamName}`

  return (
    < div className={`${className ?? ''} flex overflow-hidden flex-col flex-1 mt-2 md:mt-0 md:ml-4 lg:ml-8`} >
      <p className={nameClasses}>
        {' '}{name}
      </p>
    </div >
  )
}

DisplayName.propTypes = {
  className: PropTypes.string,
  member: PropTypes.object.isRequired,
}
