import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import Error from '@/common/components/Error'
import {
  ACTION_STATES, ENDPOINTS, HTTP_METHODS, MYFLOC_PHONE_NUMBER, MYFLOC_PHONE_NUMBER_TEXT, ROLES, ROUTES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { history } from '@/history'
import { getTeam } from '@/redux/team'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import Button from '@components/Button'
import Loading from '@components/Loading'

const RemovePerson = () => {
  const dispatch = useDispatch()
  const { submitForm } = useSubmitForm()
  const { personIdToRemove } = useParams()

  const myFlocTeam = useSelector(state => state.team.team)

  const [teamRequested, setTeamRequested] = useState(null)
  const [personToRemove, setPersonToRemove] = useState(null)

  const [isFreezingSpending, setIsFreezingSpending] = useState(false)
  const freezeSpending = async () => {
    if (isFreezingSpending) return

    setIsFreezingSpending(true)

    try {
      const { data: freezeCardResponseData, response: freezeCardResponse } =
        await submitForm(ENDPOINTS.NETSPEND_CARD_LOCK(personIdToRemove), {
          method: HTTP_METHODS.POST,
        })

      if (freezeCardResponse.status === 200) {
        dispatch(
          addToast({
            subtitle: `Card for ${personToRemove.firstName} ${personToRemove.lastName} has been locked successfully.`,
            title: 'Card Locked',
            type: TOAST_TYPES.success,
          })
        )
      }
      else {
        dispatch(
          addToast({
            subtitle: freezeCardResponseData.error.message,
            title: 'Unexpected Error',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
    catch (freezeError) {
      dispatch(
        addToast({
          subtitle: freezeError.message,
          title: 'Unexpected Error',
          type: TOAST_TYPES.error,
        })
      )
    }
    finally {
      setIsFreezingSpending(false)
      history.push(ROUTES.DASHBOARD)
    }
  }

  // exit if no user id is specified
  useEffect(() => {
    if (!personIdToRemove) {
      history.push(ROUTES.DASHBOARD)

      dispatch(
        addToast({
          subtitle: 'Unknown user id',
          title: 'Unable to Remove Member From Team',
          type: TOAST_TYPES.error,
        })
      )
    }
  }, [personIdToRemove])

  // load team data if not available
  useEffect(() => {
    if (myFlocTeam || teamRequested) return

    setTeamRequested(true)

    dispatch(getTeam())
    // TODO: need to gracefully handle scenario where team member is requested but call fails
  }, [myFlocTeam, teamRequested, setTeamRequested])

  // find person to delete from team
  useEffect(() => {
    if (
      personToRemove || // person already loaded
      !personIdToRemove || // id of person to remove not specified
      !myFlocTeam // team not yet loaded
    ) {
      return
    }

    const targetPerson = myFlocTeam.find(person => person.id === personIdToRemove)

    if (!targetPerson) {
      history.push(ROUTES.DASHBOARD)

      dispatch(
        addToast({
          subtitle: 'Could not find user',
          title: 'Unable to Remove Member From Team',
          type: TOAST_TYPES.error,
        })
      )

      return
    }

    setPersonToRemove(targetPerson)
  }, [personToRemove, myFlocTeam, personIdToRemove, setPersonToRemove])

  // cards requested
  const [cards, setCards] = useState(null)
  const [cardsRequested, setCardsRequested] = useState(ACTION_STATES.UNINITIATED)
  useEffect(() => {
    if (!personToRemove || cards || cardsRequested !== ACTION_STATES.UNINITIATED) return

    setCardsRequested(ACTION_STATES.INITIATED)

    const getCards = async () => {
      const getCardsResponse = await submitForm(ENDPOINTS.NETSPEND_PERSONS_CARD(personToRemove.id), {
        method: HTTP_METHODS.GET,
        noErrorToast: true,
      })

      setCards(getCardsResponse.response.status < 400 ? getCardsResponse.data.cards : [])
      setCardsRequested(ACTION_STATES.COMPLETED)
    }

    getCards()
  }, [personToRemove, cards, cardsRequested, setCardsRequested, setCards])

  if (personToRemove && cards !== null) {
    let name = `${personToRemove.firstName} ${personToRemove.lastName}`
    if (personToRemove.displayName) name += ` (${personToRemove.displayName})`

    const body = (
      <>
        <div className='mb-10 leading-5'>
          This action cannot be reversed. Removal will prevent their access to the myFloc platform
          and deactivate their card. You may re-invite them to your team at a later date, but note
          that it takes 7 - 10 days after enrollment to receive a new card.
        </div>

        {
          (
            personToRemove.role === ROLES.INSIDER ||
            personToRemove.role === ROLES.TEAM_MEMBER
          ) &&
          cards.find(card => card.status === 'active')
            ? <div className='text-center'>
              <Button isLoading={isFreezingSpending} onClick={freezeSpending}>Freeze Spending Instead</Button>
            </div>
            : null
        }

        <h3 className='mt-10 text-center' key='firstMessage'>
          To remove a myFloc Insider or Team Member, please contact myFloc Customer Care at{' '}
          <Button
            className='inline flat-x'
            href={'tel:' + MYFLOC_PHONE_NUMBER}
            tag='a'
            secondary
            text
          >
            {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
          </Button>{' '}
          for support.
        </h3>
      </>
    )

    const title = (
      <>
        Are you sure you want to remove <span className='text-accent'>{name}</span> from your team?
      </>
    )
    return <Error body={body} title={title} />
  }
  else {
    return <Loading className='min-h-header' />
  }
}

export default RemovePerson
