import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  ENROLLMENT, EXTERNAL_URLS, ROLES, ROUTES,
} from '@/common/constants'
import { useAuthentication } from '@/common/hooks'
import { checkEnrollmentStepPassed } from '@/common/utils'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux/user'
import Button from '@components/Button'
import Icon from '@components/Icon'

import styling from './header.module.scss'

/**
 * The navigation links in the global header
 */
const NavigationLinks = ({ setMenuOpen, showCardAccount }) => {
  const { logout } = useAuthentication()
  const closeMenu = () => setMenuOpen?.(false)

  const { pathname } = history.location
  const actualPath = (pathname || '').toLowerCase()
  const validatePath = !(actualPath === ROUTES.TEAM_SELECTION || actualPath === ROUTES.TEAM_SELECTION + '/')

  return (
    <>
      <div className='hidden flex-grow lg:flex'></div>
      <div className='flex flex-col justify-center lg:flex-row lg:items-center'>
        {validatePath && <Button
          activeClassName='text-accent pointer-events-none'
          className='mt-5 text-xl font-medium lg:px-2 lg:my-0 xl:px-10'
          link={ROUTES.DASHBOARD}
          onClick={closeMenu}
          exact
          secondary
          text
        >
          Summary
        </Button>}
        {validatePath && showCardAccount && (
          <Button
            activeClassName='text-accent pointer-events-none'
            className='mt-5 text-xl font-medium lg:px-2 lg:my-0 xl:px-10'
            link={ROUTES.ACCOUNT}
            onClick={closeMenu}
            exact
            secondary
            text
          >
            myFloc Card Account
          </Button>
        )}
        {validatePath && <Button
          activeClassName='text-accent pointer-events-none'
          className='mt-5 text-xl font-medium lg:px-2 lg:my-0 xl:px-10'
          link={ROUTES.PROFILE}
          onClick={closeMenu}
          exact
          secondary
          text
        >
          My Profile
        </Button>}
        {validatePath && <Button
          activeClassName='text-accent pointer-events-none'
          className='mt-5 text-xl font-medium lg:px-2 lg:my-0 xl:px-10'
          link={ROUTES.NOTIFICATIONS}
          onClick={closeMenu}
          exact
          secondary
          text
        >
          Notifications and Alerts
        </Button>}
      </div>
      <div className='hidden flex-grow lg:flex'></div>
      <div className='pr-4 md:pr-10'>
        <Button
          className='my-5 text-xl font-medium lg:px-2 lg:my-0 xl:px-10'
          href={EXTERNAL_URLS.FAQ.BASE}
          onClick={closeMenu}
          rel='noreferrer'
          tag='a'
          target='_blank'
          secondary
          text
        >
          Help
        </Button>
        <Button label='Logout' onClick={logout}>
          Logout
        </Button>
      </div>
    </>
  )
}

NavigationLinks.propTypes = {
  /**
   * To close the menu on click
   */
  setMenuOpen: PropTypes.func,

  /**
   * Whether or not to show the myAccount link
   */
  showCardAccount: PropTypes.bool,
}

/**
 * The navigational header for the website.
 * This is the header that the user will see the most often
 * It will provide links for the user to navigate to other areas of the site
 */
const NavigationHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const enrollmentFlow = useSelector(state => state.user.activePersonStatus?.enrollmentFlow)
  const myFlocPerson = useSelector(selectMyFlocPerson)

  const showCardAccount =
    myFlocPerson?.role === ROLES.FRIEND ||
    checkEnrollmentStepPassed(enrollmentFlow, ENROLLMENT.NS_CARD)

  return (
    <>
      <div className={styling.header}>
        {/* Logo */}
        <div className={styling.logo}>
          <Icon
            height={42}
            name='logo'
            onClick={() => history.push(ROUTES.DASHBOARD)}
            width={130}
          />
        </div>

        {/* Hamburger Menu Buttons - For Mobile */}
        <div className={styling.hamburgerMenu}>
          {menuOpen ? (
            <div className={styling.hamburgerMenuButtons} onClick={() => setMenuOpen(false)}>
              <p className={styling.menuText}>Close Menu</p>
              <div className={styling.hamburgerMenuIcon}>
                <Icon height={26} name='close_button' width={26} />
              </div>
            </div>
          ) : (
            <div className={styling.hamburgerMenuButtons} onClick={() => setMenuOpen(true)}>
              <p className={styling.menuText}>Menu</p>
              <div className={styling.hamburgerMenuIcon}>
                <Icon height={22} name='hamburger_menu' width={28} />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Navigation Links */}
      <div className={styling.headerLinks}>
        {/* Navigation Links For Mobile */}
        {menuOpen && (
          <div className={styling.headerLinksMobile + ' drop-shadow-xl'}>
            <NavigationLinks setMenuOpen={setMenuOpen} showCardAccount={showCardAccount} />
          </div>
        )}

        {/* Navigational Links For Desktop */}
        <div className='hidden flex-row justify-center items-center lg:flex'>
          <NavigationLinks showCardAccount={showCardAccount} />
        </div>
      </div>
    </>
  )
}

export default NavigationHeader
