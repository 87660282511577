import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import Button from '@components/Button'

import RobitModal from './RobitModal'

export const MODAL_TYPES = {
  /**
   * Only has a close action, this is the default
   */
  alert: 'alert',
  /**
   * Has both a close and a success action
   */
  confirm: 'confirm',
  /**
   * Has a continue and edit button
   */
  continue: 'continue',
  /**
   * Has no buttons
   */
  info: 'info',
}

/**
 * Wrapper around RobitModal which provides some additional form and function
 *
 * @param {Object}        props
 * @param {function}      [props.cancelCallback]
 * @param {string}        [props.cancelText]
 * @param {Object|string} props.children
 * @param {boolean}       props.open
 * @param {function}      [props.setOpen]
 * @param {function}      [props.secondCallback]
 * @param {function}      [props.successCallback]
 * @param {string}        [props.successText]
 * @param {string}        [props.title]
 * @param {string}        [props.type]
 */
const Modal = ({
  cancelCallback,
  cancelText,
  children,
  closeOnBackdropClick = true,
  open = false,
  setOpen,
  secondCallback,
  successCallback,
  successText,
  title,
  type = MODAL_TYPES.alert,
}) => {
  let actionClasses = 'flex flex-row '
  let actions

  const cancel = useCallback(() => {
    cancelCallback?.()
    setOpen(false)
  }, [cancelCallback, setOpen])

  const success = () => {
    successCallback?.()
    setOpen?.(false)
  }

  const second = () => {
    secondCallback?.()
    setOpen?.(false)
  }

  // Handle escape key as well
  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        cancel()
      }
    }
    // Need to specifically remove the listener on close or it opens again
    if (open) {
      window.addEventListener('keydown', close)
    }
    else {
      window.removeEventListener('keydown', close)
    }
    return () => window.removeEventListener('keydown', close)
  }, [cancel, open])

  // Setup the different actions per MODAL_TYPE
  switch (type) {
    case MODAL_TYPES.confirm:
      if (!cancelText) cancelText = 'No, Keep it'
      if (!successText) successText = 'Yes, Remove'

      actionClasses += 'justify-between'
      actions = (
        <>
          <Button className='text-gray-600 flat-left' onClick={cancel} type='button' text>
            {cancelText}
          </Button>
          <Button onClick={success} type='button' primary text>
            {successText}
          </Button>
        </>
      )
      break
    case MODAL_TYPES.continue:
      actions = (
        <>
          <Button onClick={success} type='button'>
            Continue
          </Button>
          <Button className='ml-5' onClick={second} type='button' outline>
            Cancel
          </Button>
        </>
      )
      break
    case MODAL_TYPES.info:
      break
    default:
      if (!cancelText) cancelText = 'Close'

      actions = (
        <Button className='flat-left' onClick={cancel} type='button' primary text>
          {cancelText}
        </Button>
      )
      break
  }

  return (
    <RobitModal
      closeOnBackdropClick={closeOnBackdropClick}
      modalContentClassName='max-w-4xl mx-auto px-8 py-8'
      open={open}
      toggleModal={cancel}
      centered
    >
      {title && <h4 className='mb-4'>{title}</h4>}

      <div className='mb-6'>{children}</div>

      <div className={actionClasses}>{actions}</div>
    </RobitModal>
  )
}

Modal.propTypes = {
  /**
   * Callback triggered on cancel
   */
  cancelCallback: PropTypes.func,
  /**
   * The text for the cancel button on confirm as well as the only button on alert
   */
  cancelText: PropTypes.string,
  /**
     /**
   * The children nodes.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  /**
   * Whether or not clicking the backdrop closes the modal. Defaults to true.
   */
  closeOnBackdropClick: PropTypes.bool,
  /**
   * Whether it is open, or not. Defaults to false
   */
  open: PropTypes.bool.isRequired,
  /**
   * Secondary action callback if its not cancel
   */
  secondCallback: PropTypes.func,
  /**
   * Function that controls the open boolean
   */
  setOpen: PropTypes.func,
  /**
   * Callback triggered on success action
   */
  successCallback: PropTypes.func,
  /**
   * The text the confirm button
   */
  successText: PropTypes.string,
  /**
   * Optional Title
   */
  title: PropTypes.string,
  /**
   * Controls the action style at the bottom of the modal
   */
  type: PropTypes.oneOf([
    MODAL_TYPES.alert,
    MODAL_TYPES.confirm,
    MODAL_TYPES.continue,
    MODAL_TYPES.info,
  ]),
}

export default Modal
