import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'

import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID_FRONTEND,
  AUTH0_API_AUDIENCE,
  ROUTES,
} from '@/common/constants'
import { history } from '@/history'

const AuthProvider = ({ children }) => {
  const onRedirectCallback = (appState = {}) => {
    if (history) {
      history.push(appState.returnTo ?? ROUTES.DASHBOARD)
    }
    else if (appState.returnTo) {
      window.history.pushState({}, 'myFloc', appState.returnTo)
    }
  }

  return (
    <Auth0Provider
      audience={AUTH0_API_AUDIENCE}
      clientId={AUTH0_CLIENT_ID_FRONTEND}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
