import React from 'react'

import PropTypes from 'prop-types'

import myTeam from '@/assets/images/my_team.png'
import transferInitiated from '@/assets/images/transfer_initiated.png'
import welcomeTeam from '@/assets/images/welcome_team.png'
import Icon from '@/common/components/Icon'
import Stepper from '@/common/components/Stepper'
import { ROUTES } from '@/common/constants'

export const HeroSection = ({ userPreferredFirstName, currentStep, isAdmin, hasMultipleLeads }) => {
  const stepperTitle =
    currentStep < (hasMultipleLeads ? 3 : 4)
      ? 'Continue setting up your account below:'
      : 'Your initial set up is complete!'
  let stepperSubtitle = null

  switch (currentStep) {
    case (hasMultipleLeads ? 1 : 2):
      stepperSubtitle = 'Set Up and Fund Your myFloc Card Account Below'
      break
    case (hasMultipleLeads ? 2 : 3):
      stepperSubtitle = 'Build Your myFloc Team Below'
      break
    case (hasMultipleLeads ? 3 : 4):
      stepperSubtitle = 'Go to Your Personal Dashboard Below'
      break
    default:
      break
  }

  const steps = []
  if (!hasMultipleLeads) {
    steps.push({ text: 'Enroll in myFloc' })
    steps.push({ text: 'Set up your myFloc Card Account' })
  }
  else {
    steps.push({ text: 'Set up and fund your myFloc Card Account' })
  }
  steps.push({ text: 'Build your myFloc Team' })

  return (
    <div className='container px-5 pt-10 mx-auto max-w-5xl rounded'>
      {currentStep === (hasMultipleLeads ? 1 : 2) && (
        <h1 className='text-accent'>
          {!hasMultipleLeads ? 'Welcome to myFloc, ' : 'Welcome back, '}
          <span className='text-primary'>{userPreferredFirstName}!</span>
        </h1>
      )}
      {currentStep === (hasMultipleLeads ? 2 : 3) && (
        <h1 className='text-accent'>
          You have successfully funded your
          <span className='text-primary'> myFloc Card Account.</span>
        </h1>
      )}
      {currentStep === (hasMultipleLeads ? 3 : 4) && (
        <h1 className='text-accent'>
          Nice work!
          <span className='text-primary'> You have started building your team.</span>
        </h1>
      )}

      {currentStep === 2 && !hasMultipleLeads && (
        <div className='mt-6 text-lg font-normal'>
          We believe that doing life together is definitely better. myFloc makes managing the money
          part of that easier and lets you choose who you want on your myFloc Team.
        </div>
      )}

      {currentStep === (hasMultipleLeads ? 2 : 3) && (
        <div className='mt-6 text-lg font-normal'>
          To make sure your account is always funded, we recommend establishing an automatic
          transfer directly from your bank. For more information on how to do that,{' '}
          <a href={ROUTES.TRANSFER_DIRECT}>view bank transfer instructions here.</a>
          <br />
          <br />
          You can now add people to your myFloc Team and set their spending permissions below.
        </div>
      )}

      {currentStep === (hasMultipleLeads ? 3 : 4) && (
        <div className='mt-6 text-lg font-normal'>
          Invitations have been sent to your myFloc team. Cards will arrive 7-10 days after the
          cardholder has completed their myFloc enrollment.
          <br />
          <br />
          Select <span className='font-medium text-secondary'>“Options”</span> next to their name
          below to view their details or edit permissions.
        </div>
      )}

      {currentStep === (hasMultipleLeads ? 4 : 5) && (
        <div className='flex flex-col md:flex-row md:justify-between md:mb-10'>
          <div className='flex flex-wrap justify-start content-center'>
            <h1 className='text-accent'>
              Welcome back, <span className='text-primary'>{userPreferredFirstName}!</span>
            </h1>
            <div className='flex mt-6 text-lg font-normal'>
              We do life together. myFloc is how we do money together.
            </div>
          </div>
          <div className='mt-10 md:mt-0 mx-auto max-w-[500px]'>
            <img alt='myFloc hero' className='object-contain' src={welcomeTeam} width='100%' />
          </div>
        </div>
      )}

      {(isAdmin && currentStep < (hasMultipleLeads ? 4 : 5)) && (
        <div className='flex flex-col md:flex-row md:justify-between p6 md:mb-0 mb-12'>
          <div className='flex flex-row order-2 md:order-1 mx-auto md:mr-10'>
            <div className='flex flex-col justify-center'>
              <Stepper
                currentStep={currentStep}
                steps={steps}
                subtitle={stepperSubtitle}
                title={hasMultipleLeads ? '' : stepperTitle}
              />
            </div>
          </div>
          <div className='flex order-1 md:order-2 mx-auto md:ml-10'>
            <img
              alt='myFloc hero'
              className='float-right object-contain'
              src={currentStep < (hasMultipleLeads ? 3 : 4) ? transferInitiated : myTeam}
              style={{
                height: 'intrinsic', // layout-rendering fix for safari. see: https://stackoverflow.com/a/61267144
              }}
              width={430}
            />
          </div>
        </div>
      )}
      {currentStep < (hasMultipleLeads ? 4 : 5) && (
        <div className='flex justify-center'>
          <Icon name='arrow_down' width={24} />
        </div>
      )}
    </div>
  )
}
HeroSection.propTypes = {
  /**
   * Current step of enrollment based on stepper
   * */
  currentStep: PropTypes.number,

  /**
   * Has more than one lead
   */
  hasMultipleLeads: PropTypes.bool,

  /**
   * Lead/insider or not
   */
  isAdmin: PropTypes.bool,

  /**
   * First name of user
   */
  userPreferredFirstName: PropTypes.string,
}
export default HeroSection
