import { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import DPI from 'react-day-picker/DayPickerInput'

import { ReactComponent as CalendarIcon } from '@/assets/svg/calendar.svg'
import {
  dateIsAfter, dateIsBefore, formatDate, isValidDate,
} from '@/common/utils/dates'
import Button from '@components/Button'

import 'react-day-picker/lib/style.css'

// https://github.com/gpbl/react-day-picker/issues/1194#issuecomment-814065458
const DayPickerInput = DPI.__esModule ? DPI.default : DPI

const USA_DATE_FORMAT = 'MM/DD/YYYY'

export const parseDate = date => {
  // check for 4 digits otherwise the validation occurs on 1 digit year
  const is4DigitYear = date.split('/').length === 3 && date.split('/')[2].length === 4
  if (is4DigitYear && isValidDate(date)) {
    return new Date(date)
  }
  // Parse Date failed, likely because of typing date, no date selection in calendar component until valid
}

/**
 * Date Range Picker
 * @param  {function}    [handleDateChange] triggered on From or To input change; start and end date provided
 * @return {JSX.Element}                    component
 */
const DatePicker = ({ handleDateChange: parentDateChange = () => { } }) => {
  const initialState = { from: null, to: null }
  const [from, setFrom] = useState(initialState.from)
  const [to, setTo] = useState(initialState.to)
  const toInputRef = useRef(null)
  const fromInputRef = useRef(null)
  const today = new Date()
  const modifiers = { end: to, start: from }

  const handleChangeFrom = date => {
    // If the new 'from' date is after the 'to' date then reset 't'o
    if (dateIsAfter(date, to)) {
      setTo(initialState.to)
    }

    parentDateChange(date, to)
    setFrom(date)
  }

  const handleChangeTo = date => {
    // If the new 'to' date is before the 'from' date then reset 'from'
    if (dateIsBefore(date, from)) {
      fromInputRef.current.input.focus() // Focus 'from' to encourage reselection
      setFrom(initialState.from)
    }

    parentDateChange(from, date)
    setTo(date)
  }

  // Focus 'to' after selecting from
  const handleDayFromClick = () => {
    toInputRef.current.input.focus()
  }
  const clearDates = () => {
    setTo(initialState.to)
    setFrom(initialState.from)
    parentDateChange(initialState.to, initialState.from)
  }

  const oneMonthPrior = () => {
    const date = new Date()
    date.setMonth(-1)
    return date
  }

  return (
    <div className='DatePicker'>
      <div className='inline-block mr-4 align-middle'>
        <CalendarIcon />
      </div>
      <DayPickerInput
        dayPickerProps={{
          disabledDays: { after: today },
          modifiers,
          month: oneMonthPrior(),
          numberOfMonths: 2,
          onDayClick: handleDayFromClick,
          selectedDays: [to, { from, to }],
          toMonth: today,
        }}
        format='LL'
        formatDate={formatDate}
        onDayChange={handleChangeFrom}
        parseDate={parseDate}
        placeholder={`From (${USA_DATE_FORMAT})`}
        ref={fromInputRef}
        value={from}
      />{' '}
      <div className='inline-block mr-4 align-middle'>
        <CalendarIcon />
      </div>
      <span className='DatePicker-to'>
        <DayPickerInput
          dayPickerProps={{
            disabledDays: { after: today },
            modifiers,
            month: oneMonthPrior(),
            numberOfMonths: 2,
            selectedDays: [from, { from, to }],
            toMonth: today,
          }}
          format='LL'
          formatDate={formatDate}
          onDayChange={handleChangeTo}
          parseDate={parseDate}
          placeholder={`To (${USA_DATE_FORMAT})`}
          ref={toInputRef}
          value={to}
        />
      </span>
      <Button onClick={clearDates} primary text>
        Clear
      </Button>
      <style>{`
        .DatePicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: #f0f8ff !important;
          color: #4a90e2;
        }
        .DatePicker .DayPicker-Day {
          border-radius: 0 !important;
        }
        .DatePicker .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .DatePicker .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
        .DatePicker .DayPickerInput-Overlay {
          padding: 1rem;
        }
        .DatePicker-to .DayPickerInput-Overlay {
          margin-left: -198px;
        }
        .DayPicker-wrapper {
          padding-bottom: 0;
        }
        .DayPicker-Months {
          flex-wrap: nowrap;
        }
        .DayPicker-Month + .DayPicker-Month {
          margin-left: 3rem;
        }
      `}</style>
    </div>
  )
}

DatePicker.propTypes = {
  handleDateChange: PropTypes.func,
}

export default DatePicker
