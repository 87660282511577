import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import CreditCard from '@/common/components/CreditCard'
import FormCheckbox from '@/common/components/FormCheckbox'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import { ENDPOINTS, PDFS, ROUTES } from '@/common/constants'
import { useGetBankCard, useSubmitForm } from '@/common/hooks'
import { history } from '@/history'
import { getPersonStatus, selectMyFlocPerson } from '@/redux/user'

const StepTwo = props => {
  const dispatch = useDispatch()
  const [termsAgreed, setTermsAgreed] = useState(false)
  const { loading, submitForm } = useSubmitForm()

  const { cardAch } = useGetBankCard({ getAccounts: false })
  const myFlocPerson = useSelector(selectMyFlocPerson)

  const onSubmit = async () => {
    const { response } = await submitForm(ENDPOINTS.NETSPEND_ACCOUNTS_LEGAL(myFlocPerson.id), {
      method: 'PUT',
    })
    if (response.status === 204) {
      await dispatch(getPersonStatus())
      history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE)
    }
  }

  return (
    <>
      <PageHeader stepText='Step 2 of 5'>Fund your myFloc Card Account</PageHeader>
      <div className='relative flex-col text-left main-container'>
        <h4>Congratulations!</h4>

        <div className='mt-4 text-lg'>
          Your myFloc Card Account has been created. You can access your account details at any time
          on the myFloc Card Account tab.
        </div>

        <h3 className='mt-9'>Your myFloc Card Account:</h3>

        {cardAch ? (
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row text-lg'>
              <div>
                <div>Account No.</div>
                <div>Routing No.</div>
              </div>
              <div className='ml-4'>
                <div>{cardAch.account_number}</div>
                <div>{cardAch.routing_number}</div>
              </div>
            </div>
            <CreditCard className='hidden md:inline-block' width={160} active />
          </div>
        ) : (
          <div className='mt-10'>
            <Loading />
          </div>
        )}

        <h3 className='mt-16'>
          Next, transfer money from your personal bank account to your myFloc Card Account to enable
          spending.
        </h3>

        <h3 className='mt-10'>
          To request bank transfers, you must read and agree to the following:*
        </h3>

        <FormCheckbox
          className='mt-6'
          id='joinFriendTermsAgreement'
          name='termsAgreement'
          onChange={ev => setTermsAgreed(ev.target.checked)}
          plain
        >
          <div className='text-base font-bold'>
            By checking this box, I confirm I have read and agree to the terms of the{' '}
            <Button
              className='inline p-0 m-0 font-bold outline-none'
              onClick={() => window.open(PDFS.ESIGN_DISCLOSURE, '_blank')}
              type='button'
              primary
              text
            >
              Netspend ESIGN Disclosure
            </Button>
            ,{' '}
            <Button
              className='inline p-0 m-0 font-bold outline-none'
              onClick={() => window.open(PDFS.BANK_TRANSFER_TERMS_AND_CONDITIONS, '_blank')}
              type='button'
              primary
              text
            >
              Bank Transfer Terms & Conditions
            </Button>{' '}
            and{' '}
            <Button
              className='inline p-0 m-0 font-bold outline-none'
              onClick={() => window.open(PDFS.NETSPEND_PRIVACY_POLICY, '_blank')}
              type='button'
              primary
              text
            >
              Netspend Privacy Policy
            </Button>
            .
          </div>
        </FormCheckbox>

        {/* Actions */}
        <div className='flex flex-col-reverse items-center mt-8 md:flex-row md:justify-around md:items-center md:mt-14'>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <Button className='mt-8 md:mt-0' onClick={history.goBack} type='button' primary text>
            Back to Summary
          </Button>

          <Button className='m-0' disabled={!termsAgreed} isLoading={loading} onClick={onSubmit}>
            Request Bank Transfer
          </Button>
        </div>

        <Button
          className='mt-8 md:my-16'
          onClick={() => history.push(ROUTES.TRANSFER_DIRECT)}
          primary
          text
        >
          Skip this Step: Set Up Transfer Directly From Your Bank
        </Button>

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.transfer}
        />
        <Panel className='mx-auto mt-10 w-5/6 lg:hidden' type={PANEL_TYPES.transfer} />
      </div>
    </>
  )
}

export default StepTwo
