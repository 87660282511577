import React, { useCallback, useEffect, useState } from 'react'

import { batch, useDispatch, useSelector } from 'react-redux'

import { ReactComponent as DashboardWave } from '@/assets/svg/dashboard_wave.svg'
import Button from '@/common/components/Button'
import Dropdown from '@/common/components/Dropdown'
import {
  ROLES,
  ENROLLMENT,
  ENDPOINTS,
  ROUTES,
  NETSPEND_ERROR_MESSAGES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { checkEnrollmentStepPassed, currency } from '@/common/utils'
import { formatDate } from '@/common/utils/dates'
import { history } from '@/history'
import {
  getPersonBalance, getTeamBalances, getAccounts, getCards,
} from '@/redux/netspend'
import { store } from '@/redux/store'
import { combineTeamBalance, getInvited, getTeam } from '@/redux/team'
import { selectPreferredFirstname, selectMyFlocPerson } from '@/redux/user'
import { SetupMyFloc, BuildTeam, BalanceSummary } from '@/views/dashboard/dashboardComponents'
import Loading from '@components/Loading'

import HeroSection from './dashboardComponents/HeroSection'

// Quick nav shortcuts, uncomment this and the component in the dashboard html
// import ButtonShortcuts from './ButtonShortcuts'

const Dashboard = () => {
  const state = store.getState()
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(null)
  const [createAccountStep, setCreateAccountStep] = useState('myfloc-account-created')
  const [transactionsMyFlocPersonId, setTransactionsMyFlocPersonId] = useState(null)
  const [transactions, setTransactions] = useState([])
  const [transactionsLoading, setTransactionsLoading] = useState(true)

  const [teamList, setTeamList] = useState(null)

  // Filter is set in BuildTeam Component
  const [teamStatusFilter, setTeamStatusFilter] = useState('current')
  const teamFilterOptions = [
    {
      text: 'Current Team',
      value: 'current',
    },
    {
      text: 'Closed Members Only',
      value: 'closed',
    },
    {
      text: 'Current & Closed Members',
      value: 'currentClosed',
    },
    {
      text: 'Invited',
      value: 'invited',
    },
  ]
  const teamStatusAllow = {
    closed: ['closed'],
    current: ['active', 'invited'],
    currentClosed: ['active', 'invited', 'closed'],
    invited: ['invited'],
  }

  const myFlocPerson = useSelector(selectMyFlocPerson)
  const personBalance = useSelector(state => state.netspend.personBalance)
  const bankAccounts = useSelector(state => state.netspend.accounts)
  const bankAccountsLoading = useSelector(state => state.netspend.accountsLoading)
  const teamBalance = useSelector(combineTeamBalance)
  const team = useSelector(state => state.team.team)
  const invitedTeam = useSelector(state => state.team.invitations)
  const teamCards = useSelector(state => state.netspend.cards)
  const activePersonStatus = useSelector(state => state.user.activePersonStatus)
  const leadAccountStatus = useSelector(state => state.user.activePersonStatus?.nsLeadAccountStatus)
  const teamPersons = useSelector(state => state.user.persons)
  const hasMultipleLeads = teamPersons?.filter(person => person.role === ROLES.LEAD).length > 1

  const { submitForm } = useSubmitForm()
  const userPreferredFirstName = selectPreferredFirstname(state)

  const viewPermissions = myFlocPerson.notificationSettings?.showAllTransactions
  const isAdmin = myFlocPerson.role === ROLES.LEAD || myFlocPerson.role === ROLES.INSIDER
  const canViewTeam =
    (myFlocPerson.role === ROLES.TEAM_MEMBER && viewPermissions) ||
    myFlocPerson.role === ROLES.FRIEND ||
    isAdmin
  const canTransferFunds = myFlocPerson.role === ROLES.LEAD
  const lead = teamBalance?.find(account => account.role === ROLES.LEAD)

  const showTransactionDropdown = viewPermissions

  const stepTwoDefinitions = [
    ENROLLMENT.MF_CREATED,
    ENROLLMENT.NS_CREATED,
    ENROLLMENT.NS_CARD,
    ENROLLMENT.NS_LEGAL,
  ]

  const getTransactions = useCallback(
    /**
     * Get transactions
     *
     * @param {number} limit the amount of transactions to show, truncated on the FE
     */
    async (limit = 4) => {
      if (!transactionsMyFlocPersonId) {
        return
      }

      setTransactions(null)

      let localMyFlocPersonId = transactionsMyFlocPersonId

      // Primary account
      if (transactionsMyFlocPersonId === 'primary') {
        localMyFlocPersonId = lead.id
      }

      // Use the standard transactions endpoint to query both pending and
      // completed transactions then aggregate. 11
      const resp = await Promise.all([
        submitForm(
          ENDPOINTS.NETSPEND_PERSONS_TRANSACTIONS(localMyFlocPersonId),
          {
            payload: {
              getPrimary: transactionsMyFlocPersonId === 'primary',
              limit: 5,
            },
          }
        ),
        submitForm(
          ENDPOINTS.NETSPEND_PERSONS_TRANSACTIONS(localMyFlocPersonId),
          {
            payload: {
              filter: 'pending',
              getPrimary: transactionsMyFlocPersonId === 'primary',
              limit: 5,
            },
          }
        ),
      ])

      if (resp[0].response.status >= 400 || resp[1].response.status >= 400) {
        history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.NO_CONNECTION))
      }
      else {
        const transactions = [
          ...resp[1].data?.transactions, // Pending first
          ...resp[0].data?.transactions, // Then the rest
        ]

        setTransactions(transactions.slice(0, limit))
        setTransactionsLoading(false)
      }
    },
    [transactionsMyFlocPersonId]
  )

  const getTransactionName = transaction => {
    if (transaction.status === 'pending') return 'Pending'
  }

  useEffect(() => {
    if (
      teamList === null ||
      bankAccountsLoading ||
      !myFlocPerson ||
      (!personBalance && myFlocPerson.role !== ROLES.FRIEND && myFlocPerson.role !== ROLES.LEAD)
    ) {
      return
    }

    if (myFlocPerson.role === ROLES.LEAD) {
      if (!personBalance && activePersonStatus.enrollmentFlow !== ENROLLMENT.MF_CREATED) return
      const enrollmentFlow = activePersonStatus.enrollmentFlow

      // Debug line to force bank link check since netspend.accounts isn't returning?
      const isBankLinked = !!bankAccounts?.id

      if (isBankLinked || personBalance?.actual.amount > 0) {
        team.length > 1 || invitedTeam.length > 0 || teamBalance?.length > 1
          ? setCurrentStep(hasMultipleLeads ? 4 : 5)
          : setCurrentStep(hasMultipleLeads ? 2 : 3)
      }
      else if (stepTwoDefinitions.includes(enrollmentFlow)) {
        if (team.length > 1 || (invitedTeam?.length ?? 0) > 0) {
          setCurrentStep(hasMultipleLeads ? 1 : 2)
        }
        else {
          setCurrentStep(hasMultipleLeads ? 1 : 2)
        }
        setCreateAccountStep(enrollmentFlow)
      }
      else if (isAdmin && enrollmentFlow === ENROLLMENT.MF_CREATED) {
        setCurrentStep(hasMultipleLeads ? 0 : 1)
      }

      if (isBankLinked) {
        setCreateAccountStep('bank-linked')
      }
    }
    else {
      setCurrentStep(hasMultipleLeads ? 4 : 5)
    }
  }, [teamList, personBalance, bankAccounts, bankAccountsLoading])

  useEffect(() => {
    if (myFlocPerson && invitedTeam && team) {
      const prepTeamList = {
        friend: {
          active:
            team &&
            team.filter(member => member.role === ROLES.FRIEND && member.status === 'active'),
          closed:
            team &&
            team.filter(member => member.role === ROLES.FRIEND && member.status === 'closed'),
          invited: [],
        },
        insider: {
          active:
            team &&
            team.filter(member => member.role === ROLES.INSIDER && member.status === 'active'),
          closed:
            team &&
            team.filter(member => member.role === ROLES.INSIDER && member.status === 'closed'),
          invited: [],
        },
        lead: team && team.find(member => member.role === ROLES.LEAD),
        myFlocPerson,
        teamMember: {
          active:
            team &&
            team.filter(member => member.role === ROLES.TEAM_MEMBER && member.status === 'active'),
          closed:
            team &&
            team.filter(member => member.role === ROLES.TEAM_MEMBER && member.status === 'closed'),
          invited: [],
        },
      }
      if (invitedTeam?.length > 0) {
        invitedTeam.forEach(invite => {
          const invitePerson = { ...invite }
          if (invite.status !== 'revoked') {
            invitePerson.status = 'invited'
            invitePerson.inviteId = invite.id
            prepTeamList[invite.role].invited?.push(invitePerson)
          }
        })
      }
      setTeamList(prepTeamList)
    }
  }, [team, invitedTeam])

  let balances
  if (myFlocPerson) {
    // Calculate the total balance
    const totalInCents = Object.entries(teamBalance || {})
      .filter(([, account]) => account.role !== ROLES.INSIDER)
      .reduce((a, b) => a + b[1].available.amount, 0)

    // Lead/Insider, Friend
    if (lead && (isAdmin || myFlocPerson.role === ROLES.FRIEND)) {
      balances = [
        {
          amount: totalInCents,
          title: 'myFloc Card Account Available Balance',
          transferFundsButton: canTransferFunds,
        },
        {
          amount: lead.available.amount,
          title: 'Primary Account Available Balance',
          transferFundsButton: false,
        },
      ]
    }
    // Team member with view permissions can see the total
    else if (myFlocPerson.role === ROLES.TEAM_MEMBER && viewPermissions && personBalance) {
      balances = [
        {
          amount: totalInCents,
          title: 'myFloc Card Account Available Balance',
          transferFundsButton: canTransferFunds,
        },
        {
          amount: personBalance.available.amount,
          title: 'My Available Balance',
          transferFundsButton: false,
        },
      ]
    }
    // Team member without view permissions
    else if (myFlocPerson.role === ROLES.TEAM_MEMBER && personBalance) {
      balances = [{ amount: personBalance.available.amount, title: 'My Available Balance' }]
    }
  }

  useEffect(() => {
    if (transactionsMyFlocPersonId) {
      setTransactionsLoading(true)
      const limit = 5
      getTransactions(limit)
    }
  }, [transactionsMyFlocPersonId])

  useEffect(() => {
    batch(() => {
      dispatch(getTeam())
      dispatch(getInvited())

      if (
        myFlocPerson.role === ROLES.FRIEND ||
        ((activePersonStatus.enrollmentFlow !== ENROLLMENT.MF_CREATED || viewPermissions) &&
          leadAccountStatus)
      ) {
        dispatch(getTeamBalances())

        if (myFlocPerson.role !== ROLES.FRIEND) {
          if (
            checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_LEGAL) &&
            myFlocPerson.role === ROLES.LEAD
          ) {
            dispatch(getAccounts())
          }

          dispatch(getPersonBalance())
          dispatch(getCards())
        }
      }
    })
  }, [])

  // Set initial myFlocPersonId
  useEffect(() => {
    if (teamBalance && !transactionsMyFlocPersonId) {
      let localMyFlocPersonId = myFlocPerson.id
      if (myFlocPerson.role === 'friend') {
        localMyFlocPersonId = 'primary'
      }
      setTransactionsMyFlocPersonId(localMyFlocPersonId)
    }
  }, [teamBalance, transactionsMyFlocPersonId])

  // @@ Build view member dropdown
  const viewMemberOptions = []
  if (viewPermissions && teamBalance) {
    if (myFlocPerson.role !== ROLES.TEAM_MEMBER) {
      viewMemberOptions.push({
        selected: myFlocPerson.role === ROLES.FRIEND,
        text: 'Primary',
        value: 'primary',
      })
    }

    teamBalance.forEach(account => {
      let pushAccount = !!(account.role === ROLES.TEAM_MEMBER && viewPermissions)
      if (isAdmin) {
        pushAccount = true
      }
      if (myFlocPerson.role === ROLES.FRIEND) {
        pushAccount = true
      }
      if (myFlocPerson.role === ROLES.TEAM_MEMBER && viewPermissions) {
        pushAccount = true
      }

      // For closed accounts only remove all other
      if (teamStatusFilter === 'closed' && account.status !== 'closed') {
        pushAccount = false
      }

      // Only show the closed accounts if desired
      if (!['closed', 'currentClosed'].includes(teamStatusFilter) && account.status === 'closed') {
        pushAccount = false
      }

      if (pushAccount) {
        viewMemberOptions.push({
          selected: account.id === myFlocPerson.id,
          text: `${account.firstName} ${account.lastName}`,
          value: account.id,
        })
      }
    })
  }

  return currentStep ? (
    <>
      <HeroSection
        currentStep={currentStep}
        hasMultipleLeads={hasMultipleLeads}
        isAdmin={isAdmin}
        userPreferredFirstName={userPreferredFirstName}
      />

      <DashboardWave />
      <div className='flex-grow px-5 pb-20 md:px-8 bg-tertiary'>
        {/* <ButtonShortcuts /> */}

        {currentStep <= (hasMultipleLeads ? 2 : 3) && !bankAccounts?.id && (
          <SetupMyFloc
            activePersonStatus={activePersonStatus}
            className='mt-8'
            createAccountStep={createAccountStep}
            currentStep={currentStep}
            hasMultipleLeads={hasMultipleLeads}
          />
        )}
        {currentStep > (hasMultipleLeads ? 1 : 2) && (
          <div className='mx-auto mt-8 max-w-5xl'>
            <div className='grid grid-cols-12 gap-4'>
              <div
                className={`col-span-12 md:col-span-7 rounded bg-white p-8 ${!canViewTeam &&
                  'self-start'}`}
              >
                <BalanceSummary balances={balances} />
              </div>
              <div className='col-span-12 bg-white rounded md:col-span-5'>
                <div className='flex flex-col flex-wrap p-4 md:flex-row md:justify-between'>
                  <h3 className='pl-3 md:pt-3'>Recent Transactions</h3>
                  {showTransactionDropdown && (
                    <Dropdown
                      className=''
                      label='Change Account'
                      onChange={option => setTransactionsMyFlocPersonId(option.value)}
                      options={viewMemberOptions}
                      value={transactionsMyFlocPersonId}
                    />
                  )}
                </div>
                {transactionsLoading && <Loading />}
                {/* members details */
                  transactions?.length > 0 && !transactionsLoading && (
                    <>
                      {transactions?.map((transaction, i) => (
                        <div
                          className={`flex flex-row justify-between py-2 ${i % 2 === 0 ? 'bg-grey' : ''
                          }`}
                          key={transaction.id}
                        >
                          <div className='flex flex-col pl-5 min-w-0'>
                            <h3 className='mr-3 mb-0 truncate'>{transaction.statement_memo}</h3>
                            {getTransactionName(transaction)}
                          </div>
                          <div className='flex flex-col justify-end pr-5'>
                            <h3 className='mb-0'>
                              {`${transaction.direction === 'credit' ? '+' : '-'}${currency(
                                transaction.current_amount.amount / 100
                              )}`}
                            </h3>
                            {formatDate(transaction.creation_time, 'mm/dd/yy')}
                          </div>
                        </div>
                      ))}
                      <div className='flex flex-grow justify-end p-2'>
                        <Button
                          className='pr-6 flat-left'
                          link={ROUTES.ACCOUNT_PERSON(transactionsMyFlocPersonId)}
                          arrow
                          primary
                          text
                        >
                          View Transactions
                        </Button>
                      </div>
                    </>
                  )}

                {!transactions?.length && !transactionsLoading && (
                  <div className='flex'>
                    <h3 className='mx-auto text-1-5xl'>No Transactions</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {canViewTeam && (
          <BuildTeam
            changeTeamStatusFilter={value => setTeamStatusFilter(value)}
            className='mt-8 mb-20'
            currentStep={currentStep}
            hasMultipleLeads={hasMultipleLeads}
            isAdmin={isAdmin}
            myFlocPerson={myFlocPerson}
            teamBalances={teamBalance}
            teamCards={teamCards}
            teamFilterOptions={teamFilterOptions}
            teamList={teamList}
            teamStatusAllow={teamStatusAllow}
            teamStatusFilter={teamStatusFilter}
          />
        )}
      </div>
    </>
  ) : (
    <Loading className='min-h-header' />
  )
}

Dashboard.propTypes = {}

export default Dashboard
