import { useEffect, useState } from 'react'

export const useFormFieldTracking = watch => {
  const [updatedFields, setUpdatedFields] = useState([])

  const reset = () => {
    setUpdatedFields([])
  }

  // track updated fields
  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (updatedFields.includes(name)) return

      setUpdatedFields([].concat(updatedFields, name))
    })

    const unsubscribe = () => subscription.unsubscribe()

    return unsubscribe
  }, [watch, updatedFields])

  return { reset, updatedFields }
}

export default useFormFieldTracking
