import { useEffect, useState } from 'react'

/*
*   Extracts field validation errors from useSubmit's apiErrors
*/

export const useParseApiErrors = (apiErrors, options = {}) => {
  const { filter } = options
  const [apiValidationErrors, setApiValidationErrors] = useState({})

  useEffect(() => {
    if (!apiErrors) {
      setApiValidationErrors({})
      return
    }

    const localizedValidationErrors = {}

    // map 'existing user email' error message to email field validation error
    if (apiErrors.message === 'A user with that email already exists') localizedValidationErrors.email = apiErrors.message

    const validationErrors = {
      ...(apiErrors?.validationErrors ?? {}),
      ...localizedValidationErrors,
    }

    // remove filtered fields
    let filteredValidationErrors = {}
    if (!filter) filteredValidationErrors = validationErrors
    else {
      filteredValidationErrors = Object.keys(validationErrors)
        .filter(key => filter?.indexOf(key) === -1)
        .reduce((accumulator, key) => {
          accumulator[key] = validationErrors[key]
          return accumulator
        }, {})
    }

    setApiValidationErrors(filteredValidationErrors)
  }, [apiErrors, filter])

  return { apiValidationErrors }
}

export default useParseApiErrors
