import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  ACTION_STATES, ENDPOINTS, HTTP_METHODS, ROUTES,
} from '@/common/constants'
import { useAuthentication, useSubmitForm } from '@/common/hooks'
import { history } from '@/history'
import { getMyFlocPerson, selectMyFlocPerson } from '@/redux'
import { TeamMemberEnrollmentForm } from '@components/EnrollmentForm'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'
import { EnrollmentSecurityPanel } from '@components/Panel'

const JoinTeamMemberRetryNetspend = () => {
  const dispatch = useDispatch()
  const { apiErrors, submitForm } = useSubmitForm()
  const { auth0Loading, isAuthenticated, loading } = useAuthentication()

  const myFlocUser = useSelector(state => state.user.user)
  const myFlocPerson = useSelector(selectMyFlocPerson)
  const nsSession = useSelector(state => state.netspend.session)

  const myFlocPersonContainsAddress = myFlocPerson?.residenceAddress

  const onSubmit = async payload => {
    // set country code for residence address
    payload.residenceAddress.country = 'US'

    if (myFlocPerson.shipToMember) {
      if (payload.mailToResidence) {
        // use residence address as shipping address
        payload.shippingAddress = payload.residenceAddress
      }
      else {
        // add country to user-entered shipping address
        payload.shippingAddress.country = 'US'
      }
    }

    // create netspend person
    const [MM, DD, YYYY] = payload.dob.split('/')
    const encryptedData = await window.NetspendSDK.session.encrypt({
      date_of_birth: `${YYYY}-${MM}-${DD}`,
    })

    const createNetspendPersonPayload = {
      encryptedData,
      firstName: payload.firstName,
      lastName: payload.lastName,
      residenceAddress: payload.residenceAddress,
    }

    if (payload.shippingAddress) {
      createNetspendPersonPayload.shippingAddress = payload.shippingAddress
    }

    const createNetspendPersonResponse = await submitForm(
      ENDPOINTS.NETSPEND_PERSONS_ID(myFlocPerson.id),
      {
        method: HTTP_METHODS.POST,
        payload: createNetspendPersonPayload,
      }
    )

    if (createNetspendPersonResponse.status !== 200) {
      // return history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
      return history.push(ROUTES.ERROR_ENROLLMENT)
    }

    history.push('/')
  }

  // Fetch full myFloc person information
  // only if redux has the partial that comes from user object
  const [detailedPersonRequestState, setDetailedPersonRequestState] = useState(ACTION_STATES.UNINITIATED)
  useEffect(() => {
    if (
      !isAuthenticated ||
      myFlocPersonContainsAddress ||
      detailedPersonRequestState !== ACTION_STATES.UNINITIATED
    ) return

    setDetailedPersonRequestState(ACTION_STATES.INITIATED)

    ;(async () => {
      await dispatch(getMyFlocPerson())
      setDetailedPersonRequestState(ACTION_STATES.COMPLETED)
    })()
  }, [isAuthenticated, myFlocPersonContainsAddress, detailedPersonRequestState])

  // handle errors
  useEffect(() => {
    if (
      (!loading && !auth0Loading && !isAuthenticated) || // authentication failed
      (!myFlocPersonContainsAddress && detailedPersonRequestState === ACTION_STATES.COMPLETED) // myfloc person missing address and detailed person request completed
    ) {
      history.push(ROUTES.ERROR_ENROLLMENT)
    }
  }, [loading, isAuthenticated, myFlocUser, myFlocPersonContainsAddress, detailedPersonRequestState])

  return (
    <>
      <PageHeader>Enter your personal details</PageHeader>
      <div className='relative flex-col text-left main-container'>
        {
          (
            !nsSession || // netspend session not yet initiated
            !myFlocPersonContainsAddress // full person details not yet loaded
          )
            ? <Loading className='min-h-screen' />
            : <TeamMemberEnrollmentForm
              apiErrors={apiErrors}
              loadPasswordField={false}
              loadShippingFields={myFlocPerson.shipToMember}
              onSubmit={onSubmit}
              prefill={{
                displayName: myFlocUser.displayName,
                email: myFlocUser.email,
                firstName: myFlocUser.firstName,
                lastName: myFlocUser.lastName,
                mobile: myFlocUser.mobile,
                residenceAddress: myFlocPerson.residenceAddress,
                shippingAddress: myFlocPerson.shipToMember ? myFlocPerson.shippingAddress : null,
                smsConsent: myFlocUser.smsConsent,
              }}
            />
        }
        <EnrollmentSecurityPanel />
      </div>
    </>
  )
}

export default JoinTeamMemberRetryNetspend
