import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import AnimateIn from '@/common/components/AnimateIn'
import Button, { SIGN_TYPES } from '@/common/components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import PageHeader from '@/common/components/PageHeader'
import { ENDPOINTS, ROUTES } from '@/common/constants'
import { useFormFieldTracking, useParseApiErrors, useSubmitForm } from '@/common/hooks'
import { isEmpty, isRequired } from '@/common/utils'
import { confirmEmailSchema, friendInvitationSchema } from '@/common/validations'
import { history } from '@/history'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { selectMyFlocPerson } from '@/redux/user'

/**
 * The add Team Friend Page
 * Provides the user a place to input a myFloc friend.
 */
const AddFriend = props => {
  const [showNickname, setShowNickname] = useState(false)
  const [showEmailPanel, setShowEmailPanel] = useState(false)
  const { apiErrors, loading, submitForm } = useSubmitForm()
  const dispatch = useDispatch()

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(friendInvitationSchema.concat(confirmEmailSchema)) })

  const { reset: resetFieldTracking, updatedFields } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: updatedFields })

  const myflocUser = useSelector(state => state.user.user)
  const myflocPerson = useSelector(selectMyFlocPerson)

  const ignoreDomEvent = event => {
    event.preventDefault()
    return false
  }

  const onError = formErrors => {
    const requiredValidations = isRequired(formErrors)
    if (requiredValidations) {
      dispatch(
        addToast({
          subtitle:
            'Please review and make sure you’ve included all required information before proceeding.',
          title: 'Oops, some required details were not entered.',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  const onSubmit = async formEntry => {
    resetFieldTracking()

    const resp = await submitForm(ENDPOINTS.INVITE_FRIEND, {
      payload: {
        ...formEntry,
        inviter: `${myflocUser.firstName} ${myflocUser.lastName}`,
        teamId: myflocPerson.teamId,
      },
      success: {
        redirect: {
          path: ROUTES.DASHBOARD,
        },
        toast: {
          subtitle: `You have invited ${formEntry.firstName} ${formEntry.lastName} (${formEntry.email}) to join your team as a friend.`,
          title: 'Success',
        },
      },
    })

    // Make sure to reset the form on success
    // Note Reset from RHC doesn't seem to work correctly here
    if (resp.response.status === 201) {
      setValue('displayName', '', { shouldDirty: false })
      setValue('email', '', { shouldDirty: false })
      setValue('firstName', '', { shouldDirty: false })
      setValue('lastName', '', { shouldDirty: false })
    }
  }

  // Show email info panel
  // not sure when exactly to show this so currently after they type a character
  const watchEmail = watch('email', '')
  useEffect(() => {
    if (watchEmail?.length > 1) {
      setShowEmailPanel(true)
    }
  }, [watchEmail])

  return (
    <>
      <PageHeader>Add a myFloc Friend</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='text-sm'>*Required field</div>
          <div className='mt-1 text-lg'>
            Enter their details to send an invitation to join your team. Please use legal first and
            last name (note we do not conduct residency status checks).
          </div>

          <div className='flex flex-row justify-between mt-6'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
          </div>
          <AnimateIn maxHeight={200} show={showNickname}>
            <FormInput
              errors={errors}
              label='Nickname (Optional)'
              name='displayName'
              register={register}
              validationErrors={apiValidationErrors}
            />
          </AnimateIn>

          <Button
            className='mb-7 flat-left'
            onClick={() => setShowNickname(!showNickname)}
            sign={showNickname ? SIGN_TYPES.minus : SIGN_TYPES.plus}
            type='button'
            primary
            text
          >
            {showNickname ? 'Hide Nickname' : 'Add Nickname (Optional)'}
          </Button>

          <FormInput
            autoComplete='email'
            errors={errors}
            label='Email Address'
            name='email'
            register={register}
            type={FORM_INPUT_TYPES.email}
            validationErrors={apiValidationErrors}
            required
          />

          <FormInput
            autoComplete='off'
            errors={errors}
            label='Confirm E&#8203;mail Address' // using "email" in the label triggers autocomplete. using zero-width space to break it up.
            name='confirmEmeil' // using "email" in name field triggers autocomplete. using misspelled name as workaround
            onDrop={ignoreDomEvent}
            onPaste={ignoreDomEvent}
            register={register}
            required
          />

          <AnimateIn maxHeight={200} show={showEmailPanel}>
            <div className='p-6 text-lg text-center rounded-md bg-light-blue'>
              An invitation to join myFloc will be emailed to: <b>{watchEmail}</b>. They will be
              able to view spending activity of all myFloc Team Members (including Lead and
              Insider).
            </div>
          </AnimateIn>

          <div className='flex flex-col items-center mt-9'>
            <Button
              disabled={!isEmpty(errors) || !isEmpty(apiValidationErrors)}
              isLoading={loading}
            >
              Send Invitation
            </Button>
            <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddFriend
