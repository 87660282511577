import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { AUTH0_API_AUDIENCE } from '../../common/constants'

// DEPRECATED - amrit
const Qa = () => {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const makeCall = async () => {
      const authToken = await getAccessTokenSilently({ audience: AUTH0_API_AUDIENCE })
      setToken(authToken)
    }

    if (!isAuthLoading) {
      makeCall()
    }
  }, [isAuthLoading, getAccessTokenSilently, user])

  if (isAuthLoading) {
    return <div>Loading</div>
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.pathname } })
    return <div>Redirecting</div>
  }

  if (!token) {
    return <div>No token</div>
  }

  return (
    <main>
      <div>Hi {user.given_name} here's your Auth0 token</div>
      <textarea cols='100' rows='10'>
        {token}
      </textarea>
    </main>
  )
}

export default Qa
