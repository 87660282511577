export const KEYCODES = {
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  TAB: 9,
  UP: 38,
}

export const ENV_OPTIONS = {
  development: 'development',
  local: 'local',
  production: 'production',
  test: 'test',
}

export const INPUT_TYPES = [
  'text',
  'password',
  'email',
  'number',
  'tel',
  'url',
  'search',
  'range',
  'color',
  'date',
  'time',
  'datetime',
  'datetime-local',
  'month',
  'week',
  'file',
]

export const EVENTS = {
  CLICK: ['click', 'touchstart', 'keyup'],
  FOCUS: ['focusin', 'focusout'],
  MOUSE: ['mouseenter', 'mouseleave'],
}

export const TWO_MINUTES = 120000
export const FOURTEEN_MINUTES = 840000

export const HTTP_METHODS = {
  DELETE: 'DELETE',
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
}

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID_FRONTEND = import.meta.env.VITE_AUTH0_CLIENT_ID_FRONTEND
export const AUTH0_API_AUDIENCE = import.meta.env.VITE_AUTH0_API_AUDIENCE

export const MYFLOC_API_HOST_URL = import.meta.env.VITE_MYFLOC_API_HOST_URL
export const MYFLOC_PHONE_NUMBER = '1-833-693-5621'
export const MYFLOC_PHONE_NUMBER_TEXT = '1-833-myFloc1'

export const NETSPEND_SDK_ID = import.meta.env.VITE_NETSPEND_SDK_ID
export const NETSPEND_SDK_URL = import.meta.env.VITE_NETSPEND_SDK_URL

export const ROLES = {
  FRIEND: 'friend',
  INSIDER: 'insider',
  LEAD: 'lead',
  TEAM_MEMBER: 'teamMember',
}

export const ROLES_ORDERED = [ROLES.FRIEND, ROLES.TEAM_MEMBER, ROLES.INSIDER, ROLES.LEAD]

export const STATUSES = {
  KYC_APPROVED: 'approved',
  KYC_CONDITIONAL: 'conditional',
  KYC_REJECTED: 'rejected',
  REMOVED_FROM_TEAM: 'removed',
  UNCHECKED: 'unchecked',
  UNVERIFIED_EMAIL: 'otp-unverified-email',
}

export const ACTION_STATES = {
  COMPLETED: 'completed',
  INITIATED: 'initiated',
  UNINITIATED: 'uninitiated',
}
