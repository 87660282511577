import React, { useCallback, useState, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import AnimateIn from '@/common/components/AnimateIn'
import Button from '@/common/components/Button'
import FormAddress from '@/common/components/FormAddress'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import {
  HTTP_METHODS, ROLES, YES_OR_NO, ENDPOINTS, ROUTES,
} from '@/common/constants'
import { useSubmitForm, useQuery } from '@/common/hooks'
import { useAsyncEffect } from '@/common/hooks/useAsyncEffect'
import { replaceCard } from '@/common/validations'
import { history } from '@/history'
import { TOAST_TYPES, addToast } from '@/redux'
import { selectMyFlocPerson } from '@/redux/user'
import FormRadio from '@components/FormRadio'

import ReplaceComplete from './ReplaceComplete'

const ReplaceCard = props => {
  const activePerson = useSelector(selectMyFlocPerson)
  const dispatch = useDispatch()
  const query = useQuery()
  const cardPersonId = query.get('to')

  // ---------------------------------------------------------------------------
  // ASYNC DATA
  const pushError = (message, page = '/') => {
    history.push(page)
    dispatch(
      addToast({
        subtitle: message,
        title: 'Error',
        type: TOAST_TYPES.error,
      })
    )
  }

  // Query the person who's card will be set to get fresh data
  const [cardPerson, setCardPerson] = useState(null)
  const getCardPerson = useCallback(async () => {
    if (!activePerson || !cardPersonId) {
      history.push('/')
      return
    }

    const result = await submitForm(`/teams/${activePerson.teamId}/persons`, { method: HTTP_METHODS.GET })
    if (result.response.status < 400) {
      const person = result.data.find(person => person.id === cardPersonId)
      if (!person.cardId || person.cardStatus === 'closed') {
        pushError('Card not found for that user.')
      }
      return person
    }
    else {
      pushError('Card not found for that user.')
    }
    return null
  }, [cardPersonId, activePerson])

  // Run callback with proper state protection
  useAsyncEffect(getCardPerson, setCardPerson)

  const [headerText, setHeaderText] = useState(null)
  useEffect(() => {
    if (cardPerson) {
      const displayName = cardPerson?.displayName?.trim() ? ` (${cardPerson.displayName})` : ''
      setHeaderText(`${cardPerson.firstName} ${cardPerson.lastName}${displayName}`)
    }
  }, [cardPerson])

  // Get the leads address if the card will be shipped to the lead
  const [address, setAddress] = useState(null)
  const getAddress = useCallback(async () => {
    if (!cardPerson) {
      return
    }

    // If the card is shipped to the member the address is fixed
    // If the card is shipped to the lead but the setter is not the lead the address is fixed
    if (cardPerson?.shipToMember) {
      return 'to-member'
    }
    if (!cardPerson?.shipToMember && activePerson.role !== ROLES.LEAD) {
      return 'to-lead'
    }

    // If the activePerson is the lead AND the card is shipped to the lead we check the address
    const result = await submitForm(`/persons/${activePerson.id}`, { method: HTTP_METHODS.GET })
    if (result.response.status < 400) {
      const address = result.data.shippingAddress ?? result.data.residenceAddress
      if (!address) {
        pushError('Could not retrieve shipping address information. Please try again. ')
      }
      return address
    }
    else {
      pushError('Could not retrieve shipping address information. Please try again. ')
    }
  }, [cardPerson])

  // Run callback with proper state protection
  useAsyncEffect(getAddress, setAddress)

  // Update schema based on address results
  useEffect(() => {
    setValue('needsAddress', typeof address !== 'string')
  }, [address])

  // ---------------------------------------------------------------------------
  // FORM HANDLING
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(replaceCard) })

  const { apiErrors, loading, submitForm } = useSubmitForm()

  const REPLACE_CARD_REASONS = {
    DAMAGED: 'damaged',
    LOST: 'lost',
    STOLEN: 'stolen',
  }

  // Freeze card and redirect if desired
  const freezeCard = async () => {
    if (!cardPersonId) {
      return
    }

    const result = await submitForm(ENDPOINTS.NETSPEND_CARD_LOCK(cardPersonId), { method: HTTP_METHODS.POST })
    if (result.response.status < 400) {
      history.push('/')
      dispatch(addToast({
        subtitle: 'The card has been frozen successfully.',
        title: 'Card Frozen',
        type: TOAST_TYPES.success,
      }))
    }
    else {
      dispatch(addToast({
        subtitle: 'We were unable to freeze the card at this time. Please try again or contact support. ',
        title: 'Error',
        type: TOAST_TYPES.error,
      }))
    }
  }

  const [flowComplete, setFlowComplete] = useState(false)

  // Cancel card and re-order
  const onSubmit = async formEntry => {
    const payload = {
      reason: formEntry.reason,
    }

    // Only send address if needed
    if (formEntry.address?.line1) {
      payload.address = formEntry.address
    }

    const result = await submitForm(
      ENDPOINTS.NETSPEND_CARD_CLOSE(cardPersonId),
      { method: HTTP_METHODS.POST, noErrorToast: true, payload }
    )

    if (result.response.status < 400) {
      setFlowComplete(true)
    }
    else if (result?.data?.error?.validationErrors) {
      // Map validation errors to form
      for (const item in result?.data?.error?.validationErrors) {
        setError(item)
      }
      dispatch(addToast({
        subtitle: 'Please double-check your entries.',
        title: 'Could not update permissions. ',
        type: TOAST_TYPES.error,
      }))
    }
    else {
      history.push(ROUTES.ERROR_CUSTOM('Error deactivating the card.'))
    }
  }

  // ---------------------------------------------------------------------------
  // MARKUP VARIABLES
  const reason = watch('reason')
  const reasonOptions = [
    {
      label: 'The card was lost',
      value: REPLACE_CARD_REASONS.LOST,
    },
    {
      label: 'The card was damaged',
      value: REPLACE_CARD_REASONS.DAMAGED,
    },
    {
      label: 'The card was stolen',
      value: REPLACE_CARD_REASONS.STOLEN,
    },
  ]

  // Check if the lead needs option to update their address. See address query
  const needsAddressValidation = watch('needsAddress')
  const acceptAddress = watch('acceptAddress')

  function addressText (address) {
    if (address === 'to-lead') {
      return 'The card will be send to the myFloc Leads mailing address on file.'
    }
    if (address === 'to-member') {
      return 'The card will be send to the myFloc Team Members mailing address on file.'
    }
    return 'Is this still your preferred mailing address?'
  }

  function formatAddress (address) {
    const unitNumber = address?.line2?.trim() ? ` - ${address?.line2}` : ''
    return (
      <div className='flex flex-col items-center my-4'>
        <span className='block'>{`${address?.line1}${unitNumber}`}</span>
        <span className='block'>{`${address?.city}, ${address?.region} ${address?.postal}`}</span>
      </div>
    )
  }

  return cardPerson ? (
    <>
      <PageHeader>Replace Card: <br />{headerText}</PageHeader>
      <div className='relative flex-col text-left main-container'>
        {flowComplete
          ? (<ReplaceComplete cardPersonId={cardPersonId} />)
          : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4 text-sm'>*Required field</div>
              <div className='py-5 px-8 mb-4 rounded-md border bg-light-blue border-mid-blue'>
                <b>Important: </b> Requesting a replacement card will immediately deactivate the current card.
                Once deactivated, the card cannot be reactivated. When the new card is received, any services
                that use the old card number will need to be updated.
              </div>
              <label className='mt-4 mb-2'>
                What happened to the old card?*
              </label>
              <FormRadio
                errors={errors}
                name='reason'
                options={reasonOptions}
                register={register}
                validationErrors={apiErrors?.validationErrors}
              />
              {(reason === REPLACE_CARD_REASONS.LOST && cardPerson.cardLockStatus !== 'locked') && (
                <div className='py-5 px-8 my-4 rounded-md bg-grey border-mid-blue'>
                  If you think the card may be found you can temporarily freeze spending on the card
                  instead of requesting a replacement.
                  <Button className='block m-auto mt-2'
                    disabled={false}
                    isLoading={loading}
                    onClick={freezeCard}
                    type='button'>
                    Freeze Card Instead
                  </Button>
                </div>
              )}
              {/* Address Check */}
              <label className='mt-16 mb-2'>{addressText(address)}</label>
              {!needsAddressValidation && (
                <div>Please insure they have up-to-date mailing information in their profile before continuing.</div>
              )}
              {needsAddressValidation && (
                <>
                  {formatAddress(address)}
                  <FormRadio
                    className='min-h-0'
                    errors={errors}
                    labelClassName='mt-0 flex-1'
                    name='acceptAddress'
                    options={[
                      { label: 'Yes', value: YES_OR_NO.YES },
                      { label: 'No', value: YES_OR_NO.NO },
                    ]}
                    register={register}
                    validationErrors={apiErrors?.validationErrors}
                    inline
                  />
                </>
              )}
              <AnimateIn className='px-2 mt-4' maxHeight={740} show={acceptAddress === YES_OR_NO.NO}>
                <FormAddress
                  apiErrors={{ apiErrors }}
                  errors={errors}
                  label='Address'
                  name='address'
                  register={register}
                  showHint={false}
                />
              </AnimateIn>

              {/* Submit Button ----------------------------------------- */}
              <div className='flex flex-col items-center mt-9'>
                <Button disabled={false} isLoading={loading}>
                  Deactivate Card and Send Replacement
                </Button>
                <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
                  Cancel
                </Button>
              </div>
            </form>
          )}
      </div >
    </>
  ) : (<Loading className='min-h-header' />)
}

export default ReplaceCard
