import React from 'react'

import * as Sentry from '@sentry/react'

import { log } from '@/common/utils'
import Error from '@components/Error'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (err, info) {
    this.setState({ hasError: true })
    log('Error Boundary error', err)
    log('Error Boundary info', info)
    Sentry.captureException(err, {
      level: Sentry.Severity.Error,
    })
  }

  render () {
    const { hasError } = this.state
    const { children } = this.props
    const title = (
      <>
        <span className='text-secondary'>Oh no, we have encountered an</span>{' '}
        <span className='text-accent'>error.</span>
      </>
    )
    if (hasError) {
      return <Error title={title} />
    }
    return children
  }
}

export default ErrorBoundary
