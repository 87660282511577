import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useInterval } from '@/common/hooks'
import { getAlertsForToasts, markToastNotificationDelivered } from '@/redux/notifications'
import { addToast, TOAST_TYPES } from '@/redux/toasts'

/**
 * useNotifications hook to subscribe to the notification store in the DB
 */
export const useNotifications = user => {
  const dispatch = useDispatch()
  const toastAlerts = useSelector(state => state.notifications.alertsToToast)

  useEffect(() => {
    if (toastAlerts?.length) {
      toastAlerts.forEach(i => {
        dispatch(
          addToast({
            id: i.id,
            subtitle: i.content,
            title: 'New Account Alert',
            type: TOAST_TYPES.notificationToast,
          })
        )
        dispatch(markToastNotificationDelivered(i.id))
      })
    }
  }, [toastAlerts, dispatch])

  // Fetch them once on login immediately, then set the interval to 60 seconds for new toast alerts
  useInterval(() => {
    if (user?.id) {
      dispatch(getAlertsForToasts())
    }
  }, 60000)

  // send the toast alerts
}

export default useNotifications
