import React from 'react'

// import PropTypes from 'prop-types';
import {
  Route, Switch, useRouteMatch, useLocation,
} from 'react-router'

import { history } from '@/history'

import Button from '../../common/components/Button'
import AddTeamMember from './AddTeamMember'
import EditTeamMember from './EditTeamMember'

const MyTeam = props => {
  const { path } = useRouteMatch()
  const location = useLocation()

  return (
    <div>
      My Team
      <Button
        active={location.pathname.match(`${path}/add`)}
        onClick={() => history.push(`${path}/add`)}
      >
        Add
      </Button>
      <Button
        active={location.pathname.match(`${path}/edit`)}
        onClick={() => history.push(`${path}/edit`)}
      >
        Edit
      </Button>
      <Switch>
        <Route
          path={path}
          render={() => {
            return <div>Overview</div>
          }}
          exact
        />
        <Route component={AddTeamMember} path={`${path}/add`} />
        <Route component={EditTeamMember} path={`${path}/edit`} />
      </Switch>
    </div>
  )
}

MyTeam.propTypes = {}

export default MyTeam
