import React, { memo } from 'react'

import PropTypes from 'prop-types'

import RobitButton from './RobitButton'

const Button = memo(({ primary, secondary, gray, ...props }) => {
  if (primary) {
    return <RobitButton styleType='primary' {...props} />
  }
  else if (secondary) {
    return <RobitButton styleType='secondary' {...props} />
  }
  else if (gray) {
    return <RobitButton styleType='gray' {...props} />
  }

  return <RobitButton styleType='accent' {...props} />
})

Button.propTypes = {
  /**
   * Grey color
   */
  gray: PropTypes.bool,
  /**
   * Primary color
   */
  primary: PropTypes.bool,
  /**
   * Secondary Color
   * */
  secondary: PropTypes.bool,
}

export default Button
