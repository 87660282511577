import React from 'react'

import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
import Error from '@/common/components/Error'
import {
  MYFLOC_PHONE_NUMBER,
  MYFLOC_PHONE_NUMBER_TEXT,
  NETSPEND_ERROR_MESSAGES,
  ROUTES,
} from '@/common/constants'
import { useQuery } from '@/common/hooks'
import { history } from '@/history'

/**
 * Netspend error page
 *
 * It has been expanded to include all errors which occur during the bank
 * linking / card account proccess
 *
 * @param {Object} props
 * @param {String} [props.custom] Force custom prop for storybook
 */
const NetspendError = ({ custom: customProp }) => {
  const query = useQuery()

  const tryAgain = query.get('tryAgain') !== 'false'
  const transfer = query.get('transfer') !== 'false' && query.get('transfer') !== null && query.get('transfer') !== undefined && query.get('transfer') !== ''

  const customMessage = query.get('custom') || customProp || NETSPEND_ERROR_MESSAGES.NO_CONNECTION
  const customArr = customMessage.split('**')
  const title = (
    <>
      {customArr.map((val, index) =>
        index % 2 ? (
          <span className='text-accent' key={index}>
            {val}
          </span>
        ) : (
          <span className='text-secondary' key={index}>
            {val}
          </span>
        )
      )}
    </>
  )

  const body = (
    <>
      {tryAgain && (
        <div className='text-center'>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <Button onClick={history.goBack}>Please Try Again</Button>
        </div>
      )}
      <h3 className='my-10 text-center'>
        Please contact myFloc Customer Care at{' '}
        <Button className='inline flat-x' href={'tel:' + MYFLOC_PHONE_NUMBER} tag='a' primary text>
          {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
        </Button>{' '}
        for more assistance.
      </h3>

      {transfer && (
        <div className='py-10 px-6 rounded-md border shadow-md border-grey'>
          <div className='text-lg'>
            <div className='font-bold text-secondary'>
              Prefer to set up a transfer directly with your bank?
            </div>
            Add money to your myFloc Card Account directly from your bank account using an external
            bank transfer.
          </div>

          <div className='mt-4 text-center'>
            <Button onClick={() => history.push(ROUTES.TRANSFER_DIRECT)}>
              View Bank Transfer Instructions
            </Button>
          </div>
        </div>
      )}
    </>
  )

  return <Error body={body} title={title} />
}

NetspendError.propTypes = {
  /**
   * Pass in the custom when not using the query parameters
   */
  custom: PropTypes.string,
}

export default NetspendError
