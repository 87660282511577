import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getAccounts, getCardAch } from '@/redux/netspend'

/**
 * Helper to get bank accound and card ACH
 *
 * @param   {Object}                                 props
 * @param   {boolean}                                [props.getAccounts] grab bank accounts
 * @param   {boolean}                                [props.getCardAch]  grab card ACH
 * @returns {{ accounts, accountsLoading, cardAch }}
 *
 */
export const useGetBankCard = ({
  getAccounts: getAccountsProp = true,
  getCardAch: getCardAchProp = true,
} = {}) => {
  const dispatch = useDispatch()

  const accounts = useSelector(state => state.netspend.accounts)
  const accountsLoading = useSelector(state => state.netspend.accountsLoading)
  const cardAch = useSelector(state => state.netspend.cardAch)

  // Grab the banks
  useEffect(() => {
    if (!accounts && getAccountsProp) {
      dispatch(getAccounts())
    }
  }, [accounts, getAccountsProp])

  // Grab the Card ACH
  useEffect(() => {
    if (!cardAch && getCardAchProp) {
      dispatch(getCardAch())
    }
  }, [cardAch, getCardAchProp])

  return { accounts, accountsLoading, cardAch }
}
export default useGetBankCard
