import React from 'react'

import PropTypes from 'prop-types'

import Button from '@components/Button'

import { DisplayName } from './DisplayName'

/**
 * Card used for teamMembers and friends on the dashboard
 *
 * @param   {Object}      props
 * @param   {function}    props.handleSelect
 * @param   {Object}      props.member
 * @returns {JSX.Element}
 */
export const TeamCard = ({
  handleSelect,
  member,
}) => {
  return (
    <div className='flex flex-col flex-1 py-6 px-4 border border-gray-200 md:flex-row lg:px-6'>
      <div className='flex flex-row flex-1 justify-between min-w-0 md:flex-row md:items-center'>
        <DisplayName className='md:block' member={member} />
      </div>
      <Button label='handleSelect' onClick={handleSelect}>
        Select Team
      </Button>
    </div>
  )
}

TeamCard.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
}
