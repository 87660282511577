import React, { memo } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { traverseObject } from '@/common/utils'

import RobitFormCheckBox from './RobitFormCheckbox'

/**
 * For a single line, use subtitle as it will be centered, with children you must do your own
 * spacing
 * @param {Object} props
 * @param {String} props.plain
 */
const FormCheckBox = memo(
  ({
    errors = {},
    errorText = null,
    invalid = false,
    validationErrors = {},
    children,
    name,
    plain,
    title,
    titleClass = '',
    subtitle,
    ...props
  }) => {
    const styleType = plain ? 'secondary' : 'primary'

    if (title) {
      const titleClassNames = classNames(
        'text-lg',
        'font-medium',
        titleClass
      )

      return (
        <RobitFormCheckBox
          errorText={
            traverseObject(errors, name)?.message ||
            traverseObject(validationErrors, name) ||
            errorText
          }
          invalid={
            !!traverseObject(errors, name) || !!traverseObject(validationErrors, name) || invalid
          }
          name={name}
          styleType={styleType}
          {...props}
        >
          <div className={titleClassNames}>{title}</div>
          {subtitle && <div className='mt-1'>{subtitle}</div>}
        </RobitFormCheckBox>
      )
    }
    else {
      return (
        <RobitFormCheckBox
          errorText={
            traverseObject(errors, name)?.message ||
            traverseObject(validationErrors, name) ||
            errorText
          }
          invalid={
            !!traverseObject(errors, name) || !!traverseObject(validationErrors, name) || invalid
          }
          name={name}
          styleType={styleType}
          {...props}
        >
          {subtitle && <div className='mt-2.5'>{subtitle}</div>}
          {children}
        </RobitFormCheckBox>
      )
    }
  }
)

FormCheckBox.propTypes = {
  /**
   * If we need more customization
   */
  children: PropTypes.node,
  errorText: PropTypes.string,
  errors: PropTypes.object,
  invalid: PropTypes.bool,
  name: PropTypes.string,
  /**
   * Plain checkbox without the colored animation, this should be the default
   */
  plain: PropTypes.bool,
  /**
   * Normal label text
   */
  subtitle: PropTypes.string,
  /**
   * Bolder, bigger, label text
   */
  title: PropTypes.string,
  /**
   * Class string for title
   */
  titleClass: PropTypes.string,
  validationErrors: PropTypes.object,
}

export default FormCheckBox
