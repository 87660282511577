export const TIMEOUT = {
  COLLAPSE: 350,
  FADE: 150,
  HIDE: 0,
  SHOW: 0,
}

export const TRANSITION_KEYS = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
]

export const DROPDOWN_POSITION_MAP = {
  DOWN: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'top',
}

export const TRANSITION_STATUS = {
  ENTERED: 'entered',
  ENTERING: 'entering',
  EXITED: 'exited',
  EXITING: 'exiting',
}

export const TRANSITION_CLASS_MAP = {
  [TRANSITION_STATUS.ENTERING]: 'collapsing',
  [TRANSITION_STATUS.ENTERED]: 'collapse show',
  [TRANSITION_STATUS.EXITING]: 'collapsing',
  [TRANSITION_STATUS.EXITED]: 'collapse',
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const BREAKPOINTS = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536,
}
