import React from 'react'

import classNames from 'classnames'

import {
  EXTERNAL_URLS, ROUTES,
} from '@/common/constants'
import { history } from '@/history'

import Button from '../Button'
import Icon from '../Icon'
import styling from './header.module.scss'

/**
 * A variation of the global header that is showed during signup.
 * This header only shows the myFloc logo and a 'Help' button
 */
const SignUpHeader = () => {
  return (
    <div className={styling.header}>
      {/* Logo */}
      <div className={styling.logo_center}>
        <Icon height={121} name='logo' onClick={() => history.push(ROUTES.DASHBOARD)} width={372} />
      </div>

      {/* Help Button */}
      <div className={styling.helpButtonDiv}>
        <Button
          className={classNames(styling.helpButton, 'rounded-none')}
          href={EXTERNAL_URLS.FAQ.BASE}
          rel='noreferrer'
          target='_blank'
          text
        >
          Help
        </Button>
      </div>
    </div>
  )
}

export default SignUpHeader
