import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import {
  CARD_RESTRICTIONS_INFO,
  CARD_TRANSACTIONS_ACCESS_INFO,
  ENDPOINTS,
  HTTP_METHODS,
  ROUTES,
  SHOW_TRANSACTION_TYPES,
  YES_OR_NO,
  SPENDING_LIMIT_PERIOD,
} from '@/common/constants'
import useSubmitForm from '@/common/hooks/useSubmitForm'
import { isEmpty } from '@/common/utils'
import { memberRestrictionsFormSchema } from '@/common/validations'
import { history } from '@/history'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { selectMyFlocPerson } from '@/redux/user'
import AnimateIn from '@components/AnimateIn'
import Button from '@components/Button'
import FormCheckbox from '@components/FormCheckbox'
import FormRadio from '@components/FormRadio'
import HiddenPanel from '@components/HiddenPanel'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'

const SetPermissions = props => {
  const teamMemberPersonId = useParams().id

  const dispatch = useDispatch()
  const myflocPerson = useSelector(selectMyFlocPerson)
  const { apiErrors, loading, showValidationToast, submitForm, setApiErrors } = useSubmitForm()

  // ---------------------------------------------------------------------------
  // GET CURRENT RESTRICTIONS
  const [teamPerson, setTeamPerson] = useState(null)
  useEffect(() => {
    if (!myflocPerson) return

    // Submit form to API
    const getTeamPersons = async () => {
      const result = await submitForm(`/teams/${myflocPerson.teamId}/persons`, {
        method: HTTP_METHODS.GET,
      })

      if (result.response.status < 400) {
        const personInTeam = result.data.find(person => person.id === teamMemberPersonId)
        if (personInTeam) {
          setTeamPerson(personInTeam)
        }
        else {
          history.push('/')
          dispatch(
            addToast({
              subtitle: 'Member Not Found in Team',
              title: 'Error',
              type: TOAST_TYPES.error,
            })
          )
        }
      }
      else {
        history.push('/')
        dispatch(
          addToast({
            subtitle: 'Team Data Not Found',
            title: 'Error',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
    getTeamPersons()
  }, [])

  // ---------------------------------------------------------------------------
  // FORM SUBMIT HANDLING
  const formSchema = memberRestrictionsFormSchema

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    clearErrors,
    setError,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = async formEntry => {
    const {
      restrictMerchants,
      locations,
      limitSpending,
      spendingLimitPeriod,
      spendingLimit,
      showAllTransactions,
    } = formEntry

    const isCardRestrictions =
      restrictMerchants === YES_OR_NO.YES || limitSpending === YES_OR_NO.YES

    const restrictionData = {
      cardRestrictions: isCardRestrictions ? {} : null,
      notificationSettings: {
        showAllTransactions: showAllTransactions === SHOW_TRANSACTION_TYPES.ALL,
      },
    }

    if (isCardRestrictions) {
      restrictionData.cardRestrictions.locations = {}
      for (const key in locations) {
        // Only save the form value if merchant restrictions are enabled
        restrictionData.cardRestrictions.locations[key] =
          restrictMerchants === YES_OR_NO.YES ? !!locations[key] : false
      }

      restrictionData.cardRestrictions.weeklySpend = null
      restrictionData.cardRestrictions.monthlySpend = null

      if (limitSpending === YES_OR_NO.YES) {
        if (spendingLimitPeriod === SPENDING_LIMIT_PERIOD.WEEK) {
          restrictionData.cardRestrictions.weeklySpend = Math.round(spendingLimit * 100)
        }

        if (spendingLimitPeriod === SPENDING_LIMIT_PERIOD.MONTH) {
          restrictionData.cardRestrictions.monthlySpend = Math.round(spendingLimit * 100)
        }
      }
    }

    // Submit form and handle response
    const updatePermissionsResponse = await submitForm(
      ENDPOINTS.PERSON_PERMISSIONS(teamMemberPersonId),
      {
        method: HTTP_METHODS.PATCH,
        payload: restrictionData,
      }
    )

    if (updatePermissionsResponse.response.status < 400) {
      // Successfully submitted return to dashboard
      history.push(ROUTES.DASHBOARD)
      dispatch(
        addToast({
          subtitle: `Updated ${teamPerson.firstName} ${teamPerson.lastName}'s permissions`,
          title: 'Success',
          type: TOAST_TYPES.success,
        })
      )
    }
    else {
      // Else redirect for errors
      const setPermissionsApiError = updatePermissionsResponse?.data?.error

      // Handle validation errors
      if (setPermissionsApiError?.validationErrors) {
        setApiErrors(null) // Clear API errors to allow resubmission

        // Map API errors to fields
        for (const errorField in setPermissionsApiError.validationErrors) {
          const field = errorField.split('cardRestrictions.')[1]
          if (['weeklySpend', 'monthlySpend'].includes(field)) {
            setError('spendingLimit')
          }
        }

        dispatch(
          addToast({
            subtitle: 'Please double-check your entries.',
            title: 'Could not update permissions. ',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
  }

  // ---------------------------------------------------------------------------
  // PRE-FILL FORM
  useEffect(() => {
    if (!teamPerson?.cardRestrictions) return

    // Set location restrictions
    let isMCCRestrictions = false
    const locations = teamPerson.cardRestrictions.locations
    for (const key in locations) {
      setValue(`locations.${key}`, !!locations[key])

      if (locations[key]) {
        setValue(`locations.${key}`, 'on')
        isMCCRestrictions = true
      }
    }
    setValue('restrictMerchants', isMCCRestrictions ? YES_OR_NO.YES : YES_OR_NO.NO)

    // Set transaction display permissions
    setValue(
      'showAllTransactions',
      teamPerson.notificationSettings.showAllTransactions
        ? SHOW_TRANSACTION_TYPES.ALL
        : SHOW_TRANSACTION_TYPES.OWN
    )

    // Set spending limits
    const cardRestrictions = teamPerson?.cardRestrictions
    if (cardRestrictions?.weeklySpend || cardRestrictions?.monthlySpend) {
      setValue('limitSpending', YES_OR_NO.YES)

      const period = cardRestrictions?.weeklySpend
        ? SPENDING_LIMIT_PERIOD.WEEK
        : SPENDING_LIMIT_PERIOD.MONTH
      setValue('spendingLimitPeriod', period)
      setValue(
        'spendingLimit',
        cardRestrictions.weeklySpend / 100 || cardRestrictions.monthlySpend / 100
      )
    }
    else {
      setValue('limitSpending', YES_OR_NO.NO)
      setValue('spendingLimitPeriod', SPENDING_LIMIT_PERIOD.WEEK) // Default to week
      setValue('spendingLimit', 100)
    }
  }, [teamPerson])

  // ---------------------------------------------------------------------------
  // FORM STATE
  const [showMerchantRestrictionInfo, setShowMerchantRestrictionInfo] = useState(false)
  const [showTransactionPanel, setShowTransactionPanel] = useState(false)

  const limitSpending = watch('limitSpending')
  const showAllTransactions = watch('showAllTransactions')
  const spendingLimitPeriod = watch('spendingLimitPeriod')
  const restrictMerchants = watch('restrictMerchants')
  const locations = watch('locations')

  // Clear location error if one is selected or if restrictions are turned off
  useEffect(() => {
    if (!errors?.locations?.message || !restrictMerchants) return

    if (
      locations.dating ||
      locations.gambling ||
      locations.pawnshop ||
      restrictMerchants === YES_OR_NO.NO
    ) {
      clearErrors('locations')
    }
  }, [locations, restrictMerchants])

  // Clear spending limit errors if limits disabled
  useEffect(() => {
    if (errors?.spendingLimit || errors?.spendingLimitPeriod) {
      if (limitSpending === YES_OR_NO.NO) {
        setValue('spendingLimit', '') // Clear a bad value
        clearErrors('spendingLimit')
        clearErrors('spendingLimitPeriod')
      }
    }
  }, [limitSpending])

  // ---------------------------------------------------------------------------
  if (!teamPerson) return <Loading className='min-h-screen' />

  return (
    <>
      <PageHeader>
        {teamPerson.firstName} {teamPerson.lastName}'s Permissions
      </PageHeader>
      <div className='relative flex-col text-left main-container'>
        <div className='text-sm'>*Required field</div>
        <div className='mt-1 text-lg'>
          Select desired permissions for this myFloc Team Member below.
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, errors =>
            showValidationToast(errors, { forceError: true })
          )}
        >
          {/* MCC Restrictions -------------------------------------- */}
          <label className='mt-8 mb-2'>
            Do you want to prevent use of this card at certain types of businesses?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowMerchantRestrictionInfo(true)}
              tag='a'
              primary
              text
            >
              What Does This Restrict?
            </Button>
          </label>
          <HiddenPanel setShow={setShowMerchantRestrictionInfo} show={showMerchantRestrictionInfo}>
            {CARD_RESTRICTIONS_INFO}
          </HiddenPanel>
          <FormRadio
            className='min-h-0'
            defaultSelected={restrictMerchants}
            errors={errors}
            labelClassName='mt-0 flex-1'
            name='restrictMerchants'
            options={[
              { label: 'Yes', value: YES_OR_NO.YES },
              { label: 'No', value: YES_OR_NO.NO },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
            value={restrictMerchants}
            inline
          />
          <AnimateIn maxHeight={1000} show={restrictMerchants === YES_OR_NO.YES}>
            <label className='mt-8 mb-2'>
              DO NOT allow spending at the following types of businesses:
            </label>
            <FormCheckbox
              className='mb-4 min-h-0'
              id='restrictGambling'
              initialValue={watch('locations.gambling')}
              name='locations.gambling'
              register={register}
            >
              <div className='mt-2'>Gambling</div>
            </FormCheckbox>
            <FormCheckbox
              className='mb-4 min-h-0'
              id='restrictDating'
              initialValue={watch('locations.dating')}
              name='locations.dating'
              register={register}
            >
              <div className='mt-2'>Dating / Escort Service</div>
            </FormCheckbox>
            <FormCheckbox
              className='mb-1 min-h-0'
              id='restrictPawnshop'
              initialValue={watch('locations.pawnshop')}
              name='locations.pawnshop'
              register={register}
            >
              <div className='mt-2'>Pawn shops</div>
            </FormCheckbox>
            {errors?.locations?.message ? (
              <div className='mt-5 form-control-descenders'>
                <div className='form-control-error'>{errors.locations.message}</div>
              </div>
            ) : null}
          </AnimateIn>
          <div className='mt-12 w-full border-t' />

          {/* View Permissions -------------------------------------- */}
          <label className='mt-8 mb-2'>
            What information should this myFloc Team Member be able to view?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowTransactionPanel(true)}
              tag='a'
              primary
              text
            >
              What is This?
            </Button>
          </label>
          <HiddenPanel setShow={setShowTransactionPanel} show={showTransactionPanel}>
            {CARD_TRANSACTIONS_ACCESS_INFO}
          </HiddenPanel>
          <FormRadio
            defaultSelected={showAllTransactions}
            errors={errors}
            name='showAllTransactions'
            options={[
              {
                label: 'Just their own card balance and transactions',
                value: SHOW_TRANSACTION_TYPES.OWN,
              },
              {
                label: 'All myFloc Team Member transactions and myFloc Card Account balances',
                value: SHOW_TRANSACTION_TYPES.ALL,
              },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
            value={showAllTransactions}
          />
          <div className='mt-12 w-full border-t' />

          {/* Spending Limits --------------------------------------- */}
          <label className='mt-8 mb-2'>Set a maximum spending limit for this card?</label>
          <FormRadio
            className='min-h-0'
            defaultSelected={limitSpending}
            errors={errors}
            labelClassName='mt-0 flex-1'
            name='limitSpending'
            options={[
              { label: 'Yes', value: YES_OR_NO.YES },
              { label: 'No', value: YES_OR_NO.NO },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
            value={limitSpending}
            inline
          />
          <AnimateIn maxHeight={1000} show={limitSpending === YES_OR_NO.YES}>
            <label className='mt-4'>Limit Period:</label>
            <FormRadio
              className='min-h-0'
              // defaultSelected={spendingLimitPeriod}
              defaultSelected={spendingLimitPeriod}
              errors={errors}
              labelClassName='flex-1'
              name='spendingLimitPeriod'
              options={[
                { label: 'Weekly', value: SPENDING_LIMIT_PERIOD.WEEK },
                { label: 'Monthly', value: SPENDING_LIMIT_PERIOD.MONTH },
              ]}
              register={register}
              validationErrors={apiErrors?.validationErrors}
              value={spendingLimitPeriod}
              inline
            />
            <FormInput
              className='mt-4 mr-1 ml-1'
              errors={errors}
              label='Amount'
              name='spendingLimit'
              register={register}
              type={FORM_INPUT_TYPES.currency}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </AnimateIn>

          {/* Submit Button ----------------------------------------- */}
          <div className='flex flex-col items-center mt-9'>
            <Button disabled={!isEmpty(errors) || !isEmpty(apiErrors)} isLoading={loading}>
              Save Changes
            </Button>
            <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default SetPermissions
