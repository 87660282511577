import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  ENDPOINTS,
  HTTP_METHODS,
  MICRO_APP_THEME,
  MYFLOC_PHONE_NUMBER,
  NETSPEND_ERROR_MESSAGES,
  NETSPEND_SDK_ID,
  ROUTES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { log } from '@/common/utils'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux'
import { setLoadingCursor } from '@/redux/loading'
import { getAccounts } from '@/redux/netspend'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import Modal, { MODAL_TYPES } from '@components/Modal'

export const NETSPEND_MICROAPP_ERRORS = {
  NETWORK_CONNECTIVITY_ERROR: 'NetworkConnectivityError',
  UNEXPECTED_ERROR: 'unexpectedError',
}

export const LINK_BANK_RETURN_TYPES = {
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  SUCCESS: 'success',
}

/**
 * Hook for using Netspend Session
 *
 * Returns a bankLinkContainer which must be placed in the dom to house the iFrame
 *
 * @param   {Object}                                                                                props
 * @param   {boolean}                                                                               [props.changeBankDialog] if we want the changeBank dialog in the container
 * @returns {{ bankLinkContainer, changeBank, linkBank, loaded, openMicroApp, removeBank, status }}
 */
export const useBankLink = ({ changeBankDialog = false } = {}) => {
  const dispatch = useDispatch()
  const { submitForm } = useSubmitForm()

  const [loaded, setLoaded] = useState(false)
  const [status, setStatus] = useState('closed')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalProps, setModalProps] = useState({})

  const myFlocPerson = useSelector(selectMyFlocPerson)

  // Perform cleanup actions on app close
  const closeApp = (action, state, status = 'closed') => {
    window.NetspendSDK.microApp.close()
    action(state)
    setStatus(status)
  }

  /**
   * Wrapper function to open the micro app
   *
   * @param {Object} props
   * @param {string} [props.bankLinkId] Optional bank link ID for re-linking, when auth for bank expires
   */
  const openMicroApp = ({ bankLinkId } = { bankLinkId: null }) =>
    new Promise((resolve, reject) => {
      const openApp = passcode => {
        window.NetspendSDK.microApp.open({
          onEvent: event => {
            log('NS MicroApp Event: ', event)
            if (event.payload?.error_code) {
              closeApp(reject, event)
            }
            else if (event.event === 'error' && event.payload?.type) {
              switch (event.payload.type) {
                case NETSPEND_MICROAPP_ERRORS.NETWORK_CONNECTIVITY_ERROR:
                case NETSPEND_MICROAPP_ERRORS.UNEXPECTED_ERROR:
                default:
                  closeApp(reject, {
                    payload: { error_code: NETSPEND_ERROR_MESSAGES.NO_CONNECTION },
                  })
                  break
              }
            }
          },

          onStateChange: async state => {
            log('NS MicroApp State: ', state)
            switch (state.event) {
              case 'started':
                setStatus('loading')
                dispatch(setLoadingCursor(true))

                // TODO Hacky timeout to hide Micro App janky triple loading spinner
                // Remove once they provide us with a 'loaded' event
                await new Promise(resolve => setTimeout(resolve, 1500))
                setStatus('open')
                dispatch(setLoadingCursor(false))
                break
              case 'cancelled':
              case 'error':
                closeApp(reject, state)
                break
              case 'success':
                closeApp(resolve, state, 'loading')
                break
              default:
                break
            }
          },

          // Use this when re-linking a bank
          ...(bankLinkId
            ? {
              params: {
                bankLinkId,
              },
            }
            : {}),
          passcode,
          purpose: 'linkBank',
        })
      }

      if (!loaded) {
        dispatch(
          addToast({
            subtitle: 'Please wait a few seconds and try again',
            title: 'Bank linking tool not yet loaded',
            type: TOAST_TYPES.error,
          })
        )
        setStatus('closed')
      }
      // Grab a new passcode
      else {
        getPasscode()
          .then(passcode => openApp(passcode))
      }
    })

  // Initialize microApp inside callback ref
  const bankRef = useCallback(async container => {
    if (container !== null) {
      await window.NetspendSDK.microApp.initialize({
        branding: {
          phoneNumber: MYFLOC_PHONE_NUMBER,
          productName: 'myFloc',
        },
        container,
        sdkId: NETSPEND_SDK_ID,
        theme: MICRO_APP_THEME,
      })
      setLoaded(true)
    }
  }, [])

  /**
   * Get the token needed to launch netspend micro app (Plaid)
   *
   * @returns {Promise<string>}
   */
  const getPasscode = async () => {
    const { data, response } = await submitForm(
      ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_PRE_LINK(myFlocPerson.id),
      {
        method: HTTP_METHODS.PUT,
      }
    )

    if (data.error || response.stats >= 400) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.OPENING_MICROAPP))
    }
    else {
      return data.passcode
    }
  }

  useEffect(
    () => () => {
      if (window.NetspendSDK?.microApp && window.NetspendSDK.microApp.state !== 'ready') {
        window.NetspendSDK.microApp?.close()
      }
    },
    []
  )

  /**
   * Link or re-link a bank
   *
   * @param   {Object}                      props
   * @param   {function}                    [props.cancelCallback]
   * @param   {boolean}                     [props.changeBankToast]   for success text
   * @param   {Object}                      [props.params]
   * @param   {string}                      [props.params.bankLinkId] for re-authorizing a bank
   * @returns {Promise<{accounts, status}>}                           status = 'success', 'failed', 'cancelled'
   */
  const linkBank = async ({ params = {}, changeBankToast = false, cancelCallback } = {}) => {
    setStatus('loading')
    try {
      await openMicroApp(params)
      const { payload } = await dispatch(getAccounts())
      dispatch(
        addToast({
          subtitle: `Your bank account was successfully ${changeBankToast ? 'changed' : 'linked'}.`,
          title: 'Success',
          type: TOAST_TYPES.success,
        })
      )
      setStatus(status)
      return { accounts: payload.accounts, status: LINK_BANK_RETURN_TYPES.SUCCESS }
    }
    catch (err) {
      if (err.event === 'cancelled') {
        cancelCallback?.()
        return { accounts: null, status: LINK_BANK_RETURN_TYPES.CANCELLED }
      }
      else {
        history.push(
          ROUTES.NETSPEND_ERROR(
            NETSPEND_ERROR_MESSAGES[err.payload?.error_code] || NETSPEND_ERROR_MESSAGES.GENERIC
          )
        )
        return { accounts: null, status: LINK_BANK_RETURN_TYPES.FAILED }
      }
    }
  }

  /**
   * Change bank
   *
   * Launches dialog then removes and adds a new bank
   * Added some promise logic around the dialog so we can await it
   *
   * @returns {Promise<{accounts, status}>} status = 'success', 'failed', 'cancelled'
   */
  const changeBank = () =>
    new Promise((resolve, reject) => {
      setStatus('loading')

      // Set modal props so we can fulfill the promise
      setModalProps({
        successCallback: async () => {
          if (await removeBank(false)) {
            const resp = await linkBank({ changeBankToast: true })
            if (resp.status === LINK_BANK_RETURN_TYPES.FAILED) {
              reject(resp)
            }
            else {
              resolve(resp)
            }
          }
          else {
            reject(LINK_BANK_RETURN_TYPES.FAILED)
          }
        },
      })
      setModalOpen(true)
    })

  /**
   * Remove bank
   *
   * @param   {boolean}          [refreshAccounts] if we want to refresh accounts after
   * @returns {Promise<boolean>}                   true for success, false for error with a page change
   */
  const removeBank = async (refreshAccounts = true) => {
    setStatus('loading')
    const resp = await submitForm(ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_BANKS(myFlocPerson.id), {
      method: HTTP_METHODS.DELETE,
      noErrorToast: true,
    })

    if (refreshAccounts) await dispatch(getAccounts())

    setStatus('closed')
    if (resp.response.status >= 400) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
      return false
    }
    else {
      return true
    }
  }

  const bankLinkContainer = (
    <>
      <div
        className={`fixed inset-0 z-10 ${status === 'open' ? 'block' : 'hidden'}`}
        ref={bankRef}
      />
      {changeBankDialog && (
        <Modal
          cancelCallback={() => setStatus('closed')}
          cancelText='Cancel'
          open={modalOpen}
          setOpen={() => setModalOpen(!modalOpen)}
          successText='Yes, Change Account'
          title='Are you sure you want to change your bank account?'
          type={MODAL_TYPES.confirm}
          {...modalProps}
        >
          Only one external account can be linked to myFloc at a time. Changing your linked bank
          account will remove your currently linked account.
        </Modal>
      )}
    </>
  )

  return {
    bankLinkContainer, changeBank, linkBank, loaded, openMicroApp, removeBank, status,
  }
}
export default useBankLink
