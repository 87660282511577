import React from 'react'

import PropTypes from 'prop-types'

import { ROLES } from '@/common/constants'

import styling from './teamCard.module.scss'

export const DisplayName = ({
  member,
  you,
  className,
}) => {
  // Add the blue 'you' tag if set
  const titleYou = you && (member.role === ROLES.LEAD || member.role === ROLES.INSIDER)
  const nameYou = you && !titleYou

  const titleClasses = `text-secondary py-1 ${titleYou ? styling.youLabel : ''}`
  const nameClasses = `text-xl font-light md:truncate ${nameYou ? styling.youLabel : ''}`

  let name = `${member.firstName} ${member.lastName}`
  if (member.displayName) name += ` (${member.displayName})`

  return (
    < div className={`${className ?? ''} flex overflow-hidden flex-col flex-1 mt-2 md:mt-0 md:ml-4 lg:ml-8`} >
      {/* Role */}
      {member.role === ROLES.LEAD && <div className={titleClasses}>myFloc Lead</div>}
      {member.role === ROLES.INSIDER && <div className={titleClasses}>myFloc Insider</div>}

      {/* Name */}
      <div className={nameClasses}>{name}</div>
    </div >
  )
}

DisplayName.propTypes = {
  className: PropTypes.string,
  member: PropTypes.object.isRequired,
  you: PropTypes.bool,
}
