import React, { useCallback, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import FormRadio from '@/common/components/FormRadio'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import {
  ENDPOINTS, NETSPEND_ERROR_MESSAGES, ROLES, ROUTES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { history } from '@/history'
import {
  addToast,
  createCardBatch,
  selectMyFlocPerson,
  setPerson,
  setKycQuestions,
  TOAST_TYPES,
  getKycQuestions,
} from '@/redux'

const KycQuestions = props => {
  let body = []

  const dispatch = useDispatch()

  const encryptedKyc = useSelector(state => state.netspend.kycQuestions)
  const myFlocPerson = useSelector(selectMyFlocPerson)
  const [questions, setQuestions] = useState(null)

  const { apiErrors, loading, showValidationToast, setLoading, submitForm } = useSubmitForm()

  const decryptKyc = useCallback(async () => {
    try {
      const decryptedKyc = await window.NetspendSDK.session.decrypt(encryptedKyc)
      setQuestions(decryptedKyc.questions)
    }
    catch (e) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GET_KYC))
    }
  }, [encryptedKyc])

  // Decrypt questions
  useEffect(() => {
    if (encryptedKyc) {
      decryptKyc()
    }
  }, [encryptedKyc, decryptKyc])

  // Grab questions
  useEffect(() => {
    if (!encryptedKyc) {
      dispatch(getKycQuestions())
    }
  }, [dispatch, encryptedKyc])

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = async payload => {
    // Format and encrypt answers
    const answers = questions.map(question => ({
      answer_id: payload[question.id],
      question_id: question.id,
    }))

    const encryptedData = await window.NetspendSDK.session.encrypt({ answers })
    const { data, response } = await submitForm(ENDPOINTS.NETSPEND_KYC(myFlocPerson.id), {
      manualLoading: true,
      method: 'PUT',
      payload: {
        encryptedData,
      },
    })

    if (response.status < 400 && data.netspendPerson?.personStatusKyc === 'approved') {
      await dispatch(setPerson(data.netspendPerson))
      await dispatch(createCardBatch())

      if (myFlocPerson.role === ROLES.LEAD) {
        history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO)
      }
      else {
        history.replace('/')
        dispatch(
          addToast({
            subtitle: 'You have successfully enrolled',
            title: 'Congratulations',
            type: TOAST_TYPES.success,
          })
        )
      }
    }
    else if (response?.status !== 500) {
      history.replace(ROUTES.ERROR_ENROLLMENT)
    }

    // Make sure to clear questions
    dispatch(setKycQuestions(null))
    setLoading(false)
  }

  if (questions) {
    questions.forEach(question => {
      const options = question.answer_options.map(answer => ({
        label: answer.text,
        value: answer.answer_id,
      }))
      body.push(
        <FormRadio
          className='mt-12'
          errors={errors}
          key={question.id}
          label={question.question}
          labelClassName='mb-2'
          name={question.id}
          options={options}
          register={register}
          validationErrors={apiErrors?.validationErrors}
          required
        />
      )
    })

    body.push(
      <div className='text-center mt-15' key='submit'>
        <Button isLoading={loading}>Submit</Button>
      </div>
    )
  }
  else {
    body = <Loading />
  }

  return (
    <>
      <PageHeader>We need a little more information to confirm your identity</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <h4 className='font-bold'>Please select the correct answers to the following questions.</h4>

        <form onSubmit={handleSubmit(onSubmit, showValidationToast)}>{body}</form>

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.info}
        />
        <Panel className='mx-auto mt-10 lg:hidden' type={PANEL_TYPES.info} />
      </div>
    </>
  )
}

export default KycQuestions
