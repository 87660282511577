import { useEffect, useRef, useState } from 'react'

import * as Sentry from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ENDPOINTS, HTTP_METHODS, MICRO_APP_THEME, MYFLOC_PHONE_NUMBER, NETSPEND_SDK_ID, ROUTES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { history } from '@/history'
import {
  addToast, getPerson, selectMyFlocPerson, TOAST_TYPES,
} from '@/redux'
import Loading from '@components/Loading'

const MICROAPP_STATES = {
  CLOSED: 'closed',
  OPENED: 'opened',
  UNINITIALIZED: 'uninitialized',
}

const DEFAULT_ERROR_TOAST_TITLE = 'Dispute Transaction Failed'
const DEFAULT_ERROR_TOAST_MESSAGE = 'Unable to dispute transactions at the moment. Please try again later.'

const DisputeTransactions = () => {
  const dispatch = useDispatch()
  const { submitForm } = useSubmitForm()

  const myFlocPerson = useSelector(selectMyFlocPerson)
  const netspendPerson = useSelector(state => state.netspend.person)

  const microappDom = useRef(null)
  const [microappState, setMicroappState] = useState(MICROAPP_STATES.UNINITIALIZED)
  const [microappInternalState, setMicroappInternalState] = useState(null)

  const showErrorToast = ({ title = DEFAULT_ERROR_TOAST_TITLE, message = DEFAULT_ERROR_TOAST_MESSAGE } = {}) => {
    dispatch(
      addToast({
        subtitle: message,
        title,
        type: TOAST_TYPES.error,
      })
    )
  }

  const exitDisputeTransactions = () => history.push(ROUTES.ACCOUNT)
  const exitDisputeTransactionsOnError = (details = {}) => {
    showErrorToast({ ...(details.toast ?? {}) })
    exitDisputeTransactions()
    if (details.error) Sentry.captureException(details.error)
  }
  const exitDisputeTransactionsOnSuccess = () => {
    dispatch(
      addToast({
        subtitle: 'Transaction(s) dispute has been submitted.',
        title: 'Success',
        type: TOAST_TYPES.success,
      })
    )

    exitDisputeTransactions()
  }

  const initializeMicroapps = async container => {
    try {
      await window.NetspendSDK.microApp.initialize({
        branding: {
          phoneNumber: MYFLOC_PHONE_NUMBER,
          productName: 'myFloc',
        },
        container,
        sdkId: NETSPEND_SDK_ID,
        theme: MICRO_APP_THEME,
      })

      if (microappState === MICROAPP_STATES.UNINITIALIZED) setMicroappState(MICROAPP_STATES.CLOSED)
    }

    catch (initializeMicroappsError) {
      exitDisputeTransactionsOnError({ error: initializeMicroappsError })
    }
  }

  const getMicroappPasscode = async () => {
    const { data, response } = await submitForm(
      ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_PRE_LINK(myFlocPerson.id),
      {
        method: HTTP_METHODS.PUT,
      }
    )

    if (data.error || response.stats >= 400) {
      throw new Error(data.error.message ?? 'failed to get netspend microapp passcode')
    }

    return data.passcode
  }

  const openDisputeMicroapp = async config => {
    try {
      const passcode = await getMicroappPasscode()

      await window.NetspendSDK.microApp.open({
        passcode,
        purpose: 'disputeLean',
        ...config,
      })
    }

    catch (openDisputeMicroappError) {
      exitDisputeTransactionsOnError({ error: openDisputeMicroappError })
    }
  }

  // initialize netspend microapps sdk
  const [startedInitialization, setStartedInitialization] = useState(false)
  useEffect(() => {
    if (microappState !== MICROAPP_STATES.UNINITIALIZED) return
    if (microappDom.current === null) return
    if (startedInitialization) return

    setStartedInitialization(true)
    initializeMicroapps(microappDom.current)
  }, [microappState, microappDom.current, startedInitialization])

  // open netspend dispute microapp
  const [startedOpeningMicroapp, setStartedOpeningMicroapp] = useState(false)
  useEffect(() => {
    if (microappState === MICROAPP_STATES.UNINITIALIZED) return
    if (microappState === MICROAPP_STATES.OPENED) return
    if (!netspendPerson || startedOpeningMicroapp) return

    setStartedOpeningMicroapp(true)
    openDisputeMicroapp({
      onEvent () {
        // not needed, but microapp can crash if onEvent isn't specified
      },
      onStateChange (details) {
        setMicroappInternalState({ data: details.payload, name: details.event })
      },
      params: {
        accountId: netspendPerson.accountId,
      },
    })
  }, [microappState, netspendPerson, startedOpeningMicroapp])

  // handle dispute microapp session ended
  const DISPUTE_MICROAPP_ENDED_STATES = ['success', 'error', 'cancelled']
  useEffect(() => {
    if (!microappInternalState) return

    if (DISPUTE_MICROAPP_ENDED_STATES.includes(microappInternalState.name)) {
      if (microappState === MICROAPP_STATES.OPENED) setMicroappState(MICROAPP_STATES.CLOSED)

      if (microappInternalState.name === 'error') {
        exitDisputeTransactionsOnError({
          error: new Error('dispute microapp error - ' + (microappInternalState.data.message ?? 'unknown error')),
        })
      }

      else if (microappInternalState.name === 'success') exitDisputeTransactionsOnSuccess()
      else exitDisputeTransactions()
    }
  }, [microappInternalState])

  // load netspend person, if needed
  const [startedLoadingPerson, setStartedLoadingPerson] = useState(false)
  useEffect(() => {
    if (netspendPerson) return
    if (!myFlocPerson) return
    if (startedLoadingPerson) return

    setStartedLoadingPerson(true)
    dispatch(getPerson())
  }, [netspendPerson, myFlocPerson, startedLoadingPerson])

  return (
    <div className='w-full h-[1000px]' ref={microappDom}>
      {
        !microappInternalState
          ? <Loading className='h-[1000px]' />
          : null
      }
    </div>
  )
}

export default DisputeTransactions
