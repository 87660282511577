import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { EXTERNAL_URLS, ROUTES } from '@/common/constants'
import { history } from '@/history'
import { selectApiVersion, getApiVersion } from '@/redux/version'

import Button from '../Button'
import Icon from '../Icon'
import styling from './footer.module.scss'

const Footer = () => {
  const dispatch = useDispatch()
  dispatch(getApiVersion())
  const apiVersion = useSelector(selectApiVersion)
  return (
    <div className={styling.footer}>
      <div className='mx-auto max-w-5xl'>
        <div className={styling.footerHeader}>
          <Icon name='logo_footer' onClick={() => history.push(ROUTES.DASHBOARD)} width={100} />
          <Button
            className={styling.helpButton}
            href={EXTERNAL_URLS.FAQ.BASE}
            rel='noreferrer'
            target='_blank'
            text
          >
            Help
          </Button>
        </div>
        <div>
          <Button
            className={`${styling.footerText} ${styling.terms} mb-8`}
            link={ROUTES.TERMS_AND_CONDITIONS}
            text
          >
            Review Terms and Conditions
          </Button>
          <p className={styling.footerText}>
            The myFloc™ Card is issued by Pathward, National Association, Member FDIC, pursuant to
            a license from Visa U.S.A. Inc. Netspend is a registered agent of Pathward, N.A. Card
            may be used everywhere Visa® debit cards are accepted. Certain products and services may
            be licensed under U.S. Patent Nos. 6,000,608 and 6,189,787. Use of the Card Account is
            subject to activation, ID verification, and funds availability. Transaction fees, terms,
            and conditions apply to the use and reloading of the Card Account. See the Cardholder
            Agreement for details.
          </p>
          <p className={styling.footerText}>
            Copyright 2021 CareSix Technologies, Inc. All rights reserved. © 2021 Netspend
            Corporation. All rights reserved worldwide. Netspend is the federally registered
            U.S. service mark of Netspend Corporation. myFloc™ is a trademark of CareSix
            Technologies, Inc. All other trademarks and service marks belong to their respective
            owners.
          </p>
        </div>
        <div style={{
          color: 'white',
          display: 'none',
          fontSize: 10,
          visibility: 'hidden',
        }}>
          API version: {apiVersion ?? ''}
        </div>
      </div>
    </div>
  )
}

export default Footer
