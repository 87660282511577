import React, { useEffect, useRef } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

import { formPropTypes } from '@/common/propTypes'

import Button from '../Button'
import Icon from '../Icon'
import styling from './RobitFormInput.module.scss'
import FormInputAddon from './RobitFormInputAddon'

/**
 * The form input allows you to create various text style inputs such as `text`, `password`, `email`, `number`, `url`, `search` and more.
 */
export const FormInput = React.memo(
  ({
    bottomTextClassName,
    children,
    className,
    currency,
    currencyClassName,
    defaultValue,
    disabled = false,
    errorClassName,
    errorText,
    hintContent,
    innerRef,
    inputClassName,
    invalid = false,
    isLabelInset = false,
    label,
    labelButtonAction,
    labelButtonText,
    labelClassName,
    mask,
    name,
    password,
    readonly = false,
    register,
    required,
    size,
    showPassword,
    type = 'text',
    valid = false,
    ...rest
  }) => {
    const placeholderRef = useRef()
    const ref = innerRef ?? placeholderRef
    const { ref: registerRef, ...registerProps } = register ? register(name || label, { required }) : {}

    const inputClasses = classNames(
      'form-control',
      'relative',
      styling.inputMargin,
      inputClassName,
      size && `form-control-${size}`,
      valid && 'is-valid',
      invalid && 'is-invalid',
      disabled && 'disabled',
      currency && 'pl-9',
      password && 'pr-9'
    )

    const containerClasses = classNames(
      className,
      disabled && 'disabled',
      'relative',
      'form-control-container'
    )

    const errorClasses = classNames('form-control-error', errorClassName)
    const bottomTextClasses = classNames('form-control-descenders', bottomTextClassName)

    const renderInputRow = () => {
      if (children || valid) {
        const prependers = []
        const leaders = []
        const trailers = []
        const appenders = []

        React.Children.forEach(children, element => {
          if (!React.isValidElement(element)) return

          switch (element.props.type) {
            case 'prepend':
              prependers.push(element)
              break
            case 'leading':
              leaders.push(element)
              break
            case 'trailing':
              trailers.push(element)
              break
            case 'append':
              appenders.push(element)
              break
            default:
          }
        })

        const inputGroupClasses = classNames(
          'input-group',
          (leaders.length + trailers.length > 0 || valid) && 'input-group-seamless',
          size && `input-group-${size}`
        )

        return (
          <div className={inputGroupClasses}>
            {prependers}
            {leaders}
            {renderInput()}
            {trailers}
            {appenders}
          </div>
        )
      }
      else {
        return renderInput()
      }
    }

    const validAddon = () => (
      // className={styling['is-valid-icon']}
      <FormInputAddon type='trailing'>✓</FormInputAddon>
    )

    const defaultInputVal = () => {
      if (defaultValue || defaultValue === 0) {
        return defaultValue
      }
      else if (rest.value) {
        return rest.value
      }
      return undefined
    }

    const renderInput = () => {
      if (rest.value && defaultValue) {
        delete rest.value
      }

      const inputProps = {
        defaultValue: defaultInputVal(),
        disabled,
        readOnly: readonly,
        ref,
        type,
        ...(currency && { step: '.01' }),
        ...registerProps,
        ...rest,
      }

      // required to prevent compile error (?)
      const insetLabel = label

      if (isLabelInset) {
        return (
          <fieldset className={inputClasses} tabIndex={-1}>
            <legend>
              {insetLabel}
              {required && '*'}
            </legend>
            <input {...inputProps} />
            {valid && validAddon()}
          </fieldset>
        )
      }
      else {
        inputProps.className = inputClasses

        if (mask) {
          return (
            <>
              <InputMask mask={mask} {...inputProps} />
              {valid && validAddon()}
            </>
          )
        }
        else {
          return (
            <>
              <input {...inputProps} />
              {valid && validAddon()}
            </>
          )
        }
      }
    }

    const labelClasses = classNames(size && `form-control-label-${size}`, labelClassName, 'w-full')
    const passwordClasses = classNames(styling.showHidePassword, 'cursor-pointer')

    // register field ref if possible
    useEffect(() => {
      if (!ref?.current || !registerRef) return

      registerRef(ref.current)
    }, [ref?.current, registerRef])

    // prevent scrolling from updating number field values
    useEffect(() => {
      if (type !== 'number' || !ref.current) return

      const ignoreDomEvent = event => event.preventDefault()

      ref.current.addEventListener('scroll', ignoreDomEvent)
      ref.current.addEventListener('wheel', ignoreDomEvent)

      const unlisten = () => {
        ref?.current?.removeEventListener('scroll', ignoreDomEvent)
        ref?.current?.removeEventListener('wheel', ignoreDomEvent)
      }

      return unlisten
    }, [type, ref?.current])

    // enforce two decimal place for currency fields
    useEffect(() => {
      if (!currency || !ref?.current) return

      const enforceTwoDecimals = event => {
        const [, afterDecimal] = event.target.value.split('.')
        if (!afterDecimal) event.target.value += '.00'
        else if (afterDecimal.length === 1) event.target.value += '0'
        else if (afterDecimal.length > 2) {
          event.target.value = parseFloat(event.target.value)
            .toFixed(2)
        }
      }

      ref.current.addEventListener('change', enforceTwoDecimals)

      return () => {
        ref?.current?.removeEventListener('change', enforceTwoDecimals)
      }
    }, [currency, ref?.current])

    return (
      <div className={containerClasses}>
        {label && !isLabelInset && (
          <label className={labelClasses} htmlFor={rest.id}>
            <div>
              {label}
              {required && '*'}
              {labelButtonText && (
                <Button
                  className='block m-0 border-0 md:inline md:ml-2'
                  onClick={labelButtonAction}
                  role='button'
                  tag='a'
                  primary
                  text
                >
                  {labelButtonText}
                </Button>
              )}
            </div>
            {renderInputRow()}
          </label>
        )}
        {hintContent && label ? <div className='form-control-hint'>{hintContent}</div> : []}
        {currency && <div className={classNames(styling.currency, currencyClassName)}>$</div>}
        {password && (
          <Icon
            className={passwordClasses}
            name={showPassword ? 'password_hidden' : 'password_visible'}
            onClick={password}
            width={22}
          />
        )}
        <div className={bottomTextClasses}>
          {invalid && errorText ? <div className={errorClasses}>{errorText}</div> : []}
          {hintContent && !label ? <div className='form-control-hint'>{hintContent}</div> : []}
          {required && !label && !invalid && <div className='form-control-required'>Required</div>}
        </div>
      </div>
    )
  }
)

FormInput.propTypes = {
  ...formPropTypes,

  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Prepends the dollar sign with padding
   */
  currency: PropTypes.bool,
  /**
   * To control the currency symbol
   */
  currencyClassName: PropTypes.string,
  /**
   * The default value of the input field. Must be a number for 'number' type.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  /**
   * If the Label should appear inside the input's border.
   */
  isLabelInset: PropTypes.bool,
  /**
   * Sets an input mask
   */
  mask: PropTypes.string,
  /**
   * A function to show the show/hide button which should trigger the type change
   */
  password: PropTypes.func,
  /**
   * Whether or not the field is readonly
   */
  readonly: PropTypes.bool,
  /**
   * Whether the password is showing or not
   */
  showPassword: PropTypes.bool,
  /**
   * The input's size.
   */
  size: PropTypes.string,
}

export default FormInput
