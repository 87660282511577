import React from 'react'

import PropTypes from 'prop-types'

import { useAuthentication } from '@/common/hooks'

import Toasts from '../Toasts'
import styling from './header.module.scss'
import NavigationHeader from './NavigationHeader'
import SignUpHeader from './SignUpHeader'

/**
 * The global header for the website, provides navigation to other pages.
 */
const Header = () => {
  const { isAuthenticated } = useAuthentication()

  return (
    <header>
      <div className={styling.wrapper}>
        {isAuthenticated ? <NavigationHeader /> : <SignUpHeader />}
      </div>
      <Toasts />
    </header>
  )
}

Header.propTypes = {
  /**
   * If the user is signed in or not. If yes, adds navigation links to header and logout button
   */
  authenticated: PropTypes.bool,
}

export default Header
