import { useEffect } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from '@/common/constants'
import { useAuthentication, usePolling, useNotifications } from '@/common/hooks'
import useHandleNetspendError from '@/common/hooks/useHandleNetspendError'
import { routeGuard } from '@/common/utils'
import { history } from '@/history'
import { createSession, getPersonStatus } from '@/redux'
import ErrorBoundary from '@components/ErrorBoundary'
import Loading from '@components/Loading'

export const HISTORY_ACTIONS = {
  POP: 'POP',
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
}

const RootContainer = ({ children }) => {
  const dispatch = useDispatch()

  const loadingCursor = useSelector(state => state.loading.loadingCursor)
  const nsSessionLoaded = useSelector(state => state.netspend.sessionLoaded)

  const { auth0Loading, auth0User, initializeUser, loading, login, user } = useAuthentication()

  // Setup user + netspend session
  useEffect(() => {
    if (auth0Loading) return

    if (auth0User) {
      initializeUser()
    }
    else if (!auth0User) {
      const shouldContinue = routeGuard({
        login,
        pathName: history.location.pathname,
      })
      if (shouldContinue) dispatch(createSession())
    }
  }, [auth0Loading, auth0User])

  // Initiates notifications
  useNotifications(user)

  // Handle netspend errors and polling
  usePolling()
  useHandleNetspendError()

  const activePersonId = localStorage.getItem('activePersonId')

  // Grab fresh status async
  useEffect(() => {
    // Handling redirection for team selection
    const personsId = user?.personsList || []
    if (history.location.pathname === ROUTES.DASHBOARD && !activePersonId) {
      if (personsId.length > 1) {
        history.push(ROUTES.TEAM_SELECTION)
      }
    }

    const unlisten = history.listen(route => {
      if (user && !route.pathname.startsWith('/error')) dispatch(getPersonStatus())
    })
    return unlisten
  }, [user, activePersonId])

  // Route Guard
  useEffect(() => {
    // To prevent the route guard from hitting too early, it will still get hit above or in useAuth
    if (loading) return true

    const unlisten = history.block(route =>
      routeGuard({
        login,
        pathName: route.pathname,
      })
    )
    return unlisten
  }, [loading])

  const classes = classNames(
    'min-h-screen',
    'flex',
    'flex-col',
    'items-stretch',
    'justify-between',
    'relative',
    loadingCursor && 'cursor-wait'
  )

  if (loading || !nsSessionLoaded) {
    return <Loading className='min-h-screen' />
  }
  else {
    return (
      <ErrorBoundary>
        <div className={classes}>{children}</div>
      </ErrorBoundary>
    )
  }
}

RootContainer.propTypes = {
  children: PropTypes.node,
}

export default RootContainer
