/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup'

import { currency } from '@/common/utils'

const reqStr = 'This information is required.'

export const generateTransferSchema = (max = 3500, min = 1) =>
  yup.object()
    .shape({
      amount: yup
        .number()
        .min(min, `Transfer Amount must be at least ${currency(min)}`)
        .max(max, `Transfer Amount may not exceed ${currency(max)}`)
        .nullable()
        .required(reqStr)
        .label('Transfer Amount')
        .typeError('You must specify a dollar value greater than $0.00'),
    })
