import React from 'react'

import { useSelector } from 'react-redux'

import TransferReview from '@/common/components/TransferReview'
import { TRANSFER_TYPES } from '@/common/constants'
import { FORMS } from '@/redux/formSteps'

const StepFive = props => {
  const formStepFour = useSelector(state => state.formSteps[FORMS.createCardAccount])?.[4]

  return (
    <TransferReview
      form={FORMS.createCardAccount}
      payload={{ action: TRANSFER_TYPES.WITHDRAWAL, ...formStepFour }}
      stepText={true}
    />
  )
}

export default StepFive
