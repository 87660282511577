import React from 'react'

import PropTypes from 'prop-types'

import {
  MYFLOC_PHONE_NUMBER,
  MYFLOC_PHONE_NUMBER_TEXT,
} from '@/common/constants'
import Button from '@components/Button'
import Modal, { MODAL_TYPES } from '@components/Modal'

function DisputeModal ({ open, setOpen }) {
  return (
    <Modal open={open} setOpen={setOpen} type={MODAL_TYPES.alert}>
      To dispute a transaction, please contact{' '}
      <Button
        className='inline flat-x'
        href={'tel:' + MYFLOC_PHONE_NUMBER}
        tag='a'
        primary
        text
      >
        myFloc Customer Care at {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
      </Button>
    </Modal>
  )
}

DisputeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default DisputeModal
