import {
  CARD_STATUS, ENROLLMENT, ROLES, ROUTES, STATUSES,
} from '@/common/constants'
import { checkEnrollmentStepPassed, statusGuard } from '@/common/utils'
import { history } from '@/history'
import { selectMyFlocPerson, store } from '@/redux'

/**
 * Route Guard
 *
 * For the status route groups, you only need to be in the higher one as they will trigger the
 * lower validations automatically. You can see that with the expanded arrays.
 * ie legal routes will validate cardAccount, verified, and authenticated
 *
 * However for the role based ones (adminRoutes), the guard will be in parallel
 */

// TODO use router matching
// - ACCOUNT_PERSON

// Must have legal checked
const legalRoutes = [
  ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE,
  ROUTES.CREATE_CARD_ACCOUNT.STEP_FOUR,
  ROUTES.CREATE_CARD_ACCOUNT.STEP_FIVE,
  ROUTES.TRANSFER_FUNDS,
]

// Must have a card account
const cardAccountRoutes = [ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO, ROUTES.MOVE_MONEY, ...legalRoutes]

// Verified Email is required
const verifiedEmailRoutes = [
  ROUTES.ACCOUNT,
  ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE,
  ROUTES.KYC_QUESTIONS,
  ROUTES.DASHBOARD,
  ROUTES.DISPUTE_TRANSACTIONS,
  ROUTES.NOTIFICATIONS,
  ROUTES.PROFILE,
  ROUTES.STATEMENTS,
  ROUTES.TEAM_ADD_FRIEND,
  ROUTES.TEAM_ADD_INSIDER,
  ROUTES.TEAM_ADD_TEAM_MEMBER,
  ROUTES.TEAM_SET_PERMISSIONS_ID,
  ROUTES.TEAM,
  ROUTES.TRANSFER_CONFIRMATION,
  ROUTES.TRANSFER_DIRECT,
  ROUTES.TRANSFER_REVIEW,
  ROUTES.RESTRICTED_QA,
  ...cardAccountRoutes,
]

// Must be logged in
const authenticatedRoutes = [ROUTES.VERIFY_EMAIL_OTP, ...verifiedEmailRoutes]

// Only lead or insider can access
const adminRoutes = [ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE, ROUTES.STATEMENTS, ...cardAccountRoutes]

/**
 * Perform all logic on route changes here
 *
 * @param   {Object}   props
 * @param   {function} props.login
 * @param   {string}   props.pathName
 * @returns {boolean}                 returns false to block route change, true to let it execute
 */
export const routeGuard = ({ login, pathName }) => {
  if (pathName.startsWith('/error') || pathName === ROUTES.LOGGED_OUT) return true

  // Grab only the freshest state
  const state = store.getState()
  const myFlocPerson = selectMyFlocPerson(state)

  // Allow the route when user is authenticated, and accepting an invite to a new team
  const urlSearchParams = new URLSearchParams(window.location.search)
  if (
    state.user?.persons?.length &&
    (
      pathName.toLowerCase() === ROUTES.JOIN_INSIDER.toLowerCase() ||
      pathName.toLowerCase() === ROUTES.JOIN_TEAM_MEMBER.toLowerCase() ||
      pathName.toLowerCase() === ROUTES.JOIN_FRIEND.toLowerCase()
    ) &&
    urlSearchParams.get('invite')
  ) {
    return true
  }

  const activePersonStatus = state.user.activePersonStatus

  // Call status guard
  if (!statusGuard(pathName, activePersonStatus, myFlocPerson)) return false

  // Check if user is logged in, if not log them in
  if (authenticatedRoutes.includes(pathName) && !activePersonStatus) {
    login(pathName)
    return false
  }

  // Check if the user is verifed else redirect to verification page
  if (
    verifiedEmailRoutes.includes(pathName) &&
    activePersonStatus?.userStatus === STATUSES.UNVERIFIED_EMAIL
  ) {
    history.replace(ROUTES.VERIFY_EMAIL_OTP)
    return false
  }

  // Must be a lead or insider
  if (
    adminRoutes.includes(pathName) &&
    myFlocPerson?.role !== ROLES.LEAD &&
    myFlocPerson?.role !== ROLES.INSIDER
  ) {
    history.push('/404')
    return false
  }

  // Must have a card account
  if (cardAccountRoutes.includes(pathName)) {
    // No netspend account => Step One
    if (!checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_CREATED)) {
      history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE)
      return false
    }
    // No card => Hard refresh to create card on reload
    else if (
      !checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_CARD) ||
      myFlocPerson?.cardStatus === CARD_STATUS.NO_CARD
    ) {
      // If kyc is empty then NS person call failed. Retry enrollment.
      if (!activePersonStatus.nsPersonStatusKyc) {
        history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE)
        return false
      }

      // Else we need to insure card is generated
      // TODO This COULD cause a reload loop, play with it
      // window.location.reload()
      window.location.href = '/'
      return false
    }
  }

  // Must have a Netspend legal checked
  if (
    legalRoutes.includes(pathName) &&
    !checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_LEGAL)
  ) {
    history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO)
    return false
  }

  // Special CCA guards
  if (pathName === ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE) {
    if (checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_LEGAL)) {
      history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE)
      return false
    }
    else if (
      checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_CREATED)
    ) {
      window.location.href = ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO
      return false
    }
    else if (checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_CARD)) {
      history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO)
      return false
    }
  }
  else if (
    pathName === ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO &&
    checkEnrollmentStepPassed(activePersonStatus.enrollmentFlow, ENROLLMENT.NS_LEGAL)
  ) {
    history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE)
    return false
  }

  // Join Team Member only for team members
  if (pathName === ROUTES.JOIN_TEAM_MEMBER) {
    if (myFlocPerson && myFlocPerson.role !== ROLES.TEAM_MEMBER) {
      history.push(ROUTES.DASHBOARD)
      return false
    }
  }

  // Join Insider only for insiders
  if (pathName === ROUTES.JOIN_INSIDER) {
    if (myFlocPerson && myFlocPerson.role !== ROLES.INSIDER) {
      history.push(ROUTES.DASHBOARD)
      return false
    }
  }

  // retry netspend for insiders and team members
  if (activePersonStatus?.enrollmentFlow === ENROLLMENT.MF_CREATED) {
    switch (myFlocPerson?.role) {
      // redirect insider to create netspend
      case ROLES.INSIDER:
        if (pathName !== ROUTES.JOIN_INSIDER) {
          history.push(ROUTES.JOIN_INSIDER)
          return false
        }
        break

      // redirect team member to create netspend
      case ROLES.TEAM_MEMBER:
        if (pathName !== ROUTES.JOIN_TEAM_MEMBER) {
          history.push(ROUTES.JOIN_TEAM_MEMBER)
          return false
        }
        break

      default:
    }
  }

  return true
}
