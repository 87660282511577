import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  BREAKPOINTS,
  CARD_LOCK_STATUSES,
  CARD_STATUS,
  CUSTOM_ERROR_MESSAGES,
  ENDPOINTS,
  MYFLOC_PHONE_NUMBER,
  MYFLOC_PHONE_NUMBER_TEXT,
  NETSPEND_ERROR_MESSAGES,
  PDFS,
  ROLES,
  ROUTES,
  ENV_OPTIONS,
} from '@/common/constants'
import {
  useBankLink,
  useGetBankCard,
  useQuery,
  useSubmitForm,
  useWindowDimensions,
} from '@/common/hooks'
import { camelToSentence, maskSensitive } from '@/common/utils'
import { history } from '@/history'
import { getCards, getPersonBalance, getTeamBalances } from '@/redux/netspend'
import { combineTeamBalance, getTeam } from '@/redux/team'
import { selectMyFlocPerson } from '@/redux/user'
import {
  AvailableBalance,
  FeeSummary,
  HorizontalCard,
  TransactionsTable,
} from '@/views/account/myAccountComponents'
import { cardAction } from '@/views/dashboard/dashboardComponents/BuildTeam.jsx'
import Button from '@components/Button'
import DatePicker from '@components/DatePicker'
import Dropdown from '@components/Dropdown'
import Icon from '@components/Icon'
import Loading from '@components/Loading'
import Modal, { MODAL_TYPES } from '@components/Modal'

import DisputeModal from './DisputeModal'

export const PAGE_ACTIONS = { FIRST: 'first', NEXT: 'next', PREV: 'prev' }
const TRANSACTION_LIMIT_DESKTOP = 15
const TRANSACTION_LIMIT_MOBILE = 10

/**
 * MyAccount page
 *
 * TODO chop this component up
 */
const MyAccount = props => {
  const dispatch = useDispatch()
  const query = useQuery()
  const queryTo = query.get('to')

  const [currentMyFlocPersonId, setCurrentMyFlocPersonId] = useState(queryTo ?? null)
  const [currentTab, setCurrentTab] = useState('all')
  const [nextPage, setNextPage] = useState(false)
  const [openReplacement, setOpenReplacement] = useState(false)
  const [startAfterArr, setStartAfterArr] = useState([])
  const [pendingTransactions, setPendingTransactions] = useState([])
  const [transactions, setTransactions] = useState(null)
  const [fees, setFees] = useState({
    previousMonthFees: 0,
    ytdFees: 0,
  })
  const [dateRange, setDateRange] = useState([null, null])
  const [openDispute, setOpenDispute] = useState(false)

  const user = useSelector(state => state.user.user)
  const myFlocPerson = useSelector(selectMyFlocPerson)
  const spendingLimits = person => {
    return {
      amount: person?.cardRestrictions?.weeklySpend ?? person?.cardRestrictions?.monthlySpend,
      period: person?.cardRestrictions?.weeklySpend ? 'week' : 'month',
    }
  }

  const personBalance = useSelector(state => state.netspend.personBalance)
  const combinedAccounts = useSelector(combineTeamBalance)
  const cards = useSelector(state => state.netspend.cards)
  const card =
    cards?.find(card => card.status === 'active') ?? cards?.find(card => card.status !== 'closed')

  const isAdmin = myFlocPerson.role === ROLES.LEAD || myFlocPerson.role === ROLES.INSIDER
  const viewPermissions = myFlocPerson.notificationSettings?.showAllTransactions

  const { accounts, accountsLoading, cardAch } = useGetBankCard({
    getAccounts: isAdmin,
    getCardAch: isAdmin,
  })
  const { bankLinkContainer, changeBank, linkBank, status, removeBank } = useBankLink({
    changeBankDialog: true,
  })
  const { submitForm } = useSubmitForm()
  const { width } = useWindowDimensions()

  const getFees = useCallback(async () => {
    let localMyFlocPersonId = currentMyFlocPersonId
    if (localMyFlocPersonId === 'primary') {
      localMyFlocPersonId = combinedAccounts?.find(account => account.role === ROLES.LEAD).id
    }
    const resp = await submitForm(ENDPOINTS.NETSPEND_PERSONS_FEES(localMyFlocPersonId), {
      method: 'GET',
    })
    if (resp.response.status >= 400) {
      history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.NO_CONNECTION))
    }
    else {
      setFees(resp.data)
    }
  }, [currentMyFlocPersonId, submitForm])

  useEffect(() => {
    if (currentMyFlocPersonId) {
      getFees()
    }
  }, [currentMyFlocPersonId])

  const getTransactions = useCallback(
    /** @@ Get transactions
     *
     * @param {Object} payload
     * @param {string} [payload.direction]     next, prev, first
     * @param {number} [payload.pendingLength] the number of pending transactions
     */
    async ({ direction = PAGE_ACTIONS.FIRST, pendingLength = 0 } = {}) => {
      setTransactions(null)

      const limit =
        (width > BREAKPOINTS.SM ? TRANSACTION_LIMIT_DESKTOP : TRANSACTION_LIMIT_MOBILE) -
        pendingLength
      const filter = currentTab
      let localStartAfterArr = [...startAfterArr]
      let startingAfter = null

      // Make sure we have the right startingAfter
      if (direction === PAGE_ACTIONS.FIRST) {
        localStartAfterArr = []
      }
      else if (direction === PAGE_ACTIONS.PREV) {
        localStartAfterArr.splice(-2, 2)
        startingAfter = localStartAfterArr.slice(-1)[0]
      }
      else if (direction === PAGE_ACTIONS.NEXT) {
        startingAfter = localStartAfterArr.slice(-1)[0]
      }

      const [startDate, endDate] = dateRange
      const payload = {
        endDate,
        filter,
        limit,
        startDate,
        startingAfter,
      }

      // Primary account
      let localMyFlocPersonId = currentMyFlocPersonId
      if (currentMyFlocPersonId === 'primary') {
        localMyFlocPersonId = combinedAccounts?.find(account => account.role === ROLES.LEAD).id
        payload.getPrimary = true
      }

      const resp = await submitForm(ENDPOINTS.NETSPEND_PERSONS_TRANSACTIONS(localMyFlocPersonId), {
        payload,
      })
      if (resp.response.status >= 400) {
        history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.GENERIC_LATER))
      }
      else {
        setTransactions(resp.data.transactions)
        setNextPage(resp.data.isNextPage)

        // Set the correct starting after
        if (resp.data.transactions.length) {
          localStartAfterArr.push(resp.data.transactions?.slice(-1)[0].id)
        }
      }
      setStartAfterArr(localStartAfterArr)
    },
    [currentMyFlocPersonId, currentTab, startAfterArr, submitForm, width]
  )

  useEffect(() => {
    // Date Picker change refreshes transactions
    if (currentMyFlocPersonId) {
      // needed in URL stem
      getTransactions()
    }
  }, [dateRange])

  /**
   * @@ Pending Transactions call
   */
  const getPendingTransactions = useCallback(async () => {
    setTransactions(null)
    const payload = {
      filter: 'pending',
    }

    // Primary account
    let localMyFlocPersonId = currentMyFlocPersonId
    if (currentMyFlocPersonId === 'primary') {
      localMyFlocPersonId = combinedAccounts?.find(account => account.role === ROLES.LEAD).id
      payload.getPrimary = true
    }
    const { data, response } = await submitForm(
      ENDPOINTS.NETSPEND_PERSONS_TRANSACTIONS(localMyFlocPersonId),
      {
        payload,
      }
    )
    if (response.status >= 400) {
      history.push(ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.GENERIC_LATER))
    }
    else {
      const pending = data.transactions
      setPendingTransactions(pending)
      getTransactions({ pendingLength: pending.length })
    }
  }, [combinedAccounts, currentMyFlocPersonId, getTransactions])

  // Grab initial data
  useEffect(() => {
    if (myFlocPerson.role !== ROLES.FRIEND) {
      dispatch(getCards())
      dispatch(getPersonBalance())
    }
    dispatch(getTeam())
    dispatch(getTeamBalances())
  }, [])

  // Set initial myFlocPersonId
  useEffect(() => {
    if (combinedAccounts && !currentMyFlocPersonId) {
      const selectPrimaryAsDefault = (myFlocPerson.role === ROLES.LEAD) || (myFlocPerson.role === ROLES.INSIDER)
      let localMyFlocPersonId = queryTo || myFlocPerson.id
      if (selectPrimaryAsDefault || myFlocPerson.role === 'friend') {
        localMyFlocPersonId = 'primary'
      }
      setCurrentMyFlocPersonId(localMyFlocPersonId)
    }
  }, [combinedAccounts, currentMyFlocPersonId])

  // Trigger initial getTransaction calls
  useEffect(() => {
    if (currentMyFlocPersonId) {
      if (currentTab === 'all') {
        getPendingTransactions()
      }
      else {
        setPendingTransactions([])
        getTransactions()
      }
    }
  }, [currentMyFlocPersonId, currentTab])

  // Calculate the total balance
  const totalInCents = Object.entries(combinedAccounts || {})
    .filter(([, account]) => account.role !== ROLES.INSIDER)
    .reduce((a, b) => a + b[1].available.amount, 0)

  // Set balances for available balance card
  let availableBalances
  let fundingOptions
  let myCardAction

  const cardActionUser = { ...myFlocPerson, firstName: user.firstName, lastName: user.lastName }

  // Lead or insider
  if (isAdmin && personBalance) {
    availableBalances = [
      {
        amount: totalInCents,
        title: 'myFloc Card Account Available Balance',
      },
      { amount: personBalance.available.amount, title: 'Primary Account Available Balance' },
    ]

    const cardOptions = [
      {
        action: () => {
          if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
            import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
            history.push(ROUTES.REPLACE_CARD_ID(myFlocPerson.id))
          }
          else {
            setOpenReplacement(true)
          }
        },
        text: 'Get new card (Lost/Stolen)',
      },
    ]

    if (card?.status !== CARD_STATUS.UNACTIVATED) {
      // TODO: Remove feature gate
      if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
        import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
        cardOptions.push({
          action: () => history.push(ROUTES.ACTIVATE_CARD),
          text: 'Reset card PIN',
        })
      }
    }

    // Add [un]freeze options
    if (card?.status === CARD_STATUS.ACTIVE && card?.lock?.status === CARD_LOCK_STATUSES.UNLOCKED) {
      cardOptions.push({
        action: () => cardAction('freeze', dispatch, cardActionUser, submitForm, true),
        text: 'Freeze spending on card',
      })
    }
    else if (card?.lock?.status === CARD_LOCK_STATUSES.LOCKED) {
      cardOptions.push({
        action: () => cardAction('unfreeze', dispatch, cardActionUser, submitForm, true),
        text: 'Unfreeze spending on card',
      })
    }

    myCardAction = (
      <Dropdown
        changeText={false}
        label='Card options'
        onChange={option => option.action()}
        options={cardOptions}
      />
    )

    // @@ Funding Dropdown for lead
    if (myFlocPerson.role === ROLES.LEAD) {
      fundingOptions = [
        {
          action: () => history.push(ROUTES.TRANSFER_FUNDS),
          text: 'Transfer Funds',
        },
        {
          action: () => history.push(ROUTES.TRANSFER_DIRECT),
          text: 'View Manual Transfer Options',
        },
        {
          action: () => window.open(PDFS.DIRECT_DEPOSIT_FORM, '_blank'),
          text: 'View Direct Deposit Form',
        },
      ]

      if (accounts) {
        fundingOptions.push(
          ...[
            {
              action: changeBank,
              text: 'Change Linked Bank Account',
            },
            {
              action: removeBank,
              text: 'Remove Linked Bank Account',
            },
          ]
        )
      }
      else {
        fundingOptions.push({
          action: linkBank,
          text: 'Link Bank Account',
        })
      }
    }
  }
  // Team Member
  else if (myFlocPerson.role === ROLES.TEAM_MEMBER && personBalance) {
    availableBalances = [
      ...(viewPermissions
        ? [{ amount: totalInCents, title: 'myFloc Card Account Available Balance' }]
        : []),
      { amount: personBalance.available.amount, title: 'My Available Balance' },
    ]
    const cardOptions = []
    if (card?.status !== CARD_STATUS.UNACTIVATED) {
      // TODO: Remove feature gate
      if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
        import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
        cardOptions.push({
          action: () => history.push(ROUTES.ACTIVATE_CARD),
          text: 'Reset card PIN',
        })
      }
    }

    // Add freeze options
    if (card?.status === CARD_STATUS.ACTIVE && card?.lock?.status === CARD_LOCK_STATUSES.UNLOCKED) {
      cardOptions.push({
        action: () => cardAction('freeze', dispatch, cardActionUser, submitForm, true),
        text: 'Freeze spending on card',
      })
    }

    myCardAction = cardOptions.length > 0
      ? (
        <Dropdown
          changeText={false}
          label='Card options'
          onChange={option => option.action()}
          options={cardOptions}
        />
      ) : null
  }
  // Friend
  else {
    availableBalances = [{ amount: totalInCents, title: 'myFloc Card Account Available Balance' }]
  }

  let loaded = false
  if (availableBalances) {
    // Friend
    if (myFlocPerson.role === ROLES.FRIEND) loaded = true
    else if (combinedAccounts) {
      // Team Member
      if (myFlocPerson.role === ROLES.TEAM_MEMBER) loaded = true
      else if (isAdmin && cardAch) loaded = true
    }
  }

  // @@ Build view member dropdown
  const viewMemberOptions = []

  if ((myFlocPerson.role !== ROLES.TEAM_MEMBER || viewPermissions) && combinedAccounts) {
    const selectPrimaryAsDefault = (myFlocPerson.role === ROLES.LEAD) || (myFlocPerson.role === ROLES.INSIDER)
    const primaryMemberOpts = {
      selected: selectPrimaryAsDefault,
      text: 'Primary (Lead/Insider)',
      value: 'primary',
    }
    // Pre-select the dropdown
    viewMemberOptions.push(primaryMemberOpts)
    const preSelect = queryTo ?? myFlocPerson.id
    combinedAccounts.forEach(account => {
      const roleDetail = account?.role ? ` (${camelToSentence(account.role)})` : ''
      viewMemberOptions.push({
        selected: selectPrimaryAsDefault ? false : account.id === preSelect,
        text: `${account.firstName} ${account.lastName}${roleDetail}`,
        value: account.id,
      })
    })
  }

  const selectedClasses =
    'after:left-0 after:absolute after:bottom-[-17px] after:md:bottom-[-22px] after:w-full after:h-[2px] after:md:h-1 after:bg-accent relative'

  const applyDateRange = (from, to) => {
    // Date picker defaults to 12pm local time. Must handle full day range

    if (from) {
      from.setHours(0, 0, 0, 0) // Get all transactions from start of day
    }

    if (to) {
      to.setHours(23, 59, 0, 0) // Set to EOD
      if (to.getTime() > Date.now()) {
        to = new Date(Date.now()) // Limit to not in future to prevent NS error
      }
    }

    setDateRange([from?.toISOString() ?? null, to?.toISOString() ?? null])
  }

  // Feature gate disputes microapp
  function handleDispute () {
    if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
      import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
      history.push(ROUTES.DISPUTE_TRANSACTIONS)
    }
    else {
      setOpenDispute(true)
    }
  }

  function activateCardButton () {
    // TODO: Remove feature gate
    if (import.meta.env.VITE_ENV !== ENV_OPTIONS.production &&
      import.meta.env.VITE_ENV !== ENV_OPTIONS.test) {
      return myFlocPerson.cardStatus === CARD_STATUS.UNACTIVATED
        ? (
          <Button
            className='mb-4 w-full'
            onClick={() => history.push(ROUTES.ACTIVATE_CARD)}
          >
            Activate Card
          </Button>
        )
        : null
    }
    else {
      return null
    }
  }

  return loaded ? (
    <>
      {/* Balance */}
      <>
        <div className='bg-primary'>
          <div className='relative md:py-12 wide-container'>
            <h1 className='mb-10 text-white'>myFloc Card Account</h1>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
              {/* @@ Available Balance */}
              <AvailableBalance balances={availableBalances} limits={spendingLimits(myFlocPerson)}>
                {fundingOptions && (
                  <Dropdown
                    buttonClasses='mb-0'
                    changeText={false}
                    className='mt-8'
                    isLoading={accountsLoading || status !== 'closed'}
                    label='Funding Options'
                    onChange={option => option.action()}
                    options={fundingOptions}
                  />
                )}
              </AvailableBalance>

              {bankLinkContainer}

              {/* Account Information */}
              {cardAch && (
                <HorizontalCard
                  options={[
                    {
                      body: maskSensitive(cardAch.account_number, 4),
                      title: 'Account No.',
                      tooltipBody: cardAch.account_number,
                      tooltipTitle: 'Account Number',
                    },
                    {
                      body: maskSensitive(cardAch.routing_number),
                      title: 'Routing No.',
                      tooltipBody: cardAch.routing_number,
                      tooltipTitle: 'Routing Number',
                    },
                  ]}
                  title='Account Information'
                >
                  <Button
                    className='mt-8 mb-3 flat-left'
                    link={ROUTES.STATEMENTS}
                    arrow
                    primary
                    text
                  >
                    View Statements
                  </Button>
                </HorizontalCard>
              )}

              {/* My Card */}
              {card && (
                <HorizontalCard
                  options={[
                    {
                      body: maskSensitive(`1111 1111 1111 ${card.pan_last_4}`, 4),
                      title: 'Card Number',
                    },
                    {
                      body: `${card.expiration_month}/${card.expiration_year.toString()
                        .slice(2)}`,
                      title: 'Expiration',
                    },
                  ]}
                  title='My Card'
                >
                  <div style={{ width: '16rem' }}>
                    {activateCardButton()}
                    {myCardAction}
                  </div>
                </HorizontalCard>
              )}
            </div>

            {/* Replacement modal */}
            <Modal open={openReplacement} setOpen={setOpenReplacement} type={MODAL_TYPES.alert}>
              To request a replacement card, please contact
              <Button className='inline' href={'tel:' + MYFLOC_PHONE_NUMBER} tag='a' primary text>
                myFloc Customer Care at {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
              </Button>
            </Modal>

            <Icon
              className='hidden absolute top-12 -left-1/4 md:block'
              name='birds_left'
              width={267}
            />
            <Icon
              className='hidden absolute top-12 -right-1/4 md:block'
              name='birds_right'
              width={342}
            />
          </div>
        </div>

        <div className='wide-container'>
          <div className='flex flex-row justify-between'>
            <h3 className='mb-0'>Transactions</h3>

            <Button className='md:hidden' onClick={handleDispute} primary text>
              Dispute a Transaction
            </Button>
          </div>
          <div className='mt-8 w-full border-t border-gray-100' />

          {/* @@ Member Select */}
          {viewMemberOptions.length ? (
            <div className='flex flex-row justify-between items-center mt-6'>
              <div className='mr-4 md:max-w-none max-w-[4.8rem]'>View member:</div>
              <Dropdown
                label='Member Select'
                onChange={option => setCurrentMyFlocPersonId(option.value)}
                options={viewMemberOptions}
                value={currentMyFlocPersonId}
              />
            </div>
          ) : null}

          <div className='flex flex-row justify-between items-center mt-6'>
            <div className='mr-4 md:max-w-none max-w-[4.8rem]'>View date range:</div>
            <DatePicker handleDateChange={applyDateRange} />
          </div>

          {/* @@ Tabs */}
          <div className='flex flex-row pb-3 mt-12 border-gray-500 md:justify-between md:pb-4 md:border-b'>
            <nav className='flex flex-row flex-grow justify-between md:flex-grow-0'>
              <Button
                className={`flat-left ${currentTab === 'all' && selectedClasses}`}
                onClick={() => setCurrentTab('all')}
                primary={currentTab !== 'all'}
                text
              >
                All Transactions
              </Button>
              <Button
                className={`md:ml-4 ${currentTab === 'credit' && selectedClasses}`}
                onClick={() => setCurrentTab('credit')}
                primary={currentTab !== 'credit'}
                text
              >
                <span className='hidden md:inline'>Deposits/</span>Credits
              </Button>
              <Button
                className={`md:ml-4 ${currentTab === 'debit' && selectedClasses}`}
                onClick={() => setCurrentTab('debit')}
                primary={currentTab !== 'debit'}
                text
              >
                <span className='hidden md:inline'>Withdrawals/</span>Debits
              </Button>
              <Button
                className={`md:ml-4 flat-right ${currentTab === 'fee' && selectedClasses}`}
                onClick={() => setCurrentTab('fee')}
                primary={currentTab !== 'fee'}
                text
              >
                Fees
              </Button>
            </nav>

            <Button className='hidden md:block' onClick={handleDispute} primary text>
              Dispute a Transaction
            </Button>
            <DisputeModal open={openDispute} setOpen={setOpenDispute} />
          </div>

          {/* @@ Transactions */}
          <TransactionsTable
            callback={direction => {
              getTransactions({ direction, pendingLength: pendingTransactions.length })
            }}
            firstPage={startAfterArr.length > 1}
            nextPage={nextPage}
            pendingTransactions={pendingTransactions}
            prevPage={startAfterArr.length > 1}
            transactions={transactions}
          />

          <FeeSummary fees={fees} />
        </div>
      </>
    </>
  ) : (
    <Loading />
  )
}

export default MyAccount
