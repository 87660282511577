import { useState } from 'react'

import * as yup from 'yup'

/**
 * UsePassword hook to factor out the state for the FormPasswordComponent
 */
export const usePassword = () => {
  const [passwordErrors, setPasswordErrors] = useState({
    atLeast3: false,
    atLeast8Char: false,
    lowerCase: false,
    numbers: false,
    special: false,
    upperCase: false,
  })

  // Check all steps of validation manually
  const validatePassword = (ev, errors) => {
    const password = ev.target.value
    const numbers = /\d/g.test(password)
    const lowerCase = /[a-z]/g.test(password)
    const special = /[$&+,:;=?@#|'<>.^*()%!-]/g.test(password)
    const upperCase = /[A-Z]/g.test(password)
    const atLeast8Char = password.length >= 8
    const atLeast3 = [numbers, lowerCase, upperCase, special].filter(val => val).length >= 3

    setPasswordErrors({
      atLeast3,
      atLeast8Char,
      lowerCase,
      numbers,
      special,
      upperCase,
    })

    // Remove the errors from the password field
    if (atLeast8Char && atLeast3) {
      delete errors.password
    }
  }

  // Add Password validation to schema
  const passwordSchema = yup.object({
    password: yup
      .string()
      .test('password', '', () => passwordErrors.atLeast8Char && passwordErrors.atLeast3),
    password2: yup
      .string()
      .test('password', '', () => passwordErrors.atLeast8Char && passwordErrors.atLeast3),
  })

  return { passwordErrors, passwordSchema, validatePassword }
}
export default usePassword
