import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import {
  ENDPOINTS, ENV_OPTIONS, HTTP_METHODS, ROUTES, TRANSFER_TYPES,
} from '@/common/constants'
import { useGetBankCard, useSubmitForm } from '@/common/hooks'
import { currency } from '@/common/utils'
import { history } from '@/history'
import { clearSteps } from '@/redux/formSteps'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { getMyFlocPerson, selectMyFlocPerson } from '@/redux/user'

const STATES = {
  CA: { link: 'https://www.netspend.com/help/licenses/california-receipt/', name: 'California' },
  NY: { link: 'https://www.netspend.com/help/licenses/new-york-receipt/', name: 'New York' },
  TX: { link: 'https://www.netspend.com/help/licenses/#Texas', name: 'Texas' },
}

/**
 * Factored out Transfer Review component
 *
 * @param   {Object}      props
 * @param   {string}      props.form           The form to clear
 * @param   {object}      props.payload
 * @param   {string}      props.payload.action WITHDRAWAL vs DEPOSIT
 * @param   {number}      props.payload.cents  The amount in cents
 * @param   {boolean}     props.stepText       Include the stepText in the header
 * @returns {JSX.Element}
 */
const TransferReview = ({ form, payload = null, stepText = false }) => {
  const dispatch = useDispatch()

  const { loading, submitForm } = useSubmitForm()
  const { accounts, cardAch } = useGetBankCard()

  const myFlocPerson = useSelector(selectMyFlocPerson)

  useEffect(() => {
    if (payload?.action && payload?.cents) return

    dispatch(
      addToast({
        subtitle: 'You must create a transfer first, please try again',
        title: 'Bad Request',
        type: TOAST_TYPES.error,
      })
    )
    history.push(ROUTES.DASHBOARD)
  }, [])

  const pageHeaderProps = stepText ? { stepText: 'Step 5 of 5' } : {}
  let endpoint, from, to

  if (accounts) {
    // Withdrawal
    if (payload.action === TRANSFER_TYPES.WITHDRAWAL) {
      from = `${accounts.bank} (...${accounts.accountNumberLast4})`
      to = `myFloc Card Account (...
        ${cardAch.account_number.slice(cardAch.account_number.length - 4)})`
      endpoint = ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_BANK_TRANSFERS_WITHDRAWALS
    }
    // Deposit
    else {
      from = `myFloc Card Account (...
          ${cardAch.account_number.slice(cardAch.account_number.length - 4)})`
      to = `${accounts.bank} (...${accounts.accountNumberLast4})`
      endpoint = ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_BANK_TRANSFERS_DEPOSITS
    }
  }

  const onSubmit = async (debug = null) => {
    const { response } = await submitForm(endpoint(myFlocPerson.id), {
      method: HTTP_METHODS.POST,
      payload: {
        amount: payload.cents,
        debug,
        sourceId: accounts.id,
      },
      success: {
        redirect: {
          path: ROUTES.TRANSFER_CONFIRMATION,
        },
      },
    })
    if (response.status === 200) {
      dispatch(clearSteps({ form }))
    }
  }

  // Set location data
  const location = STATES[myFlocPerson.residenceAddress?.region]
  useEffect(() => {
    dispatch(getMyFlocPerson())
  }, [])

  const notProd = import.meta.env.VITE_ENV !== ENV_OPTIONS.production

  return (
    <>
      <PageHeader {...pageHeaderProps}>Review transfer details</PageHeader>
      <div className='relative flex-col text-left main-container'>
        <div className='mt-4 text-lg'>Confirm your transfer request.</div>

        {cardAch && accounts ? (
          <>
            <div className='mt-10 text-lg font-medium'>Transfer</div>
            <div className='text-lg'>{currency(payload.cents / 100)}</div>

            <div className='mt-10 text-lg font-medium'>From</div>
            <div className='text-lg'>{from}</div>

            <div className='mt-10 text-lg font-medium'>To</div>
            <div className='text-lg'>{to}</div>

            <div className='mt-10 text-lg font-medium'>Fee</div>
            <div className='text-lg'>$0.00</div>

            <div className='mt-10 text-lg'>
              By clicking Confirm Transfer, you authorize Netspend to make a one-time ACH transfer
              from {accounts.bank} (...{accounts.accountNumberLast4}). Once you submit this
              transfer, the transaction can not be cancelled.{' '}
              <span className='font-bold text-secondary'>
                For security purposes, transferred funds may not be available for up to three
                business days.
              </span>{' '}
              Fraud monitoring and availability of funds may delay or cancel your transfer.
            </div>
          </>
        ) : (
          <div className='p-12'>
            <Loading />
          </div>
        )}

        {/* Actions */}
        <div className='flex flex-col-reverse items-center mt-8 md:flex-row md:justify-around md:items-start md:mt-14'>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <Button className='mt-8 md:mt-3' onClick={history.goBack} primary text>
            Go Back
          </Button>

          <Button
            className='m-0'
            disabled={!accounts}
            isLoading={loading}
            onClick={() => onSubmit(null)}
          >
            Confirm Transfer
          </Button>
        </div>

        {notProd && (
          <>
            <div className='mt-8 text-red-error'>
              These buttons are for dev only. The Confirm Transfer button will not update the status
              of the transfer so you will have to do it manually using netspend's self service tool
              (SST).
              <p className='mt-4'>
                Or you can use the buttons below which will automagically hit the SST and update the
                status.
              </p>
            </div>

            <Button
              className='m-0'
              isLoading={loading}
              onClick={() => onSubmit('status-failed')}
              outline
            >
              Failed Transfer
            </Button>
            <Button
              className='block mt-4'
              isLoading={loading}
              onClick={() => onSubmit('status-posted')}
            >
              Posted (successful) Transfer
            </Button>
          </>
        )}

        {/* Extra  */}
        {location && (
          <div className='mt-10'>
            {location.name} customers{' '}
            <Button
              className='inline flat-x'
              href={location.link}
              tag='a'
              target='_blank'
              primary
              text
            >
              click here
            </Button>{' '}
            for information about filing complaints about our money transmission service.
          </div>
        )}

        <Panel
          className='hidden absolute top-20 -left-3/4 mx-auto mt-11 lg:block'
          type={PANEL_TYPES.transfer}
        />
        <Panel className='mx-auto mt-10 w-5/6 lg:hidden' type={PANEL_TYPES.transfer} />
      </div>
    </>
  )
}

TransferReview.propTypes = {
  /**
   * The form to clear
   */
  form: PropTypes.string.isRequired,
  /**
   * Action = withdrawal or deposit, cents = amount in cents
   */
  payload: PropTypes.object.isRequired,
  /**
   * Whether or not to include the stepText in the header
   */
  stepText: PropTypes.bool,
}

export default TransferReview
