import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ROLES } from '@/common/constants'

import creditCard from '../../../assets/images/credit_card.png'
import Icon from '../../../common/components/Icon'
import styling from './creditCard.module.scss'
/**
 * Simple component for displaying credit card images
 * @param {Object}  props
 * @param {boolean} [props.active = false]
 * @param {object}  [props.person]
 * @param {string}  [props.className = '']
 * @param {boolean} [props.png = false]
 * @param {number}  [props.width = 100]
 * @param {boolean} [props.renderBg = false]
 */
const CreditCard = ({
  active = false,
  className = '',
  png = false,
  renderBg = true,
  width = 100,
  person,
  ...props
}) => {
  const classes = classNames(className, 'inline-block', 'relative')

  /**
   * Map various user statuses to a display status
   *
   * @param   {object} person person object to parse status from
   * @returns {string}        status to display
   */
  const getCardDisplayStatus = person => {
    if (!person) {
      return ''
    }

    // cardStatus: no-card|unactivated|active|closed|disabled

    if (person.status === 'invited') {
      return 'invited'
    }
    if (person.status === 'closed') {
      return 'closed'
    }
    if (!person.cardStatus || person.role === ROLES.FRIEND) {
      return 'no-card'
    }

    // Override other card statuses with Frozen
    if (person.cardLockStatus === 'locked') {
      return 'locked'
    }

    return person.cardStatus
  }

  /**
   * Create a CSS Card
   *
   * @param   {string}      name name to display
   * @returns {JSX.Element}
   */
  const cssCard = name => <div className={styling.cardText} style={{ height: 0.625 * width, width }}>{name}</div>

  const STATUSES = {
    active: 'Active',
    closed: 'Closed',
    disabled: 'Disabled',
    invited: 'Invited',
    locked: 'Frozen',
    'no-card': 'No Card',
    noCard: 'No Card',
    unactivated: 'Not Active',
  }

  /**
   * Create the display card
   *
   * @param   {boolean}     isActive active status to display image
   * @param   {object}      person   person to get display status for
   * @returns {JSX.Element}
   */
  const createCard = (isActive, person) => {
    // For active card show either the PNG or the SVG
    if (isActive || person.cardStatus === 'active') {
      return png
        ? (<img alt='Credit Card' className={styling.active} src={creditCard} width={width} {...props} />)
        : (<Icon name={'credit_card'} width={width} {...props} />)
    }

    // Map person statuses to display text and return a CSS Card
    const displayStatusCode = getCardDisplayStatus(person)
    return cssCard(STATUSES[displayStatusCode] ?? 'No Card')
  }

  return (
    <div className={classes} style={{ margin: '1px' }}>
      {createCard(active, person)}
      {renderBg && <div className={styling.background} />}
    </div>
  )
}

CreditCard.propTypes = {
  /**
   * Active card or not
   */
  active: PropTypes.bool,
  /**
   * Any class names to merge into the topmost element
   */
  className: PropTypes.string,

  /**
   * Person to map statuses for
   */
  person: PropTypes.object,

  /**
   * Decides if the card is an svg or png
   */
  png: PropTypes.bool,

  /**
   * Whether to show the huge blue blooming background or not
   */
  renderBg: PropTypes.bool,
  /**
   * The width of the card
   */
  width: PropTypes.number,
}

export default CreditCard
