import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

import styling from './viewContainer.module.scss'

const ViewContainer = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [location])

  return <div className={styling['view-container']}>{children}</div>
}

ViewContainer.propTypes = {
  children: PropTypes.node,
}

export default ViewContainer
