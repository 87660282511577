import React from 'react'

import Button from '@/common/components/Button'
import { EXTERNAL_URLS, PDFS } from '@/common/constants'

const TermsAndConditions = () => {
  const standardDocuments = [
    {
      href: PDFS.CARDHOLDER_AGREEMENT,
      title: 'Cardholder Agreement',
    },
    {
      href: PDFS.BANK_PRIVACY_POLICY,
      title: 'Pathward Privacy Policy',
    },
    {
      href: PDFS.ESIGN_DISCLOSURE,
      title: 'Pathward E-Sign Disclosure',
    },
    {
      href: EXTERNAL_URLS.PRIVACY_POLICY,
      title: 'myFloc Privacy Policy',
    },
    {
      href: EXTERNAL_URLS.TERMS_AND_CONDITIONS,
      title: 'myFloc Terms and Conditions',
    },
  ]

  const bankDocuments = [
    {
      href: PDFS.NETSPEND_PRIVACY_POLICY,
      title: 'Netspend Online Privacy Policy',
    },
    {
      href: PDFS.BANK_TRANSFER_TERMS_AND_CONDITIONS,
      title: 'Bank Transfer Terms and Conditions',
    },
    {
      href: PDFS.BANK_TRANSFER_ESIGN,
      title: 'Bank Transfer E-Sign',
    },
  ]

  return (
    <>
      <div className='pb-24 wide-container'>
        <h1 className='text-secondary'>Terms and Conditions</h1>

        <div className='mt-10 text-lg font-bold'>Standard Documents</div>
        <div className='flex flex-col'>
          {standardDocuments.map(doc => (
            <Button
              className='mt-2 w-max flat-left'
              href={doc.href}
              key={doc.href}
              rel='noreferrer'
              target='_blank'
              primary
              text
            >
              {doc.title}
            </Button>
          ))}
        </div>

        <div className='mt-10 text-lg font-bold'>Bank Transfer Documents</div>
        <div className='flex flex-col'>
          {bankDocuments.map(doc => (
            <Button
              className='block mt-2 w-max flat-left'
              href={doc.href}
              key={doc.href}
              rel='noreferrer'
              target='_blank'
              primary
              text
            >
              {doc.title}
            </Button>
          ))}
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions
