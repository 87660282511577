import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import Button from '@/common/components/Button'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import Panel, { PANEL_TYPES } from '@/common/components/Panel'
import { NETSPEND_ERROR_MESSAGES, PDFS, ROUTES } from '@/common/constants'
import { useBankLink, useGetBankCard } from '@/common/hooks'
import { LINK_BANK_RETURN_TYPES } from '@/common/hooks/useBankLink'
import { history } from '@/history'
import { addToast, TOAST_TYPES } from '@/redux'

const StepThree = props => {
  const dispatch = useDispatch()

  const { accounts, accountsLoading } = useGetBankCard({ getCardAch: false })
  const { bankLinkContainer, linkBank, status } = useBankLink()

  // Handle success/errors from microapp
  const linkBankWrapper = async () => {
    const { status } = await linkBank()
    switch (status) {
      case LINK_BANK_RETURN_TYPES.CANCELLED:
        dispatch(
          addToast({
            subtitle: NETSPEND_ERROR_MESSAGES.USER_CANCELLED,
            type: TOAST_TYPES.error,
          })
        )
        break
      case LINK_BANK_RETURN_TYPES.SUCCESS:
        history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_FOUR)
        break
      default:
    }
  }

  useEffect(() => {
    if (!accountsLoading && accounts) {
      history.replace(ROUTES.CREATE_CARD_ACCOUNT.STEP_FOUR)
    }
  }, [accounts, accountsLoading])

  return (
    <>
      {bankLinkContainer}

      {accountsLoading ? (
        <Loading className='min-h-header' />
      ) : (
        <>
          <PageHeader stepText='Step 3 of 5'>
            Link to a personal account to transfer funds
          </PageHeader>
          <div className='relative flex-col text-left main-container'>
            <h4>Linking to your bank account</h4>

            <div className='mt-4 text-lg'>
              <p>
                Linked accounts must be personal checking accounts issued by a US-based financial
                institution.
              </p>
              <p>
                We use Plaid to help step you through the process of finding and linking your
                personal bank account to your myFloc Card Account.
              </p>
              <p>
                If you are having trouble finding your financial institution using Plaid, don’t
                worry. You can also set up a transfer directly from your bank.{' '}
                <Button
                  className='inline flat-x'
                  href={PDFS.DIRECT_DEPOSIT_FORM}
                  tag='a'
                  target='_blank'
                  primary
                  text
                >
                  View direct bank transfer instructions
                </Button>
                .
              </p>
            </div>

            <Button
              className='mt-6 mb-10'
              isLoading={status !== 'closed'}
              onClick={linkBankWrapper}
            >
              Link Bank Account
            </Button>

            <div className='text-sm'>
              Bank Transfer is a money transfer service provided by Netspend Corporation (NMLS#
              906983). See www.netspend.com/licenses. Not all financial institutions are supported.
              View{' '}
              <Button
                className='inline flat-x'
                onClick={() => history.push(ROUTES.TRANSFER_DIRECT)}
                tag='a'
                primary
                text
              >
                manual transfer instructions.
              </Button>
            </div>
            <Panel
              className='hidden absolute top-12 -left-3/4 mx-auto lg:block'
              type={PANEL_TYPES.transfer}
            />
            <Panel className='mx-auto mt-10 lg:hidden' type={PANEL_TYPES.transfer} />
          </div>
        </>
      )}
    </>
  )
}

export default StepThree
