import React from 'react'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'

import App from '@/App'
import AuthProvider from '@/common/components/AuthProvider/AuthProvider'
import reportWebVitals from '@/reportWebVitals'

// Sentry error reporting
if (import.meta.env.VITE_SENTRY_URL && import.meta.env.VITE_SENTRY_URL !== 'false') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_URL,
    environment: import.meta.env.VITE_ENV,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
