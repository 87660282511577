/**
 * Store for the currently logged in user
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  ENDPOINTS, MYFLOC_API_HOST_URL, ROLES, ROLES_ORDERED,
} from '@/common/constants'
import { selectMyFlocPerson } from '@/redux/user'

export const getTeam = createAsyncThunk(
  'team/getTeam',
  /**
   * Get the team
   *
   * @param   {Object}            params
   * @param   {String}            params.teamId team id
   * @returns {Promise<{Object}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const myFlocPerson = selectMyFlocPerson(state)
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.TEAMS_PERSONS(myFlocPerson.teamId)}`

    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })
    return await resp.json()
  }
)

export const getInvited = createAsyncThunk(
  'team/getInvitations',
  /**
   * Get the team's invitations
   *
   * @param   {Object}            params
   * @param   {String}            params.teamId team id
   * @returns {Promise<{Object}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const myFlocPerson = selectMyFlocPerson(state)
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.TEAM_INVITATIONS(myFlocPerson.teamId)}`

    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })
    return await resp.json()
  }
)

const teamSlice = createSlice({
  initialState: {
    error: null,
    invitations: null,
    team: null,
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  extraReducers: builder => {
    builder.addCase(getTeam.fulfilled, (state, { payload }) => {
      if (payload.error) {
        state.error = payload.error
      }
      else {
        state.team = payload
      }
    })
    builder.addCase(getInvited.fulfilled, (state, { payload }) => {
      if (payload.error) {
        state.error = payload.error
      }
      else {
        state.invitations = payload
      }
    })
  },
  name: 'team',
  reducers: {},
})

/**
 * Combines teamAccounts with netspendTeamBalances
 *
 * We also filter out friend and sort
 */
export const combineTeamBalance = state => {
  if (state.team.team && state.netspend.teamBalances) {
    return Object.keys(state.netspend.teamBalances)
      .map(myFlocPersonId => {
        const person = state.team.team.find(person => person.id === myFlocPersonId)
        return {
          ...person,
          ...state.netspend.teamBalances[myFlocPersonId],
        }
      })
      .filter(person => person.role !== ROLES.FRIEND)
      .sort((a, b) => {
        const aLevel = ROLES_ORDERED.indexOf(a.role)
        const bLevel = ROLES_ORDERED.indexOf(b.role)

        if (aLevel > bLevel) {
          return -1
        }
        else if (aLevel < bLevel) {
          return 1
        }
        else {
          return 0
        }
      })
  }
  else return null
}

// export const { x } = teamSlice.actions
export default teamSlice.reducer
