import React from 'react'

import PropTypes from 'prop-types'

import { UNITED_STATES } from '@/common/constants'
import FormInput from '@components/FormInput'
import FormSelect from '@components/FormSelect'

/**
 * Factor out common address component
 *
 * @param {Object}   props
 * @param {Object}   props.apiErrors Validation errors from our API
 * @param {Object}   props.errors    Validation errors object from the front-end
 * @param {String}   props.name      The name of the form object
 * @param {String}   props.label     Label for the formAddress object
 * @param {Boolean}  props.showHint  Boolean to show/hide the hint text
 * @param {Function} props.register  The registration function from RHF
 */
export const FormAddress = ({ apiErrors, errors, name, register, label = 'Address', showHint = true }) => {
  return (
    <>
      <FormInput
        autoComplete='address-line1'
        errors={errors}
        hintContent={showHint && 'Physical addresses only (no P.O. Boxes allowed).'}
        label={label}
        name={`${name}.line1`}
        register={register}
        validationErrors={apiErrors?.validationErrors}
        required
      />

      <FormInput
        autoComplete='address-line2'
        errors={errors}
        label='Suite/Apartment Number (optional)'
        name={`${name}.line2`}
        register={register}
        validationErrors={apiErrors?.validationErrors}
      />

      <FormInput
        autoComplete='address-level2'
        errors={errors}
        label='City'
        name={`${name}.city`}
        register={register}
        validationErrors={apiErrors?.validationErrors}
        required
      />

      <div className='flex flex-row justify-start'>
        <FormSelect
          autoComplete='address-level1'
          className='mr-4 w-28'
          errors={errors}
          label='State'
          name={`${name}.region`}
          register={register}
          validationErrors={apiErrors?.validationErrors}
          required
        >
          <option hidden />
          {UNITED_STATES.map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </FormSelect>

        <FormInput
          autoComplete='postal-code'
          className='w-28'
          errors={errors}
          inputMode='numeric'
          inputType='text'
          label='ZIP Code'
          name={`${name}.postal`}
          register={register}
          validationErrors={apiErrors?.validationErrors}
          required
        />
      </div>
    </>
  )
}

FormAddress.propTypes = {
  /**
   * The error object returned from the API, may contain field validations
   */
  apiErrors: PropTypes.object,
  /**
   * Validation errors object from the front-end
   */
  errors: PropTypes.object.isRequired,
  /**
   * Label text for the address field
   */
  label: PropTypes.string,
  /**
   * Name of the parent object for the form components
   */
  name: PropTypes.string.isRequired,
  /**
   * Register method from RHF
   */
  register: PropTypes.func.isRequired,
  /**
   * Boolean whether or not to show the hint text
   */
  showHint: PropTypes.bool,
}

export default FormAddress
