import React from 'react'

import PropTypes from 'prop-types'

import { ReactComponent as ErrorSVG } from '@/assets/svg/error.svg'

import styling from './error.module.scss'

/**
 * Displays the error screen with helpful messages
 *
 * @param {Object}                               props
 * @param {JSX.Element[] | JSX.Element | String} props.body    The myFloc message and phone number to call for help
 * @param {String}                               [props.image] Image user will see, if none provided will default to lady with question mark
 * @param {JSX.Element | String}                 props.title   Description of the error
 *
 */
export const Error = ({ body, image, title }) => {
  return (
    <div className={styling.container}>
      <div className={styling.imageWrapper}>
        {image ? <img alt='Error Explanation' src={image} /> : <ErrorSVG />}
      </div>
      <h1 className={styling.title}>{title}</h1>
      {body && <div className={styling.body}>{body}</div>}
    </div>
  )
}

Error.propTypes = {
  /**
   * The smaller text telling you to call myfloc etc
   */
  body: PropTypes.node,
  /**
   * The image
   */
  image: PropTypes.any,
  /**
   * The primary error message
   */
  title: PropTypes.node,
}

export default Error
