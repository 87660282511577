import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/browser'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from '@/common/constants'
import { routeGuard } from '@/common/utils'
import { history } from '@/history'
import {
  createSession,
  deleteSession,
  getAuth0Token,
  selectMyFlocPerson,
  setAuth0Token,
  setupUserBatch,
  setUserId,
} from '@/redux'

/**
 * A wrapper around the useAuth0 hook because we want to log our users in programatically
 * after enrollment. Apparently this isn't possible in auth0 alone hence our wrapper.
 *
 * https://community.auth0.com/t/is-there-any-to-set-the-user-session-manually/19400/3
 *
 * The caveat is once we refresh or the token expires, the user will have to login
 *
 * @returns {{ auth0Loading, auth0User, enrollmentLogin, error, getAccessTokenSilently, initializeUser, isAuthenticated, loading, login, logout, user }}
 */
const useAuthentication = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const myFlocPerson = useSelector(selectMyFlocPerson)
  const userState = useSelector(state => state.user)
  const auth0Token = localStorage.getItem('auth0Token') || null
  const user = userState.user
  const userId = userState.id
  const userError = userState.userError

  const {
    error: auth0Error,
    getAccessTokenSilently,
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
    logout: auth0Logout,
    user: auth0User,
  } = useAuth0()

  /**
   * Our wrapper for the Auth0 login function
   *
   * @param {string} [returnTo] Path to return to after login, defaults to current path
   */
  const login = (returnTo = window.location.pathname) =>
    loginWithRedirect({ appState: { returnTo } })

  // Grabbing initial data for user
  const initializeUser = async () => {
    await dispatch(setUserId({ auth0User }))
    await dispatch(getAuth0Token({ getAccessTokenSilently }))
    await setupUser(true)
  }

  // Get User, create session and card, keep fresh data for route guard
  const setupUser = async (dispatchCreateSession = false) => {
    await dispatch(setupUserBatch())

    // We patch the season on enrollment on the backend so no need for creating session there
    if (dispatchCreateSession) await dispatch(createSession())

    routeGuard({
      login,
      pathName: history.location.pathname,
    })
    setLoading(false)
  }

  // Make sure all data is loaded if logged in
  useEffect(() => {
    if (!auth0Loading && !auth0Authenticated) {
      setLoading(false)
    }
    else if (auth0Error || userError) {
      setLoading(false)
      Sentry.captureException(auth0Error || userError, {
        contexts: {
          auth0ErrorMsg: {
            msg: auth0Error?.message,
            stack: auth0Error?.stack,
          },
          current: {
            stack: new Error().stack,
          },
          userError: {
            msg: userError?.message,
            stack: userError?.stack,
          },
        },
        level: Sentry.Severity.Error,
      })
      const message =
        auth0Error?.message ||
        userError?.message ||
        'There was an error with your login, please try again'
      // history.push(`/error?message=${message}`)
      if (message === 'Missing Authorization Token') {
        setTimeout(() => {
          logout()
        }, 2000)
      }
      else {
        history.push(`/error?message=${message}`)
      }
    }
  }, [auth0Error, auth0Authenticated, auth0Loading, userError])

  // Our wrapper for the Auth0Logout function
  const logout = () => {
    dispatch(deleteSession())
    dispatch({ type: 'RESET' })

    const loggedOutUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTES.LOGGED_OUT}`
    auth0Logout({ returnTo: loggedOutUrl })
  }

  // Add personId to sentry
  useEffect(() => {
    if (myFlocPerson) Sentry.setUser({ id: myFlocPerson.id })
  }, [myFlocPerson?.id])

  /**
   * For login on enrollment, performs all of the goodies
   *
   * @param {Object} resp formSubmit response
   */
  const enrollmentLogin = async resp => {
    if (resp.data.id && resp.data.auth?.access_token) {
      setLoading(true)
      await dispatch(setUserId(resp.data))
      await dispatch(setAuth0Token(resp.data.auth.access_token))
      await setupUser()
      return true
    }
    else {
      // TODO handle non-toast error
      return false
    }
  }

  return {
    auth0Loading,
    auth0User,
    enrollmentLogin,
    error: { ...auth0Error, ...userError },
    getAccessTokenSilently,
    initializeUser,
    isAuthenticated: auth0Token && userId,
    loading,
    login,
    logout,
    user,
  }
}

export default useAuthentication
