import React from 'react'

import PropTypes from 'prop-types'

/**
 * For displaying information horizontally
 *
 * @param   {Object}                                                                             props
 * @param   {JSX.Element}                                                                        [props.children]
 * @param   {Array.<{body: string, title: string, tooltipBody?: string, tooltipTitle?: string}>} props.options
 * @param   {string}                                                                             props.title
 * @returns {JSX.Element}
 */
export const HorizontalCard = ({ children, options, title }) => (
  <div className='flex flex-col p-7 bg-white rounded-md'>
    <h3>{title}</h3>

    <div className='flex flex-row justify-between mt-4'>
      {options.map(option => (
        <div className='flex flex-col' key={option.title}>
          <div>{option.title}</div>
          <div className='text-xl text-secondary has-tooltip'>
            {option.body}
            {option.tooltipBody && (
              <div className='p-2 w-max text-center bg-white rounded-md border border-gray-200 shadow tooltip'>
                <div className='text-sm font-bold text-black'>{option.tooltipTitle}</div>
                <div className='text-lg font-medium tracking-wider'>{option.tooltipBody}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>

    <div className='flex flex-grow'></div>
    {children}
  </div>
)

HorizontalCard.propTypes = {
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default HorizontalCard
