import React from 'react'

import PropTypes from 'prop-types'

import Icon from '@components/Icon'

import styling from './pageHeader.module.scss'

/**
 * Teal page header with optional step text
 *
 * @param {Object} props
 * @param {Object} [props.children]
 * @param {String} [props.stepText]
 */
const PageHeader = ({ children, stepText }) => {
  return (
    <div className={styling.pageHeader}>
      <Icon className={`${styling.birdSVG} ${styling.leftBird}`} name='birds_left' width={231} />
      <div className={styling.headerDivText}>
        <h4 className={styling.stepText}>{stepText}</h4>
        <h1 className={styling.instructionText}>{children}</h1>
      </div>
      <Icon className={`${styling.birdSVG} ${styling.leftBird}`} name='birds_right' width={231} />
    </div>
  )
}

PageHeader.propTypes = {
  /**
   * The smaller text above the title used for steps
   */
  stepText: PropTypes.string,
}

export default PageHeader
