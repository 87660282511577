/* eslint-disable sort-keys-fix/sort-keys-fix */
export const ENROLLMENT = {
  MF_CREATED: 'myfloc-account-created',
  NS_CREATED: 'netspend-account-created',
  NS_CARD: 'netspend-card-created',
  NS_LEGAL: 'netspend-legal-checked',
  NS_FUNDED: 'netspend-card-funded',
}

export const ENROLLMENT_ORDERED = [
  ENROLLMENT.MF_CREATED,
  ENROLLMENT.NS_CREATED,
  ENROLLMENT.NS_CARD,
  ENROLLMENT.NS_LEGAL,
  ENROLLMENT.NS_FUNDED,
]

export const CARD_STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  DISABLED: 'disabled',
  NO_CARD: 'no-card',
  UNACTIVATED: 'unactivated',
}

export const CARD_LOCK_STATUSES = {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
}

export const PERSON_STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed',
}

export const ACCOUNT_STATUS = {
  AUTHENTICATION_REQUIRED: 'authentication_required',
}
