import React, { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@/common/components/Button'
import Loading from '@/common/components/Loading'
import PageHeader from '@/common/components/PageHeader'
import {
  HTTP_METHODS, ENDPOINTS, ROUTES,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { useAsyncEffect } from '@/common/hooks/useAsyncEffect'
import { leadInvitationSchema } from '@/common/validations'
import { history } from '@/history'
import { TOAST_TYPES, addToast } from '@/redux'
import { selectMyFlocPerson } from '@/redux/user'
import FormInput from '@components/FormInput'

const LeadInvite = props => {
  const activePerson = useSelector(selectMyFlocPerson)
  const dispatch = useDispatch()

  // ---------------------------------------------------------------------------
  // ASYNC DATA
  const pushError = (message, page = '/') => {
    history.push(page)
    dispatch(
      addToast({
        subtitle: message,
        title: 'Error',
        type: TOAST_TYPES.error,
      })
    )
  }

  // Check the user is an admin account
  const [authLevel, setAuthLevel] = useState(null)
  const getAuthPermission = useCallback(async () => {
    if (!activePerson) {
      history.push('/')
      return
    }

    const result = await submitForm('/admin', { method: HTTP_METHODS.GET })

    if (result.response.status < 400) {
      return result.data.authLevel
    }
    else if (result.response.status === 404) {
      history.push(ROUTES.ERROR404)
    }
    else {
      pushError('Service Issue. Please try again later.')
    }
    return null
  }, [activePerson])

  // Run callback with proper state protection
  useAsyncEffect(getAuthPermission, setAuthLevel)

  // ---------------------------------------------------------------------------
  // FORM HANDLING
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    reset,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(leadInvitationSchema) })

  const { apiErrors, loading, submitForm } = useSubmitForm()

  // Cancel card and re-order
  const onSubmit = async formEntry => {
    const { firstName, lastName, email } = formEntry
    const payload = { email, firstName, lastName }

    const result = await submitForm(
      ENDPOINTS.INVITE_LEAD,
      { method: HTTP_METHODS.POST, noErrorToast: true, payload }
    )

    if (result.response.status < 400) {
      reset()
      dispatch(addToast({
        subtitle: `${firstName} ${lastName} has been invited using ${email}`,
        title: 'Success',
        type: TOAST_TYPES.success,
      }))
    }
    else if (result?.data?.error?.validationErrors) {
      // Map validation errors to form
      for (const item in result?.data?.error?.validationErrors) {
        setError(item)
      }
      dispatch(addToast({
        subtitle: 'Please double-check your entries.',
        title: 'Could not invite that person. ',
        type: TOAST_TYPES.error,
      }))
    }
    else {
      history.push(ROUTES.ERROR_CUSTOM('Error inviting lead.'))
    }
  }

  // ---------------------------------------------------------------------------
  // MARKUP VARIABLES
  return authLevel ? (
    <>
      <PageHeader>Invite myFloc Lead</PageHeader>
      <div className='relative flex-col text-left main-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-row justify-between mt-6'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </div>
          <FormInput
            autoComplete='email-address'
            errors={errors}
            label='Email Address'
            name='email'
            register={register}
            validationErrors={apiErrors?.validationErrors}
            required
          />
          {/* Submit Button ----------------------------------------- */}
          <div className='flex flex-col items-center mt-9'>
            <Button disabled={false} isLoading={loading}>
              Send Invite
            </Button>
          </div>
        </form>
      </div >
    </>
  ) : (<Loading className='min-h-header' />)
}

export default LeadInvite
