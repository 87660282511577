import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ReactComponent as LoginSVG } from '@/assets/svg/login_background.svg'
import Button from '@/common/components/Button'
import { ROUTES } from '@/common/constants'
import { history } from '@/history'

/**
 * Logged Out page
 */
const LoggedOut = () => {
  const myFlocUser = useSelector(state => state.user.user)

  // redirect logged-in users to dashboard
  useEffect(() => {
    if (myFlocUser) history.push(ROUTES.DASHBOARD)
  }, [myFlocUser])

  return (
    <div className='main-container relative flex-col text-left'>
      <div className='center'>
        <LoginSVG width='100%' />
      </div>
      <h1 className='text-secondary mt-8 text-center'>You are currently logged out.</h1>
      <div className='text-center mt-8'>
        <Button onClick={() => history.push(ROUTES.LOGIN)}>Log In</Button>
      </div>
    </div>
  )
}

export default LoggedOut
