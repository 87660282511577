/**
 * Store for the currently logged in user
 */
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
  CARD_STATUS,
  ENDPOINTS,
  ENROLLMENT,
  HTTP_METHODS,
  MYFLOC_API_HOST_URL,
  ROLES,
  STATUSES,
} from '@/common/constants'
import { checkEnrollmentStepPassed } from '@/common/utils'

import { selectMyFlocPerson } from './user'

/**
 * Fetch wrapper to factor our common code between these Netspend thunks
 *
 * @param   {Object}          props
 * @param   {Function}        props.endpoint    the endpoint function
 * @param   {Function}        props.getState    the getState method to get state from thunk
 * @param   {string}          [props.method]    the HTTP method, defaults to get
 * @param   {boolean}         [props.useTeamId] if you want to use teamId instead of personId
 *
 * @returns {Promise<Object>}
 */
const executeApiCall = async ({ endpoint, getState, method = 'GET', useTeamId = false }) => {
  const state = getState()

  const myFlocPerson = selectMyFlocPerson(state)
  const auth0Token = localStorage.getItem('auth0Token') || null

  const resp = await fetch(
    `${MYFLOC_API_HOST_URL}/v1${endpoint(myFlocPerson[useTeamId ? 'teamId' : 'id'])}`,
    {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
        ...(state.netspend.session?.id ? { 'session-id': state.netspend.session.id } : {}),
      },
      method,
    }
  )
  return await resp.json()
}

const executeSession = async ({
  auth0Token,
  enrollmentFlow,
  init,
  myFlocPerson,
  session,
  userSessionInitializationData,
}) => {
  const sessionPath = checkEnrollmentStepPassed(enrollmentFlow, ENROLLMENT.NS_CREATED)
    ? ENDPOINTS.NETSPEND_PERSONS_SESSIONS(myFlocPerson.id)
    : ENDPOINTS.NETSPEND_SESSIONS

  const response = await fetch(`${MYFLOC_API_HOST_URL}/v1${sessionPath}`, {
    body: JSON.stringify({
      deviceData: init.deviceData,
      userSessionInitializationData,
    }),
    headers: {
      'Content-Type': 'application/json',
      ...(session?.id ? { 'session-id': session.id } : {}),
      ...(auth0Token ? { Authorization: `Bearer ${auth0Token}` } : {}),
    },
    method: HTTP_METHODS.POST,
  })

  if (response.status === 200) {
    const data = await response.json()
    await init.open(data.encrypted_data)
    return {
      data,
      response,
    }
  }
  else {
    return {
      data: { error: { message: response.statusText } },
      response,
    }
  }
}

export const createCard = createAsyncThunk(
  'netspend/createCard',
  /**
   * @@ Creates netspend card if doesn't exist while performing checks
   *
   * @returns {Promise<{activePersonStatus, cards, error}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const myFlocPerson = selectMyFlocPerson(state)
    const activePersonStatus = state.user.activePersonStatus

    if (!myFlocPerson || !activePersonStatus) return

    // Ensure we only continue on if we have a netspend account created and kyc approved for a non-friend
    if (
      (activePersonStatus.enrollmentFlow !== ENROLLMENT.NS_CREATED &&
        myFlocPerson.cardStatus !== CARD_STATUS.NO_CARD) ||
      activePersonStatus.nsPersonStatusKyc !== STATUSES.KYC_APPROVED ||
      myFlocPerson.role === ROLES.FRIEND
    ) {
      return
    }

    return await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_PERSONS_CARD,
      getState,
      method: HTTP_METHODS.POST,
    })
  }
)

export const createSession = createAsyncThunk(
  'netspend/createSession',
  /**
   * @@ Creates initial Netspend session
   *
   */
  async (_params, { getState }) => {
    const nsSession = window.NetspendSDK.session

    const state = getState()
    const auth0Token = localStorage.getItem('auth0Token') || null
    const enrollmentFlow = state.user.activePersonStatus?.enrollmentFlow
    const myFlocPerson = selectMyFlocPerson(state)
    const session = state.netspend.session

    const response = await fetch(`${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NETSPEND_INITIALIZATIONS}`)
    const result = await response.json()
    const userSessionInitializationData = result.user_session_initialization_data

    // Close existing session if ready
    const nsState = await nsSession.getState()
    if (nsState === 'ready') {
      await nsSession.close()
    }

    const init = await nsSession.initialize(userSessionInitializationData)
    return await executeSession({
      auth0Token,
      enrollmentFlow,
      init,
      myFlocPerson,
      session,
      userSessionInitializationData,
    })
  }
)

export const getSession = createAsyncThunk(
  'netspend/getSession',
  /**
   * Refresh netspend session
   *
   * @returns {Promise<{accounts, error}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const auth0Token = localStorage.getItem('auth0Token') || null
    const nsSession = state.netspend.session

    const resp = await fetch(`${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NETSPEND_SESSIONS}`, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
        ...(nsSession?.id ? { 'session-id': nsSession.id } : {}),
      },
      method: HTTP_METHODS.GET,
    })
    return await resp.json()
  }
)

export const deleteSession = createAsyncThunk(
  'netspend/deleteSession',
  /**
   * Delete netspend session
   *
   * @returns {Promise<{isDeleteSession: boolean}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const auth0Token = localStorage.getItem('auth0Token') || null
    if (
      typeof state === 'object' && 'netspend' in state &&
      typeof state.netspend === 'object' && 'session' in state.netspend &&
      typeof state.netspend.session === 'object' && 'id' in state.netspend.session &&
      typeof state.netspend.session.id === 'string' && state.netspend.session.id
    ) {
      const nsSession = state.netspend.session.id
      const resp = await fetch(`${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NETSPEND_SESSIONS}`, {
        headers: {
          Authorization: `Bearer ${auth0Token}`,
          'session-id': nsSession,
        },
        method: HTTP_METHODS.DELETE,
      })
      return {
        isDeleteSession: resp.status === 204,
      }
    }
    return { isDeleteSession: true }
  }
)

export const getAccounts = createAsyncThunk(
  'netspend/getAccounts',
  /**
   * Get bank info for display purposes
   *
   * @returns {Promise<{accounts, error}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({ endpoint: ENDPOINTS.NETSPEND_ACCOUNTS_EXTERNAL_BANKS, getState })
)

export const getTeamAccounts = createAsyncThunk(
  'netspend/getTeamAccounts',
  /**
   * Get the team's accounts
   *
   * @returns {Promise<{error, teamAccounts}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_TEAMS_ACCOUNTS,
      getState,
      useTeamId: true,
    })
)

export const getCardAch = createAsyncThunk(
  'netspend/getCardAch',
  /**
   * Get the CardACH if we land on Step 2 directly
   *
   * @returns {Promise<{cardACH, error}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({ endpoint: ENDPOINTS.NETSPEND_ACCOUNTS_ACH, getState })
)

export const getCards = createAsyncThunk(
  'netspend/getCards',
  /**
   * Get the persons card number, expiry etc
   *
   * @returns {Promise<{error, cards}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_PERSONS_CARD,
      getState,
    })
)

export const getKycQuestions = createAsyncThunk(
  'netspend/getKycQuestions',
  /**
   * Get the KYC Questions in a new session
   *
   * @returns {Promise<{error, encryptedData}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({ endpoint: ENDPOINTS.NETSPEND_KYC, getState })
)

export const getPerson = createAsyncThunk(
  'netspend/getPerson',
  /**
   * Get the netspend person
   *
   * @returns {Promise<{error, person}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_PERSONS_ID,
      getState,
    })
)

export const getPersonBalance = createAsyncThunk(
  'netspend/getPersonBalance',
  /**
   * Get the netspend person's balance
   *
   * @returns {Promise<{error, balance}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_PERSONS_BALANCE,
      getState,
    })
)

export const getTeamBalances = createAsyncThunk(
  'netspend/getTeamBalances',
  /**
   * Get the team's balances balance
   *
   * @returns {Promise<{error, teamBalances}>}
   */
  async (_params, { getState }) =>
    await executeApiCall({
      endpoint: ENDPOINTS.NETSPEND_TEAMS_BALANCES,
      getState,
      useTeamId: true,
    })
)

const createThunkError = (error, reduxAction) => {
  if (!error) throw new Error('error (object or string) is required to create thunk error')

  let errorObject
  if (typeof error === 'string') errorObject = new Error(error)
  else errorObject = error

  if (reduxAction && typeof reduxAction === 'string') error.reduxAction = reduxAction

  return errorObject
}

const initialState = {
  accounts: null,
  accountsLoading: false,
  cardAch: null,
  cards: null,
  error: null,
  kycQuestions: null,
  pdfs: null,
  person: null,
  personBalance: null,
  session: null,
  sessionLoaded: false,
  teamAccounts: null,
  teamBalances: null,
}

const netspendSlice = createSlice({
  initialState,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  extraReducers: builder => {
    // @@ Create Session
    builder.addCase(createSession.fulfilled, (state, { payload: { data, response }, type }) => {
      if (response.status === 200) {
        state.session = data
      }
      else {
        state.error = createThunkError(new Error(data.error.message), type)
      }
      state.sessionLoaded = true
    })
    builder.addCase(createSession.rejected, (state, { error, type }) => {
      state.error = createThunkError(error, type)
      state.sessionLoaded = true
    })

    // Get Accounts
    builder.addCase(getAccounts.fulfilled, (state, { payload, type }) => {
      if (payload.error) {
        state.error = createThunkError(new Error(payload.error.message), type)
      }
      else {
        state.accounts = payload.accounts
      }
      state.accountsLoading = false
    })
    builder.addCase(getAccounts.rejected, (state, { error, type }) => {
      state.error = createThunkError(error, type)
      state.accountsLoading = false
    })
    builder.addCase(getAccounts.pending, state => {
      state.accountsLoading = true
    })

    // Get KYC Questions
    builder.addCase(getKycQuestions.fulfilled, (state, { payload, type }) => {
      if (payload.error) {
        state.error = createThunkError(payload.error, type)
      }
      else {
        state.kycQuestions = payload.encryptedData
      }
    })
    builder.addCase(getKycQuestions.rejected, (state, { error, type }) => {
      state.error = createThunkError(error, type)
    })

    // Get Person
    builder.addCase(getPerson.fulfilled, (state, { payload, type }) => {
      if (payload.error) {
        state.error = createThunkError(new Error(payload.error.message), type)
      }
      else {
        state.person = payload
      }
    })
    builder.addCase(getPerson.rejected, (state, { error, type }) => {
      state.error = createThunkError(error, type)
    })

    // Get Session
    builder.addCase(getSession.fulfilled, (state, { payload, type }) => {
      if (payload.error) {
        state.error = createThunkError(payload.error, type)
      }
      else {
        state.session = payload
      }
    })

    // Delete Session
    builder.addCase(deleteSession.fulfilled, (state, { payload, type }) => {
      if (payload.error) {
        state.error = createThunkError(payload.error, type)
      }
      else {
        state.session = null
      }
    })

    // All card and account fulfilled
    builder.addMatcher(
      isAnyOf(
        createCard.fulfilled,
        getCardAch.fulfilled,
        getCards.fulfilled,
        getPersonBalance.fulfilled,
        getSession.fulfilled,
        deleteSession.fulfilled,
        getTeamAccounts.fulfilled,
        getTeamBalances.fulfilled
      ),
      (state, { payload, type }) => {
        if (!payload) return
        if (payload.error) {
          state.error = createThunkError(new Error(payload.error.message), type)
        }
        else if (payload.cardACH) {
          state.cardAch = payload.cardACH
        }
        else if (payload.cards) {
          state.cards = payload.cards
        }
        else if (payload.accounts) {
          state.teamAccounts = payload.accounts
        }
        else if (payload.sessionId) {
          state.session.id = payload.sessionId
        }
        else if (payload.teamBalances) {
          state.teamBalances = payload.teamBalances
        }
        else if (payload.isDeleteSession) {
          state.session = null
        }
        else {
          state.personBalance = payload
        }
      }
    )

    // All card and account errors
    builder.addMatcher(
      isAnyOf(
        createCard.rejected,
        getCardAch.rejected,
        getCards.rejected,
        getPersonBalance.rejected,
        getSession.rejected,
        deleteSession.rejected,
        getTeamBalances.rejected,
        getTeamAccounts.rejected
      ),
      (state, { error, type }) => {
        state.error = createThunkError(error, type)
      }
    )
  },
  name: 'netspend',
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearSession: state => {
      state = initialState
    },
    setAccounts: (state, { payload }) => {
      state.accounts = payload
    },
    setCardAch: (state, { payload }) => {
      state.cardAch = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setKycQuestions: (state, { payload }) => {
      state.kycQuestions = payload
    },
    setPerson: (state, { payload }) => {
      state.person = payload
    },
    setSession: (state, { payload }) => {
      state.session = payload.session
    },
  },
})

export const {
  clearError,
  clearSession,
  setAccounts,
  setCardAch,
  setError,
  setKycQuestions,
  setPerson,
  setSession,
} = netspendSlice.actions
export default netspendSlice.reducer
