import React, { useEffect, useState } from 'react'

import { useIdleTimer } from 'react-idle-timer'

import { FOURTEEN_MINUTES } from '@/common/constants'
import { useAuthentication } from '@/common/hooks'
import Modal, { MODAL_TYPES } from '@components/Modal'

/**
 * A wrapper for the idle timer component
 *
 * Waits 14 minutes and shows a dialog. Then starts a 1 minute timer to logout
 *
 * @returns {JSX.Element}
 */
export const IdleTimerWrapper = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [seconds, setSeconds] = useState(60)
  const { logout } = useAuthentication()

  // Time delta is for when the tab is sleeping and you come back, ex ios safari tab
  const handleOnIdle = () => {
    const timeDelta = new Date()
      .getTime() - getLastActiveTime()
    if (timeDelta > FOURTEEN_MINUTES) {
      logout()
    }
    else {
      setSeconds(60)
      setModalOpen(true)
    }
  }

  const { getLastActiveTime } = useIdleTimer({
    onIdle: handleOnIdle,
    throttle: FOURTEEN_MINUTES,
    timeout: FOURTEEN_MINUTES,
  })

  // Create another timer for the logout
  // this one needs to tick to update the modal with time remaining
  useEffect(() => {
    if (!modalOpen) return

    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    }
    else {
      logout()
    }
  })

  return (
    <>
      <Modal
        cancelCallback={logout}
        cancelText='Sign out'
        open={modalOpen}
        setOpen={() => setModalOpen(!modalOpen)}
        successText='Stay signed in'
        title='Your session is about to end'
        type={MODAL_TYPES.confirm}
      >
        <p>
          You have been inactive for a while. For your security, we will automatically sign you out
          in {seconds} seconds.
        </p>
        <p>You may choose to "Stay signed in" to continue or sign out if you are done.</p>
      </Modal>
      {children}
    </>
  )
}
export default IdleTimerWrapper
