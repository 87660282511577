import { ENROLLMENT_ORDERED, ENV_OPTIONS } from '@/common/constants'

/**
 * A collection of helpful functions
 */

/**
 * Check if an Object is empty
 *
 * @param {Object}  obj       The object to check
 * @param {Boolean} allowNull if you want to allow null values, true by default
 */
export const isEmpty = (obj = {}, allowNull = true) =>
  (allowNull && obj === null) || (!Object.entries(obj).length && obj.constructor === Object)

/**
 * Removes empty strings or null parameters from an object (including nested objects)
 *
 * @param   {Object} obj
 * @returns {Object}
 */
export const removeEmptyNested = (obj = {}) =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null && v !== '' && !isEmpty(v) && Object.keys(obj).length > 0)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyNested(v) : v])
  )

/**
 * Simple function to check if any required form validation errors exist
 *
 * @param {Object} obj the object to check
 */
export const isRequired = obj => {
  return !!Object.entries(obj)
    .find(param => param[1]?.type === 'required')
}

/**
 * Wrapper function to only log in local
 *
 * @param {...any} args
 */
export const log = (...args) => {
  if (import.meta.env.VITE_ENV === ENV_OPTIONS.local) {
    console.info(...args)
  }
}

/**
 * Takes an object and a string of properties separated by dots and then traverses said object
 * https://gist.github.com/jasonrhodes/2321581#gistcomment-1813156
 *
 * @param {Object} obj  The object to traverse
 * @param {String} path The dot separated string
 */
export const traverseObject = (obj, path) =>
  path?.split('.')
    .reduce((acc, part) => acc && acc[part], obj)

/**
 * Capitalize first letter of the string
 *
 * @param   {string} word
 * @returns {string}
 */
export const capitalize = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join('')

/**
 * Get the number of days in a given month
 *
 * @param   {Date}   date date in month of get number of days
 * @returns {number}      number of days in the month
 */
export const daysInMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0)
  .getDate()

/**
 * Method for destructing the register function to include onChange
 * we handle register manually to ensure onChange doesn't get overwritten
 *
 * @param   {String}                    name           Name of the input
 * @param   {Function}                  register       The register function from RHF
 * @param   {Boolean}                   [required]     Whether or not its required
 * @param   {Function}                  [onChangeProp] The onChange function
 * @returns {{ onBlur, onChange, ref }}                { onRegisterBlur, registerRef, onChange }
 *
 */
export const registerWithChange = (name, register, required, onChangeProp) => {
  let onRegisterBlur
  let onRegisterChange
  let registerRef
  if (register) {
    const { onBlur, onChange, ref } = register(name, { required })
    onRegisterBlur = onBlur
    onRegisterChange = onChange
    registerRef = ref
  }

  // Clean up string booleans
  const onChange = async e => {
    onChangeProp?.(e)
    await onRegisterChange?.(e)
  }

  return { onBlur: onRegisterBlur, onChange, ref: registerRef }
}

/**
 * Check that a user has passed a required enrollment step
 *
 * @param   {string}  currentStep  to check
 * @param   {string}  requiredStep required to be completed
 * @returns {boolean}              whether the required step has been completed
 */
export const checkEnrollmentStepPassed = (currentStep, requiredStep) => {
  const currentIdx = ENROLLMENT_ORDERED.findIndex(step => step === currentStep)
  const requiredIdx = ENROLLMENT_ORDERED.findIndex(step => step === requiredStep)

  return currentIdx >= requiredIdx && requiredIdx >= 0
}

/**
 * Ordinalize number
 */
export const ordinalize = num =>
  num + ([, 'st', 'nd', 'rd'][(num / 10) % 10 ^ 1 && num % 10] || 'th')

/**
 * Weekdays for converting back to string
 */
export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
