import React from 'react'

import Button from '@/common/components/Button'
import { EXTERNAL_URLS } from '@/common/constants'

export const FAQ = () => {
  return (
    <>
      <div className='px-5 pt-12 pb-12 md:p-0 bg-light-blue'>
        <div className='container mx-auto max-w-7xl md:px-10 md:pt-12 md:pb-16'>
          <div className='max-w-5xl'>
            <h4>Frequently Asked Questions</h4>
            <h3 className='mt-6'>When will funds be moved?</h3>
            <ul className='list-disc list-inside'>
              <li>
                Scheduled money movements will take place on the morning of the day you selected.
              </li>
              <li>One-time requests are processed immediately.</li>
            </ul>
            <h3 className='mt-6'>
              Are there limits on money movements between the Primary Card Account and the myFloc
              Team Card Accounts?
            </h3>
            <ul className='list-disc list-inside'>
              <li>There are no limits on the number of times you can move money.</li>
              <li>
                All money movements must be between the primary account and a myFloc Team Member.
                Money can not be moved between myFloc Team Members.
              </li>
              <li>The maximum balance for a myFloc Team Member Card Account is $2,500.</li>
            </ul>
            <h3 className='mt-6'>
              What happens if there is not enough money in the Card Account to cover the request?
            </h3>
            <ul className='list-disc list-inside'>
              <li>
                In the event the Card Account balance cannot cover the full requested amount, an
                error message will be displayed and the money movement will not be completed.
              </li>
            </ul>

            <Button
              className='mt-10 flat-left'
              href={EXTERNAL_URLS.FAQ.BASE}
              target='_blank'
              arrow
              primary
              text
            >
              View all FAQs
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
