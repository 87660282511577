import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import styling from './stepper.module.scss'

export const RobitStepper = ({ className, currentStep = 0, large, steps, subtitle, title }) => {
  const containerClasses = classNames('flex-column', className)

  const rows = steps.map((step, index) => {
    const outerRowClass = classNames(
      large && styling.large,
      index + 1 === currentStep && styling.current,
      currentStep > index + 1 && styling.complete
    )
    const innerRowClass = classNames(
      typeof steps[index].handleClick === 'function' && 'cursor-pointer',
      'flex',
      'flex-row'
    )

    return (
      <div className={outerRowClass} key={'stepperRow' + index}>
        <div className={innerRowClass} onClick={step.handleClick}>
          <div className='items-center mt-[0.2rem] md:mt-0'>
            <div className={styling.circle}>{index + 1}</div>
            <div className='flex-grow mb-2 md:mb-2' />
            {index < steps.length - 1 && (
              <div className={styling['separator-container']}>
                <div className={styling.separator} />
              </div>
            )}
            <div className='flex-grow mb-2 md:mb-2' />
          </div>
          <div className='flex-column'>
            <div className='text-lg font-medium leading-5 text-secondary'>Step {index + 1}</div>
            <div className='text-xl font-medium leading-6'>{step.text}</div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={containerClasses}>
      {title && <div className='mb-5 font-bold text-1-5xl text-secondary'>{title}</div>}
      {rows}
      {subtitle && <div className='mt-10 text-lg font-medium text-primary md:mt-12'>{subtitle}</div>}
    </div>
  )
}

RobitStepper.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The current step 1 indexed, the lower ones will be checked
   */
  currentStep: PropTypes.number,
  /**
   * Bigger spacing between
   */
  large: PropTypes.bool,

  /**
   * Need an array of steps
   */
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Lower subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Upper title
   */
  title: PropTypes.string,
}

export default RobitStepper
