import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Button from '@/common/components/Button'
import { FREQUENCY_TYPES } from '@/common/constants'
import {
  capitalize, currency, ordinalize, weekdays,
} from '@/common/utils'

/**
 * Scheduled Money Movment Card
 *
 * @param   {Object}      props
 * @param   {Object}      props.movement     The scheduled money movement
 * @param   {function}    props.onEdit       Edit scheduled movement
 * @param   {Object}      props.isEditing    Is this scheduled movement being edited?
 * @param   {function}    props.onCancelEdit Stop editing scheduled movement
 * @param   {function}    props.onRemove     Remove function
 * @returns {JSX.Element}
 */
export const ScheduledMoneyMovement = ({ isEditing, movement, onCancelEdit, onEdit, onRemove }) => {
  const team = useSelector(state => state.team.team)
  const person = team.find(member => member.id === movement.memberId)

  let dayInfo = capitalize(movement.period)
  if (movement.period === FREQUENCY_TYPES.WEEKLY) {
    dayInfo += ` (${weekdays[movement.day]})`
  }
  else {
    dayInfo += ` (${ordinalize(movement.day)} of the month)`
  }

  const nextTransStr = movement.nextTransaction.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })

  return (
    <div className={`mt-6 rounded-[10px] ${isEditing ? 'border-2 border-secondary p-[19px]' : 'border border-gray-500 p-[20px]'}`}>
      <div className='flex flex-row justify-between font-medium'>
        <div>
          Move to {person.firstName} {person.lastName}
        </div>
        <div>{currency(movement.amount / 100, true)}</div>
      </div>

      <div className='flex flex-col justify-between sm:flex-row'>
        <div>{dayInfo}</div>
        <div>
          <span className='block mt-2 font-medium sm:inline sm:mt-0 sm:font-normal'>
            Next transaction:{' '}
          </span>
          <span>{nextTransStr}</span>
        </div>
      </div>

      <div className='flex flex-row justify-end mt-4'>
        {
          !isEditing
            ? <Button onClick={onRemove} primary text>
              Remove
            </Button>
            : null
        }
        <Button className='ml-4 flat-right' onClick={() => isEditing ? onCancelEdit?.(movement) : onEdit?.(movement)} primary text>
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </div>
    </div>
  )
}

ScheduledMoneyMovement.propTypes = {
  isEditing: PropTypes.bool,
  movement: PropTypes.object,
  onCancelEdit: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}
