import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  ADMIN_MINIMUM_AGE, DOB_VERIFY, EXTERNAL_URLS, PDFS, PHONE_INFO, SSN_VERIFY,
} from '@/common/constants'
import {
  useFormFieldTracking, useParseApiErrors, usePassword, useSubmitForm,
} from '@/common/hooks'
import { isEmpty } from '@/common/utils'
import { addressSchema, joinInsiderSchema } from '@/common/validations'
import AnimateIn from '@components/AnimateIn'
import Button, { SIGN_TYPES } from '@components/Button'
import FormAddress from '@components/FormAddress'
import FormCheckbox from '@components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'
import FormPassword from '@components/FormPassword'
import HiddenPanel from '@components/HiddenPanel'

const InsiderEnrollmentForm = ({
  apiErrors = null,
  loadPasswordField = true,
  loadShippingFields = false,
  onSubmit,
  prefill = {},
  loadPhoneNumber = true,
  teamName = 'myFloc',
}) => {
  if (!onSubmit || typeof onSubmit !== 'function') {
    throw new Error('form submission handler must be a function')
  }

  const { passwordErrors, passwordSchema, validatePassword } = usePassword()
  const { passwordErrors: password2Errors, validatePassword: validatePassword2 } = usePassword()
  const { showValidationToast } = useSubmitForm()

  const [showPhoneInfo, setShowPhoneInfo] = useState(false)
  const [showBdayInfo, setShowBdayInfo] = useState(false)
  const [showSSNInfo, setShowSSNInfo] = useState(false)
  const [showDisplayName, setDisplayName] = useState(false)
  const [shippingSchema, setShippingSchema] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const doOnSubmit = async formFields => {
    if (isSubmitting) return

    setIsSubmitting(true)

    try {
      await onSubmit(formFields)
    }
    catch (submitError) {
      console.error('error submitting form:', submitError)
    }

    setIsSubmitting(false)
  }

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      joinInsiderSchema
        .concat(loadPasswordField ? passwordSchema : null)
        .concat(loadShippingFields ? shippingSchema : null)
    ),
  })
  const { reset: resetFieldTracking, updatedFields: fieldsUpdatedSinceSubmit } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: fieldsUpdatedSinceSubmit })

  // Conditionally require shipping address
  const mailToResidence = watch('mailToResidence', true)
  useEffect(() => {
    if (!loadShippingFields || mailToResidence) {
      delete errors.shippingAddress
      setShippingSchema(null)
    }
    else {
      setShippingSchema(
        yup.object({
          shippingAddress: addressSchema,
        })
      )
    }
  }, [mailToResidence, loadShippingFields, errors])

  // prefill form fields
  useEffect(() => {
    // prefill non-nested fields
    Object.keys(prefill)
      .forEach(fieldName => {
        if (typeof prefill[fieldName] !== 'object' && prefill[fieldName] !== null) {
          setValue(fieldName, prefill[fieldName])
        }
      })

    // prefill residence address
    if (prefill.residenceAddress) {
      Object.keys(prefill.residenceAddress)
        .forEach(fieldName => {
          if (
            typeof prefill.residenceAddress[fieldName] !== 'object' &&
          prefill.residenceAddress[fieldName] !== null
          ) {
            setValue(`residenceAddress.${fieldName}`, prefill.residenceAddress[fieldName])
          }
        })
    }

    // prefill shipping address if allowed
    if (loadShippingFields && prefill.shippingAddress) {
      Object.keys(prefill.shippingAddress)
        .forEach(fieldName => {
          if (
            typeof prefill.shippingAddress[fieldName] !== 'object' &&
          prefill.shippingAddress[fieldName] !== null
          ) {
            setValue(`shippingAddress.${fieldName}`, prefill.shippingAddress[fieldName])
          }
        })
    }
  }, [prefill])

  // reset updated fields list on form submission
  useEffect(() => {
    if (isSubmitting && fieldsUpdatedSinceSubmit.length > 0) resetFieldTracking()
  }, [isSubmitting, fieldsUpdatedSinceSubmit, resetFieldTracking])

  return (
    <form
      onSubmit={handleSubmit(doOnSubmit, showValidationToast)}
    >
      <div className='text-sm'>*Required field</div>
      <div className='mt-1 text-lg'>Please enter the following information to join {teamName}&#185;</div>

      {/* Personal Info */}

      <div className='flex flex-row justify-between mt-6'>
        <FormInput
          autoComplete='given-name'
          errors={errors}
          hintContent='Use full legal name.'
          label='First Name'
          name='firstName'
          register={register}
          validationErrors={apiValidationErrors}
          required
        />
        <div className='mx-2' />
        <FormInput
          autoComplete='middle-name'
          errors={errors}
          label='Middle (optional)'
          name='middleName'
          register={register}
          validationErrors={apiValidationErrors}
        />
      </div>
      <FormInput
        autoComplete='family-name'
        errors={errors}
        label='Last Name'
        name='lastName'
        register={register}
        validationErrors={apiValidationErrors}
        required
      />
      <AnimateIn maxHeight={121} show={showDisplayName}>
        <FormInput
          errors={errors}
          label='Preferred First Name (Optional)'
          name='displayName'
          register={register}
          validationErrors={apiValidationErrors}
        />
      </AnimateIn>

      <Button
        className='mb-7 flat-left'
        onClick={() => setDisplayName(!showDisplayName)}
        sign={showDisplayName ? SIGN_TYPES.minus : SIGN_TYPES.plus}
        type='button'
        primary
        text
      >
        {showDisplayName ? 'Hide Preferred First Name' : 'Add Preferred First Name (Optional)'}
      </Button>

      <FormAddress
        apiErrors={apiErrors}
        errors={errors}
        name='residenceAddress'
        register={register}
      />

      {loadShippingFields ? (
        <FormCheckbox
          checked={mailToResidence}
          className='mb-0'
          id='mailToResidence'
          name='mailToResidence'
          register={register}
          title='Mail my card to this address'
          titleClass='mt-1.5'
          plain
        />
      ) : null}

      <div className='w-full border-t' />

      {/* Animate in this section based on the checkbox above */}
      {loadShippingFields ? (
        <AnimateIn maxHeight={740} show={!mailToResidence}>
          <h4 className='mt-9 mb-8'>Where should we mail your myFloc card?</h4>
          <FormAddress
            apiErrors={apiErrors}
            errors={errors}
            name='shippingAddress'
            register={register}
          />
          <div className='w-full border-t' />
        </AnimateIn>
      ) : null}

      <FormInput
        autoComplete='email'
        className='my-7'
        hintContent='You will use this email address when logging in to myFloc. Email address may be updated from My Profile.'
        label='Email Address'
        name='email'
        register={register}
        type={FORM_INPUT_TYPES.email}
        disabled
        required
      />

      {loadPhoneNumber ? (
        <>
          <FormInput
            autoComplete='tel'
            errors={errors}
            inputClass='w-60'
            label='Cell Phone Number (optional)'
            labelButtonAction={() => setShowPhoneInfo(true)}
            labelButtonText='Why Provide This?'
            name='mobile'
            register={register}
            type={FORM_INPUT_TYPES.phone}
            validationErrors={apiValidationErrors}
          />

          <HiddenPanel setShow={setShowPhoneInfo} show={showPhoneInfo}>
            {PHONE_INFO}
          </HiddenPanel>

          <FormCheckbox
            className='mb-0'
            id='smsConsent'
            name='smsConsent'
            register={register}
            subtitle='Phone provider message and data rates may apply.'
            title='Allow Text Message Notifications'
            plain
          />
        </>
      ) : null}

      <FormInput
        errors={errors}
        hintContent='Must be at least 18 years old.'
        inputClass='w-60'
        label='Date of Birth (MM/DD/YYYY)'
        labelButtonAction={() => setShowBdayInfo(true)}
        labelButtonText='Why Do We Need This?'
        name='dob'
        register={register}
        type={FORM_INPUT_TYPES.date}
        validationErrors={apiValidationErrors}
        required
      />

      <HiddenPanel setShow={setShowBdayInfo} show={showBdayInfo}>
        {DOB_VERIFY} {ADMIN_MINIMUM_AGE}
      </HiddenPanel>

      <FormInput
        errors={errors}
        inputClass='w-60'
        label='Social Security Number'
        labelButtonAction={() => setShowSSNInfo(true)}
        labelButtonText='Why Do We Need This?'
        name='social'
        register={register}
        type={FORM_INPUT_TYPES.social}
        validationErrors={apiValidationErrors}
        required
      />

      <HiddenPanel setShow={setShowSSNInfo} show={showSSNInfo}>
        {SSN_VERIFY}
      </HiddenPanel>

      {/* Password + Validation */}
      {loadPasswordField ? (
        <>
          <FormPassword
            apiErrors={apiErrors}
            errors={errors}
            passwordErrors={passwordErrors}
            register={register}
            validatePassword={validatePassword}
          />
          <FormPassword
            apiErrors={apiErrors}
            className='mt-10'
            controlName='password2'
            errors={errors}
            passwordErrors={password2Errors}
            register={register}
            validatePassword={validatePassword2}
          />
        </>
      ) : null}

      <h3 className='mt-10 mb-8'>Please review and agree to the following before proceeding:*</h3>

      <FormCheckbox
        className='mb-8 min-h-0'
        errors={errors}
        id='myflocAgreements'
        name='myflocAgreements'
        register={register}
        validationErrors={apiValidationErrors}
        plain
      >
        <div className='text-base font-bold'>
          I agree to myFloc's{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            href={EXTERNAL_URLS.TERMS_AND_CONDITIONS}
            rel='noreferrer'
            target='_blank'
            primary
            text
          >
            Terms and Conditions
          </Button>{' '}
          and{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            href={EXTERNAL_URLS.PRIVACY_POLICY}
            rel='noreferrer'
            target='_blank'
            primary
            text
          >
            Privacy Policy
          </Button>
          .
        </div>
      </FormCheckbox>

      <FormCheckbox
        className='mb-8 min-h-0'
        errors={errors}
        id='issuingBankAgreements'
        name='issuingBankAgreements'
        register={register}
        validationErrors={apiValidationErrors}
        plain
      >
        <div className='text-base font-bold'>
          By checking this box, I confirm I have read and agree to the terms and conditions of the{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.CARDHOLDER_AGREEMENT, '_blank')}
            type='button'
            primary
            text
          >
            Cardholder Agreement
          </Button>{' '}
          and the issuing bank’s{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.BANK_PRIVACY_POLICY, '_blank')}
            type='button'
            primary
            text
          >
            Privacy Policy
          </Button>
          .
        </div>
      </FormCheckbox>

      <FormCheckbox
        className='mb-8 min-h-0'
        errors={errors}
        id='netspendAgreements'
        name='netspendAgreements'
        register={register}
        validationErrors={apiValidationErrors}
        plain
      >
        <div className='text-base font-bold'>
          By checking this box, I confirm I have read and agree to the terms of the{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.ESIGN_DISCLOSURE, '_blank')}
            type='button'
            primary
            text
          >
            Netspend ESIGN Disclosure
          </Button>
          ,{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.BANK_TRANSFER_TERMS_AND_CONDITIONS, '_blank')}
            type='button'
            primary
            text
          >
            Bank Transfer Terms & Conditions
          </Button>{' '}
          and{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.NETSPEND_PRIVACY_POLICY, '_blank')}
            type='button'
            primary
            text
          >
            Netspend Privacy Policy
          </Button>
          .
        </div>
      </FormCheckbox>

      <div className='flex flex-row justify-center items-center mt-9'>
        <Button
          disabled={
            !watch(['myflocAgreements', 'issuingBankAgreements', 'netspendAgreements'])
              .every(
                x => x
              ) ||
            !isEmpty(errors) ||
            !isEmpty(apiValidationErrors)
          }
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </form>
  )
}

InsiderEnrollmentForm.propTypes = {
  apiErrors: PropTypes.object,
  loadPasswordField: PropTypes.bool,
  loadPhoneNumber: PropTypes.bool,
  loadShippingFields: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  prefill: PropTypes.object,
  teamName: PropTypes.string,
  textMessageNotificationsField: PropTypes.bool,
}

export default InsiderEnrollmentForm
