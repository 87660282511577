import React from 'react'

import { useSelector } from 'react-redux'

import TransferReview from '@/common/components/TransferReview'
import { FORMS } from '@/redux/formSteps'

const StepFive = props => {
  const transferForm = useSelector(state => state.formSteps[FORMS.transferFunds])?.[1]

  return <TransferReview form={FORMS.transferFunds} payload={transferForm} stepText={false} />
}

export default StepFive
