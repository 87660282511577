import PropTypes from 'prop-types'

/**
 * Common proptypes used in the inner (robit) from components
 */
export const formPropTypes = {
  /**
   * The class name gets added to the bottom text: error, hint etc
   */
  bottomTextClassName: PropTypes.string,
  /**
   * The class name gets added to the container
   */
  className: PropTypes.string,
  /**
   * Whether or not the component is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Class names which get added to the Error text
   */
  errorClassName: PropTypes.string,
  /**
   * Text to display if the field is invalid.
   */
  errorText: PropTypes.string,
  /**
   * Text to display below the input as clues to the user
   */
  hintContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Class names which get added to the input
   */
  inputClassName: PropTypes.string,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * Optional label to display above the input
   */
  label: PropTypes.string,
  /**
   * The action for the explanation button
   */
  labelButtonAction: PropTypes.func,
  /**
   * The text for the explanation button
   */
  labelButtonText: PropTypes.string,
  /**
   * Adds classNames to the label
   */
  labelClassName: PropTypes.string,
  /**
   * Sets the name for the form hook
   */
  name: PropTypes.string,
  /**
   * The register function form react hook form
   */
  register: PropTypes.func,
  /**
   * Whether the field is required or not
   */
  required: PropTypes.bool,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
}
