import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  DOB_VERIFY,
  EXTERNAL_URLS,
  MINIMUM_AGE,
  PDFS,
  PHONE_INFO,
} from '@/common/constants'
import {
  useFormFieldTracking, useParseApiErrors, usePassword, useSubmitForm,
} from '@/common/hooks'
import { isEmpty } from '@/common/utils'
import { addressSchema, joinTeamMemberSchema } from '@/common/validations'
import AnimateIn from '@components/AnimateIn'
import Button, { SIGN_TYPES } from '@components/Button'
import FormAddress from '@components/FormAddress'
import FormCheckbox from '@components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'
import FormPassword from '@components/FormPassword'
import HiddenPanel from '@components/HiddenPanel'

const TeamMemberEnrollmentForm = ({
  apiErrors = null,
  loadPasswordField = true,
  loadPhoneNumberField = true,
  loadShippingFields = false,
  onSubmit,
  prefill = {},
  teamName = 'myFloc',
}) => {
  if (!onSubmit || typeof onSubmit !== 'function') throw new Error('form submission handler must be a function')

  const { passwordErrors, passwordSchema, validatePassword } = usePassword()
  const { passwordErrors: password2Errors, validatePassword: validatePassword2 } = usePassword()
  const { showValidationToast } = useSubmitForm()

  const [showDisplayName, setShowDisplayName] = useState(false)
  const [showPhoneInfo, setShowPhoneInfo] = useState(false)
  const [showBdayInfo, setShowBdayInfo] = useState(false)
  const [shippingSchema, setShippingSchema] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const doOnSubmit = async formFields => {
    if (isSubmitting) return

    setIsSubmitting(true)

    try {
      await onSubmit(formFields)
    }
    catch (submitError) {
      console.error('error submitting form:', submitError)
    }

    setIsSubmitting(false)
  }

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      joinTeamMemberSchema
        .concat(loadShippingFields ? shippingSchema : null)
        .concat(loadPasswordField ? passwordSchema : null)
    ),
  })
  const { reset: resetFieldTracking, updatedFields: fieldsUpdatedSinceSubmit } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: fieldsUpdatedSinceSubmit })

  const mailToResidence = watch('mailToResidence')

  // prefill form fields
  useEffect(() => {
    // prefill non-nested fields
    Object.keys(prefill)
      .forEach(fieldName => {
        if (typeof prefill[fieldName] !== 'object' && prefill[fieldName] !== null) setValue(fieldName, prefill[fieldName])
      })

    // prefill residence address
    if (prefill.residenceAddress) {
      Object.keys(prefill.residenceAddress)
        .forEach(fieldName => {
          if (typeof prefill.residenceAddress[fieldName] !== 'object' && prefill.residenceAddress[fieldName] !== null) {
            setValue(`residenceAddress.${fieldName}`, prefill.residenceAddress[fieldName])
          }
        })
    }

    // prefill shipping address if allowed
    if (loadShippingFields && prefill.shippingAddress) {
      Object.keys(prefill.shippingAddress)
        .forEach(fieldName => {
          if (typeof prefill.shippingAddress[fieldName] !== 'object' && prefill.shippingAddress[fieldName] !== null) {
            setValue(`shippingAddress.${fieldName}`, prefill.shippingAddress[fieldName])
          }
        })
    }
  }, [])

  // define shipping schema and update as needed
  useEffect(() => {
    if (!loadShippingFields) return

    if (mailToResidence) {
      delete errors.shippingAddress
      setShippingSchema(null)
    }
    else {
      setShippingSchema(
        yup.object({
          shippingAddress: addressSchema,
        })
      )
    }
  }, [loadShippingFields, mailToResidence, setShippingSchema, errors])

  // reset updated fields list on form submission
  useEffect(() => {
    if (isSubmitting && fieldsUpdatedSinceSubmit.length > 0) resetFieldTracking()
  }, [isSubmitting, fieldsUpdatedSinceSubmit, resetFieldTracking])

  return (
    <>
      <form
        onSubmit={
          handleSubmit(doOnSubmit, showValidationToast)
        }
      >
        <div className='text-sm'>*Required field</div>
        <div className='mt-1 text-lg'>Please enter the following information to join {teamName}</div>

        {/* Personal Info */}

        <div className='flex flex-row justify-between mt-6'>
          <FormInput
            autoComplete='given-name'
            errors={errors}
            label='First Name'
            name='firstName'
            register={register}
            validationErrors={apiValidationErrors}
            required
          />
          <div className='mx-2' />
          <FormInput
            autoComplete='family-name'
            errors={errors}
            label='Last Name'
            name='lastName'
            register={register}
            validationErrors={apiValidationErrors}
            required
          />
        </div>

        <AnimateIn maxHeight={121} show={showDisplayName}>
          <FormInput
            errors={errors}
            label='Preferred First Name (Optional)'
            name='displayName'
            register={register}
            validationErrors={apiValidationErrors}
          />
        </AnimateIn>

        <Button
          className='mb-7 flat-left'
          onClick={() => setShowDisplayName(!showDisplayName)}
          sign={showDisplayName ? SIGN_TYPES.minus : SIGN_TYPES.plus}
          type='button'
          primary
          text
        >
          {showDisplayName ? 'Hide Preferred First Name' : 'Add Preferred First Name (Optional)'}
        </Button>

        <FormAddress
          apiErrors={{ validationErrors: apiValidationErrors }}
          errors={errors}
          name='residenceAddress'
          register={register}
        />

        {
          loadShippingFields
            ? (
              <FormCheckbox
                checked={mailToResidence}
                className='mb-0'
                id='mailToResidence'
                name='mailToResidence'
                register={register}
                title='Mail my card to this address'
                titleClass='mt-1.5'
                plain
              />
            )
            : null
        }

        <div className='w-full border-t' />

        {/* Animate in this section based on the checkbox above */}
        {
          loadShippingFields
            ? (
              <AnimateIn maxHeight={740} show={!mailToResidence}>
                <h4 className='mt-9 mb-8'>Where should we mail your myFloc card?</h4>
                <FormAddress
                  apiErrors={{ validationErrors: apiValidationErrors }}
                  errors={errors}
                  name='shippingAddress'
                  register={register}
                />
                <div className='w-full border-t' />
              </AnimateIn>
            )
            : null
        }

        <FormInput
          autoComplete='email'
          className='my-3'
          hintContent='You will use this email address when logging in to myFloc. Email address may be updated from My Profile.'
          label='Email Address'
          labelClassName='mt-5'
          name='email'
          register={register}
          type={FORM_INPUT_TYPES.email}
          disabled
          required
        />

        {loadPhoneNumberField && (
          <>
            <FormInput
              autoComplete='tel'
              errors={errors}
              label='Cell Phone Number (optional)'
              labelButtonAction={() => setShowPhoneInfo(true)}
              labelButtonText='Why Provide This?'
              labelClassName='mt-5'
              name='mobile'
              register={register}
              type={FORM_INPUT_TYPES.phone}
              validationErrors={apiValidationErrors}
            />

            <HiddenPanel setShow={setShowPhoneInfo} show={showPhoneInfo}>
              {PHONE_INFO}
            </HiddenPanel>

            <FormCheckbox
              className='mb-0'
              id='smsConsent'
              name='smsConsent'
              register={register}
              subtitle='Phone provider message and data rates may apply.'
              title='Allow Text Message Notifications'
              plain
            />
          </>
        )}

        <FormInput
          errors={errors}
          hintContent='Must be at least 13 years old.'
          inputClass='w-60'
          label='Date of Birth (MM/DD/YYYY)'
          labelButtonAction={() => setShowBdayInfo(true)}
          labelButtonText='Why Do We Need This?'
          labelClassName='mt-3'
          name='dob'
          register={register}
          type={FORM_INPUT_TYPES.date}
          validationErrors={apiValidationErrors}
          required
        />

        <HiddenPanel setShow={setShowBdayInfo} show={showBdayInfo}>
          {DOB_VERIFY} {MINIMUM_AGE}
        </HiddenPanel>

        {/* Password + Validation */}

        <div className='mb-8'></div>
        {
          loadPasswordField
            ? <>
              <FormPassword
                apiErrors={{ validationErrors: apiValidationErrors }}
                errors={errors}
                passwordErrors={passwordErrors}
                register={register}
                validatePassword={validatePassword}
              />
              <FormPassword
                apiErrors={{ validationErrors: apiValidationErrors }}
                className='mt-10'
                controlName='password2'
                errors={errors}
                passwordErrors={password2Errors}
                register={register}
                validatePassword={validatePassword2}
              />
            </>
            : null
        }

        <div className='mt-10 w-full border-t' />

        {/* Agreements and Form Submit Btn */}

        <div className='mt-8 text-base font-bold'>
          By continuing your enrollment, you agree to:
        </div>
        <div className='mt-8 text-base font-bold'>
          myFloc{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(EXTERNAL_URLS.TERMS_AND_CONDITIONS, '_blank')}
            type='button'
            primary
            text
          >
            Terms and Conditions
          </Button>{' '}
          and{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(EXTERNAL_URLS.PRIVACY_POLICY, '_blank')}
            type='button'
            primary
            text
          >
            Privacy Policy
          </Button>
          .
        </div>
        <div className='mt-8 text-base font-bold'>
          Cardholders also agree to the terms and conditions of the Cardholder Agreement and the
          issuing bank’s Privacy Policy.{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.CARDHOLDER_AGREEMENT, '_blank')}
            type='button'
            primary
            text
          >
            Cardholder Agreement
          </Button>{' '}
          and{' '}
          <Button
            className='p-0 m-0 font-bold flat-x'
            onClick={() => window.open(PDFS.BANK_PRIVACY_POLICY, '_blank')}
            type='button'
            primary
            text
          >
            Privacy Policy
          </Button>
          .
        </div>

        <div className='flex flex-row justify-center items-center mt-9'>
          <Button
            disabled={!isEmpty(errors) || !isEmpty(apiValidationErrors)}
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </form>
    </>
  )
}

TeamMemberEnrollmentForm.propTypes = {
  apiErrors: PropTypes.object,
  loadPasswordField: PropTypes.bool,
  loadPhoneNumberField: PropTypes.bool,
  loadShippingFields: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  prefill: PropTypes.object,
  teamName: PropTypes.string,
}

export default TeamMemberEnrollmentForm
