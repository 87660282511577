import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  CUSTOM_ERROR_MESSAGES,
  NETSPEND_ERROR_CODES,
  NETSPEND_ERROR_MESSAGES,
  ROUTES,
} from '@/common/constants'
import { history } from '@/history'
import { clearError } from '@/redux/netspend'

/**
 * Hook for handling netspend error page routing
 *
 * @returns {{ netspendError }}
 */
export const useHandleNetspendError = () => {
  const dispatch = useDispatch()
  const netspendError = useSelector(state => state.netspend.error)

  useEffect(() => {
    if (!netspendError) return

    let redirectTo

    if (netspendError.reduxAction) {
      const [, action, result] = netspendError.reduxAction.split('/')
      switch (action) {
        case 'getCardAch':
        case 'getCards':
        case 'getPersonBalance':
        case 'getTeamBalances':
          if (result === 'fulfilled') {
            redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GET_CARD_ACH)
          }
          else redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC)
          break

        case 'getKycQuestions':
          if (result === 'rejected') {
            redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GET_KYC)
          }
          else {
            if (
              netspendError.code ===
              NETSPEND_ERROR_CODES.IDENTITY_VERIFICATION_QUESTIONS_NOT_AVAILABLE
            ) {
              redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GET_KYC_EXPIRED)
            }
            else {
              redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GET_KYC)
            }
          }
          break

        case 'createSession':
        case 'getSession':
          redirectTo = ROUTES.ERROR_CUSTOM(CUSTOM_ERROR_MESSAGES.GENERIC_HARD)
          break

        case 'getAccounts':
        case 'getPerson':
          redirectTo = ROUTES.ERROR_ENROLLMENT
          break
        default:
          redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC)
      }
    }
    else {
      redirectTo = ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC)
    }

    if (!history.location.pathname.startsWith('/error')) {
      history.push(redirectTo)
      dispatch(clearError())
    }
  }, [netspendError])

  return {
    netspendError,
  }
}
export default useHandleNetspendError
