import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import FormCheckbox from '@/common/components/FormCheckbox'
import {
  CARD_RECIPIENT_ADDRESS_ENTRY,
  ROLES,
  ROUTES,
  CARD_RESTRICTIONS_INFO,
  CARD_TRANSACTIONS_ACCESS_INFO,
  ENDPOINTS,
  SHOW_TRANSACTION_TYPES,
  YES_OR_NO,
  SPENDING_LIMIT_PERIOD,
} from '@/common/constants'
import { useSubmitForm } from '@/common/hooks'
import { isEmpty } from '@/common/utils'
import { memberInvitationSchema, memberRestrictionsFormSchema } from '@/common/validations'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import AnimateIn from '@components/AnimateIn'
import Button, { SIGN_TYPES } from '@components/Button'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'
import FormRadio from '@components/FormRadio'
import HiddenPanel from '@components/HiddenPanel'
import PageHeader from '@components/PageHeader'

/**
 * The Add Team Member Step One
 * First step of the place to create an Team Member for a myFloc team.
 */
const AddTeamMember = props => {
  const dispatch = useDispatch()

  // Get person object for app user
  const myFlocPerson = useSelector(selectMyFlocPerson)
  const myFlocUser = useSelector(state => state.user.user)

  // State vars to change the schema when on step two
  const [stepTwoSchema, setStepTwoSchema] = useState(null)

  // React Hook Form Setup
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(memberInvitationSchema.concat(stepTwoSchema)),
  })

  // Change the schema depending on step. Required to share schema with permissions page
  const [currentStep, setCurrentStep] = useState(0)
  useEffect(() => {
    if (currentStep === 0) {
      setStepTwoSchema(null)
    }
    else {
      setStepTwoSchema(memberRestrictionsFormSchema)
    }
  }, [currentStep])

  // API call handlers
  const { apiErrors, loading, showValidationToast, submitForm, setApiErrors } = useSubmitForm()

  // ---------------------------------------------------------------------------
  // Form Submission

  const STEP_ONE_KEYS = ['firstName', 'lastName', 'email', 'displayName', 'shipToMember']

  const onSubmit = async formEntry => {
    // For step one just move to step 2 after validation
    if (currentStep === 0) {
      setCurrentStep(1)
      return
    }

    const {
      restrictMerchants,
      locations,
      limitSpending,
      spendingLimitPeriod,
      spendingLimit,
      showAllTransactions,
    } = formEntry

    // resetFieldTracking()

    const stepOneData = {}
    STEP_ONE_KEYS.forEach(key => {
      stepOneData[key] = formEntry[key]
    })

    // Handle cardRestrictions formatting
    const isCardRestrictions =
      restrictMerchants === YES_OR_NO.YES || limitSpending === YES_OR_NO.YES

    const stepTwoData = {
      cardRestrictions: isCardRestrictions ? {} : null,
      notificationSettings: {
        showAllTransactions: showAllTransactions === SHOW_TRANSACTION_TYPES.ALL,
      },
    }

    if (isCardRestrictions) {
      stepTwoData.cardRestrictions.locations = {}
      for (const key in locations) {
        stepTwoData.cardRestrictions.locations[key] = !!locations[key]
      }

      stepTwoData.cardRestrictions.weeklySpend = null
      stepTwoData.cardRestrictions.monthlySpend = null

      if (limitSpending === YES_OR_NO.YES) {
        if (spendingLimitPeriod === SPENDING_LIMIT_PERIOD.WEEK) {
          stepTwoData.cardRestrictions.weeklySpend = Math.round(spendingLimit * 100)
        }

        if (spendingLimitPeriod === SPENDING_LIMIT_PERIOD.MONTH) {
          stepTwoData.cardRestrictions.monthlySpend = Math.round(spendingLimit * 100)
        }
      }
    }

    // Make request and handle response
    const inviteMemberRequest = await submitForm(ENDPOINTS.INVITE_MEMBER, {
      payload: {
        ...stepOneData,
        ...stepTwoData,
        inviter: `${myFlocUser.firstName} ${myFlocUser.lastName}`,
        teamId: myFlocPerson.teamId,
      },
      success: {
        toast: {
          subtitle: `You have invited ${stepOneData.firstName} ${stepOneData.lastName} (${stepOneData.email}) to join your team as a Member.`,
          title: 'Success',
        },
      },
    })

    // Successfully submitted return to dashboard
    if (inviteMemberRequest?.response.status < 400) {
      history.push(ROUTES.DASHBOARD)
    }
    else {
      // Else redirect for errors
      const inviteMemberApiError = inviteMemberRequest?.data?.error

      // Handle validation errors
      if (inviteMemberApiError?.validationErrors) {
        setApiErrors(null) // Clear API errors to allow resubmission

        for (const errorField in inviteMemberApiError.validationErrors) {
          // Move back to step one for API field errors in step one fields
          if (STEP_ONE_KEYS.includes(errorField)) {
            setCurrentStep(0)
            setError(errorField) // Explicity set form errors for each
          }
          else {
            // Handle step two errors
            const field = errorField.split('cardRestrictions.')[1]
            if (['weeklySpend', 'monthlySpend'].includes(field)) {
              setError('spendingLimit')
            }
          }
        }
        dispatch(
          addToast({
            subtitle: 'Please double-check your entries.',
            title: 'Could not Invite Team Member',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
  }

  // ---------------------------------------------------------------------------
  // Form State Handling

  // STEP ONE Form State
  const [showNickname, setShowNickname] = useState(false)
  const [showCardMailDetails, setShowCardMailDetails] = useState(false)
  const shipToMember = watch('shipToMember')

  // STEP TWO Form State
  const [showMerchantRestrictionInfo, setShowMerchantRestrictionInfo] = useState(false)
  const [showTransactionPanel, setShowTransactionPanel] = useState(false)

  // Track form values for effects and conditional renders
  const showAllTransactions = watch('notificationSettings.showAllTransactions')
  const limitSpending = watch('limitSpending')
  const spendingLimitPeriod = watch('spendingLimitPeriod')
  const restrictMerchants = watch('restrictMerchants')
  const locations = watch('locations')

  // Clear location error if one is selected or if restrictions are turned off
  useEffect(() => {
    if (!errors?.locations?.message || !restrictMerchants) return

    if (
      locations.dating ||
      locations.gambling ||
      locations.pawnshop ||
      restrictMerchants === YES_OR_NO.NO
    ) {
      clearErrors('locations')
    }
  }, [locations, restrictMerchants])

  // Clear spending limit errors if limits disabled
  useEffect(() => {
    if (errors?.spendingLimit || errors?.spendingLimitPeriod) {
      if (limitSpending === YES_OR_NO.NO) {
        setValue('spendingLimit', '') // Clear a bad value
        clearErrors('spendingLimit')
        clearErrors('spendingLimitPeriod')
      }
    }
  }, [limitSpending])

  // ---------------------------------------------------------------------------
  // Utils

  // Handle cancel form button
  const onCancel = () => {
    history.push(ROUTES.DASHBOARD)
  }

  // Ignore events for email validation
  const ignoreDomEvent = event => {
    event.preventDefault()
    return false
  }

  return (
    <>
      <PageHeader stepText={`Step ${currentStep + 1} of 2`}>Add a myFloc Team Member</PageHeader>

      {/* STEP ONE VIEW */}
      <div
        className={`main-container relative flex-col text-left ${
          currentStep === 0 ? '' : 'hidden'
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='text-sm'>*Required field</div>
          <div className='mt-1 text-lg'>
            Enter their details to send an invitation to join your team. Please use legal first and
            last name (note we do not conduct residency status checks).
          </div>

          <div className='flex flex-row justify-between mt-6'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </div>
          <AnimateIn maxHeight={200} show={showNickname}>
            <FormInput
              errors={errors}
              label='Nickname (Optional)'
              name='displayName'
              register={register}
              validationErrors={apiErrors?.validationErrors}
            />
          </AnimateIn>

          <Button
            className='mb-7 flat-left'
            onClick={() => setShowNickname(!showNickname)}
            sign={showNickname ? SIGN_TYPES.minus : SIGN_TYPES.plus}
            type='button'
            primary
            text
          >
            {showNickname ? 'Hide Nickname' : 'Add Nickname (Optional)'}
          </Button>

          <FormInput
            autoComplete='email'
            errors={errors}
            label='Email Address'
            name='email'
            register={register}
            type={FORM_INPUT_TYPES.email}
            validationErrors={apiErrors?.validationErrors}
            required
          />

          <FormInput
            autoComplete='off'
            errors={errors}
            label='Confirm E&#8203;mail Address' // using "email" in the label triggers autocomplete. using zero-width space to break it up.
            name='confirmEmeil' // using "email" in name field triggers autocomplete. using misspelled name as workaround
            onDrop={ignoreDomEvent}
            onPaste={ignoreDomEvent}
            register={register}
            required
          />

          <label className='mb-4'>
            Where should we mail their card?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowCardMailDetails(true)}
              tag='a'
              primary
              text
            >
              More Details
            </Button>
          </label>

          <HiddenPanel setShow={setShowCardMailDetails} show={showCardMailDetails}>
            {CARD_RECIPIENT_ADDRESS_ENTRY(ROLES.TEAM_MEMBER)}
          </HiddenPanel>

          <FormRadio
            defaultSelected={
              typeof shipToMember === 'string'
                ? JSON.parse(shipToMember)
                : [true, false].indexOf(shipToMember) > -1
                  ? shipToMember
                  : null
            }
            errors={errors}
            name='shipToMember'
            onChange={() => trigger('shipToMember')}
            options={[
              {
                label: `Send card to ${myFlocPerson?.role === ROLES.LEAD ? 'me' : 'Lead'}`,
                value: false,
              },
              { label: 'Send card to Member', value: true },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
          />

          <div className='flex flex-col items-center mt-9'>
            <Button disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}>
              Set Permissions
            </Button>
            <Button
              className='mt-6'
              onClick={() => {
                history.push(ROUTES.DASHBOARD)
              }}
              type='button'
              primary
              text
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
      {/* --------------
          STEP TWO VIEW
      */}
      <div
        className={`main-container relative flex-col text-left ${
          currentStep === 1 ? '' : 'hidden'
        }`}
      >
        <div className='text-sm'>*Required field</div>
        <div className='mt-1 text-lg'>
          Select desired permissions for this myFloc Team Member below.
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, errors =>
            showValidationToast(errors, { forceError: true })
          )}
        >
          <label className='mt-8 mb-2'>
            Do you want to prevent use of this card at certain types of businesses?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowMerchantRestrictionInfo(true)}
              tag='a'
              primary
              text
            >
              What Does This Restrict?
            </Button>
          </label>
          <HiddenPanel setShow={setShowMerchantRestrictionInfo} show={showMerchantRestrictionInfo}>
            {CARD_RESTRICTIONS_INFO}
          </HiddenPanel>
          <FormRadio
            className='min-h-0'
            defaultSelected={restrictMerchants}
            errors={errors}
            labelClassName='mt-0 flex-1'
            name='restrictMerchants'
            options={[
              { label: 'Yes', value: YES_OR_NO.YES },
              { label: 'No', value: YES_OR_NO.NO },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
            inline
          />
          <AnimateIn maxHeight={1000} show={restrictMerchants === YES_OR_NO.YES}>
            <label className='mt-8 mb-2'>
              DO NOT allow spending at the following types of businesses:
            </label>
            <FormCheckbox
              className='mb-4 min-h-0'
              id='restrictGambling'
              name='locations.gambling'
              register={register}
            >
              <div className='mt-2'>Gambling</div>
            </FormCheckbox>
            <FormCheckbox
              className='mb-4 min-h-0'
              id='restrictDating'
              name='locations.dating'
              register={register}
            >
              <div className='mt-2'>Dating / Escort Service</div>
            </FormCheckbox>
            <FormCheckbox
              className='mb-1 min-h-0'
              id='restrictPawnshop'
              name='locations.pawnshop'
              register={register}
            >
              <div className='mt-2'>Pawn shops</div>
            </FormCheckbox>
            {errors?.locations?.message ? (
              <div className='mt-5 form-control-descenders'>
                <div className='form-control-error'>{errors.locations.message}</div>
              </div>
            ) : null}
          </AnimateIn>
          <div className='mt-12 w-full border-t' />
          <label className='mt-8 mb-2'>
            What information should this myFloc Team Member be able to view?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowTransactionPanel(true)}
              tag='a'
              primary
              text
            >
              What is This?
            </Button>
          </label>
          <HiddenPanel setShow={setShowTransactionPanel} show={showTransactionPanel}>
            {CARD_TRANSACTIONS_ACCESS_INFO}
          </HiddenPanel>
          <FormRadio
            defaultSelected={showAllTransactions}
            errors={errors}
            name='showAllTransactions'
            options={[
              {
                label: 'Just their own card balance and transactions',
                value: SHOW_TRANSACTION_TYPES.OWN,
              },
              {
                label: 'All myFloc Team Member transactions and myFloc Card Account balances',
                value: SHOW_TRANSACTION_TYPES.ALL,
              },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
          />
          <div className='mt-12 w-full border-t' />

          {/* Spending Limits --------------------------------------- */}
          <label className='mt-8 mb-2'>Set a maximum spending limit for this card?</label>
          <FormRadio
            className='min-h-0'
            defaultSelected={limitSpending}
            errors={errors}
            labelClassName='mt-0 flex-1'
            name='limitSpending'
            options={[
              { label: 'Yes', value: YES_OR_NO.YES },
              { label: 'No', value: YES_OR_NO.NO },
            ]}
            register={register}
            validationErrors={apiErrors?.validationErrors}
            value={limitSpending}
            inline
          />
          <AnimateIn maxHeight={1000} show={limitSpending === YES_OR_NO.YES}>
            <label className='mt-4'>Limit Period:</label>
            <FormRadio
              className='min-h-0'
              defaultSelected={spendingLimitPeriod}
              errors={errors}
              labelClassName='flex-1'
              name='spendingLimitPeriod'
              options={[
                { label: 'Weekly', value: SPENDING_LIMIT_PERIOD.WEEK },
                { label: 'Monthly', value: SPENDING_LIMIT_PERIOD.MONTH },
              ]}
              register={register}
              validationErrors={apiErrors?.validationErrors}
              value={spendingLimitPeriod}
              inline
            />
            <FormInput
              className='mt-4 mr-1 ml-1'
              errors={errors}
              label='Amount'
              name='spendingLimit'
              register={register}
              type={FORM_INPUT_TYPES.currency}
              validationErrors={apiErrors?.validationErrors}
              required
            />
          </AnimateIn>

          <div className='p-6 mt-8 text-lg text-center rounded-md bg-light-blue'>
            An invitation to join myFloc will be emailed to: <b>{getValues('email')}</b>. They will
            have spending and viewing permissions as set above.
          </div>
          <div className='flex flex-col items-center mt-9'>
            <Button
              disabled={!isEmpty(errors) || !isEmpty(apiErrors?.validationErrors)}
              isLoading={loading}
            >
              Send Invitation
            </Button>
            <Button className='mt-6' onClick={onCancel} type='button' primary text>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddTeamMember
