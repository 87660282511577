import { useEffect } from 'react'

import { ROUTES } from '@/common/constants'
import { useAuthentication } from '@/common/hooks'
import { history } from '@/history'

/**
 * Login redirect route catcher, mostly for reset password
 */
export const Login = () => {
  const { auth0User, auth0Loading, login } = useAuthentication()

  // alter effect for logged in vs logged out users
  useEffect(() => {
    if (auth0Loading) return

    // send logged in to dashboard
    if (auth0User) history.push(ROUTES.DASHBOARD)

    // redirect logged out to auth0 tenant, then back to dashboard
    else login(ROUTES.DASHBOARD)
  }, [auth0Loading, auth0User])

  return null
}
