/**
 * Store for the currently logged in user
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AUTH0_API_AUDIENCE, ENDPOINTS, MYFLOC_API_HOST_URL } from '@/common/constants'
import { createCard, getAlertsForToasts } from '@/redux'

/**
 * Batch calls to create netspend card:
 * - GET getPersonStatus
 * - POST createCard
 * - GET getPersonStatus
 */
export const createCardBatch = createAsyncThunk(
  'user/batchCreateCard',
  async (_params, { dispatch }) => {
    await dispatch(getPersonStatus())
    const { payload: cardCreated } = await dispatch(createCard())

    // No need for second calls if we didn't create a card here
    if (!cardCreated) return
    await dispatch(getPersonStatus())
    await dispatch(getMyFlocPerson())
  }
)

/**
 * Batch calls to setup user on login or enrollment
 * - GET getPersonStatus
 * - POST createCard
 * - GET getPersonStatus
 */
export const setupUserBatch = createAsyncThunk(
  'user/batchSetupUser',
  async (_params, { dispatch }) => {
    const {
      payload: { error },
    } = await dispatch(getUser())
    if (error) return
    await dispatch(getAlertsForToasts())
    await dispatch(createCardBatch())
  }
)

/**
 * Use thunk to fetch token from Auth0
 */
export const getAuth0Token = createAsyncThunk(
  'user/getAuth0Token',
  /**
   *
   * @param   {Object}           getAccessTokenSilently
   * @returns {Promise<{token}>}
   */
  async ({ getAccessTokenSilently, ignoreCache = false }) => {
    const token = await getAccessTokenSilently({ audience: AUTH0_API_AUDIENCE, ignoreCache })

    return { token }
  }
)

export const getPersonStatus = createAsyncThunk(
  'user/getPersonStatus',
  /**
   * @@ Get active person status
   *
   * @returns {Promise<{Object}>}
   */
  async (_params, { getState }) => {
    const state = getState()
    const person = selectMyFlocPerson(state)
    if (!person) {
      return null
    }
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.PERSON_STATUS(person.id)}`

    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })

    return await resp.json()
  }
)

export const getMyFlocPerson = createAsyncThunk(
  'user/getMyFlocPerson',
  /**
   * Updates the correct person within the user object
   *
   * We get additional information from this call such as address which was not included
   * in the user call
   *
   * @param   {Object}            params
   * @param   {String}            params.id The persons uuid
   * @returns {Promise<{Object}>}
   */
  async (_userParams, { getState }) => {
    const state = getState()
    const myFlocPerson = selectMyFlocPerson(state)
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.PERSONS_ID(myFlocPerson.id)}`

    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })
    return await resp.json()
  }
)

export const getUser = createAsyncThunk(
  'user/getUser',
  /**
   * @@ This gets the users as well as the status table
   *
   * @returns {Promise<{error, persons, user}>}
   */
  async (_userParams, { getState }) => {
    const state = getState()
    const id = state.user.id
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.USERS}/${id}`

    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })
    return await resp.json()
  }
)

export const getOfferByPersonId = createAsyncThunk(
  'user/getOfferByPersonId',
  /**
   * Get offer by person id
   *
   * @returns {Promise<{Object}>}
   */
  async (_userParams, { getState }) => {
    const state = getState()
    const myFlocPerson = selectMyFlocPerson(state)
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.PERSONS_OFFERS(myFlocPerson.id)}`
    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'GET',
    })
    return await resp.json()
  }
)

export const createOtherLead = createAsyncThunk(
  'user/createOtherLead',
  /**
   * Create other lead by user id
   * @param   {String}            orderId
   * @returns {Promise<{Object}>}
   */
  async orderId => {
    const auth0Token = localStorage.getItem('auth0Token') || null
    const url = `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.ACCEPT_OTHER_LEAD}?order=${orderId}`
    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
      method: 'POST',
    })
    return await resp.json()
  }
)

const userSlice = createSlice({
  initialState: {
    activePersonId: null,
    activePersonStatus: null,
    auth0Token: null,
    id: null,
    offer: null,
    persons: null,
    user: null,
    userError: null,
    validationErrors: null,
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  extraReducers: builder => {
    // Fulfilled
    builder.addCase(getAuth0Token.fulfilled, (state, { payload }) => {
      localStorage.setItem('auth0Token', payload.token)
      // state.auth0Token = payload.token
    })
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      if (payload.error) {
        state.userError = payload.error
      }
      else {
        state.user = payload.user
        state.persons = payload.persons

        const validateStatus = team => {
          let verbose = true
          if (team.nsAccountStatus === 'blocked' || team.nsAccountStatus === 'closed') {
            verbose = false
          }
          if (verbose && team.nsKycStatus === 'rejected') {
            verbose = false
          }
          if (verbose && !['', 'active', 'new'].includes(team.nsAccountStatus) && team.nsOfacStatus === '') {
            verbose = false
          }
          return verbose
        }
        // check if currently active person is still valid
        const activePersonId = localStorage.getItem('activePersonId') !== 'null' ? localStorage.getItem('activePersonId') : null
        if (activePersonId) {
          const activePerson = state.persons?.find(person => person.id === activePersonId)
          if (!activePerson) {
            const persons = state.persons?.filter(person => validateStatus(person))
            if (persons?.length === 1) {
              // replace invalid person with first valid person available
              localStorage.setItem('activePersonId', persons[0].id)
            }
            else {
              // unset invalid person
              localStorage.removeItem('activePersonId')
            }
          }
        }
        else {
          // no active person? assign first valid person as active
          const persons = state.persons?.filter(person => validateStatus(person))
          if (persons && persons.length === 1) {
            localStorage.setItem('activePersonId', persons[0].id)
          }
        }
      }
    })
    builder.addCase(getPersonStatus.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.userError = action.payload.error
      }
      else {
        state.activePersonStatus = action.payload
      }
    })
    builder.addCase(getMyFlocPerson.fulfilled, (state, { payload }) => {
      let persons
      if (payload.error) {
        // TODO handle error for getting person
      }
      else {
        const index = state.persons?.findIndex(person => (person.id = payload.id))
        if (index > -1) {
          persons = [...state.persons]
          persons[index] = payload
        }
      }

      // Need to do it this way due to the way redux works with immer
      if (persons) {
        return {
          ...state,
          persons,
        }
      }
      else {
        return {
          ...state,
        }
      }
    })

    builder.addCase(getOfferByPersonId.fulfilled, (state, { payload }) => {
      if (payload.error) {
        state.userError = payload.error
      }
      else {
        state.offer = payload.offer
      }
    })

    builder.addCase(createOtherLead.fulfilled, (state, { payload }) => {
      if (payload.error) {
        state.userError = payload.error
      }
      else {
        state.user = { ...state.user, ...payload }
        localStorage.removeItem('activePersonId')
        localStorage.setItem('activePersonId', payload.personsList[0])
      }
    })

    // Failure
    builder.addCase(getPersonStatus.rejected, (state, action) => {
      state.userError = action.error
    })
  },
  name: 'user',
  reducers: {
    setActivePerson: (state, { payload }) => {
      const nextActivePersonId = payload

      if (
        !nextActivePersonId ||
        !state.persons ||
        !Array.isArray(state.persons) ||
        state.persons.length < 1
      ) {
        localStorage.removeItem('activePersonId')
        return
      }

      const nextActivePerson = state.persons?.find(person => person.id === nextActivePersonId)
      if (nextActivePerson) localStorage.setItem('activePersonId', nextActivePersonId) // state.activePersonId = nextActivePersonId
    },
    setAuth0Token: (state, { payload }) => {
      localStorage.setItem('auth0Token', payload)
      // state.auth0Token = payload
    },
    setUserId: (state, { payload }) => {
      state.id = payload.auth0User?.[AUTH0_API_AUDIENCE + '/user_metadata']?.myfloc_id ?? payload.id
    },
    setValidationErrors: (state, { payload }) => {
      state.validationErrors = payload
    },
    updateAndSwitchTeam: (state, { payload }) => {
      localStorage.removeItem('activePersonId')
      localStorage.setItem('activePersonId', payload.id)
    },
    updateAndSwitchUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
      localStorage.removeItem('activePersonId')
      localStorage.setItem('activePersonId', payload.personsList[0])
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
    },
  },
})

/**
 * Additional Selectors
 */
export const selectMyFlocPerson = state => {
  let activePerson = null
  const activePersonId = localStorage.getItem('activePersonId') !== 'null' ? localStorage.getItem('activePersonId') : null
  if (activePersonId) {
    activePerson = state.user?.persons?.find(person => person.id === activePersonId)
  }
  else if (state.user?.persons?.length === 1) {
    activePerson = state.user?.persons[0]
  }

  if (activePerson?.status !== 'active') {
    const hasActiveUser = state.user?.persons?.find(person => person.status === 'active')
    if (hasActiveUser) {
      activePerson = hasActiveUser
    }
  }
  return activePerson
}

export const selectPreferredFirstname = state => {
  return state.user.user?.displayName || state.user.user?.firstName
}

export const {
  setActivePerson,
  setAuth0Token,
  setUserId,
  setValidationErrors,
  updateUser,
  updateAndSwitchUser,
  updateAndSwitchTeam,
} = userSlice.actions
export default userSlice.reducer
