import React from 'react'

import './styles.module.scss'

export const PdfTable = () => {
  const summaryFees = () => {
    return (
      <section aria-label='Summary of Fees' className='sf sf__1-plan'>
        <h2 className='cfpb__form-heading'>Summary of fees:</h2>
        <div className='cfpb__table-container'>
          <ul aria-label='Highlighted Fees' className='cfpb__sf-highlights'>
            <li>
              <h3 className='cfpb__fee-title'>Monthly fee</h3>
              <p className='cfpb__fee'>$36.00</p>
            </li>
            <li>
              <h3 className='cfpb__fee-title'>Per purchase</h3>
              <p className='cfpb__fee'>$0</p>
            </li>
            <li>
              <h3 className='cfpb__fee-title'>ATM withdrawal</h3>
              <p className='cfpb__fee'>$2.50<sup>*</sup></p>
            </li>
            <li>
              <h3 className='cfpb__fee-title'>Cash reload</h3>
              <p className='cfpb__fee'>N/A</p>
            </li>
          </ul>
          <table className='cfpb__sf-fees'>
            <thead>
              <tr>
                <th className='cfpb__plan-header--hidden' scope='col'><span className='cfpb__a11y-hidden'>Type of fee</span></th>
                <th className='cfpb__plan-header--hidden' scope='col'><span className='cfpb__a11y-hidden'>Amount</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className='cfpb__fee-title cfpb__light' scope='row'>ATM balance inquiry</th>
                <td className='cfpb__fee'>$0.50</td>
              </tr>
              <tr>
                <th className='cfpb__fee-title cfpb__light' scope='row'>
                  Customer service <small>(automated or live agent)</small>
                </th>
                <td className='cfpb__fee'>$0</td>
              </tr>
              <tr>
                <th className='cfpb__fee-title cfpb__light' scope='row'>Inactivity</th>
                <td className='cfpb__fee'>$0</td>
              </tr>
            </tbody>
          </table>
          <table className='cfpb__sf-additional-fees'>
            <caption className='extra-fee-count'>We charge 3 other types of fees. <span className='cfpb__light'>Here is one of them:</span></caption>
            <thead>
              <tr>
                <th className='cfpb__plan-header--hidden' scope='col'><span className='cfpb__a11y-hidden'>Type of fee</span></th>
                <th className='cfpb__plan-header--hidden' scope='col'><span className='cfpb__a11y-hidden'>Amount</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className='cfpb__fee-title cfpb__light' scope='row'>Account Set-Up Fee</th>
                <td className='cfpb__fee'>$50.00</td>
              </tr>
            </tbody>
          </table>
          <div className='cfpb__fee-detail'>
            <p><sup>*</sup> This fee can be lower depending on how and where this card is used.</p>
            <p>
              <strong>No overdraft/credit feature.</strong>
              <br /> Register your card for FDIC insurance eligibility and other protections.
            </p>
            <p>
              For general information about prepaid accounts, visit{' '}
              <a href='https://www.cfpb.gov/prepaid' rel='noopener noreferrer' target='_blank'>cfpb.gov/prepaid</a>
              .<br /> Find details and conditions for all fees and services below.
            </p>
          </div>
        </div>
        <div className='sf__details'>
          The myFloc<sup>&trade;</sup> Card is issued by Pathward, National Association,
          Member FDIC, pursuant to a license from Visa U.S.A. Inc. Card may be used everywhere Visa
          <sup>&reg;</sup> debit cards are accepted.
        </div>
      </section>
    )
  }

  const allFeesAssociated = () => {
    return (
      <section aria-label='List of All Fees' className='lf'>
        <h2 className='cfpb__form-heading'>List of all fees associated with your myFloc<sup>&trade;</sup> Card</h2>
        <div className='cfpb__heading'>Details of All Fees</div>
        <table>
          <caption className='cfpb__heading'>
            <h3>Set-Up Fee</h3>
          </caption>
          <tr>
            <th className='cfpb__fee-title' scope='row'>Account Set-Up Fee</th>
            <td className='cfpb__fee'>$50.00</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              One-time fee for the activation and set-up of the Card Account,
              charged to your Payment Method as described in the Cardholder Agreement.
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Plan Fee</h3>
          </caption>
          <tr>
            <th className='cfpb__fee-title' scope='row'>Monthly Fee</th>
            <td className='cfpb__fee'>$36.00</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              Per month; fee assessed on the monthly anniversary of your Billing Date,
              charged to your Payment Method as described in the Cardholder Agreement.
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Check your Balance</h3>
          </caption>
          <tr>
            <td>Customer Service (Automated or Live Agent)</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              No fee for calling Customer Service (Automated or Live Agent) for inquiries,
              including balance inquiries. 1&#8209;833&#8209;693&#8209;5621.
            </td>
          </tr>
          <tr>
            <td>ATM Balance Inquiry Fee &ndash; Domestic</td>
            <td className='cfpb__fee'>$0.50</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>Per inquiry. You may also be charged a fee by the ATM operator.</td>
          </tr>
          <tr>
            <td>Balance Inquiry via myFloc Online Account Center</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              Log in to the myFloc Online Account Center at{' '}
              <a href='https://www.myfloc.com/' rel='noopener noreferrer' target='_blank'>www.myfloc.com</a>.
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Withdraw Cash</h3>
          </caption>
          <tr>
            <td>ATM Withdrawal Fee &ndash; Domestic</td>
            <td className='cfpb__fee'>$2.50</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              <p>
                Per withdrawal. This fee is waived for the first two (2) withdrawals per
                calendar month at MoneyPass Network ATMs. You may also be charged a fee by
                the ATM operator. ATM operator surcharge fees will not apply to withdrawals
                made at any MoneyPass Network ATM in the U.S. This service may change and/or be withdrawn.
              </p>
              <p>You can avoid ATM fees if you select “DEBIT” and enter your PIN to get cash
                back when making purchases at many retailers, such as grocery stores.
              </p>
              <p><strong>Only Primary and Secondary Cardholders may make ATM withdrawals.</strong></p>
            </td>
          </tr>
          <tr>
            <td>ATM Transaction Decline Fee &ndash; Domestic</td>
            <td className='cfpb__fee'>$1.00</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              <p>Per declined ATM withdrawal transaction. You may also be charged a fee by the ATM operator.</p>
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Add Money to your Card Account</h3>
          </caption>
          <tr>
            <td>Direct Deposit (ACH Deposit)</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>No fee. <strong>This feature is not available for use with Subaccounts.</strong></td>
          </tr>
          <tr>
            <td>Mobile Check Load Fee &ndash; Standard</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              This is a third-party fee and is subject to change.
              Standard text message or data rates may apply.
              <strong>This feature is not available for use with Subaccounts.</strong>
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Move Money to and from your Card Account</h3>
          </caption>
          <tr>
            <td>Bank Transfers &ndash; Inbound</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              Transfer of funds via ACH from your bank account at another financial
              institution to your Primary Card Account. This is a third-party fee and
              is subject to change.
              <strong>This feature is not available for use with Subaccounts.</strong>
            </td>
          </tr>
          <tr>
            <td>Bank Transfers &ndash; Outbound</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              Per transfer. Transfer of funds via ACH from your Primary Card Account to
              your bank account at another financial institution. This is a third-party
              fee and is subject to change.
              <strong>This feature is not available for use with Subaccounts.</strong>
            </td>
          </tr>
        </table>
        <table>
          <caption className='cfpb__heading'>
            <h3>Add or Replace a Card</h3>
          </caption>
          <tr>
            <td>Replacement Card Fee</td>
            <td className='cfpb__fee'>$5.00</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>Per lost, stolen, or damaged Card replaced.</td>
          </tr>
          <tr>
            <td>Card Delivery Fee &ndash; 7-10 Business Days</td>
            <td className='cfpb__fee'>$0</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>No fee.</td>
          </tr>
          <tr>
            <td>Expedited Card Delivery Fee &ndash; 3 Business Days</td>
            <td className='cfpb__fee'>$25.00</td>
          </tr>
          <tr>
            <td className='cfpb__fee-detail' colSpan={2}>
              Charged in addition to Replacement Card Fee. Fee will be assessed when
              this service is requested with replacement Card orders.
            </td>
          </tr>
        </table>
        <div className='lf__details'>
          <p>
            Register your Card Account for FDIC insurance eligibility and other protections.
            Your funds will be held at or transferred to Pathward, National Association, an
            FDIC-insured institution. Once there, your funds are insured up to $250,000 by
            the FDIC in the event Pathward, N.A., fails, if specific deposit insurance requirements
            are met and your Card Account is registered. See{' '}
            <a href='https://www.fdic.gov/deposit/deposits/prepaid.html' rel='noopener noreferrer' target='_blank'>
              www.fdic.gov/deposit/deposits/prepaid.html
            </a>
            {' '}for details.</p>
          <strong>No Overdraft/Credit Feature</strong>
          <p>
            Contact us by calling 1&#8209;833 myFloc1 (1&#8209;833&#8209;693&#8209;5621),
            by mail at P.O. Box 2136, Austin, TX 78768-2136, or visit{' '}
            <a href='https://www.myfloc.com/' rel='noopener noreferrer' target='_blank'>
              www.myfloc.com
            </a>
            {' '}to learn more about the terms and conditions applicable to your Card Account,
            obtain balance information, request a copy of your transaction history, or to
            report an unauthorized transaction.
          </p>
          <p>
            For general information about prepaid accounts, visit{' '}
            <a href='https://www.cfpb.gov/prepaid' rel='noopener noreferrer' target='_blank'>www.cfpb.gov/prepaid</a>.
            If you have a complaint about a prepaid account, call the Consumer Financial
            Protection Bureau at 1&#8209;855&#8209;411&#8209;2372 or visit{' '}
            <a href='https://www.cfpb.gov/complaint' rel='noopener noreferrer' target='_blank'>www.cfpb.gov/complaint</a>.</p>
        </div>
      </section>
    )
  }

  return (
    <div className='cfpb-container'>
      <div className='cfpb'>
        {summaryFees()}
        <div className='short-long-divider'></div>
        {allFeesAssociated()}
      </div>
    </div>
  )
}
