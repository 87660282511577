import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import AnimateIn from '@/common/components/AnimateIn'
import Button from '@/common/components/Button'
import FormAddress from '@/common/components/FormAddress'
import FormCheckbox from '@/common/components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@/common/components/FormInput'
import HiddenPanel from '@/common/components/HiddenPanel'
import Loading from '@/common/components/Loading'
import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import PageHeader from '@/common/components/PageHeader'
import {
  ENDPOINTS, HTTP_METHODS, PHONE_INFO, ROUTES,
} from '@/common/constants'
import { useFormFieldTracking, useParseApiErrors, useSubmitForm } from '@/common/hooks'
import { isEmpty, statusGuard } from '@/common/utils'
import { addressSchema, profileSchema } from '@/common/validations'
import { history } from '@/history'
import { addToast, TOAST_TYPES } from '@/redux/toasts'
import { getMyFlocPerson, getUser, selectMyFlocPerson } from '@/redux/user'

/**
 * Profile update form
 *
 */
const Profile = props => {
  const dispatch = useDispatch()

  // Get user & person objects from state
  const { user } = useSelector(state => state.user)
  const myFlocPerson = useSelector(selectMyFlocPerson)

  // Fetch full myFloc person information if redux
  // has the partial that comes from user object
  useEffect(() => {
    if (myFlocPerson.residenceAddress) return

    dispatch(getMyFlocPerson())
  }, [myFlocPerson.residenceAddress])

  // Setting some variables we'll need
  const [showPhoneInfo, setShowPhoneInfo] = useState(false)
  const [shippingSchema, setShippingSchema] = useState(null)
  const [residenceSchema, setResidenceSchema] = useState(null)
  const [phone, setPhone] = useState('')
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  // Setup role permissions for fields
  const fieldRoles = {
    residenceAddress: ['lead', 'insider', 'teamMember'],
    residenceMailingSame: ['lead', 'insider', 'teamMember'],
    shippingAddress: ['lead', 'insider', 'teamMember'],
  }

  // React hook form stuff we need
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(profileSchema.concat(shippingSchema)
      .concat(residenceSchema)),
  })
  const { apiErrors, showValidationToast, loading, submitForm } = useSubmitForm()
  const {
    reset: resetFieldTracking,
    updatedFields: fieldsUpdatedSinceSubmit,
  } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: fieldsUpdatedSinceSubmit })

  const handlePhoneChange = ({ target: { value } }) => {
    clearErrors('mobile')
    setPhone(value)
  }

  const resetPassword = async () => {
    const resp = await submitForm(ENDPOINTS.RESET_PASSWORD(myFlocPerson.id), {
      method: 'POST',
    })

    if (resp.response.status === 204) {
      dispatch(
        addToast({
          subtitle: 'Password reset email sent successfully.',
          title: 'Password Reset Initiatied',
          type: TOAST_TYPES.success,
        })
      )
    }
    else {
      dispatch(
        addToast({
          subtitle: resp.error.message,
          title: 'Unexpected Error',
          type: TOAST_TYPES.error,
        })
      )
    }
  }

  // Populate form fields with existing values, set validation rules
  useEffect(() => {
    if (!user) return

    setValue('displayName', user.displayName, { shouldValidate: false })
    setValue('email', user.email, { shouldValidate: false })

    // Workaround for accounts that have 11 digit phones in db
    if (user.mobile) {
      const mobileValue = user.mobile.length === 11 ? user.mobile.substring(1) : user.mobile
      setValue('mobile', mobileValue, { shouldValidate: false })
      setPhone(mobileValue)
    }

    // smsConsent could be null
    // - coerce to boolean
    setValue('smsConsent', user.smsConsent !== null ? user.smsConsent : false)

    const canSetResidenceAddress = fieldRoles.residenceAddress.includes(myFlocPerson.role)
    let resAddr
    let shipAddr

    if (canSetResidenceAddress) {
      setResidenceSchema(
        yup.object({
          residenceAddress: addressSchema,
        })
      )

      if (myFlocPerson.residenceAddress) {
        const { id, personId, addressType, ...rest } = myFlocPerson.residenceAddress
        setValue('residenceAddress', rest, { shouldValidate: false })
        resAddr = rest
      }
    }
    else {
      setResidenceSchema(null)
      clearErrors('residenceAddress')
    }

    const canSetShippingAddress = fieldRoles.shippingAddress.includes(myFlocPerson.role)
    if (canSetShippingAddress) {
      if (myFlocPerson.shippingAddress) {
        const { id, personId, addressType, ...rest } = myFlocPerson.shippingAddress
        setValue('shippingAddress', rest, { shouldValidate: false })
        shipAddr = rest
      }
    }

    if (canSetShippingAddress && canSetResidenceAddress) {
      const addressesMatch = isEqual(resAddr, shipAddr)
      setValue('residenceMailingSame', addressesMatch)
    }
  }, [myFlocPerson])

  // Schema control for addresses
  const residenceMailingSame = watch('residenceMailingSame')
  useEffect(() => {
    if (residenceMailingSame) {
      clearErrors('shippingAddress')
      setShippingSchema(null)
    }
    else {
      if (fieldRoles.shippingAddress.includes(myFlocPerson.role)) {
        setShippingSchema(
          yup.object({
            shippingAddress: addressSchema,
          })
        )
      }
    }
  }, [residenceMailingSame])

  // reset updated fields list on form submission
  useEffect(() => {
    if (isSubmittingForm && fieldsUpdatedSinceSubmit.length > 0) resetFieldTracking()
  }, [isSubmittingForm, fieldsUpdatedSinceSubmit, resetFieldTracking])

  const formatPayload = payload => {
    // no phone number? backend wants null, not empty string
    if (payload.mobile === '') payload.mobile = null

    return payload
  }

  // Submit handler
  const onSubmit = async payload => {
    if (isSubmittingForm) return

    setIsSubmittingForm(true)

    try {
      if (fieldRoles.residenceAddress.includes(myFlocPerson.role)) {
        if (residenceMailingSame) {
          payload.shippingAddress = {
            ...payload.residenceAddress,
          }
        }
      }

      const { response } = await submitForm(`${ENDPOINTS.USERS_PATCH(user.id)}`, {
        includeSessionId: true,
        method: HTTP_METHODS.PATCH,
        payload: formatPayload(payload),
        removeEmptyKeys: false,
      })

      if (response.status === 200) {
        const {
          payload: { activePersonStatus },
        } = await dispatch(getUser())

        // Send to OTP verification page
        if (user.email !== payload.email) statusGuard(history.location.pathname, activePersonStatus)

        dispatch(
          addToast({
            subtitle: 'Your profile information has been updated successfully.',
            title: 'Profile Updated',
            type: TOAST_TYPES.success,
          })
        )

        if (user.email !== payload.email) {
          history.push(ROUTES.VERIFY_EMAIL_OTP)
        }
      }
      else if (response.status === 500) {
        dispatch(
          addToast({
            subtitle:
              'Your profile information was not updated. Please try again in a moment and if the problem persists, contact support.',
            title: 'Error updating profile',
            type: TOAST_TYPES.error,
          })
        )
      }
    }
    catch (submitError) {
      console.error('error submitting form:', submitError)
    }

    setIsSubmittingForm(false)
  }

  const userFullName = [user.firstName, user.middleName, user.lastName]
    .filter(name => name)
    .join(' ')

  if (!user) return <Loading />

  return (
    <>
      <PageHeader>My profile</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <form onSubmit={handleSubmit(onSubmit, showValidationToast)}>
          <h3
            className='cursor-pointer hover:underline text-secondary'
            onClick={() => setModalOpen(true)}
          >
            Reset myFloc Password
          </h3>
          <div className='text-sm'>* Required field</div>

          <div className='flex flex-col mt-6 mb-8'>
            <h3>Name</h3>
            <div className='text-lg'>{userFullName}</div>
            <div className='mt-3'>
              For security purposes, to edit your name contact myFloc Customer Care at
              1-833-693-5621 (1-833-myFloc1).
            </div>
          </div>

          <FormInput
            autoComplete='preferred-name'
            errors={errors}
            label='Preferred Name'
            name='displayName'
            register={register}
            validationErrors={apiValidationErrors}
          />

          <FormInput
            autoComplete='email-address'
            errors={errors}
            label='Email Address'
            name='email'
            register={register}
            validationErrors={apiValidationErrors}
            required
          />

          <FormInput
            autoComplete='tel'
            className='mb-0'
            defaultValue={phone}
            errors={errors}
            inputClass='w-6/12'
            label='Mobile Phone (optional)'
            labelButtonAction={() => setShowPhoneInfo(true)}
            labelButtonText='Why Provide This?'
            name='mobile'
            onChange={handlePhoneChange}
            register={register}
            type={FORM_INPUT_TYPES.phone}
            validationErrors={apiValidationErrors}
          />

          <FormCheckbox
            className='mt-0 mb-0'
            id='joinFriendSmsConsent'
            name='smsConsent'
            register={register}
            subtitle='Phone provider message and data rates may apply.'
            title='Allow Text Message Notifications'
            plain
          />

          <HiddenPanel setShow={setShowPhoneInfo} show={showPhoneInfo}>
            {PHONE_INFO}
          </HiddenPanel>

          {fieldRoles.residenceAddress.includes(myFlocPerson.role) && (
            <FormAddress
              apiErrors={{ validationErrors: apiValidationErrors }}
              errors={errors}
              label='Address'
              name='residenceAddress'
              register={register}
              showHint={true}
            />
          )}

          {fieldRoles.residenceMailingSame.includes(myFlocPerson.role) && (
            <FormCheckbox
              className='mb-0'
              id='residenceMailingSame'
              name='residenceMailingSame'
              register={register}
              subtitle='Mailing and physical address are the same'
              plain
            />
          )}

          {fieldRoles.shippingAddress.includes(myFlocPerson.role) && (
            <AnimateIn maxHeight={740} show={!residenceMailingSame}>
              <FormAddress
                apiErrors={{ validationErrors: apiValidationErrors }}
                errors={errors}
                label='Mailing Address'
                name='shippingAddress'
                register={register}
                showHint={false}
              />
            </AnimateIn>
          )}

          <Button disabled={!isEmpty(errors) || !isEmpty(apiValidationErrors)} isLoading={loading}>
            Save Changes
          </Button>
        </form>
      </div>

      <Modal
        cancelText='Cancel'
        open={modalOpen}
        setOpen={() => setModalOpen(!modalOpen)}
        successCallback={() => resetPassword()}
        successText='Yes, Send Reset Email'
        title='Reset Password?'
        type={MODAL_TYPES.confirm}
      >
        Would you like to receive a password reset email?
      </Modal>
    </>
  )
}

export default Profile
