import { combineReducers } from '@reduxjs/toolkit'
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import formSteps from './formSteps'
import loading from './loading'
import netspend from './netspend'
import notifications from './notifications'
import team from './team'
import toasts from './toasts'
import user from './user'
import version from './version'

// const netspendPersistConfig = {
//   blacklist: ['accounts', 'cardAch', 'kycQuestions', 'passcode'],
//   key: 'netspend',
//   storage: storage,
// }

const rootReducer = combineReducers({
  formSteps,
  loading,
  netspend,
  notifications,
  team,
  toasts,
  user,
  version,
})

export default rootReducer
