import React, { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as DashboardWave } from '@/assets/svg/dashboard_wave.svg'
import { ROLES } from '@/common/constants'
import { deleteSession } from '@/redux'
import { store } from '@/redux/store'
import { selectPreferredFirstname } from '@/redux/user'

import HeroSection from '../dashboard/dashboardComponents/HeroSection'
import BuildListTeam from './components/BuildListTeam'

const rolesToNumbersMap = {
  [ROLES.LEAD]: 1,
  [ROLES.INSIDER]: 2,
  [ROLES.TEAM_MEMBER]: 3,
  [ROLES.FRIEND]: 4,
}
const TeamSelection = () => {
  const state = store.getState()
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const userPreferredFirstName = selectPreferredFirstname(state)
  const userState = useSelector(s => s.user)

  useEffect(() => {
    dispatch(deleteSession())
  }, [dispatch])

  if (!isAuthenticated && !isLoading && !isRedirecting) {
    setIsRedirecting(true)
    loginWithRedirect({ appState: { returnTo: `${window.location.pathname}${window.location.search}` } })
    return null
  }
  if (isRedirecting || !isAuthenticated) {
    return null
  }
  const isAdmin = true

  const baseClasses = 'max-w-5xl container bg-white rounded mx-auto p-6'
  const classes = {
    wrapper: classnames(baseClasses),
  }

  return (
    <>
      <HeroSection
        currentStep={5}
        isAdmin={isAdmin}
        userPreferredFirstName={userPreferredFirstName}
      />

      <DashboardWave />
      <div className='flex-grow px-5 pb-20 md:px-8 bg-tertiary'>
        <div className={classes.wrapper}>
          <div className='flex flex-col'>
            <h3 className='pl-3 md:pt-3'>Select a Team to Continue</h3>
            <pre>
              <BuildListTeam
                rolesToNumbersMap={rolesToNumbersMap}
                teamList={userState.persons}
              />
            </pre>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamSelection
