import React from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

/**
 * Simple component to display icons from the assets/svg folder
 *
 * Should only be used for dyamically loading svgs, or for convenience. If not load it regularly!
 *
 * @param {Object}         props
 * @param {string}         [props.className = '']
 * @param {string|boolean} [props.fill]           override the fill color, if boolean then will get set to 'currentColor'
 * @param {string}         props.name
 * @param {number}         [props.width = 40]
 * @param {number}         [props.height = 40]
 */
const Icon = ({ className = '', fill: fillProp, name, width = 40, height, ...props }) => {
  const fill = fillProp === true ? 'currentColor' : fillProp

  return (
    <SVG
      className={className}
      height={height}
      key={`${name}-${Date.now()}-${Math.ceil(Math.random() * 1000)}`}
      preProcessor={fill && (code => code.replace(/fill=".*?"/g, `fill="${fill}"`))}
      role='img'
      src={`/svg/${name}.svg`}
      width={width}
      {...props}
    />
  )
}

Icon.propTypes = {
  /**
   * Any classNames we wish to propogate to this element
   */
  className: PropTypes.string,
  /**
   * Override the fill color, if boolean then will get set to 'currentColor'
   */
  fill: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
  /**
   * The height of the icon, no default, should scale with width by default
   */
  height: PropTypes.number,
  /**
   * The name of the icon in the svg folder
   */
  name: PropTypes.string.isRequired,
  /**
   * The width of the icon, defaults to 40
   */
  width: PropTypes.number,
}

export default Icon
