import React, { useState } from 'react'

import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
// import Icon from '@/common/components/Icon'
import Modal, { MODAL_TYPES } from '@/common/components/Modal'
import { MYFLOC_PHONE_NUMBER, MYFLOC_PHONE_NUMBER_TEXT } from '@/common/constants'
import { currency } from '@/common/utils'

/**
 * Fee Summary
 *
 * @param   {Object}      props
 * @param   {Object}      props.fees the aggregated fees
 * @returns {JSX.Element}
 */
export const FeeSummary = ({ fees = null }) => {
  const [openQuestions, setOpenQuestions] = useState(false)

  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 1)
  const previousMonth = currentDate.toLocaleString('default', { month: 'long' })

  return (
    <>
      <div className='my-8 w-full border-t border-gray-100' />
      <h3 className='flex flex-row items-center'>
        Fee Summary
        {/* <Icon className='ml-2' name='info_bubble' width={14} /> */}
      </h3>

      <div className='-mr-5 -ml-5'>
        <div className='flex flex-row justify-between py-4 pl-4 border-b md:px-10'>
          <div className='pl-1.5'>{previousMonth} Fees</div>
          <div className='pr-4 font-bold md:pr-6'>{currency(fees.previousMonthFees / 100)}</div>
        </div>
        <div className='flex flex-row justify-between py-4 pl-4 border-b md:px-10'>
          <div className='pl-1.5'>Current Year-to-Date Fees</div>
          <div className='pr-4 font-bold md:pr-6'>{currency(fees.ytdFees / 100)}</div>
        </div>
      </div>
      <Button
        className='mt-4 text-left md:ml-6'
        onClick={() => setOpenQuestions(true)}
        arrow
        primary
      >
        In Case of Errors or Questions About Your Electronic Transfers
      </Button>

      {/* Questions Modal */}
      <Modal open={openQuestions} setOpen={setOpenQuestions} type={MODAL_TYPES.alert}>
        <h3>In case of errors or questions about your electronic transfers:</h3>
        <p>
          Telephone us at{' '}
          <Button
            className='inline flat-x'
            href={'tel:' + MYFLOC_PHONE_NUMBER}
            tag='a'
            primary
            text
          >
            {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
          </Button>{' '}
          or write us at myFloc Customer Care P.O. Box 2136 Austin, TX 78768-2136 as soon as you
          can, if you think your statement or receipt is wrong or if you need more information about
          a transfer on the statement or receipt. We must allow you to report an error until 60 days
          after the earlier of the date you electronically access your account, if the error could
          be viewed in your electronic history, or the date we sent the first written history on
          which the error appeared. You may request a written history of your transactions at any
          time by calling us at 1-833-693-5621 (1-833-myFloc1) or writing us at myFloc Customer Care
          P.O. Box 2136 Austin, TX 78768-2136. You will need to tell us:
        </p>

        <ol className='list-decimal list-inside'>
          <li>Tell us your name and account number (if any).</li>
          <li>
            Describe the error or the transfer you are unsure about, and explain as clearly as you
            can why you believe it is an error or why you need more information.
          </li>
          <li>Tell us the dollar amount of the suspected error.</li>
        </ol>

        <p className='mt-4'>
          We will investigate your complaint and correct any error promptly. If we take more than 10
          business days to do this, we will credit your account for the amount you think is in
          error, so that you will have the use of the money during the time it takes us to complete
          our investigation.
        </p>
      </Modal>
    </>
  )
}

FeeSummary.propTypes = {
  fees: PropTypes.object,
}

export default FeeSummary
