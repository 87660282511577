import React from 'react'

import PropTypes from 'prop-types'

import CreditCard from '@/common/components/CreditCard'
import Dropdown from '@/common/components/Dropdown'
import { ROLES } from '@/common/constants'

import { DisplayName } from './DisplayName'

/**
 * Card used for teamMembers and friends on the dashboard
 *
 * @param   {Object}         props
 * @param   {string}         [props.balance]
 * @param   {string}         [props.creditCardType]
 * @param   {function}       props.dropdownOnChange
 * @param   {Array.<Object>} props.dropdownOptions
 * @param   {Object}         props.member
 * @param   {Boolean}        [props.you]
 * @returns {JSX.Element}
 */
export const TeamCard = ({
  balance = null,
  dropdownOnChange,
  dropdownOptions,
  member,
  you = false,
}) => {
  const isPrimary = member.role === ROLES.LEAD || member.role === ROLES.INSIDER
  return (
    <>
      <section className={`flex flex-1 flex-col md:flex-row py-6 px-4 lg:px-6 ${isPrimary ? 'border-u' : ''}`}>
        <div className='flex flex-row flex-1 justify-between min-w-0 md:flex-row md:items-center'>
          <CreditCard active={member.cardStatus === 'active'} person={member} />
          <DisplayName className='hidden md:block' member={member} you={you} />
          {/* Dropdowns if they have permission */}
          {dropdownOptions?.length ? (
            <Dropdown
              changeText={false}
              className='flex flex-col col-span-2 justify-start items-end text-right md:col-span-1 md:justify-center md:items-start md:text-left'
              label='Options'
              onChange={action => dropdownOnChange(member, action)}
              options={dropdownOptions}
            />
          ) : (
            <div></div>
          )}
        </div>
        <DisplayName className='md:hidden' member={member} you={you} />
      </section>
      {/* Balances, lead and insider are outside of this component */}
      {(member.role === ROLES.TEAM_MEMBER || member.role === ROLES.FRIEND) && (
        <div className='flex flex-col justify-center items-center pb-5 md:pb-0 md:text-right'
          style={{ minWidth: '18rem' }}>
          {member.role === ROLES.TEAM_MEMBER ? (
            <>
              <div className='body-2'>Available Balance</div>
              <h2 className='mb-0 text-secondary'>{balance}</h2>
            </>
          ) : (
            <span className='text-center body-2'>View Permissions<br />Only</span>
          )}
        </div>
      )}
    </>
  )
}

TeamCard.propTypes = {
  balance: PropTypes.string,
  creditCardType: PropTypes.string,
  dropdownOnChange: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  member: PropTypes.object.isRequired,
  you: PropTypes.bool,
}
