/**
 * Centralize any constants related to financials
 */

export const FEES = {
  ATM_BALANCE: 0.5,
  ATM_WITHDRAWAL: 2.5,
  CASH_RELOAD: 'N/A',
  CUSTOMER_SERVICE: 0,
  INACTIVITY: 0,
  MONTHLY: 0,
  PER_PURCHASE: 0,
}

export const TRANSFER_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
}

export const FREQUENCY_TYPES = {
  MONTHLY: 'monthly',
  ONCE: 'once',
  WEEKLY: 'weekly',
}
