import { createSlice } from '@reduxjs/toolkit'

export const FORMS = {
  createCardAccount: 'createCardAccount',
  transferFunds: 'transferFunds',
}

const formStepsSlice = createSlice({
  initialState: {},
  name: 'formSteps',
  reducers: {
    clearSteps: (state, { payload }) => {
      const form = payload.form

      if (!form) throw new Error('name of form not specified')

      delete state[form]
    },
    saveStep: (state, { payload }) => {
      const form = payload.form
      const step = payload.step
      const data = payload.data

      if (!form) throw new Error('name of form not specified')
      if (!step) throw new Error(`step in form "${form}" not specified`)
      if (!data) throw new Error(`data for step "${step}" in form "${form}" not specified`)

      if (!(form in state)) state[form] = {}

      state[form][step] = data
    },
  },
})

export const { clearSteps, saveStep } = formStepsSlice.actions
export default formStepsSlice.reducer
