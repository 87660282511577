import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import { history } from '@/history'

import Button from '../../../common/components/Button'
export const ActionRow = ({ className = '', children, link, label, disabled = false }) => {
  const wrapperClass = 'px-3 py-5 md:p-3 border-u flex flex-col md:flex-row md:text-left text-center'
  return (
    <div className={classnames(className, wrapperClass)}>
      <div className='flex flex-row items-center flex-start'>
        <Button
          className='w-full md:w-auto'
          disabled={disabled}
          onClick={() => history.push(link)}
          style={{ minWidth: '25rem' }}
        >
          {label}
        </Button>
      </div>
      <div className='mt-4 text-left md:col-span-2 md:pl-3 md:m-0 text-dark md:text-left text-center'>{children}</div>
    </div >
  )
}

ActionRow.propTypes = {
  /**
   * The body text, uses children in case of markup
   */
  children: PropTypes.node.isRequired,
  /**
   * Any class names to merge into the topmost element
   */
  className: PropTypes.string,
  /**
   * Whether or not this row is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Label for the button
   */
  label: PropTypes.string.isRequired,
  /**
   * The path of the location you wish to navigate to
   */
  link: PropTypes.string.isRequired,
}

export default ActionRow
