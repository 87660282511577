import React from 'react'

import PropTypes from 'prop-types'

import Button from '@/common/components/Button'
import Loading from '@/common/components/Loading'
import { currency } from '@/common/utils'
import { PAGE_ACTIONS } from '@/views/account/MyAccount'

export const TRANSACTION_DIRECTION = {
  CREDIT: 'credit',
  DEBIT: 'debit',
}

/**
 * Transactions table web and mobile
 *
 * @param   {Object}      props
 * @param   {Function}    props.callback
 * @param   {boolean}     [props.firstPage]           enables the first page button
 * @param   {boolean}     [props.nextPage]            enables the next page button
 * @param   {boolean}     [props.prevPage]            enables the previous page button
 * @param   {Object[]}    [props.pendingTransactions] pending transactions at the top
 * @param   {Object[]}    [props.transactions]        the list of transactions to display
 * @returns {JSX.Element}
 */
export const TransactionsTable = ({
  callback,
  firstPage = false,
  nextPage = false,
  prevPage = false,
  pendingTransactions = [],
  transactions = null,
}) => {
  const combinedTransactions = pendingTransactions.concat(transactions)
  return (
    <>
      {transactions ? (
        combinedTransactions.length === 0 ? (
          <div className='min-h-[300px] flex items-center justify-center'>
            There were no transactions with the selected filters.
          </div>
        ) : (
          <>
            <table className='-mr-5 -ml-5 table-auto md:ml-0 md:w-full w-full-plus-4'>
              <thead className='hidden md:table-header-group'>
                <tr>
                  <th className='py-6 font-normal'>Date</th>
                  <th className='font-normal text-left'>Transaction</th>
                  <th className='font-normal'>Deposits/Credits</th>
                  <th className='pl-8 font-normal'>Withdrawals/Debits</th>
                </tr>
              </thead>
              <tbody>
                {combinedTransactions.map((trans, i) => (
                  <tr className={i % 2 ? '' : 'bg-gray-200'} key={trans.id}>
                    <td className='px-4 text-center md:px-8'>
                      {trans.status === 'pending'
                        ? 'Pending'
                        : new Date(trans.creation_time)
                          .toLocaleString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                          })}
                    </td>
                    <td className='py-6 font-medium md:py-8'>{trans.statement_memo}</td>
                    <td className='hidden font-bold text-center md:table-cell'>
                      {trans.direction === TRANSACTION_DIRECTION.CREDIT &&
                        `+${currency(trans.current_amount.amount / 100)}`}
                    </td>
                    <td className='hidden font-bold text-center md:table-cell'>
                      {trans.direction === TRANSACTION_DIRECTION.DEBIT &&
                        `-${currency(trans.current_amount.amount / 100)}`}
                    </td>
                    <td className='pr-4 font-bold text-right md:hidden'>
                      {`${trans.direction === TRANSACTION_DIRECTION.CREDIT ? '+' : '-'}${currency(
                        trans.current_amount.amount / 100
                      )}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='flex flex-row flex-wrap mt-5 md:flex-row'>
              <Button
                className='mr-4 mb-4 md:mb-0'
                disabled={!firstPage}
                onClick={() => callback(PAGE_ACTIONS.FIRST)}
                leftArrow
                primary
                text
              >
                First Page
              </Button>
              <div className='flex flex-row justify-between flex-basis-100 md:flex-basis-auto'>
                <Button
                  className='mr-3'
                  disabled={!prevPage}
                  onClick={() => callback(PAGE_ACTIONS.PREV)}
                  leftArrow
                  primary
                  text
                >
                  Previous Page
                </Button>
                <Button
                  disabled={!nextPage}
                  onClick={() => callback(PAGE_ACTIONS.NEXT)}
                  arrow
                  primary
                  text
                >
                  Next page
                </Button>
              </div>
            </div>
          </>
        )
      ) : (
        <Loading className='min-h-[300px]' />
      )}
    </>
  )
}

TransactionsTable.propTypes = {
  callback: PropTypes.func.isRequired,
  firstPage: PropTypes.bool,
  nextPage: PropTypes.bool,
  pendingTransactions: PropTypes.arrayOf(PropTypes.object),
  prevPage: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
}

export default TransactionsTable
