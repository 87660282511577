// ---------------------------------------------------------------------------
// DATE FORMATTING UTILITIES
// substitute for dayjs due to issues with safari compatibility

// TODO: Needs unit testing coverage

/**
 *
 * @typedef {'mm/dd/yyyy'|
 *           'mm/dd/yy'|
 *           'dd/mm/yyyy'|
 *           'mmmm yyyy'|
 *           'iso'
 * } DateFormat
 */

/**
 *
 * @param {Date|string} date   to return formatted string for
 * @param {DateFormat}  format format specification
 */
export function formatDate (date, format = 'mm/dd/yyyy') {
  const _format = format.toLowerCase()
  const _date = new Date(date)

  if (_date.toString()
    .toLocaleLowerCase() === 'invalid date') {
    console.error('Invalid Date Provided. ')
    return
  }

  switch (_format) {
    case 'mm/dd/yyyy': {
      return new Intl.DateTimeFormat('en-US').format(_date)
    }
    case 'mm/dd/yy': {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      }).format(_date)
    }
    case 'dd/mm/yyyy': {
      return new Intl.DateTimeFormat('en-DE').format(_date)
    }
    case 'mmmm yyyy': {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        year: 'numeric',
      }).format(_date)
    }
    case 'yyyy-mm': {
      const month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(_date)
      const year = _date.getFullYear()
      return `${year}-${month}`
    }
    case 'll': {
      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(_date)
    }
    case 'mm/dd/yy hh:mm': {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        month: '2-digit',
        year: '2-digit',
      }).format(_date)
    }
    default: {
      return _date.toISOString()
    }
  }
}

/**
 * Return standard US time format
 *
 * @param   {Date|string} _date       date to get time for
 * @param   {boolean}     showSeconds options to add seconds or not
 * @returns
 */
export function formatTime (_date, showSeconds = false) {
  const date = new Date(_date)
  return showSeconds
    ? date.toLocaleTimeString('en-us')
    : date.toLocaleTimeString('en-us', { hour: 'numeric', minute: '2-digit' })
}

/**
 * Check if date is valid
 *
 * @param   {Date|string} _date check if date is valid
 * @returns {boolean}
 */
export function isValidDate (_date) {
  const date = new Date(_date)
  return !isNaN(date.getTime())
}

// ---------------------------------------------------------------------------
// DATE MANIPULATION UTILITIES

/**
 *
 * @param   {Date|string} date      to remove months from
 * @param   {number}      numMonths number of months to subtract
 * @returns {Date}
 */
export function subtractMonths (date, numMonths) {
  const _date = new Date(date)
  _date.setMonth(_date.getMonth() - numMonths)
  return _date
}

/**
 * Check if the first date is after the second
 *
 * @param   {Date|string} date1 date to check if its after
 * @param   {Date|string} date2 date to compare against
 * @returns
 */
export function dateIsAfter (date1, date2) {
  return new Date(date1).getTime() > new Date(date2).getTime()
}

/**
 * Check if the first date is before the second
 *
 * @param   {Date|string} date1 date to check if its after
 * @param   {Date|string} date2 date to compare against
 * @returns
 */
export function dateIsBefore (date1, date2) {
  return new Date(date1).getTime() < new Date(date2).getTime()
}

/**
 * Convert a database date from UTC to local time
 *
 * @param   {Date|string} date
 * @returns {Date}
 */
export function localDate (date) {
  const d = new Date(date)
  return new Date(Date.UTC(d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()))
}
