/**
 * Store to handle global loading
 */
import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
  initialState: {
    loadingCursor: false,
  },
  name: 'loading',
  reducers: {
    setLoadingCursor: (state, { payload }) => {
      state.loadingCursor = payload
    },
  },
})

export const { setLoadingCursor } = loadingSlice.actions
export default loadingSlice.reducer
