import React from 'react'

import PropTypes from 'prop-types'

import RobitStepper from './RobitStepper'

/**
 * Generates the stepper component
 */
export const Stepper = ({ steps, ...props }) => {
  return <RobitStepper steps={steps} {...props} />
}

Stepper.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The current step 1 indexed, the lower ones will be checked
   */
  currentStep: PropTypes.number,
  /**
   * Bigger spacing between
   */
  large: PropTypes.bool,
  /**
   * Array of steps
   */
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Lower subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Upper title
   */
  title: PropTypes.string,
}

export default Stepper
