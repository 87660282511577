import React, { useState } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

/**
 * This component will animate in/out based on the show prop
 *
 * Currently only animates the maxHeight but more to come!
 * Also sets visibility to hidden to prevent focusing inside
 *
 * @param {Object}  props
 * @param {string}  [props.className]
 * @param {Object}  props.children
 * @param {number}  props.maxHeight
 * @param {boolean} props.show
 */
export const AnimateIn = ({ className, children, maxHeight, show = false }) => {
  const [hidden, setHidden] = useState(!show)

  const classes = classNames(
    'transition-max-height',
    'duration-300',
    'overflow-y-hidden',
    'ease-in-out',
    hidden && 'invisible',
    className
  )

  // Wait for transition to end before hiding
  if (show && hidden) setHidden(false)
  const transitionEnd = event => !show && setHidden(true)

  return (
    <div
      className={classes}
      onTransitionEnd={transitionEnd}
      style={{ maxHeight: show ? maxHeight : 0 }}
    >
      {children}
    </div>
  )
}

AnimateIn.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.node.isRequired,
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * We need maxHeight for any height animations
   */
  maxHeight: PropTypes.number,
  /**
   * Prop to control when to show
   */
  show: PropTypes.bool.isRequired,
}

export default AnimateIn
