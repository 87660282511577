import React from 'react'

import '@/common/styles/tailwind.css'
import '@/common/styles/global.module.scss'

import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router'
import { PersistGate } from 'redux-persist/integration/react'

import { ROUTES } from '@/common/constants'
import { history } from '@/history'
import { store, persistor } from '@/redux/store'
import ActivateCard from '@/views/account/ActivateCard/ActivateCard'
import JoinFriend from '@/views/account/JoinFriend'
import JoinInsider from '@/views/account/JoinInsider/JoinInsider'
import JoinLead from '@/views/account/JoinLead/JoinLead'
import JoinTeamMember from '@/views/account/JoinTeamMember/JoinTeamMember'
import KycQuestions from '@/views/account/KycQuestions'
import LoggedOut from '@/views/account/LoggedOut'
import { Login } from '@/views/account/Login'
import MyAccount from '@/views/account/MyAccount'
import VerifyEmail from '@/views/account/VerifyEmail'
import LeadInvite from '@/views/admin/LeadInvite'
import CreateCardAccountStepFive from '@/views/createCardAccount/StepFive'
import CreateCardAccountStepFour from '@/views/createCardAccount/StepFour'
import CreateCardAccountStepOne from '@/views/createCardAccount/StepOne'
import CreateCardAccountStepThree from '@/views/createCardAccount/StepThree'
import CreateCardAccountStepTwo from '@/views/createCardAccount/StepTwo'
import Dashboard from '@/views/dashboard/Dashboard'
import DisputeTransactions from '@/views/disputeTransactions'
import NetspendError from '@/views/errors/NetspendError'
import PageNotFound from '@/views/errors/PageNotFound'
import ErrorScreen from '@/views/errorScreen/ErrorScreen'
import Notifications from '@/views/notifications/Notifications'
import Profile from '@/views/profile/Profile'
import ReplaceCard from '@/views/replaceCard/ReplaceCard'
import Qa from '@/views/restricted/Qa'
import Statements from '@/views/statements/Statements'
import AddFriend from '@/views/team/AddFriend'
import AddInsider from '@/views/team/AddInsider'
import AddTeamMember from '@/views/team/AddTeamMember'
import MyTeam from '@/views/team/MyTeam'
import RemovePerson from '@/views/team/RemovePerson'
import SetPermissions from '@/views/team/SetPermissions'
import TeamSelection from '@/views/teamSelection/TeamSelection'
import TermsAndConditions from '@/views/termsAndConditions/TermsAndConditions'
import MoveMoney from '@/views/transfer/MoveMoney'
import TransferConfirmation from '@/views/transfer/TransferConfirmation'
import TransferDirect from '@/views/transfer/TransferDirect'
import TransferFunds from '@/views/transfer/TransferFunds'
import TransferReview from '@/views/transfer/TransferReview'
import Footer from '@components/Footer'
import Header from '@components/Header'
import IdleTimerWrapper from '@components/IdleTimerWrapper/IdleTimerWrapper'
import Loading from '@components/Loading'
import RootContainer from '@components/RootContainer'
import ViewContainer from '@components/ViewContainer'

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router history={history}>
        <RootContainer>
          <IdleTimerWrapper>
            <Header />
            <ViewContainer>
              <Switch>
                {/* Catch for login */}
                <Route component={Login} path={ROUTES.LOGIN} />

                <Route component={LeadInvite} path={ROUTES.LEAD_INVITE} />

                <Route component={LoggedOut} path={ROUTES.LOGGED_OUT} exact />
                <Route component={Dashboard} path={ROUTES.DASHBOARD} exact />
                <Route component={MyAccount} path={ROUTES.ACCOUNT} exact />
                <Route component={ActivateCard} path={ROUTES.ACTIVATE_CARD} exact />
                <Route component={Profile} path={ROUTES.PROFILE} exact />
                <Route component={VerifyEmail} path={ROUTES.VERIFY_EMAIL_OTP} exact />
                <Route component={JoinLead} path={ROUTES.JOIN_LEAD} exact />
                <Route component={JoinInsider} path={ROUTES.JOIN_INSIDER} exact />
                <Route component={JoinTeamMember} path={ROUTES.JOIN_TEAM_MEMBER} exact />
                <Route component={JoinFriend} path={ROUTES.JOIN_FRIEND} exact />
                <Route component={AddFriend} path={ROUTES.TEAM_ADD_FRIEND} />
                <Route component={AddInsider} path={ROUTES.TEAM_ADD_INSIDER} />
                <Route component={AddTeamMember} path={ROUTES.TEAM_ADD_TEAM_MEMBER} />
                <Route component={ReplaceCard} path={ROUTES.REPLACE_CARD} />
                <Route component={RemovePerson} path={ROUTES.TEAM_REMOVE_MEMBER_ID} exact />
                <Route component={SetPermissions} path={ROUTES.TEAM_SET_PERMISSIONS_ID} exact />
                <Route component={MyTeam} path={ROUTES.TEAM} />
                <Route component={Notifications} path={ROUTES.NOTIFICATIONS} exact />
                <Route component={Statements} path={ROUTES.STATEMENTS} exact />
                <Route component={TeamSelection} path={ROUTES.TEAM_SELECTION} exact />

                <Route component={KycQuestions} path={ROUTES.KYC_QUESTIONS} exact />
                <Route
                  component={CreateCardAccountStepOne}
                  path={ROUTES.CREATE_CARD_ACCOUNT.STEP_ONE}
                  exact
                />
                <Route
                  component={CreateCardAccountStepTwo}
                  path={ROUTES.CREATE_CARD_ACCOUNT.STEP_TWO}
                  exact
                />
                <Route
                  component={CreateCardAccountStepThree}
                  path={ROUTES.CREATE_CARD_ACCOUNT.STEP_THREE}
                  exact
                />
                <Route
                  component={CreateCardAccountStepFour}
                  path={ROUTES.CREATE_CARD_ACCOUNT.STEP_FOUR}
                  exact
                />
                <Route
                  component={CreateCardAccountStepFive}
                  path={ROUTES.CREATE_CARD_ACCOUNT.STEP_FIVE}
                  exact
                />
                <Route component={TransferDirect} path={ROUTES.TRANSFER_DIRECT} exact />
                <Route component={TransferConfirmation} path={ROUTES.TRANSFER_CONFIRMATION} exact />
                <Route component={TransferFunds} path={ROUTES.TRANSFER_FUNDS} exact />
                <Route component={TransferReview} path={ROUTES.TRANSFER_REVIEW} exact />
                <Route component={DisputeTransactions} path={ROUTES.DISPUTE_TRANSACTIONS} exact />
                <Route component={TermsAndConditions} path={ROUTES.TERMS_AND_CONDITIONS} exact />
                <Route component={MoveMoney} path={ROUTES.MOVE_MONEY} exact />
                <Route component={NetspendError} path='/error/bank' />
                <Route component={ErrorScreen} path='/error*' />
                <Route component={Qa} path={ROUTES.RESTRICTED_QA} exact />
                <Route component={PageNotFound} />
                <Route component={PageNotFound} path={ROUTES.ERROR404} />
              </Switch>
            </ViewContainer>
            <Footer />
          </IdleTimerWrapper>
        </RootContainer>
      </Router>
    </PersistGate>
  </Provider>
)
export default App
