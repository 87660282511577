import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  CARD_RECIPIENT_ADDRESS_ENTRY, ENDPOINTS, ROLES, ROUTES,
} from '@/common/constants'
import { useFormFieldTracking, useParseApiErrors, useSubmitForm } from '@/common/hooks'
import { isEmpty } from '@/common/utils'
import { confirmEmailSchema, insiderInvitationSchema } from '@/common/validations'
import { history } from '@/history'
import { selectMyFlocPerson } from '@/redux/user'
import AnimateIn from '@components/AnimateIn'
import Button, { SIGN_TYPES } from '@components/Button'
import FormCheckbox from '@components/FormCheckbox'
import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'
import FormRadio from '@components/FormRadio'
import HiddenPanel from '@components/HiddenPanel'
import Modal, { MODAL_TYPES } from '@components/Modal'
import PageHeader from '@components/PageHeader'

/**
 * The Add Insider page
 * Provides the user a place to create an Insider for a myFloc team.
 */
const AddInsider = props => {
  const [showCardMailDetails, setShowCardMailDetails] = useState(false)
  const [showNickname, setShowNickname] = useState(false)
  const [showEmailPanel, setShowEmailPanel] = useState(false)
  const [showInsiderPrivilegeDetailsModal, setShowInsiderPrivilegeDetailsModal] = useState(false)
  const [insiderPrivilegeAcknowledged, setInsiderPrivilegeAcknowledged] = useState(false)
  const { apiErrors, loading, showValidationToast, submitForm } = useSubmitForm()

  const {
    formState: { errors },
    handleSubmit,
    register,
    trigger,
    watch,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(insiderInvitationSchema.concat(confirmEmailSchema)) })

  const { reset: resetFieldTracking, updatedFields } = useFormFieldTracking(watch)
  const { apiValidationErrors } = useParseApiErrors(apiErrors, { filter: updatedFields })

  const myflocUser = useSelector(state => state.user.user)
  const myflocPerson = useSelector(selectMyFlocPerson)

  const onSubmit = async formEntry => {
    resetFieldTracking()

    await submitForm(ENDPOINTS.INVITE_INSIDER, {
      payload: {
        ...formEntry,
        inviter: `${myflocUser.firstName} ${myflocUser.lastName}`,
        teamId: myflocPerson.teamId,
      },
      success: {
        redirect: {
          path: ROUTES.DASHBOARD,
        },
        toast: {
          subtitle: `An invitation has been emailed to ${formEntry.firstName} ${formEntry.lastName} to join your myFloc team.`,
          title: 'Success',
        },
      },
    })
  }

  const ignoreDomEvent = event => {
    event.preventDefault()
    return false
  }

  // Show email info panel
  // not sure when exactly to show this so currently after they type a character
  const watchEmail = watch('email', '')
  useEffect(() => {
    setShowEmailPanel(watchEmail?.length > 0)
  }, [watchEmail])

  useEffect(() => {
    setShowInsiderPrivilegeDetailsModal(true)
  }, [])

  return (
    <>
      <PageHeader>Designate a myFloc Insider</PageHeader>

      <div className='relative flex-col text-left main-container'>
        <form onSubmit={handleSubmit(onSubmit, showValidationToast)}>
          <div className='text-sm'>*Required field</div>
          <div className='mt-1 text-lg'>
            Enter their details to send an invitation to join your team. Please use legal first and
            last name (note we do not conduct residency status checks).
          </div>

          <div className='flex flex-row justify-between mt-6'>
            <FormInput
              autoComplete='given-name'
              errors={errors}
              label='First Name'
              name='firstName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
            <div className='mx-2' />
            <FormInput
              autoComplete='family-name'
              errors={errors}
              label='Last Name'
              name='lastName'
              register={register}
              validationErrors={apiValidationErrors}
              required
            />
          </div>
          <AnimateIn maxHeight={200} show={showNickname}>
            <FormInput
              errors={errors}
              label='Nickname (Optional)'
              name='displayName'
              register={register}
              validationErrors={apiValidationErrors}
            />
          </AnimateIn>

          <Button
            className='mb-7 flat-left'
            onClick={() => setShowNickname(!showNickname)}
            sign={showNickname ? SIGN_TYPES.minus : SIGN_TYPES.plus}
            type='button'
            primary
            text
          >
            {showNickname ? 'Hide Nickname' : 'Add Nickname (Optional)'}
          </Button>

          <FormInput
            autoComplete='email'
            errors={errors}
            label='Email Address'
            name='email'
            register={register}
            type={FORM_INPUT_TYPES.email}
            validationErrors={apiValidationErrors}
            required
          />

          <FormInput
            autoComplete='off'
            errors={errors}
            label='Confirm E&#8203;mail Address' // using "email" in the label triggers autocomplete. using zero-width space to break it up.
            name='confirmEmeil' // using "email" in name field triggers autocomplete. using misspelled name as workaround
            onDrop={ignoreDomEvent}
            onPaste={ignoreDomEvent}
            register={register}
            required
          />

          <label className='mb-2'>
            Where should we mail their card?*{' '}
            <Button
              className='inline flat-x'
              onClick={() => setShowCardMailDetails(true)}
              tag='a'
              primary
              text
            >
              More Details
            </Button>
          </label>

          <HiddenPanel setShow={setShowCardMailDetails} show={showCardMailDetails}>
            {CARD_RECIPIENT_ADDRESS_ENTRY(ROLES.INSIDER)}
          </HiddenPanel>

          <FormRadio
            errors={errors}
            name='shipToMember'
            onChange={() => trigger('shipToMember')}
            options={[
              { label: `Send card to ${myflocPerson.role === ROLES.LEAD ? 'me' : 'Lead'}`, value: false },
              { label: 'Send card to Insider', value: true },
            ]}
            register={register}
            validationErrors={apiValidationErrors}
          />

          <FormCheckbox
            className='mt-7'
            id='acknowledgeInsiderPrivileges'
            name='acknowledgeInsiderPrivileges'
            register={register}
            plain
          >
            <>
              I understand that the myFloc Insider will have full management privileges on the
              myFloc Card Account including making transactions on the account, adding and ordering
              cards for other Team Members. You (the myFloc Lead) will remain the sole owner of the
              myFloc Card Account. Please review the{' '}
              <Button
                className='inline flat-x'
                onClick={event => {
                  event.preventDefault() // prevents button clicks from toggling Insider Privileges Acknowledge checkbox
                  setShowInsiderPrivilegeDetailsModal(true)
                }}
                tag='a'
                primary
                text
              >
                full list of myFloc Insider privileges
              </Button>
              .
            </>
          </FormCheckbox>

          <AnimateIn maxHeight={200} show={showEmailPanel}>
            <div className='p-6 mt-7 text-lg text-center rounded-md bg-light-blue'>
              An invitation to join myFloc will be emailed to: <b>{watchEmail}</b>.
            </div>
          </AnimateIn>

          <div className='flex flex-col items-center mt-9'>
            <Button
              disabled={
                !watch('acknowledgeInsiderPrivileges', false) ||
                !isEmpty(errors) ||
                !isEmpty(apiValidationErrors)
              }
              isLoading={loading}
            >
              Send Invitation
            </Button>
            <Button className='mt-6' onClick={() => history.goBack()} type='button' primary text>
              Cancel
            </Button>
          </div>
        </form>

        {/* modal is outside form because close button triggers form submission */}
        <Modal
          cancelCallback={() => {
            if (insiderPrivilegeAcknowledged) return
            history.push(ROUTES.DASHBOARD)
          }}
          cancelText={(!insiderPrivilegeAcknowledged && 'Exit to Summary') || null}
          closeOnBackdropClick={insiderPrivilegeAcknowledged}
          open={showInsiderPrivilegeDetailsModal}
          setOpen={() => setShowInsiderPrivilegeDetailsModal(!showInsiderPrivilegeDetailsModal)}
          successCallback={() => setInsiderPrivilegeAcknowledged(true)}
          successText='Confirm'
          title='myFloc Insider Privileges'
          type={(!insiderPrivilegeAcknowledged && MODAL_TYPES.confirm) || null}
        >
          <p>
            After your myFloc Insider successfully enrolls and joins your myFloc Team, they will
            have the following capabilities:
          </p>
          <ul className='ml-5 w-3/4 list-disc list-outside'>
            <li className='mb-4'>
              Invite myFloc Team Members and myFloc Friends to join your team and resend invitations
              as needed
            </li>
            <li className='mb-4'>Remove myFloc Team Members or myFloc Friends from your team</li>
            <li className='mb-4'>Make purchases from the primary myFloc Card Account</li>
            <li className='mb-4'>Set and modify myFloc Team Member permissions</li>
            <li className='mb-4'>View transactions for all myFloc Team Members</li>
            <li className='mb-4'>
              Move money between the Primary Card Account and myFloc Team Members cards
            </li>
            <li className='mb-4'>View myFloc Card Account statements</li>
            <li className='mb-4'>Dispute transactions for any myFloc Team Member</li>
            <li className='mb-4'>Order card replacements</li>
            <li className='mb-4'>Freeze cards (and remove freeze)</li>
            <li className='mb-4'>
              Receive notifications on important events on the myFloc Card Account
            </li>
          </ul>
        </Modal>
      </div>
    </>
  )
}

export default AddInsider
