import React from 'react'

import PropTypes from 'prop-types'

import Icon from '@/common/components/Icon'
import { formatDate, localDate } from '@/common/utils/dates'

import styles from '../Notifications.module.scss'

/**
 * Notification Alert Component
 *
 * @param   {Object}      props
 * @param   {function}    [props.dismissAlert]
 * @param   {boolean}     [props.hr]
 * @param   {number}      props.index
 * @param   {Object}      props.notification
 * @returns {JSX.Element}
 */
export const NotificationAlert = ({ dismissAlert, hr = false, index, notification }) => {
  const icon = dismissAlert ? (
    <Icon className={styles['alert-icon-warning']} name='warning' />
  ) : (
    <Icon className={styles['notification-icon']} name='notification' />
  )

  // To bold the first bit of the notification
  const formatContent = content => {
    let [first, ...rest] = content.split(':')
    if (rest.length > 0) {
      first += ':'
    }
    return (
      <div>
        <span className='font-medium'>{first}</span>
        <span>{rest}</span>
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-col justify-between md:flex-row'>
        <div className='flex flex-row items-start md:items-center'>
          <div className={styles['alert-icon']}>{icon}</div>
          <div className={styles['notification-text']}>{formatContent(notification.content)}</div>
        </div>
        <div className='flex flex-col items-end mt-4 min-w-max font-bold md:justify-center md:mt-0'>
          {formatDate(localDate(notification.created), 'mm/dd/yy hh:mm')}
          {dismissAlert && (
            <div
              className='flex flex-row cursor-pointer md:flex-grow'
              onClick={dismissAlert({ ...notification, key: index })}
            >
              <Icon
                className={styles['dismiss-alert-icon']}
                height={20}
                name='close_x'
                width={32}
              />{' '}
              Dismiss
            </div>
          )}
        </div>
      </div>
      {hr && <hr className={styles['notification-hr']} />}
    </>
  )
}

NotificationAlert.propTypes = {
  dismissAlert: PropTypes.func,
  hr: PropTypes.bool,
  index: PropTypes.number,
  notification: PropTypes.object,
}
