import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../Button'
import Icon from '../Icon'
import styling from './robitPanel.module.scss'

/**
 * Panels provide a nice way to present information either on the side(desktop) or inline(mobile)
 */
export const RobitPanel = ({
  children,
  className = '',
  href = null,
  icon,
  iconWidth = 40,
  label,
  link = null,
  onClick,
  titleLineOne,
  titleLineTwo,
}) => {
  const classes = classNames(className, styling.container)
  let buttonProps

  if (label && (onClick || link || href)) {
    buttonProps = {
      className: 'inline flat-x my-0',
      link,
      onClick,
      primary: true,
      role: 'button',
      tag: 'a',
      text: true,
      type: 'button',
    }
    if (href) {
      buttonProps = {
        ...buttonProps,
        href,
        rel: 'noreferrer',
        target: '_blank',
      }
    }
  }

  return (
    <div className={classes}>
      {icon && <Icon className='mx-auto mb-5' name={icon} width={iconWidth} />}
      {titleLineOne && <h4 className={classNames(styling.title, 'mb-0')}>{titleLineOne}</h4>}
      {titleLineTwo && <h4 className={styling.title}>{titleLineTwo}</h4>}
      <div className='text-left body-2'>
        {children}
        {buttonProps && <Button {...buttonProps}>{label}</Button>}
      </div>
    </div>
  )
}

RobitPanel.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.any.isRequired,
  /**
   * Any classNames we wish to propogate to this element
   */
  className: PropTypes.string,
  /**
   * If we want an external link, will add target _blank and noreferrer as well
   */
  href: PropTypes.string,
  /**
   * Name of icon from svgs folder
   */
  icon: PropTypes.string,
  /**
   * Width of the icon
   */
  iconWidth: PropTypes.number,
  /**
   * The label for the bottom action button
   */
  label: PropTypes.string,
  /**
   * Make a react router link
   */
  link: PropTypes.string,
  /**
   * Callback for the bottom action button
   */
  onClick: PropTypes.func,
  /**
   * Top line title
   */
  titleLineOne: PropTypes.string,
  /**
   * Bottom line title
   */
  titleLineTwo: PropTypes.string,
}

export default RobitPanel
