import React from 'react'

import Button from '@/common/components/Button'
import Icon from '@/common/components/Icon'
import TransferDepositLinks from '@/common/components/TransferDepositLinks'
import { ROUTES } from '@/common/constants'
import { history } from '@/history'

export const TransferConfirmation = () => {
  return (
    <div className='main-container'>
      <Icon className='w-full' name='transfer_done' />

      <h1 className='mt-12 text-secondary'>Your transfer has been initiated</h1>
      <div className='mt-4 text-lg'>
        Your transfer details, including confirmation number have been emailed to the address on
        file.{' '}
      </div>

      <h4 className='mt-10'>Set up future direct transfers</h4>
      <div className='text-lg'>
        We recommend establishing recurring deposits directly from your bank account to help keep
        your account continuously funded. You can also have other funds such as income from the
        Social Security Administration deposited directly into your myFloc Card Account.
      </div>

      <div className='py-10 px-6 mt-14 rounded-md border shadow-md border-grey'>
        <TransferDepositLinks />
      </div>

      <div className='text-center'>
        <Button className='mt-12' onClick={() => history.push(ROUTES.DASHBOARD)}>
          Return to Summary page
        </Button>
      </div>
    </div>
  )
}

export default TransferConfirmation
