import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { registerWithChange } from '@/common/utils'

import styling from './formCheckbox.module.scss'

/**
 * The `RobitFormCheckbox` component allows for a on/off selection in either a checkbox or toggle display
 *
 * TODO currently not handling errors or errorText correctly, remove from here and move to parent
 */
export const RobitFormCheckbox = React.memo(
  ({
    className,
    children,
    errors,
    errorText,
    inline,
    invalid,
    innerRef,
    id,
    leftColor,
    leftLabel,
    name,
    onChange: onChangeProp,
    required,
    register,
    small,
    styleType = 'primary',
    toggle,
    valid,
    validationErrors,
    ...props
  }) => {
    const containerClasses = classNames(
      className,
      'form-control-container',
      styling['checkbox-container'],
      inline && 'inline',
      props.disabled && 'disabled',
      toggle && styling['as-toggle']
    )

    const labelClasses = classNames(
      styling['form-checkbox'],
      small && styling.smaller,
      valid && styling['is-valid'],
      invalid && styling['is-invalid'],
      toggle && leftColor && styling[`left-${leftColor}`],
      styleType && styling[styleType]
    )

    const inputClasses = classNames(valid && styling['is-valid'], invalid && styling['is-invalid'])

    const { onBlur, ref, onChange } = registerWithChange(name, register, required, onChangeProp)

    return (
      <div className={containerClasses}>
        {leftLabel && toggle ? (
          <label className={`${styling.description} ${styling['left-label']}`} htmlFor={id}>
            {leftLabel}
          </label>
        ) : (
          []
        )}
        <label className={labelClasses}>
          <input
            {...props}
            className={inputClasses}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref ?? innerRef}
            required={required}
            type='checkbox'
          />
          <span className={styling.description}>{children}</span>
          <label aria-hidden='true' className='inline' htmlFor={id} />
        </label>
        <div className='form-control-descenders'>
          {invalid && errorText ? <div className='form-control-error'>{errorText}</div> : []}
          {required && !invalid ? <div className='form-control-required'>Required</div> : []}
        </div>
      </div>
    )
  }
)

RobitFormCheckbox.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * Whether or not the component is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Text to display if the field is invalid.
   */
  errorText: PropTypes.string,
  /**
   * Validation errors object from the front-end
   */
  errors: PropTypes.object,
  /**
   * The input ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The background color when the toggle is inactive (to the left)
   */
  leftColor: PropTypes.string,
  /**
   * A label placed to the left of the toggle to identify the inactive state
   */
  leftLabel: PropTypes.string,
  /**
   * Sets the name for the form hook
   */
  name: PropTypes.string,
  /**
   * Change handler for the checkbox
   */
  onChange: PropTypes.func,
  /**
   * The register function form react hook form
   */
  register: PropTypes.func,
  /**
   * Whether the field is required or not
   */
  required: PropTypes.bool,
  /**
   * Whether it is small (toggle), or not.
   */
  small: PropTypes.bool,
  /**
   * The type of checkbox to render. 'primary' is default; can also choose 'plain'.
   */
  styleType: PropTypes.string,
  /**
   * Whether it is a toggle button, or not.
   */
  toggle: PropTypes.bool,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Validation errors from our API
   */
  validationErrors: PropTypes.object,
}

export default RobitFormCheckbox
