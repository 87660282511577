import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  ENDPOINTS, HTTP_METHODS, MYFLOC_API_HOST_URL, ROUTES,
} from '@/common/constants'
import { useAuthentication, useSubmitForm, useValidateToken } from '@/common/hooks'
import { history } from '@/history'
import { addToast, TOAST_TYPES, updateAndSwitchUser } from '@/redux'
import { TeamMemberEnrollmentForm } from '@components/EnrollmentForm'
import Loading from '@components/Loading'
import PageHeader from '@components/PageHeader'
import { EnrollmentSecurityPanel } from '@components/Panel'

const JoinTeamMemberAcceptInvite = ({ token }) => {
  const auth0State = useAuth0()
  const {
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
  } = auth0State

  const [isRedirecting, setIsRedirecting] = useState(false)
  const dispatch = useDispatch()
  const nsSession = useSelector(state => state.netspend.session)
  const nsSessionLoaded = useSelector(state => state.netspend.sessionLoaded)

  const { decoded, loading, existingUser } = useValidateToken({ token })
  const { enrollmentLogin } = useAuthentication()
  const { apiErrors, setLoading, submitForm } = useSubmitForm()

  const onSubmit = async formData => {
    const { mailToResidence, ...payload } = formData

    // set country code for residence address
    payload.residenceAddress.country = 'US'

    if (decoded.shipToMember) {
      if (mailToResidence) {
        // use residence address as shipping address
        payload.shippingAddress = payload.residenceAddress
      }
      else {
        // add country to user-entered shipping address
        payload.shippingAddress.country = 'US'
      }
    }

    const createMemberResponse = await submitForm(ENDPOINTS.ACCEPT_MEMBER, {
      manualLoading: true,
      method: HTTP_METHODS.PUT,
      payload: {
        ...payload,
        token,
      },
    })

    if (createMemberResponse.response.status !== 201) {
      setLoading(false)

      if (createMemberResponse.data.error?.validationErrors) {
        dispatch(
          addToast({
            subtitle: 'Please review submitted information.',
            title: 'Could Not Create Account',
            type: TOAST_TYPES.error,
          })
        )
      }
      else {
        history.push('/error-join-team-failed')
      }

      return
    }

    dispatch(updateAndSwitchUser(createMemberResponse.data))

    const myFlocUser = createMemberResponse.data
    const myFlocMemberId = myFlocUser.personsList[0]
    const accessToken = myFlocUser.auth?.access_token || localStorage.getItem('auth0Token')

    // create netspend person
    const [MM, DD, YYYY] = payload.dob.split('/')
    const encryptedData = await window.NetspendSDK.session.encrypt({
      date_of_birth: `${YYYY}-${MM}-${DD}`,
    })

    const netspendPersonPayload = {
      encryptedData,
      firstName: payload.firstName,
      lastName: payload.lastName,
      residenceAddress: payload.residenceAddress,
      token,
    }

    if (payload.shippingAddress) {
      netspendPersonPayload.shippingAddress = payload.shippingAddress
    }

    // note: using fetch because submitForm is highly tied to a logged-in user
    //       and doing an enrollment login will cause this component to be
    //       unmounted then remounted with a wiped state. bugs galore.
    const createNetspendPersonResponse = await fetch(
      `${MYFLOC_API_HOST_URL}/v1${ENDPOINTS.NETSPEND_PERSONS}/${myFlocMemberId}`,
      {
        body: JSON.stringify(netspendPersonPayload),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'session-id': nsSession.id,
        },
        method: HTTP_METHODS.POST,
      }
    )

    if (createNetspendPersonResponse.status !== 200) {
      // return history.push(ROUTES.NETSPEND_ERROR(NETSPEND_ERROR_MESSAGES.GENERIC))
      return history.push(ROUTES.ERROR_ENROLLMENT)
    }

    if (!auth0Authenticated) {
      await enrollmentLogin(createMemberResponse)
      history.push('/')
    }
    else {
      window.location.href = '/'
    }
  }

  // handle errors
  useEffect(() => {
    if (!token) {
      history.push(ROUTES.ERROR_ENROLLMENT)
    }
  }, [token])

  const login = (returnTo = `${window.location.pathname}${window.location.search}`) => {
    loginWithRedirect({ appState: { returnTo } })
  }

  let firstName = decoded?.firstName || ''
  let lastName = decoded?.lastName || ''
  if (existingUser?.user) {
    if (!auth0Authenticated && !isRedirecting && !auth0Loading && !loading) {
      setIsRedirecting(true)
      login()
      return null
    }
    firstName = existingUser.user.firstName
    lastName = existingUser.user.lastName
  }

  return (
    <>
      <PageHeader>Enter your personal details</PageHeader>
      <div className='relative flex-col text-left main-container'>
        {
          (!decoded || !nsSession || loading || auth0Loading || isRedirecting || !nsSessionLoaded)
            ? <Loading className='min-h-screen' />
            : <TeamMemberEnrollmentForm
              apiErrors={apiErrors}
              loadPasswordField={!existingUser?.user?.id}
              loadPhoneNumberField={!existingUser?.user?.id}
              loadShippingFields={decoded.shipToMember}
              onSubmit={onSubmit}
              prefill={{
                email: existingUser?.user?.email || decoded.email,
                firstName,
                lastName,
              }}
              teamName={existingUser?.teamName || ''}
            />
        }
        <EnrollmentSecurityPanel />
      </div>
    </>
  )
}

JoinTeamMemberAcceptInvite.propTypes = {
  token: PropTypes.string.isRequired,
}

export default JoinTeamMemberAcceptInvite
