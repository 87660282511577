/**
 * Collection of error constants, messages, and codes
 */

export const CUSTOM_ERROR_MESSAGES = {
  ACCOUNT_BLOCKED: 'Your myFloc Card Account has been **blocked.**',
  ACCOUNT_BLOCKED_LEAD: 'The myFloc Card Account has been **blocked.**',
  ENROLLMENT: '**We are sorry**. Your enrollment cannot be completed online.&tryAgain=false',
  GENERIC: '**Apologies**, we are unable to complete your request at this time.',
  GENERIC_HARD:
    '**Apologies**, we are unable to complete your request at this time.&tryAgainHard=true.',
  GENERIC_LATER:
    '**Apologies**, we are unable to complete your request at this time. Please try again later.',
  GENERIC_TRY_AGAIN:
    '**Apologies**, we are unable to complete your request at this time.&tryAgain=true',
  INSTITUTION_NO_LONGER_SUPPORTED:
    '**Apologies**, this institution is no longer supported. Please link a new bank to utilize this feature.',
  INVITATION_EXPIRED:
    '**We are sorry.** This invitation is no longer valid. Please contact the myFloc account holder for a new invitation.',
  INVITATION_FOR_INCORRECT_USER:
    '**We are sorry.** This invitation is not for you. Please contact the myFloc account holder for a new invitation.',
  MOVE_MONEY:
    'Please add at least one **myFloc team member** in order to move money.&tryAgain=true',
  OFFER_EXPIRED:
    '**We are sorry.** This offer is no longer valid. Please contact the myFloc account holder for a new offer.',
}

export const NETSPEND_ERROR_CODES = {
  IDENTITY_VERIFICATION_QUESTIONS_NOT_AVAILABLE: 'identity_verification_questions_not_available',
}

export const NETSPEND_ERROR_MESSAGES = {
  COUNTRY_NOT_SUPPORTED: CUSTOM_ERROR_MESSAGES.GENERIC,
  GENERIC: CUSTOM_ERROR_MESSAGES.GENERIC,
  GET_CARD_ACH: CUSTOM_ERROR_MESSAGES.GENERIC,
  GET_KYC: CUSTOM_ERROR_MESSAGES.ENROLLMENT,
  GET_KYC_EXPIRED: CUSTOM_ERROR_MESSAGES.ENROLLMENT,
  INSTITUTION_DOWN: CUSTOM_ERROR_MESSAGES.GENERIC_LATER,
  INSTITUTION_NO_LONGER_SUPPORTED: CUSTOM_ERROR_MESSAGES.INSTITUTION_NO_LONGER_SUPPORTED,
  INSTITUTION_NOT_FOUND: CUSTOM_ERROR_MESSAGES.INSTITUTION_NO_LONGER_SUPPORTED,
  INSTITUTION_NOT_RESPONDING: CUSTOM_ERROR_MESSAGES.GENERIC_LATER,
  INVALID_CREDENTIALS: 'Your **username or password** was incorrect.',
  INVALID_MFA: CUSTOM_ERROR_MESSAGES.GENERIC,
  INVALID_SEND_METHOD: CUSTOM_ERROR_MESSAGES.GENERIC,
  ITEM_LOCKED: CUSTOM_ERROR_MESSAGES.GENERIC,
  ITEM_NOT_SUPPORTED: CUSTOM_ERROR_MESSAGES.GENERIC,
  MFA_NOT_SUPPORTED: CUSTOM_ERROR_MESSAGES.GENERIC,
  NO_CONNECTION: CUSTOM_ERROR_MESSAGES.GENERIC,
  OPENING_MICROAPP: CUSTOM_ERROR_MESSAGES.GENERIC,
  PAYMENT_INVALID_RECIPIENT: CUSTOM_ERROR_MESSAGES.GENERIC,
  RECAPTCHA_BAD: CUSTOM_ERROR_MESSAGES.GENERIC,
  USER_CANCELLED: 'You have closed the bank linking tool.',
  USER_REJECTED: CUSTOM_ERROR_MESSAGES.ENROLLMENT,
  USER_SETUP_REQUIRED: CUSTOM_ERROR_MESSAGES.GENERIC,
}
