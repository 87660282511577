import React from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { removeToast, TOAST_TYPES } from '@/redux/toasts'

import Icon from '../Icon'

/**
 * Toast component
 * Completely handles toasts from the store, no props required
 */
export const Toasts = () => {
  const toasts = useSelector(state => state.toasts.list)
  const dispatch = useDispatch()

  const handleClose = id => {
    dispatch(removeToast(id))
  }

  /**
   * You will see a `findDOMNode is deprecated in StrictMode` error in the console
   * https://github.com/reactjs/react-transition-group/issues/687
   * tried the workarounds but they break the animation, so going to leave it for now
   */
  return (
    <TransitionGroup>
      {toasts.map(toast => (
        <CSSTransition classNames='anim-toast' key={toast.id} timeout={300}>
          <PureToast handleClose={handleClose} key={toast.id} {...toast} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

/**
 * The presentation component of toast
 *
 * @param {Object}   options
 * @param {function} options.handleClose
 * @param {number}   options.id
 * @param {string[]} options.subtitles
 * @param {string}   options.title
 * @param {string}   options.type
 */
export const PureToast = ({ handleClose, id, subtitles, title, type }) => {
  let bodyClasses = 'flex flex-row flex-grow justify-between '
  let iconContainerClasses = 'flex flex-col items-center justify-center p-4 md:p-6 '
  let iconName = 'info'

  if (type === TOAST_TYPES.error) {
    iconName = 'warning'
    iconContainerClasses += 'bg-error'
    bodyClasses += 'bg-light-red'
  }
  else if (type === TOAST_TYPES.notificationToast) {
    iconContainerClasses += 'bg-mid-blue'
    bodyClasses += 'bg-light-blue'
  }
  else {
    iconContainerClasses += 'bg-mid-blue'
    bodyClasses += 'bg-light-blue'
  }

  // Add spacer between multiple subtitles
  const subtitleClasses = index =>
    subtitles.length > 1 && index !== 0 ? 'text-lg mt-2.5 md:mt-0 md:ml-1' : 'text-lg'

  return (
    <div className='flex absolute right-0 left-0 z-10 flex-row mx-auto w-full shadow-toast max-w-8xl'>
      <div className={iconContainerClasses}>
        <Icon height={34} name={iconName} width={34} />
      </div>
      <div className={bodyClasses}>
        <div className='flex flex-col py-8 px-5 md:py-4'>
          <h3 className='mb-1'>{title}</h3>

          {/* Loop on subtitles for multi line */}
          <div className='flex flex-col md:flex-row'>
            {subtitles.map((subtitle, index) => {
              return (
                <div className={subtitleClasses(index)} key={subtitle}>
                  {subtitle}
                </div>
              )
            })}
          </div>
        </div>
        <div className='py-8 pr-7 pl-3'>
          <Icon
            className='cursor-pointer'
            height={20}
            name='close_x'
            onClick={() => handleClose(id)}
            width={20}
          />
        </div>
      </div>
    </div>
  )
}

PureToast.propTypes = {
  /**
   * The close function to trigger the inProp
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Unique integer
   */
  id: PropTypes.number,
  /**
   * Lower subtitle
   */
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Upper title
   */
  title: PropTypes.string.isRequired,
  /**
   * The type of toast, error or success
   */
  type: PropTypes.oneOf([TOAST_TYPES.error, TOAST_TYPES.success]).isRequired,
}

export default Toasts
