import React from 'react'

import PropTypes from 'prop-types'

import { EXTERNAL_URLS } from '@/common/constants'

import RobitPanel from './RobitPanel'

export const PANEL_TYPES = {
  info: 'info',
  link: 'link',
  transfer: 'transfer',
}

/**
 * A wrapper around the RobitPanel component which provides some default values based on the type
 */
export const Panel = ({ className, type = 'info' }) => {
  let body = (
    <>
      <span className='font-medium text-secondary'>myFloc</span> uses bank level encryption to
      ensure your data is safe, secure, and private.
    </>
  )
  let icon = 'safe'
  let iconWidth = 36
  let titleLineOne = 'Your information is'
  let titleLineTwo = 'safe and secure.'
  let href

  if (type === PANEL_TYPES.info) {
    href = EXTERNAL_URLS.FAQ.BASE
  }
  else if (type === PANEL_TYPES.transfer) {
    body = (
      <>
        myFloc uses trusted providers, <span className='font-medium text-secondary'>NetSpend</span>{' '}
        and <span className='font-medium text-secondary'>Plaid</span>, to provide encrypted, secure,
        and private transactions. Your banking login credentials are never visible to myFloc. Our
        priority is your financial safety.
      </>
    )
    icon = 'pin'
    iconWidth = 46
    href = EXTERNAL_URLS.FAQ.BASE
    titleLineOne = 'Safely transfer money'
    titleLineTwo = 'from your bank account'
  }

  return (
    <RobitPanel
      className={className}
      href={href}
      icon={icon}
      iconWidth={iconWidth}
      titleLineOne={titleLineOne}
      titleLineTwo={titleLineTwo}
    >
      {body}
    </RobitPanel>
  )
}

Panel.propTypes = {
  /**
   * Any classNames we wish to propogate to this element
   */
  className: PropTypes.string,
  /**
   * The type of panel to show, defaults to info, use the TYPES enum
   */
  type: PropTypes.oneOf(['info', 'link', 'transfer']),
}

export default Panel
