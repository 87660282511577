import React from 'react'

import { PDFS, ROUTES } from '@/common/constants'
import { history } from '@/history'
import Button from '@components/Button'

/**
 * Simple dumb component for factoring out these links
 *
 * @returns {JSX.Element}
 */
export const TransferDepositLinks = () => {
  return (
    <>
      <div className='text-lg'>
        <span className='font-bold text-secondary'>External Bank Transfers:</span> Add money to your
        myFloc Card Account directly from your bank account using an external bank transfer.
      </div>

      <div className='mt-4 mb-8 text-center'>
        <Button onClick={() => history.push(ROUTES.TRANSFER_DIRECT)}>
          View Bank Transfer Instructions
        </Button>
      </div>

      <div className='text-lg'>
        <span className='font-bold text-secondary'>Direct Deposit:</span> Use a Direct Deposit Form
        to have all or a portion of other income sources deposited directly to your myFloc Card
        Account.
      </div>

      <div className='mt-4 text-center'>
        <Button href={PDFS.DIRECT_DEPOSIT_FORM} tag='a' target='_blank'>
          Get a Direct Deposit Form
        </Button>
      </div>
    </>
  )
}

export default TransferDepositLinks
