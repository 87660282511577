import React from 'react'

import Button from '@/common/components/Button'
import Error from '@/common/components/Error'
import { MYFLOC_PHONE_NUMBER, MYFLOC_PHONE_NUMBER_TEXT, ROUTES } from '@/common/constants'
import { history } from '@/history'

const PageNotFound = props => {
  const title = (
    <>
      <span className='text-accent'>Apologies,</span> we cannnot find the page.
    </>
  )

  const body = (
    <div className='text-center'>
      <Button onClick={() => history.replace(ROUTES.DASHBOARD)}>Return to Summary</Button>
      <h3 className='mt-9 text-center' key='firstMessage'>
        Please contact myFloc Customer Care at{' '}
        <Button
          className='inline flat-x'
          href={'tel:' + MYFLOC_PHONE_NUMBER}
          tag='a'
          secondary
          text
        >
          {MYFLOC_PHONE_NUMBER} ({MYFLOC_PHONE_NUMBER_TEXT})
        </Button>{' '}
        for support.
      </h3>
    </div>
  )

  return <Error body={body} title={title} />
}

export default PageNotFound
