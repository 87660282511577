import React from 'react'

import PropTypes from 'prop-types'

import { currency } from '@/common/utils'

/**
 * Factored out available balance card
 *
 * @param   {Object}      props
 * @param   {Object[]}    props.balances
 * @param   {Object}      props.limits
 * @param   {number}      props.limits.amount
 * @param   {string}      props.limits.period
 * @param   {JSX.Element} [props.children]
 * @returns {JSX.Element}
 */
export const AvailableBalance = ({ balances, limits, children }) => (
  <div className='flex flex-col p-7 bg-white rounded-md drop-shadow-sm'>
    <h3>Available Balance</h3>
    {balances.map(balance => (
      <div key={balance.title}>
        <div className='flex flex-row items-center mt-4'>
          <div>{balance.title}</div>
          {/* <Icon className='ml-2' name='info_bubble' width={14} /> */}
        </div>
        <div className='text-1-75xl text-secondary'>{currency(balance.amount / 100)}</div>
      </div>
    ))}
    {limits.amount && (
      <div>
        <div className='flex flex-row items-center mt-4'>
          <div>Spending Limit</div>
        </div>
        <div className='text-1-75xl text-secondary'>
          {currency(limits.amount / 100)} <span className='text-base'>per {limits.period}</span>
        </div>
      </div>
    )}

    {children}
  </div>
)

AvailableBalance.propTypes = {
  addFundsButton: PropTypes.bool,
  balances: PropTypes.arrayOf(PropTypes.object).isRequired,
  limits: PropTypes.object,
}

export default AvailableBalance
