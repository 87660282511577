import React, { useEffect, useRef } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import FormInput, { FORM_INPUT_TYPES } from '@components/FormInput'

export const FormPassword = ({ apiErrors, className = '', controlName = 'password1', errors, passwordErrors, register, validatePassword }) => {
  const mappings = {
    password1: {
      label: 'Create Password',
      name: 'password',
    },
    password2: {
      label: 'Confirm Password',
      name: 'password2',
    },
  }
  const validationStepClasses = validation => {
    return validation ? 'circle-success mr-2.5' : 'circle-error mr-2.5'
  }
  const ignoreDomEvent = event => {
    event.preventDefault()
    return false
  }

  const passwordRef = useRef(null)

  useEffect(() => {
    if (!passwordRef.current) return

    const validatePasswordOnChange = ev => validatePassword(ev, errors)

    // validate password when input field is updated
    passwordRef.current.addEventListener('input', validatePasswordOnChange)

    const unsubscribe = () => {
      passwordRef.current?.removeEventListener?.('input', validatePasswordOnChange)
    }

    return unsubscribe
  }, [passwordRef.current])

  return (
    <>
      <FormInput
        autoComplete='off'
        className={classNames('min-h-0', className)}
        errors={errors}
        innerRef={passwordRef}
        label={mappings[controlName].label}
        name={mappings[controlName].name}
        onDrop={ignoreDomEvent}
        onPaste={ignoreDomEvent}
        register={register}
        type={FORM_INPUT_TYPES.password}
        validationErrors={apiErrors?.validationErrors}
        required
      />
      <div className='mt-4 text-sm'>
        <div>Your password must contain:</div>
        <div className='flex flex-row items-center mt-2 large'>
          <div className={validationStepClasses(passwordErrors.atLeast8Char)} />
          At least 8 characters
        </div>
        <div className='flex flex-row items-center mt-2 large'>
          <div className={validationStepClasses(passwordErrors.atLeast3)} />
          At least 3 of the following:
        </div>
        <div className='pl-7'>
          <div className='flex flex-row items-center mt-2'>
            <div className={validationStepClasses(passwordErrors.lowerCase)} />
            Lower case letters (a-z)
          </div>
          <div className='flex flex-row items-center mt-2'>
            <div className={validationStepClasses(passwordErrors.upperCase)} />
            Upper case letters (A-Z)
          </div>
          <div className='flex flex-row items-center mt-2'>
            <div className={validationStepClasses(passwordErrors.numbers)} />
            Numbers (0-9)
          </div>
          <div className='flex flex-row items-center mt-2'>
            <div className={validationStepClasses(passwordErrors.special)} />
            Special characters (ex. !@#)
          </div>
        </div>
      </div>
    </>
  )
}

FormPassword.propTypes = {
  /**
   * The error object returned from the API, may contain field validations
   */
  apiErrors: PropTypes.object,
  /**
   * HTML autocomplete string
   */
  autoComplete: PropTypes.string,
  /**
   * class names
   */
  className: PropTypes.string,
  /**
   * control name
   */
  controlName: PropTypes.string,
  /**
   * Validation errors object from the front-end
   */
  errors: PropTypes.object.isRequired,
  /**
   * Password errors from the hook
   */
  passwordErrors: PropTypes.object.isRequired,
  /**
   * Register method from RHF
   */
  register: PropTypes.func.isRequired,
  /**
   * Validation method from the hook
   */
  validatePassword: PropTypes.func.isRequired,
}

export default FormPassword
