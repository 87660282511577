import React from 'react'

import PropTypes from 'prop-types'

import { formPropTypes } from '@/common/propTypes'
import { traverseObject } from '@/common/utils'

import RobitFormRadio from './RobitFormRadio'

/**
 * A wrapper around the RobitFormRadio component which provides some variations
 * Will automatically handle validation as long as you pass in the error prop, or the
 * validationErrors prop. Preferably both. If either are passed, no need to use the manual
 * valid or errorText props.
 *
 * TODO robitFormRadio actually does not handle validation very will, needs to be rewritten
 *
 * @param {Object}   props
 * @param {Object}   [props.errors]           The error object returned from react hook form
 * @param {String}   [props.errorText]        A string to display under the error hint
 * @param {String}   [props.inputClass]       Classes which will be added to the inputElement
 * @param {Boolean}  [props.invalid]          To manually trigger the invalid state
 * @param {String}   props.name               The name of the field, should match with our back-end schema
 * @param {Array}    props.options            The input options in an array
 * @param {String}   [props.type]             The type of field for special attributes, see the exported TYPES
 * @param {Function} [props.register]         The register function from react hook forms
 * @param {Object}   [props.validationErrors] The validation errors object returned from our API
 */
export const FormRadio = ({
  errors = {},
  errorText = null,
  invalid = false,
  name,
  options,
  register = null,
  validationErrors = {},
  ...props
}) => {
  const fieldError = traverseObject(errors, name)
  const fieldValidationError = traverseObject(validationErrors, name)
  return (
    <RobitFormRadio
      errorText={fieldError?.message || fieldValidationError || errorText}
      invalid={!!fieldError || !!fieldValidationError || invalid}
      name={name}
      options={options}
      register={register}
      {...props}
    />
  )
}

FormRadio.propTypes = {
  ...formPropTypes,
  /**
   * A more traditional Circular radio button
   */
  circular: PropTypes.bool,
  /**
   * Label + value for the radio options
   */
  options: PropTypes.array.isRequired,
}

export default FormRadio
