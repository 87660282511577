import { useEffect } from 'react'

/**
 * Run an async effect and prevent state setting if unmounted
 *
 * @param   {Function} asyncFunction onCallback async effect function to run
 * @param   {Function} onSuccess     success handler that takes result of asyncFunction
 * @returns {void}
 */
export function useAsyncEffect (asyncFunction, onSuccess) {
  useEffect(() => {
    let isMounted = true

    const runFunction = async () => {
      const result = await asyncFunction()

      // Only run success if the component is still mounted
      if (isMounted) {
        onSuccess(result)
      }
    }
    runFunction()

    return () => {
      isMounted = false
    }
  }, [asyncFunction, onSuccess])
}
