import { useEffect, useState } from 'react'

import { debounce } from 'lodash'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return { height, width }
}

/**
 * Simple hook to cache window dimensions and only check on window re-size
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = debounce(
      () => {
        setWindowDimensions(getWindowDimensions())
      },
      200,
      {
        leading: false,
        trailing: true,
      }
    )

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
export default useWindowDimensions
